import { Component, Input } from '@angular/core';

@Component({
  selector: 'campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent {
  @Input() public text: any;
  @Input() public image: string;
  @Input() public title: string;
  @Input() public displayNewIcon: boolean;
}
