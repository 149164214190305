import { Component, Inject, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AnalyticsService } from 'spotlight-ui/app/core/analytics';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { CareConstants } from '../care.constants';
import { ServiceNowCase } from '../../models/recover/ServiceNowCase';
import * as care from '../../actions/care';
import {
  IconConfirmationDialogComponent
} from '../../shared/icon-confirmation-dialog/icon-confirmation-dialog.component';
import { ActionSelectionDialogComponent } from '../action-selection-dialog/action-selection-dialog.component';
import { CareService } from '../care.service';

@Component({
  selector: 'service-now-report',
  templateUrl: './service-now-report.component.html',
  styleUrls: ['./service-now-report.component.scss']
})
export class ServiceNowReportComponent {
  public isMobile$: Observable<boolean>;
  public serviceNowCase$: Observable<ServiceNowCase>;
  public selectedReport$: Observable<any>;
  public serviceNowCaseError$: Observable<string>;
  public serviceNowCaseLoading$: Observable<boolean>;
  public sendWithOffer = CareConstants.withCareOffer;

  public closeReportDialogText: string = CareConstants.closeReportDialogText;

  constructor(private analytics: AnalyticsService,
              private dialog: MatDialog,
              private store: Store<fromRoot.State>,
              private router: Router,
              private careService: CareService,
              @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
              public dialogRef: MatDialogRef<ServiceNowReportComponent>) {
    this.isMobile$ = this.store.select(fromRoot.getIsMobile);
    this.serviceNowCase$ = this.store.select(fromRoot.getCareServiceNowCase);
    this.selectedReport$ = store.select(fromRoot.getSelectedCareReport);
    this.serviceNowCaseError$ = this.store.select(fromRoot.getCareServiceNowCaseError);
    this.serviceNowCaseLoading$ = this.store.select(fromRoot.getCareServiceNowCaseLoading);
  }

  public closeReport() {
    this.dialogRef.close();
  }

  public acknowledgeReport() {
    this.serviceNowCase$.pipe(take(1)).subscribe((serviceNowCase) => {
      this.analytics.sendMParticleEvent(
        'Report Acknowledged',
        { number: serviceNowCase.number }
      );
      this.store.dispatch(new care.AcknowledgeReport({
        id: serviceNowCase.number
      }));
      this.closeReport();
    });
  }

  public reassignReport() {
    this.serviceNowCase$.pipe(take(1)).subscribe((serviceNowCase) => {
      this.analytics.sendMParticleEvent(
        'Report Reassigned',
        { number: serviceNowCase.number }
      );
      this.store.dispatch(new care.ReassignReport({
        id: serviceNowCase.number,
        locationNumber: ''
      }));
      this.closeReport();
    });
  }

  public goToActionSelection = () : void => {
    this.closeReport();
    this.store.dispatch(new care.CloseAllOpenNotes());

    this.isMobile$.pipe(take(1)).subscribe((isMobile) => {
      this.selectedReport$.pipe(take(1)).subscribe((report) => {
        if (isMobile) {
          this.router.navigate(
            ['/care/action-selection'],
            { queryParams: { incidentId: report.id } }
          );
        } else {
          this.careService.openActionSelectionDialog(report, this.sendWithOffer, true);
        }
      });
    });
  }

  public goToCustomerProfile() {
    this.serviceNowCase$.pipe(take(1)).subscribe((serviceNowCase) => {
      this.selectedReport$.pipe(take(1)).subscribe((selectedReport) => {
        if (selectedReport && selectedReport.eligibleForSpotEngagement) {
          this.analytics.sendMParticleEvent(
            'Customer Profile Viewed From OVCC',
            { 'Customer ID': serviceNowCase.cfaid }
          );
          this.closeReport();
          this.router.navigate(['/customer'], { queryParams: { id: serviceNowCase.cfaid, previousUrl: '/care' } });
        }
      });
    });
  }
}
