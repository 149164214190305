<mat-card class="template-card__card">
  <div *ngIf="isDraft" fxLayout="column" fxLayoutAlign="space-around center">
    <div class="template-card__draft-container" fxLayout="row" fxLayoutAlign="space-evenly center">
      <mat-icon class="template-card__icon" [attr.data-testid]="name + '-edit-button'" (click)="$event.stopPropagation(); editClicked.emit()">
        edit
      </mat-icon>
      <h2 class="template-card__draft-header">{{title}}</h2>
      <mat-icon class="template-card__icon"
                *ngIf="!readOnlyUser"
                [attr.data-testid]="name + '-delete-button'"
                (click)="$event.stopPropagation(); deleteClicked.emit()">
        delete
      </mat-icon>
      <mat-icon class="template-card__icon-disabled"
                *ngIf="readOnlyUser"
                [attr.data-testid]="name + '-delete-button'"
                (click)="$event.stopPropagation();">
        delete
      </mat-icon>
    </div>
    <div class="template-card__meta-data-container" fxLayout="row" fxLayoutAlign="center center">
      <div class="template-card__created-by" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon class="template-card__person-icon">
          person
        </mat-icon>
        <span>{{createdBy}}</span>
      </div>
      <div class="template-card__created-at">
        {{createdAt | locTimeZoneDate:'MMM D, YYYY h:mm A'}}
      </div>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-between center" class="template-card__meta-container">
    <div fxLayout="column" fxLayoutAlign="center center" class="template-card__image-container">
      <img alt="Template Image" class="template-card__image" [src]="imageUrl">
    </div>
    <mat-divider class="template-card__divider"></mat-divider>
    <div class="template-card__item-name-wrapper" [attr.data-testid]="name + '-event'">
      <p class="template-card__item-name">{{name}}</p>
    </div>
    <div class="template-card__description">
      <span [innerHTML]="description"></span>
    </div>
  </div>
</mat-card>
