<div fxLayout="row">
  <div *ngIf="!isMobile" fxFlex="5" fxLayoutAlign="center start">
    <button mat-button
            class="template-preview__back-btn-arrow"
            data-testid="back-button"
            (click)="backBtnClicked.emit()">
      <i class="material-icons">arrow_back</i>
    </button>
  </div>
  <div fxFlex="95">
    <mat-tab-group class="template-preview__tab-group"
                   [selectedIndex]="selectedTabIndex"
                   (selectedTabChange)="selectedTabChanged.emit($event.index)"
                   mat-align-tabs="center">
      <mat-tab *ngIf="showEmailPreview && !showBonusPointPreview" data-testid="email-tab" label="Email">
        <div *ngIf="templateHtml">
          <preview-selection containerId="headerContainer"
                             [isDisabled]="!isChangeThemeEnabled"
                             [elementId]="'cfa-header'"
                             [text]="'Update Your Template'"
                             [html]="templateHtml"
                             (elementClicked)="changeThemeClicked.emit()">
          </preview-selection>
          <preview-selection containerId="changeImageContainer"
                             [isDisabled]="!additionalImagesAvailable() || !isChangeImageEnabled"
                             [elementId]="'cfa-image'"
                             [text]="'Update Image'"
                             [html]="templateHtml"
                             (elementClicked)="changeImageClicked.emit()">
          </preview-selection>
          <preview-selection containerId="copyContainer"
                             [isDisabled]="!isChangeCopyEnabled"
                             [elementId]="'cfa-copy'"
                             [text]="'Update Email Message'"
                             [html]="templateHtml"
                             (elementClicked)="changeCopyClicked.emit()">
          </preview-selection>
          <div class="template-preview__template" [innerHTML]="templateHtml"></div>
        </div>
        <div *ngIf="!templateHtml">
          <img alt='' class="template-preview__image" src='../../../assets/img/preview_placeholder.png'/>
        </div>
      </mat-tab>
      <mat-tab *ngIf="showBonusPointPreview" data-testid="chick-fil-a-tab" label="Chick-fil-A App">
        <img alt='' class="template-preview__bpp-image"
             src='https://www.cfacdn.com/img/Spotlight/Other/BPP-desktop.svg'/>
      </mat-tab>
      <mat-tab *ngIf="showRsvpPreview" label="RSVP Reminder">
        <div *ngIf="rsvpHtml">
          <div class="template-preview__template" [innerHTML]="rsvpHtml"></div>
        </div>
        <div *ngIf="!rsvpHtml">
          <img alt='' class="template-preview__image" src='../../../assets/img/preview_placeholder.png'/>
        </div>
      </mat-tab>
      <mat-tab *ngIf="showRsvpPreview" label="No RSVP Reminder">
        <div *ngIf="noRsvpHtml">
          <div class="template-preview__template" [innerHTML]="noRsvpHtml"></div>
        </div>
        <div *ngIf="!noRsvpHtml">
          <img alt='' class="template-preview__image" src='../../../assets/img/preview_placeholder.png'/>
        </div>
      </mat-tab>
      <mat-tab *ngIf="showAppPreview" label="Chick-fil-A App">
        <img alt='' class="template-preview__reward-message-image"
             src='../../../assets/img/reward-message-preview.png'/>
      </mat-tab>
      <mat-tab *ngIf="showPromotionAppPreview && !isMobile" label="Chick-fil-A App Promotion">
        <img alt='' class="template-preview__bpp-image"
             src='https://www.cfacdn.com/img/Spotlight/Other/BPP-desktop.svg'/>
      </mat-tab>
      <mat-tab *ngIf="showCommunityAppPreview && !isMobile" label="Chick-fil-A App">
        <img alt='' class="template-preview__community-image"
             src='https://www.cfacdn.com/img/Spotlight/CFA_Combined_mobile_previews.svg'/>
      </mat-tab>
      <mat-tab *ngIf="showFreeItemInCartPreview && !isMobile" label="Chick-fil-A App">
        <img alt='' class="template-preview__community-image"
             src='https://www.cfacdn.com/img/Spotlight/Other/FIC_Mobile_combined.png'/>

      </mat-tab>
      <mat-tab *ngIf="showThirdPartyPromotion" label="Third Party Platform View">
        <div fxLayout = "column" fxLayoutAlign = "center">
          <div  fxLayout = "row" fxLayoutAlign = "center">
            <img alt='' class="template-preview__third-party-promotion-image"
                 src='https://www.cfacdn.com/img/Spotlight/Icons/3P_Promotions_App.png'/>
          </div>
          <div  fxLayout = "row" fxLayoutAlign = "center" class = "template-preview__text">
            <span class = "template-preview__bold-font">Note:</span> This image is for demonstrational purpose only, and does not display the exact experience of customers
          </div>

        </div>

      </mat-tab>
      <ng-container *ngIf="showPromotionAppPreview && isMobile">
        <mat-tab label="Preview 1">
          <img alt='' class="template-preview__bpp-image"
               src='https://www.cfacdn.com/img/Spotlight/Other/BPP-mobile-app-confirmation.svg'/>
        </mat-tab>
        <mat-tab label="Preview 2">
          <img alt='' class="template-preview__bpp-image"
               src='https://www.cfacdn.com/img/Spotlight/Other/BPP-mobile-app-destination.svg'/>
        </mat-tab>
      </ng-container>
      <ng-container *ngIf="showCommunityAppPreview && isMobile">
        <mat-tab label="Preview 1">
          <img alt='' class="template-preview__bpp-image"
               src='https://www.cfacdn.com/img/Spotlight/CCE_1.2.0_modal.svg'/>
        </mat-tab>
        <mat-tab label="Preview 2">
          <img alt='' class="template-preview__bpp-image"
               src='https://www.cfacdn.com/img/Spotlight/CCE_3rd_Step.svg'/>
        </mat-tab>
      </ng-container>
      <ng-container *ngIf="showFreeItemInCartPreview && isMobile">
        <mat-tab label="Preview 1">
          <img alt='' class="template-preview__bpp-image"
               src='https://www.cfacdn.com/img/Spotlight/Other/FIC_Select_Delivery.png'/>
        </mat-tab>
        <mat-tab label="Preview 2">
          <img alt='' class="template-preview__bpp-image"
               src='https://www.cfacdn.com/img/Spotlight/Other/FIC_MB.png'/>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </div>
</div>
