<ng-container *ngIf="!(loading$ | async) && !(this.discoverTilesLoading$ | async) && !(error$ | async)">
    <div fxLayout="row" fxLayoutAlign="none center">
        <spot-nav fxFlex="100"
                  [title]="title$ | async"
                  [actionMenu]="false"
                  [isSmallNav]="true"
                  [isMobile]="isMobile$ | async"
                  [hideBackArrow]="!(isMobile$ | async)"
                  (back)="goToDiscover()">
        </spot-nav>
    </div>
    <div class="edit-details__form">
        <template-summary [templateName]="title$ | async"
                          [type]="engagementType"
                          [imageURL]="'../../../assets/img/cfa-logo.png'"
                          [details]="suggestedUseText$ | async"
                          [isMobile]="isMobile$ | async"
                          [isDisabled]="true"
                          [isRoundedBorder]="true">
        </template-summary>

        <div fxLayout="column" fxLayoutAlign="center start">
            <div class="edit-details__header">Customer Selection</div>
            <div *ngIf="(!(editableCustomerSelection$ | async) || (engagementType$ | async) === emailType)" class="edit-details__sub-header">{{maxCustomers$ | async}} Customers Selected</div>
            <div *ngIf="((editableCustomerSelection$ | async) && (engagementType$ | async) !== emailType)">
                <div class="edit-details__sub-header">How many customers ({{minCustomers}}-{{maxCustomers$ | async}}) would you like to engage?</div>
                <mat-form-field  class="edit-details__duration-input">
                    <input matInput
                           maxlength="5"
                           autocomplete="false"
                           [formControl]="customerCountFormControl"
                           [value]="customerCount$ | async"
                           (keypress)="restrictNumeric($event)"
                           (focusout)="updateCustomerCount($event)">
                    <mat-error *ngIf="customerCountFormControl.errors && customerCountFormControl.errors.required">
                        Customers are required
                    </mat-error>
                    <mat-error *ngIf="customerCountFormControl.errors && (customerCountFormControl.errors.min || customerCountFormControl.errors.max)">
                        Customers must be between 1 and {{maxCustomers$ | async}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="(engagementType$ | async) === rewardType && !(tile$ | async)?.automationEnabled"
                 class="edit-details__value">
                {{(tile$ | async)?.customerRewardText}}
            </div>

            <div *ngIf="(engagementType$ | async) === emailType && !(tile$ | async)?.automationEnabled"
                 class="edit-details__value">
                {{(tile$ | async)?.customerEmailText}}
            </div>

            <div *ngIf="(tile$ | async)?.automationEnabled"
                 fxLayout="column"
                 fxLayoutAlign="center start">
                <div *ngIf="expandMore"
                     fxLayout="row"
                     fxLayoutAlign="start center"
                     class="edit-details__expand-section"
                     (click)="expandMore = !expandMore">
                    <span class="edit-details__expand-text">More about these customers</span>
                    <mat-icon class="edit-details__expand-icon">expand_more</mat-icon>
                </div>
                <div *ngIf="!expandMore">
                    <div class="edit-details__about-customers">
                        <div>The customers receiving this reward</div>
                        <div *ngIf="(tile$ | async).helpText && (tile$ | async).helpText.filterText">
                            <ul *ngFor="let filter of (tile$ | async).helpText.filterText;"
                                class="edit-details__expand-filter-text">
                                <li>{{filter}}</li>
                            </ul>
                        </div>
                        <div>This audience is pre-selected because they are most likely to drive long-term profitability. Each customer will only receive this reward once.</div>
                        <div>To send to a different audience, send a reward via Engage.</div>
                    </div>
                    <div fxLayout="row"
                         fxLayoutAlign="start center"
                         class="edit-details__expand-section"
                         (click)="expandMore = !expandMore">
                        <span class="edit-details__expand-text">Less about these customers</span>
                        <mat-icon class="edit-details__expand-icon">expand_less</mat-icon>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="(engagementType$ | async) !== emailType" fxLayout="column" fxLayoutAlign="center start">
            <div class="edit-details__header">Reward Duration (7-30 days)</div>
            <div *ngIf="isStartASunday() && ((engagementType$ | async) !== automationType)"
                 class="edit-details__sunday-text">
                Today is {{today}}; if you choose to continue, this reward and email
                notification will be sent on {{tomorrow}}. Reward campaigns cannot start
                on Sundays.
            </div>
            <mat-form-field class="edit-details__duration-input">
                <input matInput
                       [formControl]="durationFormControl"
                       [value]="duration$ | async"
                       (keypress)="restrictNumeric($event)"
                       (focusout)="updateTreatData()"
                       maxlength="2">
            </mat-form-field>
            <mat-error *ngIf="durationFormControl.errors && durationFormControl.errors.required">
                Duration is required
            </mat-error>
            <mat-error *ngIf="durationFormControl.errors && (durationFormControl.errors.min || durationFormControl.errors.max)">
                Duration must be between 7 and 30
            </mat-error>

            <mat-error *ngIf="(isEndASunday$() | async) && !isOutsideOfRange() && ((engagementType$ | async) !== automationType) && !(isEndDateRestriction$ | async)">
                Uh oh, it looks like {{durationFormControl.value}} days from
                <span *ngIf="!isStartASunday()">now</span>
                <span *ngIf="isStartASunday()">tomorrow</span>
                is {{getSundayEndDate()}} so you'll need
                to try again. Reward campaigns cannot end on Sundays.
            </mat-error>
            <mat-error *ngIf="(isEndDateRestriction$ | async) && !isOutsideOfRange()">
                This reward is only available until {{(endDateRestriction$ | async) | locTimeZoneDate:'MMM DD, YYYY'}}.
                Please pick a duration that falls within the campaign dates.
            </mat-error>
        </div>

        <div *ngIf="((isRewardCategoriesAvailable$ | async) && (engagementType$ | async) !== emailType)"
             fxLayout="column"
             fxLayoutAlign="center start">
            <div class="edit-details__header">Reward Selection</div>
            <ng-container>
                <mat-form-field *ngIf="!(isMobile$ | async)" appearance="outline" class="edit-details__reward-dropdown">
                    <mat-select
                            [formControl]="selectedRewardFormControl"
                            [value]="selectedReward$ | async"
                            (selectionChange)="updateReward($event.value)">
                        <mat-select-trigger>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <img fxFlex class="edit-details__reward-image" src="{{(selectedReward$ | async).offerImage}}" alt="">
                                <span fxFlex class="edit-details__reward-title" [ngClass]="{'edit-details__reward-disabled': (engagementMode$ | async) !== editMode }">{{(selectedReward$ | async).name}}</span>
                            </div>
                        </mat-select-trigger>
                        <div class="edit-details__reward-category">Select the Reward you'd like to send
                            <img alt="Chevron"
                                 class="edit-details__chevron"
                                 src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png"/>
                        </div>
                        <div *ngFor="let category of rewardCategories$ | async">
                            <div *ngIf="(rewardList$ | async).length > 6" class="edit-details__reward-category">{{category.category}}</div>
                            <mat-option *ngFor="let reward of category.rewards" [value]="reward" class="edit-details__reward-option">
                                <div matLine fxLayout="row" fxLayoutAlign="start center">
                                    <div class="edit-details__reward-width">
                                        <img alt="Reward Image" fxflex class="edit-details__reward-image" src="{{reward.offerImage}}"/>
                                    </div>
                                    <span fxFlex class="edit-details__reward-title">{{reward.name}}</span>
                                </div>
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
                <mat-nav-list  *ngIf="(isMobile$ | async)"  class="edit-details__reward-dropdown-mobile">
                        <mat-list-item
                                (click)="openSelectRewardForMB()">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <img fxFlex class="edit-details__reward-image" src="{{(selectedReward$ | async).offerImage}}" alt="">
                                <span fxFlex class="edit-details__reward-title" [ngClass]="{'edit-details__reward-disabled': (engagementMode$ | async) !== editMode }">{{(selectedReward$ | async).name}}</span>
                            </div>
                        </mat-list-item>
                </mat-nav-list>
            </ng-container>
        </div>

        <ng-container>
            <div class="edit-details__header"
                 [ngClass]="{'edit-details__error-missing-field-header': (campaignNameFormControl && campaignNameFormControl.invalid)}">
                Internal Campaign Name
            </div>
            <mat-form-field  class="edit-details__name-input">
                <input matInput
                       maxlength="75"
                       [formControl]="campaignNameFormControl"
                       [value]="campaignName$ | async"
                       (keyup)="onCampaignNameChange($event)">
                <mat-error>
                    Internal Campaign Name is required
                </mat-error>
            </mat-form-field>
        </ng-container>

        <ng-container>
            <div class="edit-details__header"
                 [ngClass]="{'edit-details__error-missing-field-header': (subjectFormControl && subjectFormControl.invalid)}">
                Email Subject
            </div>
            <mat-form-field  class="edit-details__name-input">
                <input matInput
                       type="text"
                       maxlength="75"
                       [formControl]="subjectFormControl"
                       [value]="subject$ | async"
                       (input)="onSubjectChange($event)"/>
                <mat-error>
                    Email Subject is required
                </mat-error>
            </mat-form-field>
        </ng-container>

        <email-copy [headerText]="'Messaging'"
                    [emailCopy]="emailCopy$ | async"
                    [appCopy]="appCopy$ | async"
                    [pushMessageCopy]="appPushCopy$ | async"
                    [emailCopyText]="'Email'"
                    [appCopyText]="'Chick-fil-A App'"
                    [pushMessageText]="'App Push (View Only)'"
                    [displayAppOption]="(engagementType$ | async) !== emailType"
                    [displayPushMessageOption]="isPushNotificationEnabled$ | async"
                    (emailCopyClick)="goToRewardCopy(0)"
                    (appCopyClick)="goToRewardCopy(1)"
                    (pushMessageCopyClick)="goToRewardCopy(2)">
        </email-copy>

        <div *ngIf="(engagementMode$ | async) !== editMode && (engagementType$ | async) !== emailType
                && (tile$ | async)?.automationEnabled"
             fxLayout="column"
             fxLayoutAlign="center start">
            <div class="edit-details__container"
                 fxLayout.gt-sm="row"
                 fxLayoutAlign.gt-sm="space-between center"
                 fxLayout="column"
                 fxLayoutAlign="space-around start">
                <div class="edit-details__header">Automate This Reward</div>
                <button mat-button
                        class="edit-details__automation-dialog-button"
                        (click)="openAutomationHelpDialog()">
                  How do automated rewards work?
                </button>
            </div>
            <mat-checkbox class="edit-details__checkbox" [ngModel]="(engagementType$ | async) === automationType"
                          (change)="automationChanged($event.checked)">
                <div class="edit-details__checkbox-text">
                    Automatically send this reward to eligible customers
                </div>
            </mat-checkbox>
        </div>

        <div *ngIf="(engagementMode$ | async) !== editMode && !(isMobile$ | async)"
             fxLayout="row"
             fxLayoutAlign="end center"
             class="edit-details__button-container">
            <button *ngIf="(engagementType$ | async) === rewardType"
                    mat-raised-button
                    class="edit-details__send-button"
                    fxFlex="45"
                    fxFlexAlign="end"
                    [ngClass]="{'edit-details__send-button-disabled': editForm.invalid}"
                    [disabled]="(editForm.invalid || (isEndDateRestriction$ | async) || (isEndASunday$() | async) || (readOnlyUser$ | async))"
                    (click)="sendReward()">
                Send Reward
            </button>
            <button *ngIf="(engagementType$ | async) === automationType"
                    mat-raised-button
                    class="edit-details__send-button"
                    fxFlex="45"
                    fxFlexAlign="end"
                    [ngClass]="{'edit-details__send-button-disabled': editForm.invalid}"
                    [disabled]="(editForm.invalid || (isEndDateRestriction$ | async) || (readOnlyUser$ | async))"
                    (click)="sendAutomation()">
                Automate Reward
            </button>
            <button *ngIf="(engagementType$ | async) === emailType"
                    mat-raised-button
                    class="edit-details__send-button"
                    fxFlex="45"
                    fxFlexAlign="end"
                    [ngClass]="{'edit-details__send-button-disabled': editForm.invalid}"
                    [disabled]="(editForm.invalid || (readOnlyUser$ | async))"
                    (click)="sendEmail()">
                Send Email
            </button>
        </div>

        <div *ngIf="(engagementMode$ | async) === editMode && !(isMobile$ | async)"
             class="edit-details__button-container"
             fxLayout="row"
             fxLayoutAlign="end center">
            <button mat-raised-button
                    class="edit-details__send-button"
                    [disabled]="readOnlyUser$ | async"
                    (click)="stopAutomation()">
                Stop Automation
            </button>

            <button mat-raised-button
                    class="edit-details__send-button"
                    fxFlexAlign="end"
                    [ngClass]="{'edit-details__send-button-disabled': editForm.invalid}"
                    [disabled]="(editForm.invalid || (isEndDateRestriction$ | async) || (readOnlyUser$ | async))"
                    (click)="update()">
                Save Update
            </button>
        </div>

        <div *ngIf="isMobile$ | async" class="edit-details__mobile-footer" fxLayout="row" fxLayoutAlign="space-between">
            <button mat-raised-button
                    class="edit-details__mobile-action-button"
                    fxFlex="45"
                    (click)="goToDiscover()">
                Back
            </button>
            <button mat-raised-button
                    class="edit-details__mobile-send-button"
                    fxFlex="45"
                    fxFlexAlign="end"
                    [ngClass]="{'edit-details__send-button-disabled': editForm.invalid}"
                    [disabled]="editForm.invalid || (isEndDateRestriction$ | async) || ((isEndASunday$() | async) && !((engagementType$ | async) === automationType))"
                    (click)="goToPreviewPage()">
                Preview
            </button>
        </div>
    </div>
</ng-container>
