<div fxLayout="row" fxLayoutAlign="center end" class="vertical-bar">
  <div *ngFor="let row of chartData" fxLayout="column" fxLayoutAlign="end center" [ngStyle]="{'width': barWidth, 'height':'100%'}" >
    <div fxLayout="column" fxLayoutAlign="start center"
     [ngClass]="getData(row, listValueProp) !== 0 ? 'vertical-bar__fill' : 'vertical-bar__empty-fill'"
     [ngStyle]="{'height': getBarPercentage(row, listValueProp),
                 'width': '85%',
                 'background-color': getData(row, listValueProp) !== 0 ? setColor(row) : '',
                 'border-color': getData(row, listValueProp) === 0 ? setColor(row) : ''
                }">
        <div [ngClass]="getData(row, listValueProp) !== 0 ? 'vertical-bar__value' : 'vertical-bar__empty-value'">
          {{getData(row, listValueProp)}}
        </div>
    </div>
    <div class="vertical-bar__title-{{category}}">
       {{getData(row, listTitleProp)}}
    </div>
  </div>
</div>
