import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { findIndex, propEq } from 'ramda';
import { AnalyticsService } from '../../core/analytics';

import * as fromRoot from '../../reducers';
import { SharedConstants } from '../shared.constants';
import { NoteComponent } from '../note/note.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IconConfirmationDialogComponent } from '../icon-confirmation-dialog/icon-confirmation-dialog.component';
import * as care from '../../actions/care';

@Component({
  selector: 'activity-history-with-notes',
  templateUrl: 'activity-history-with-notes.component.html',
  styleUrls: ['./activity-history-with-notes.component.scss']
})

export class ActivityHistoryWithNotesComponent {
  @ViewChildren(NoteComponent, { read: NoteComponent }) noteComponents: QueryList<NoteComponent>;

  @Input()
  set activityHistory(history: any[]) {
    this.allRecords = history;
    this.displayRecords = this.allRecords.slice(0, this.pageSize);
  }

  @Input() public userName: string;
  @Input() public customerId: string;
  @Input() public noResultsText: string;
  @Input() public statusVariable: string;
  @Input() public dateLabel: string;
  @Input() public readonly: boolean;
  @Input() public loading: boolean;
  @Input() public error: string;
  @Input() public openCustomerNote: boolean = false;
  @Input() public isMobile: boolean = false;

  @Output() public viewEmailDetails = new EventEmitter<any>();
  @Output() public onNewNoteSaveClick = new EventEmitter();
  @Output() public saveNoteClicked = new EventEmitter();

  public pageSize: number = SharedConstants.activityHistoryPageSize;
  public currentPage: number = 0;
  public allRecords = [];
  public displayRecords;
  public unsavedChangesTitle: string = SharedConstants.unsavedChangesTitle;
  public unsavedChangesText: string = SharedConstants.unsavedChangesText;
  public unsavedChangesIcon: string = SharedConstants.unsavedChangesIcon;
  public unsavedChangesConfirm: string = SharedConstants.unsavedChangesConfirmButton;
  public unsavedChangesCancel: string = SharedConstants.unsavedChangesCancelButton;

  constructor(private dialog: MatDialog, public store: Store<fromRoot.State>, private analytics: AnalyticsService) { }

  public getCreatedBy(name): string {
    return name === null ? 'N/A' : name;
  }

  public openNote = (activityId): void => {
    this.store.dispatch(new care.OpenActionHistoryNote());
    let recordIndex = findIndex(propEq('activityId', activityId))(this.allRecords);
    this.allRecords[recordIndex].recoveryNotes = this.allRecords[recordIndex].recoveryNotes === null
      ? [] : this.allRecords[recordIndex].recoveryNotes;

    for (let i = 0; i < this.allRecords.length; i++) {
      const numberOfNotes = this.allRecords[i].recoveryNotes ? this.allRecords[i].recoveryNotes.length : 0;

      for (let j = 0; j < numberOfNotes; j++) {
        // Close any unsaved new notes
        if (j === 0 && this.allRecords[i].recoveryNotes[j] && this.allRecords[i].recoveryNotes[j].isNewNote) {
          this.allRecords[i].recoveryNotes.shift();
        }
        // Close any other note that is currently being edited
        if (this.allRecords[i].recoveryNotes[j]) {
          this.allRecords[i].recoveryNotes[j].isEditMode = false;
        }
      }
    }

    if (this.allRecords[recordIndex].recoveryNotes[0] && this.allRecords[recordIndex].recoveryNotes[0].isNewNote) {
      // If there is already an unsaved note open for this activityId then do nothing
    } else {
      this.allRecords[recordIndex].recoveryNotes.unshift({
        note: '',
        cfaUid: this.customerId,
        updatedBy: this.userName,
        updatedText: '',
        isNewNote: true,
        isEditMode: true
      });
    }
  }

  public getNumberOfPages(totalElements) {
    return Math.ceil(totalElements / this.pageSize);
  }

  public sliceArrBasedOnCurrentPage() {
    const nextRowIndex = this.currentPage * this.pageSize;
    if (nextRowIndex + this.pageSize < this.allRecords.length) {
      this.displayRecords = this.allRecords.slice(nextRowIndex, nextRowIndex + this.pageSize);
    } else {
      this.displayRecords = this.allRecords.slice(nextRowIndex, this.allRecords.length);
    }
  }

  public pageLeft() {
    this.currentPage--;
    this.sliceArrBasedOnCurrentPage();
  }

  public pageRight() {
    this.currentPage++;
    this.sliceArrBasedOnCurrentPage();
  }

  public editNote = (activityId, noteIndex) : void => {
    this.store.dispatch(new care.OpenActionHistoryNote());
    const recordIndex = findIndex(propEq('activityId', activityId))(this.allRecords);
    let closedNewNote = false;

    for (let i = 0; i < this.allRecords.length; i++) {
      const numberOfNotes = this.allRecords[i].recoveryNotes ? this.allRecords[i].recoveryNotes.length : 0;

      for (let j = 0; j < numberOfNotes; j++) {
        // Close any unsaved new notes
        if (j === 0 && this.allRecords[i].recoveryNotes[j] && this.allRecords[i].recoveryNotes[j].isNewNote) {
          this.allRecords[i].recoveryNotes.shift();
          if (i === recordIndex) {
            closedNewNote = true;
          }
        }
        // Close any other note that is currently being edited
        if (!(i === recordIndex && j === noteIndex) && this.allRecords[i].recoveryNotes[j]) {
          this.allRecords[i].recoveryNotes[j].isEditMode = false;
        }
      }
    }

    if (closedNewNote) {
      this.allRecords[recordIndex].recoveryNotes[noteIndex - 1].isEditMode = true;
      this.allRecords[recordIndex].recoveryNotes[noteIndex - 1].error = null;
      this.allRecords[recordIndex].recoveryNotes[noteIndex - 1].updatedText
        = this.allRecords[recordIndex].recoveryNotes[noteIndex - 1].note;
    } else {
      this.allRecords[recordIndex].recoveryNotes[noteIndex].isEditMode = true;
      this.allRecords[recordIndex].recoveryNotes[noteIndex].error = null;
      this.allRecords[recordIndex].recoveryNotes[noteIndex].updatedText = this.allRecords[recordIndex].recoveryNotes[noteIndex].note;
    }
  }

  public updateNoteText(text, activityId, noteIndex) {
    let recordIndex = findIndex(propEq('activityId', activityId))(this.allRecords);
    this.allRecords[recordIndex].recoveryNotes[noteIndex].updatedText = text;
  }

  public cancelEdit = (note, activityId, noteIndex) : void => {
    this.store.dispatch(new care.CloseActionHistoryNote());
    if (note.isNewNote) {
      let recordIndex = findIndex(propEq('activityId', activityId))(this.allRecords);
      this.allRecords[recordIndex].recoveryNotes.splice(noteIndex, 1);
    } else {
      let recordIndex = findIndex(propEq('activityId', activityId))(this.allRecords);
      this.allRecords[recordIndex].recoveryNotes[noteIndex].isEditMode = false;
      this.allRecords[recordIndex].recoveryNotes[noteIndex].updatedText = this.allRecords[recordIndex].recoveryNotes[noteIndex].note;
    }
  }

  public saveNote(note, activityId, noteIndex) {
    const recordIndex = findIndex(propEq('activityId', activityId))(this.allRecords);
    const selectedNote = this.allRecords[recordIndex].recoveryNotes[noteIndex];
    const updatedNote = { ...selectedNote, note: selectedNote.updatedText.trim() };

    if (updatedNote.isNewNote) {
      this.onNewNoteSaveClick.emit({ note: { ...updatedNote, noteText: updatedNote.note }, activityId });
      this.analytics.sendMParticleEvent(
        'Create New Action Note',
        { 'New Note': updatedNote }
      );
    } else {
      this.saveNoteClicked.emit({ note: { ...updatedNote, noteText: updatedNote.note }, activityId });
      this.analytics.sendMParticleEvent(
        'Edit Action Note',
        { 'Edited Note': updatedNote }
      );
    }
  }

  // If there is an open note with unsaved changes, open confirmation dialog
  // This will accept a function as an input that will be called based on confirmation dialog status
  public checkForUnsavedChanges(userAction: (param: any, param1: any, param2: any)
    => any, optionalParam?, optionalParam1?, optionalParam2?) : void {
    if (this.isNoteUnsaved()) {
      let config: MatDialogConfig = {
        data: {
          title: this.unsavedChangesTitle,
          displayText: this.unsavedChangesText,
          iconUrl: this.unsavedChangesIcon,
          cancelText: this.unsavedChangesCancel,
          confirmText: this.unsavedChangesConfirm
        },
        autoFocus: false
      }
      config.panelClass = 'dialog-responsive';
      this.dialog.open(IconConfirmationDialogComponent, config).afterClosed().subscribe((shouldCancel) => {
        // Specifically check for false since clicking outside of dialog will return undefined
        let shouldDiscardChanges = shouldCancel === false;
        this.analytics.sendMParticleEvent(
          'Unsaved Changes Closed',
          { changesDiscarded: shouldDiscardChanges }
        );
        if (shouldDiscardChanges) {
          userAction(optionalParam, optionalParam1, optionalParam2);
        }
      });
    } else {
      userAction(optionalParam, optionalParam1, optionalParam2);
    }
  }

  // Returns if a note is both open and has unsaved changes
  public isNoteUnsaved() {
    let isNoteUnsaved = false;
    this.allRecords.forEach(record => {
      let recoveryNotes = record.recoveryNotes;
      if (recoveryNotes) {
        let index = findIndex(propEq('isEditMode', true))(recoveryNotes);
        if (index > -1) {
          let selectedNote = recoveryNotes[index];
          isNoteUnsaved = selectedNote.updatedText && selectedNote.updatedText !== selectedNote.note;
        }
      }
    });
    return isNoteUnsaved || this.openCustomerNote;
  }
}
