import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppConstants } from '../../app.constants';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import * as moment from 'moment-timezone';
import * as fromRoot from '../../reducers';
import { VersionService } from 'spotlight-ui/app/version.service';

@Component({
  selector: 'spot-footer',
  templateUrl: './spot-footer.component.html',
  styleUrls: ['./spot-footer.component.scss']
})
export class SpotFooterComponent {
  public versionNumber: string;
  public isMobile$: Observable<boolean>;
  public url: string = AppConstants.feedbackUrl;
  public currentYear = moment().format('YYYY');
  public showFooter: boolean;
  public isBlueBackground: boolean = false;

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private versionService: VersionService
  ) {
    this.versionNumber = versionService.getVersion();
    this.isMobile$ = this.store.select(fromRoot.getIsMobile);
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
      .forEach(() => {
        let routeData: { [x: string]: any; hideNavFooter?: any; isBlueBackground?: any; };
        if (this.activatedRoute.root.firstChild.firstChild) {
          routeData = this.activatedRoute.root.firstChild.firstChild.snapshot.data
        }
        this.showFooter = routeData ? !routeData.hideNavFooter : true;
        this.isBlueBackground = routeData ? routeData.isBlueBackground : false;
      });
  }

  public goToFeedback() {
    window.open(this.url);
  }
}
