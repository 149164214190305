export class DiscoverConstants {
  public static get disableSendingFlag(): string {
    return 'disableAllSending';
  }

  public static get reward(): string {
    return 'reward';
  }

  public static get email(): string {
    return 'email';
  }

  public static get automation(): string {
    return 'automation';
  }

  public static get stopAutomationError(): string {
    return 'There was an error stopping this automation. Please try again later.';
  }

  public static get promotion(): string {
    return 'promotion';
  }

  public static get bonusPoint(): string {
    return 'bonus-point';
  }

  public static get edit(): string {
    return 'edit';
  }

  public static get stop(): string {
    return 'stop';
  }

  public static get defaultDuration(): number {
    return 30;
  }

  public static get automationWarning(): string {
    return 'This is an automated reward; all changes/settings' +
      ' applied will be reflected in all future instances of this reward';
  }

  public static get automationConfirmText(): string {
    return 'Automate reward';
  }

  public static get customerListColumns(): any[] {
    return [{
      prop: 'firstName',
      title: 'First Name'
    }, {
      prop: 'lastName',
      title: 'Last Name'
    }];
  }

  public static get noResultsMessage(): string {
    return 'There was an error displaying customers for this reward.';
  }

  public static get pageSize(): number {
    return 5;
  }

  // For tiles that are reliant on the discoverFilters call
  public static get filterDependentTiles(): string[] {
    return ['grow-breakfast', 'recognize-catering', 'cfa-one-filters'];
  }

  public static get sundaySendWarning(): string {
    return 'Campaigns cannot be sent on a Sunday. If you continue,' +
      ' this campaign will be sent tomorrow, Monday, at ~5AM, ET.';
  }

  public static get minDays(): number {
    return 7;
  }

  // For Push Notification
  public static get pushMessageWarningText(): string {
    return 'App Push Message cannot be edited. Not all customers will receive this push message,' +
      'such as if they already have an active reward, have received a reward recently,' +
      'do not have app push notifications enabled, and/or if reward length is less than 7 days.';
  }

  public static get pushNotificationFlag(): string {
    return 'pushNotification';
  }

  public static get globalBannerProp(): any {
    return 'global-banner';
  }

  public static get discoverSearchBottomSheet(): any {
    return {
      'list': [],
      'isCancel': true
    };
  }

  public static get unsavedChangesTitle(): string {
    return 'You have unsaved changes';
  }

  public static get unsavedChangesText(): string {
    return 'Would you like to apply the changes you\'ve made to this message?';
  }

  public static get unsavedChangesIcon(): string {
    return 'https://www.cfacdn.com/img/Spotlight/Icons/UnsavedChanges_Icon.png';
  }

  public static get unsavedChangesConfirmButton(): string {
    return 'Apply';
  }

  public static get unsavedChangesCancelButton(): string {
    return 'Discard Changes';
  }

  public static get automationInfoSections(): any[] {
    return [{
      icon: 'https://www.cfacdn.com/img/Spotlight/Icons/J_Icon_Authentic.png',
      description: 'When you automate a reward, we’ll only target customers in your Spotlight who have met the required criteria.'
    }, {
      icon: 'https://www.cfacdn.com/img/Spotlight/Icons/J_Icon_Community%20Gathering.png',
      description: 'The criteria chosen was selected through extensive customer research to drive long-term profitability for your' +
        ' restaurant. We’ll only target customers who are likely to visit your restaurant to redeem their reward.'
    }, {
      icon: 'https://www.cfacdn.com/img/Spotlight/Icons/J_Icon_Reward.png',
      description: 'Each customer will receive an automated reward only once.'
    }, {
      icon: 'https://www.cfacdn.com/img/Spotlight/Icons/J_Icon_Email.png',
      description: 'Qualifying customers can receive only one email per automated reward, and will still be able to receive' +
        ' individual rewards through Engage and Recover.'
    }, {
      icon: 'https://www.cfacdn.com/img/Spotlight/Icons/J_Icon_Calendar.png',
      description: 'Every day we’ll check your Spotlight for new and unique customers who have recently met the qualifications,' +
        ' and we’ll send them a reward automatically.'
    }];
  }

  public static get categoryCampaign(): string {
    return 'campaign';
  }

  public static get categoryCommunityCare(): string {
    return 'communityCareEvent';
  }

  public static get communityCareEventsFlag(): string {
    return 'communityCareEvents';
  }

  public static get welcomeModalBanner(): string {
    return 'welcome-modal-banner';
  }

  public static get thirdPartyPromotion(): string {
    return 'third-party-promotion';
  }

  public static get freeItemInCartPromotion(): string {
    return 'free-item-in-cart-promotion';
  }

  public static get freeItemInCartError(): string {
    return 'There was an error stopping this Free Item In Cart promotion. Please try again later.';
  }

  public static get internalPromotionType(): string {
    return 'internal-promotions';
  }

  public static get freeItemInCartPromotionFlag(): string {
    return 'freeItemInCartEnabled';
  }

  public static get freeItemInCartPickupFlag(): string {
    return 'freeItemInCartPickup';
  }

  public static get freeItemInCartCFADeliveryFlag(): string {
    return 'freeItemInCartCFADelivery';
  }

  public static get freeItemInCartCFADeliveryDestinationOption(): string {
    return 'delivery';
  }

  public static get freeItemInCartDoorDashFlag(): string {
    return 'freeItemInCartDoorDash';
  }

  public static get freeItemInCartDoorDashDestinationOption(): string {
    return 'checkoutWithDD';
  }

  public static get freeItemInCartAnyId(): string {
    return 'any';
  }

  public static get freeItemInCartBreakfastId(): string {
    return 'breakfast';
  }

  public static get freeItemInCartLunchId(): string {
    return 'lunch';
  }

  public static get freeItemInCartCustomId(): string {
    return 'custom';
  }

  public static get deliveryTileId() {
    return 'operator-led-delivery';
  }

  public static get eventBannerId(): string {
    return 'image-banner';
  }

  public static get communityCareEventTileId(): string {
    return 'community-care-event';
  }

  public static get communityCareEventType(): string {
    return 'cce';
  }

  public static get communityCareStopEventError(): string {
    return 'There was an error stopping this Community Care Event. Please try again later.';
  }

  public static get communityCareStopEventSuccess(): string {
    return 'Community Care Event successfully stopped.';
  }

  public static get thirdPartyEventType(): string {
    return '3pp';
  }

  public static get breakfastDayPart(): string {
    return 'Breakfast';
  }

  public static get afternoonDayPart(): string {
    return 'Afternoon';
  }

  public static get datePickerFooter(): string {
    return 'Bonus Points and Free Item promotions may not overlap. Dates will be blocked off to prevent promotions running concurrently';
  }
}
