import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AnalyticsService } from '../../../core/analytics';
import * as fromRoot from '../../../reducers';
import {
  getEngagementDefaultMessageForApp, getEngagementEmail, getEngagementMessage, getEngagementPushMessageText, getEngagementPushMessageTitle,
  getEngagementSelectedReward,
  State
} from '../../../reducers';
import { browserRefreshed } from '../../../app.component';
import { DiscoverConstants } from '../../discover.constants';
import { UpdateEngagement } from '../../../actions/engagement';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IconConfirmationDialogComponent } from '../../../shared/icon-confirmation-dialog/icon-confirmation-dialog.component';

@Component({
  templateUrl: './copy-review.component.html',
  styleUrls: ['./copy-review.component.scss']
})

export class DiscoverCopyReviewComponent implements OnInit {
  public email$: Observable<any>;
  public reward$: Observable<any>;
  public emailCopy$: Observable<string>;
  public appCopy$: Observable<string>;
  public pushMessageTitle$: Observable<string>;
  public pushMessageText$: Observable<string>;
  public isMobile$: Observable<boolean>;
  public engagementType$: Observable<string>;
  public title$: Observable<string>;
  public isPushNotificationEnabled$: Observable<boolean>;

  public index: number = 0;
  public emailCopy: string = ' ';
  public appCopy: string = ' ';
  public emailMessageChanges: boolean = false;
  public appMessageChanges: boolean = false;
  public emailType = DiscoverConstants.email;
  public pushMessageWarningText = DiscoverConstants.pushMessageWarningText;
  public unsavedChangesTitle: string = DiscoverConstants.unsavedChangesTitle;
  public unsavedChangesText: string = DiscoverConstants.unsavedChangesText;
  public unsavedChangesIcon: string = DiscoverConstants.unsavedChangesIcon;
  public unsavedChangesConfirm: string = DiscoverConstants.unsavedChangesConfirmButton;
  public unsavedChangesCancel: string = DiscoverConstants.unsavedChangesCancelButton;

  constructor(private store: Store<State>,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private analytics: AnalyticsService,
              private dialog: MatDialog) { }

  public ngOnInit() {
    if (browserRefreshed) {
      this.router.navigate(['/discover']);
    } else {
      window.scroll(0, 0);
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        const { index } = params;
        this.index = index;
      });
      this.engagementType$ = this.store.select(fromRoot.getEngagementType);
      this.title$ = this.engagementType$.pipe(map((type) => {
        return `${type} Message`;
      }));

      this.email$ = this.store.select(getEngagementEmail);
      this.reward$ = this.store.select(getEngagementSelectedReward);
      this.emailCopy$ = this.store.select(getEngagementMessage);
      this.emailCopy$.pipe(take(1)).subscribe((emailCopy) => {
        this.emailCopy = emailCopy;
      });

      this.appCopy$ = this.store.select(getEngagementDefaultMessageForApp);
      this.appCopy$.pipe(take(1)).subscribe((appCopy) => {
        this.appCopy = appCopy;
      });

      this.pushMessageTitle$ = this.store.select(getEngagementPushMessageTitle);
      this.pushMessageText$ = this.store.select(getEngagementPushMessageText);
      this.isMobile$ = this.store.select(fromRoot.getIsExtraSmallMobile);

      this.isPushNotificationEnabled$ = this.store.select(fromRoot.getFeatureEnabled(DiscoverConstants.pushNotificationFlag));
    }
  }

  public goBack = () : void => {
    this.router.navigate(['/discover/edit/edit-details']);

    this.engagementType$.pipe(take(1)).subscribe((engagementType) => {
      if (engagementType === DiscoverConstants.reward || engagementType === DiscoverConstants.automation) {
        this.reward$.pipe(take(1)).subscribe((reward) => {
          this.store.dispatch(new UpdateEngagement({ message: reward.offerDetails }));
        });
      } else {
        this.email$.pipe(take(1)).subscribe((email) => {
          this.store.dispatch(new UpdateEngagement({ message: email.defaultText }));
        });
      }
    });
  }

  public emailMessageUpdate(copy) {
    this.emailCopy = copy;
    this.emailMessageChanges = true;
    this.store.dispatch(new UpdateEngagement({ message: this.emailCopy }));
  }

  public appMessageUpdate(evt) {
    this.appCopy = evt.target.value;
    this.appMessageChanges = true;
  }

  public apply() {
    if (this.emailMessageChanges) {
      this.analytics.sendMParticleEvent(
        'Discover Reward Email Copy Changed',
        { updatedRewardEmailCopy: this.emailCopy }
      );
      this.store.dispatch(new UpdateEngagement({ message: this.emailCopy }));
    }
    if (this.appMessageChanges) {
      this.analytics.sendMParticleEvent(
        'Discover Default Message For App Changed',
        { updatedAppCopy: this.appCopy }
      );
      let appCopy = this.appCopy;
      this.store.dispatch(new UpdateEngagement({ defaultMessageForApp: appCopy }));
    }
    this.router.navigate(['/discover/edit/edit-details']);
  }

  public isEmpty() {
    return this.appCopy === undefined ? !this.emailCopy.trim()
      : !this.emailCopy.trim() || !this.appCopy.trim();
  }

  // If there are unsaved copy changes, open the confirmation dialog
  // This will accept a function as an input that will be called based on confirmation dialog status
  public checkForUnsavedChanges(userAction: (param: any) => any, optionalParam?) : void {
    if (this.emailMessageChanges || this.appMessageChanges) {
      let config: MatDialogConfig = {
        data: {
          title: this.unsavedChangesTitle,
          displayText: this.unsavedChangesText,
          iconUrl: this.unsavedChangesIcon,
          cancelText: this.unsavedChangesCancel,
          confirmText: this.unsavedChangesConfirm
        },
        autoFocus: false,
        panelClass: 'dialog-responsive'
      }

      this.dialog.open(IconConfirmationDialogComponent, config).afterClosed().subscribe((shouldCancel) => {
        // Specifically check for false since clicking outside of dialog will return undefined
        let shouldDiscardChanges = shouldCancel === false;
        this.analytics.sendMParticleEvent(
          'Discover Copy Unsaved Changes Closed',
          { changesDiscarded: shouldDiscardChanges }
        );
        if (shouldDiscardChanges) {
          userAction(optionalParam);
        } else if (shouldDiscardChanges === false) {
          this.apply();
        }
      });
    } else {
      userAction(optionalParam);
    }
  }
}
