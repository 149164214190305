<div class="campaign">
    <div fxLayout="row" fxLayoutAlign="flex-start center">
        <div class="campaign__width">
            <img alt="Campaign Image" class="campaign__image" fxLayoutAlign="start" [src]="image">
        </div>
        <div class="campaign__text" [attr.data-testid]="title + 'campaign-text'">
            {{text.secondary}}<br>
            <span *ngIf="text.secondarySubText" class="campaign__text">{{text.secondarySubText}}</span>
        </div>
        <img alt="New" *ngIf="displayNewIcon" class="campaign__new-image" src="../../../assets/img/NewBadge.png">
    </div>
</div>
