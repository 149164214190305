import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { findIndex, propEq } from 'ramda';
import { UserService } from '../../core/user';
import { UserLocation } from '../../models/user/location.model';
import { NavbarConstants } from './navbar.constants';
import { SharedConstants } from '../shared.constants';
import { AnalyticsService } from '../../core/analytics';
import * as fromRoot from '../../reducers';
import { NavigationService } from '../../navigation.service';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavBarComponent implements OnInit, OnDestroy {
  @Input() public showLocationNumber: boolean = false;
  @Input() public showSelectedLocationName: boolean = false;
  @Input() public selectedIndex: number = 0;

  public locations: UserLocation[];
  public locations$: Observable<UserLocation[]>;
  public selectedLocation$: Observable<UserLocation>;
  public disableDiscoverTab$: Observable<boolean>;
  public disableMeetTab$: Observable<boolean>;
  public disableRecoverTab$: Observable<boolean>;
  public disableEngageTab$: Observable<boolean>;
  public disableCalendarTab$: Observable<boolean>;
  public navLinks: any[];
  public isMobile$: Observable<boolean>;
  public unreadNotificationCount$: Observable<number>;
  public isStaffOrContractor = null;
  public flagSub: Subscription = null;
  public searchTerm = '';
  public currentPage = 0;
  public pageSize = 5;
  public selectedLocation: UserLocation;
  protected readonly location = location;

  constructor(private analytics: AnalyticsService,
              private us: UserService,
              private router: Router,
              private sanitizer: DomSanitizer,
              private navService: NavigationService,
              private store: Store<fromRoot.State>) {}

  public ngOnInit() {
    this.disableDiscoverTab$ = this.store.select(fromRoot.getFeatureEnabled(SharedConstants.disableDiscoverTabFlag));
    this.disableMeetTab$ = this.store.select(fromRoot.getFeatureEnabled(SharedConstants.disableMeetTabFlag));
    this.disableRecoverTab$ = this.store.select(fromRoot.getFeatureEnabled(SharedConstants.disableRecoverTabFlag));
    this.disableEngageTab$ = this.store.select(fromRoot.getFeatureEnabled(SharedConstants.disableEngageTabFlag));
    this.disableCalendarTab$ = this.store.select(fromRoot.getFeatureEnabled(SharedConstants.disableCalendarTabFlag));

    this.selectedLocation$ = this.store.select(fromRoot.getSelectedLocation);
    this.locations$ = this.store.select(fromRoot.getUserLocations);
    this.store.select(fromRoot.isAdminUser).pipe(take(1)).subscribe((isStaffOrContractor) => {
      this.isStaffOrContractor = isStaffOrContractor;
    });

    this.flagSub = this.disableDiscoverTab$.pipe(take(1)).subscribe((disableDiscoverTab) => {
      this.disableMeetTab$.pipe(take(1)).subscribe((disableMeetTab) => {
        this.disableRecoverTab$.pipe(take(1)).subscribe((disableRecoverTab) => {
          this.disableEngageTab$.pipe(take(1)).subscribe((disableEngageTab) => {
            this.disableCalendarTab$.pipe(take(1)).subscribe((disableCalendarTab) => {
              this.navLinks = NavbarConstants.navbarTabs(disableDiscoverTab, disableMeetTab, disableRecoverTab, disableEngageTab,
                disableCalendarTab);
              this.navLinks[this.selectedIndex].active = true;
            });
          });
        });
      });
    });

    this.locations$.subscribe((locs) => {
      this.locations = locs;
    });
    this.selectedLocation$.subscribe((location) => {
      this.selectedLocation = location;
    });

    this.unreadNotificationCount$ = this.store.select(fromRoot.getNotificationUnreadCount);
    this.isMobile$ = this.store.select(fromRoot.getIsMobile);
  }

  public updateLocation(location) {
    let newLocationIndex = findIndex(propEq('locationNumber', location.locationNumber))(this.locations);
    let newLocation = this.locations[newLocationIndex]
    this.selectedLocation = location
    this.analytics.sendMParticleEvent(
      'Navbar Location Change',
      {
        'Location #': newLocation.locationNumber,
        'Location Name': newLocation.locationName
      }
    );
    localStorage.removeItem('selectedLocation');
    localStorage.setItem('selectedLocation', location.locationNumber);
    this.us.updateSelectedLocation(newLocation);
  }

  public openSettings() {
    this.analytics.sendMParticleEvent('Settings Click', {});
    this.router.navigate(['/settings']);
  }

  public openNotifications() {
    this.analytics.sendMParticleEvent('Notifications Click', {});
    this.router.navigate(['/notifications']);
  }

  public logoutUser() {
    localStorage.removeItem('selectedLocation');
    this.analytics.sendMParticleEvent('Logout Click', {});
    this.navService.signOut();
  }

  public stateDisabled() {
    return !this.locations || (this.locations && this.locations.length <= 1);
  }

  public onSearch(event) {
    this.searchTerm = event.target.value;
    this.currentPage = 0;
  }

  public pageLeft() {
    this.currentPage--;
  }

  public pageRight() {
    this.currentPage++;
  }

  public clearLocationFiltering() {
    this.currentPage = 0;
    this.searchTerm = '';
  }

  public getFilteredLocations$(): Observable<UserLocation[]> {
    return this.locations$.pipe(map((locations) => {
      return locations.filter((loc) => {
        return loc.locationNumber.includes(this.searchTerm)
          || (loc.locationName ? loc.locationName.toLowerCase().includes(this.searchTerm.toLowerCase()) : false);
      });
    }));
  }

  public getDisplayLocations$(): Observable<UserLocation[]> {
    if (this.isStaffOrContractor) {
      return this.getFilteredLocations$().pipe(map((locations) => {
        const nextRowIndex = this.currentPage * this.pageSize;
        if (nextRowIndex + this.pageSize < this.locations.length) {
          locations = locations.slice(nextRowIndex, nextRowIndex + this.pageSize);
        } else {
          locations = locations.slice(nextRowIndex, locations.length);
        }
        let locationIndex = findIndex(propEq('locationNumber', this.selectedLocation.locationNumber))(locations);
        if (locationIndex == -1) {
          locations = [this.selectedLocation].concat(locations)
        } else {
          locations[locationIndex] = this.selectedLocation
        }
        return locations;
      }));
    } else {
      return this.locations$.pipe(map(locations =>{
        let locationIndex = findIndex(propEq('locationNumber', this.selectedLocation.locationNumber))(locations);
        if (locationIndex == -1) {
          locations = [this.selectedLocation].concat(locations)
        } else {
          locations[locationIndex] = this.selectedLocation
        }
        return locations
      }))
    }
  }

  public navigateToMenu() {
    if (this.isStaffOrContractor) {
      const previousUrl = window.location.href.substring(window.location.href.lastIndexOf('#') + 1);
      this.router.navigate(
        ['menu/location-search'],
        { queryParams: { previousUrl: previousUrl } }
      );
    } else {
      this.router.navigate(['/menu']);
    }
  }

  public ngOnDestroy() {
    if (this.flagSub) {
      this.flagSub.unsubscribe();
    }
  }
}
