import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '../core/error-handler';
import { UserService } from '../core/user';
import { AnalyticsService } from '../core/analytics';
import { UserLocation } from '../models/user/location.model';
import { CareConstants } from './care.constants';
import { environment } from '../../environments/environment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActionSelectionDialogComponent } from './action-selection-dialog/action-selection-dialog.component';
import * as care from '../actions/care';
import { Store } from '@ngrx/store';
import * as fromRoot from '../reducers';
import { Router } from '@angular/router';

@Injectable()
export class CareService {
  constructor(private http: HttpClient,
              private ehs: ErrorHandlerService,
              private analytics: AnalyticsService,
              private us: UserService,
              private dialog: MatDialog,
              private store: Store<fromRoot.State>,
              private router: Router) { }

  public getCareReports(request) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/recovery/guests`;
        return this.http.post(environment.spotlightApiUrl + url, request);
      }),
      map((res: any) => res.responseObject),
      catchError((error) => {
        return this.ehs.handleError(error)
      })
    );
  }

  public getCareReportsForOVCC(request) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/recovery/2.0/cases`;
        return this.http.post(environment.spotlightApiUrl + url, request);
      }),
      map((res: any) => res.responseObject),
      catchError((error) => {
        return this.ehs.handleError(error);
      })
    );
  }

  public acknowledgeReport(acknowledgeDetails) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        const locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/recovery/2.0/case/acknowledge`;
        return this.http.patch(environment.spotlightApiUrl + url, acknowledgeDetails);
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }

  public reassignReport(reassignDetails) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        const locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/recovery/2.0/case`;
        return this.http.patch(environment.spotlightApiUrl + url, reassignDetails);
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }

  public getReportDetails(id) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/recovery/ticket/${id}`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }

  public getReportActivityHistory(report) {
    const { id, reportDateTime, cfaId } = report;
    const payload = { incidentDate: reportDateTime };

    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/recovery/ticket/history/${id}/${cfaId}`;
        return this.http.post(environment.spotlightApiUrl + url, payload);
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }

  public getCareEmailDetails(id) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/recovery/ticket/history/email/${id}`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }

  public getCareTemplate(request) {
    const { incidentId, type, memberType } = request;
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/recovery/${incidentId}/`
          + `${memberType}/response/templates?type=${type}`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => {
        return res.responseObject;
      }),
      catchError((error) => {
        return this.ehs.handleError(error)
      })
    );
  }

  public getSpotCustomerCareTemplate(type) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/recovery/response/templates?type=${type}`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError((error) => {
        return this.ehs.handleError(error)
      })
    );
  }

  public updateIncident(request) {
    const { incidentId, cfaId, resolutionStatus, type, note } = request;
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/recovery/${incidentId}/${cfaId}/response?type=${type}`;
        return this.http.post(environment.spotlightApiUrl + url, { resolutionStatus, note });
      }),
      map((res) => res),
      catchError(this.ehs.handleError)
    );
  }

  public sendCareResponse(payload) {
    const { incidentId, cfaId, includeReward, careCopyChanged, offerTemplateId } = payload;
    const type = includeReward ? CareConstants.rewardType : CareConstants.emailType;

    this.analytics.sendMParticleEvent(
      'Response sent',
      { includeReward, careCopyChanged, offerTemplateId }
    );
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/recovery/${incidentId}/${cfaId}/response?type=${type}`;
        return this.http.post(environment.spotlightApiUrl + url, payload);
      }),
      map(() => incidentId),
      catchError((error) => {
        return this.ehs.handleError(error)
      })
    );
  }

  public sendSpotCareResponse(payload) {
    const { cfaId, includeReward, careCopyChanged, offerTemplateId } = payload;
    const type = includeReward ? CareConstants.rewardType : CareConstants.emailType;

    this.analytics.sendMParticleEvent(
      'Spot Response sent',
      { includeReward, careCopyChanged, offerTemplateId, cfaId }
    );
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/recovery/response/${cfaId}?type=${type}`;
        return this.http.post(environment.spotlightApiUrl + url, payload);
      }),
      map(() => {
        return {
          customerFirstName: payload.firstName ? payload.firstName : '',
          activeSpotlightCustomer: payload.activeSpotlightCustomer,
          id: cfaId,
          memberType: CareConstants.spotCustomer
        };
      }),
      catchError((error) => {
        return this.ehs.handleError(error)
      })
    );
  }

  public reopenReport(report) {
    const { id, cfaId } = report;
    const payload = { resolutionStatus: 'Reopen' };

    this.analytics.sendMParticleEvent('Reopen Closed Care Contact', {});

    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/recovery/${id}/${cfaId}/response?type=status`;
        return this.http.post(environment.spotlightApiUrl + url, payload);
      }),
      map((response) => response),
      catchError(this.ehs.handleError)
    );
  }

  public getCustomersHttp(payload) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = '/api/location/' + locationNumber + '/filters/customers';
        return this.http.post(environment.spotlightApiUrl + url, payload);
      }),
      map((res: any) => res.responseObject || {}),
      catchError((error) => {
        return this.ehs.handleError(error)
      })
    );
  }

  public getCustomerInformation(report) {
    const cfaId = report.cfaId;

    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let baseLocationUrl = '/api/location/';
        let url = baseLocationUrl + loc.locationNumber + '/customer/' + cfaId;
        let req = {};

        return this.http.get(environment.spotlightApiUrl + url, req).pipe(
          map((res) => {
            return res;
          }),
          catchError(this.ehs.handleError)
        );
      })
    );
  }

  public createCustomerNote(note, ticketNumber) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        const cfaId = note.cfaUid;
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/notes/${cfaId}?ticket=${ticketNumber}`;
        let req = note.note;
        return this.http.post(environment.spotlightApiUrl + url, req).pipe(
          map((res: any) => {
            this.analytics.sendMParticleEvent('CARE: Customer Note Created', {});
            let response = { ...res.responseObject };
            return response || {};
          }),
          catchError((error) => {
            this.analytics.sendMParticleEvent('CARE: Create Customer Note Failed', {});
            return this.ehs.handleError(error);
          })
        );
      })
    );
  }

  public updateCustomerNote(note, ticketNumber) {
    const cfaId = note.cfaUid;
    let noteId = note.noteId;
    let locationNumber = note.locationNumber;
    const url = `/api/location/${locationNumber}/notes/${cfaId}/${noteId}?ticket=${ticketNumber}`;
    let req = note.note;

    return this.http.put(environment.spotlightApiUrl + url, req).pipe(
      map((res: any) => {
        this.analytics.sendMParticleEvent('CARE: Customer Note Updated', {});
        let response = { ...res.responseObject };
        return response || {};
      }),
      catchError((error) => {
        this.analytics.sendMParticleEvent('CARE: Update Customer Note Failed', {});
        return this.ehs.handleError(error);
      })
    );
  }

  public deleteCustomerNote(note) {
    let baseNoteUrl = '/api/location/';
    let url = baseNoteUrl + note.locationNumber + '/notes' + '/' + note.cfaUid + '/' + note.noteId;

    return this.http.delete(environment.spotlightApiUrl + url).pipe(
      map((res: any) => {
        this.analytics.sendMParticleEvent('CARE: Customer Note Deleted', {});
        let response = { ...res.responseObject };
        return response || {};
      }),
      catchError((error) => {
        this.analytics.sendMParticleEvent('CARE: Delete Customer Note Failed', {});
        return this.ehs.handleError(error);
      })
    );
  }

  public createActionHistoryNote(payload) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        const activityId = payload.activityId;
        const locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/recovery/${activityId}/notes`;
        let req = payload.note;
        return this.http.post(environment.spotlightApiUrl + url, req).pipe(
          map((res: any) => {
            this.analytics.sendMParticleEvent('CARE: Action History Note Created', {});
            let response = { ...res.responseObject };
            return response || {};
          }),
          catchError((error) => {
            this.analytics.sendMParticleEvent('CARE: Create ActionHistory Note Failed', {});
            return this.ehs.handleError(error);
          })
        );
      })
    );
  }

  public updateActionHistoryNote(payload) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        const activityId = payload.activityId;
        const noteId = payload.note.noteId;
        const locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/recovery/${activityId}/notes/${noteId}`;
        let req = payload.note;

        return this.http.put(environment.spotlightApiUrl + url, req).pipe(
          map((res: any) => {
            this.analytics.sendMParticleEvent('CARE: Action History Note Updated', {});
            const response = { ...res.responseObject };
            return response || {};
          }),
          catchError((error) => {
            this.analytics.sendMParticleEvent('CARE: Update Action History Note Failed', {});
            return this.ehs.handleError(error);
          })
        );
      })
    );
  }

  public getEligibleForRecovery(payload) {
    const { id } = payload;
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        const locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/recovery/reward/eligibility/${id}`;
        return this.http.get(environment.spotlightApiUrl + url, payload);
      }),
      map((res: any) => res.responseObject),
      catchError((err) => {
        return this.ehs.handleError(err);
      })
    );
  }

  public getCaseDetails(reportNumber: any) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        const locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/recovery/2.0/case/${reportNumber}`;
        return this.http.get(environment.spotlightApiUrl + url, reportNumber);
      }),
      map((res: any) => res.responseObject),
      catchError((err) => {
        return this.ehs.handleError(err);
      })
    );
  }

  public getClosedCareContacts(request) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/care/stats/tickets/details?sortBy=priority`;
        return this.http.post(environment.spotlightApiUrl + url, request);
      }),
      map((res: any) => res.responseObject),
      catchError((err) => {
        return this.ehs.handleError(err);
      })
    );
  }

  public getClosedCareContactsForOVCC(request) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/recovery/2.0/cases`;
        return this.http.post(environment.spotlightApiUrl + url, request);
      }),
      map((res: any) => res.responseObject),
      catchError((err) => {
        return this.ehs.handleError(err);
      })
    );
  }

  public getRecoveredCfaOneContacts(request) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/recovery/cfaone/closed/contacts`;
        return this.http.post(environment.spotlightApiUrl + url, request);
      }),
      map((res: any) => res.responseObject),
      catchError((err) => {
        return this.ehs.handleError(err);
      })
    );
  }

  public openActionSelectionDialog(report, sendWithOffer: string, navigateToProfile: boolean) {
    let incidentId = report.id;

    let config = new MatDialogConfig();
    config.data = { ...report };
    let dialogRef = this.dialog.open(ActionSelectionDialogComponent, config);

    if (dialogRef) {
      dialogRef.afterClosed().subscribe((responseObj) => {
        if (responseObj) {
          let { isSend, value, note } = responseObj;
          if (isSend) {
            this.analytics.sendMParticleEvent(
              'Respond flow started on desktop',
              { IncludeReward: value }
            );

            this.store.dispatch(new care.SelectReport(report));
            this.store.dispatch(new care.SelectTemplate({
              includeReward: value === sendWithOffer,
              memberType: report.membership,
              note
            }));
            this.router.navigate(['/care/respond/respond-details'], { queryParams: { incidentId, note } });
          } else {
            this.analytics.sendMParticleEvent(
              'Incident status updated',
              { ChangedTo: value }
            );

            this.store.dispatch(new care.UpdateStatus({
              incidentId,
              note,
              cfaId: report.cfaId,
              resolutionStatus: value,
              type: 'status'
            }));
            if (navigateToProfile) {
              this.router.navigate([`/care/details`], { queryParams: { incidentId, note } });
            }
          }
        }
      });
    }
  }
}
