import { Injectable } from '@angular/core'
import { Inject } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth, TokenManagerInterface, AccessToken } from '@okta/okta-auth-js';
import Bugsnag from '@bugsnag/js';
import { ErrorHandlerService } from './core/error-handler';
import { environment } from '../environments/environment';
import { SharedConstants } from './shared/shared.constants';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { Store } from '@ngrx/store';
import * as fromRoot from './reducers';
import * as user from './actions/user';
import { VersionService } from './version.service';

@Injectable()
export class NavigationService {
  tokenManager: TokenManagerInterface;
  accessToken: AccessToken;

  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private store: Store<fromRoot.State>,
    private ehs: ErrorHandlerService,
    private versionService: VersionService
  ) { }

  checkWasPerm(functionTag: string) {
    let token = this.oktaAuth.getAccessToken();
    if (!token) {
      console.warn('Access token is not present.');
      return false;
    }
    const claims = this.oktaAuth.token.decode(token).payload;
    const perms = claims['cfa_perms']['SPOTLIGHT'];
    return perms? perms[functionTag] != undefined : false;
  }

  public signOut() {
    this.oktaAuth.signOut({ clearTokensBeforeRedirect: true }).then(() => {
      this.oktaAuth.signInWithRedirect().then();
    }).catch(error =>{
      return this.ehs.handleError(error);
    });
  }

  // Initialize Bugsnag and LaunchDarkly
  public setup() {
    const releaseStage = environment.env;

    let tokenPresent = this.oktaAuth.getAccessToken() != undefined

    if (tokenPresent) {
      Bugsnag.start({
        apiKey: SharedConstants.bugsnagApiKey,
        autoTrackSessions: false,
        maxBreadcrumbs: 100,
        maxEvents: 100,
        projectRoot: '/spotlight',
        releaseStage,
        trackInlineScripts: false,
        appVersion: this.versionService.getVersion()
      });
      let sdkKey = environment.launchDarklySdkKey;
      let clientKey = environment.launchDarklyClientKey;
      const context: LaunchDarkly.LDContext = {
        kind: 'user',
        key: sdkKey
      };
      const client = LaunchDarkly.initialize(clientKey, context);
      client.on('ready', () => {
        this.store.dispatch(new user.LaunchDarklyInitialized(client.allFlags()));
      });
    }

    return Promise.resolve(tokenPresent);
  }
}
