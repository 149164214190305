<button *ngIf="!isMobile"
        mat-raised-button
        class="actions-menu__button"
        data-testid="meet-action-button"
        [ngClass] = "{'actions-menu__button-pressed': menuOpen}"
        [cfaLoading]="isLoading"
        [disabled]="selectedCustomerIds.length === 0 || disableActionMenu"
        [matMenuTriggerFor]="menu"
        (menuOpened)="isOpened()"
        (menuClosed)="isClosed()">
  <div *ngIf="selectedCustomerIds.length > 0"
       class="actions-menu__badge" data-testid="meet-action-menu-badge"
       [ngClass] = "{'actions-menu__badge-pressed': menuOpen}">
    {{selectedCustomerIds.length}}
  </div>
  Actions
</button>

<div *ngIf="(isMobile && selectedCustomerIds.length > 0) && !disableActionMenu"
     fxLayout="row"
     fxLayoutAlign="center center">
  <i class="material-icons actions-menu__mobile-dropdown-enabled"
     [matMenuTriggerFor]="menu"
     [ngClass] = "{'actions-menu__button-pressed': menuOpen}">more_vert</i>
  <div class="actions-menu__badge-mobile" fxLayout="column" fxLayoutAlign="center center"
       [ngClass] = "{'actions-menu__badge-pressed': menuOpen}">
    {{selectedCustomerIds.length}}
  </div>
</div>

<i *ngIf="(isMobile && selectedCustomerIds.length > 0) && disableActionMenu"
   class="material-icons">more_vert</i>

<i *ngIf="(isMobile && selectedCustomerIds.length === 0)"
   class="material-icons actions-menu__mobile-dropdown-disabled">more_vert</i>

<mat-menu #menu="matMenu">
  <ng-container *ngIf="!canMakeInactive">
    <ng-container *ngIf="!(isAllSendingDisabled$ | async)">
      <button data-testid="send-reward-button" *ngIf="!(isRewardsSendingDisabled$ | async)" (click)="checkForUnsavedChanges(engageWithCustomer, 0)" mat-menu-item>
        Send Reward
      </button>
      <button data-testid="send-event-button" (click)="checkForUnsavedChanges(engageWithCustomer, 1)" mat-menu-item>
        Send Event
      </button>
      <button data-testid="send-email-button" (click)="checkForUnsavedChanges(engageWithCustomer, 2)" mat-menu-item>
        Send Email
      </button>
    </ng-container>
    <mat-divider class="actions-menu__display-mat-divider" *ngIf="!(isAllSendingDisabled$ | async)"></mat-divider>
    <button data-testid="move-to-inactive-button" *ngIf="!isMobile" (click)="makeInactive()" mat-menu-item>
      Move to Inactive List
    </button>
  </ng-container>

  <button data-testid="move-to-active-button" *ngIf="canMakeInactive" mat-menu-item (click)="makeActive()">
    Move to Active List
  </button>
</mat-menu>
