<div class="filter-sub-option" [ngClass]="{'filter-sub-option__chip-selected' : selected}" (click)="subOptionToggled.emit(name)">
  <div fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="90" fxLayout="column">
      <div class="filter-sub-option__text" [attr.data-testid]="name + '-sub-name'">{{name}}</div>
      <div *ngIf="subtext" class="filter-sub-option__subtext">{{subtext}}</div>
    </div>
    <div fxFlex="10" fxLayout="column" fxLayoutAlign="end center">
      <img *ngIf="image" class="filter-sub-option__image" alt="" src="{{image}}">
    </div>
  </div>
</div>
