import { Component, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AnalyticsService } from '../core/analytics';
import * as fromRoot from '../reducers';
import * as calendar from '../actions/calendar';
import * as customer from '../actions/customer';
import * as discover from '../actions/discover';
import * as engagement from '../actions/engagement';
import * as meet from '../actions/meet';
import * as user from '../actions/user';
import { Filter } from '../models/filters/filter.model';
import { DiscoverTile } from '../reducers/discover';
import { AppConstants } from '../app.constants';
import { CalendarConstants } from '../calendar/calendar.constants';
import { DiscoverConstants } from './discover.constants';
import { MeetConstants } from '../meet/meet.constants';
import { SharedConstants } from '../shared/shared.constants';
import { getBannerById } from '../reducers';

@Component({
  templateUrl: './discover.component.html',
  styleUrls: ['./discover.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class DiscoverComponent {
  public generalTiles$: Observable<DiscoverTile[]>;
  public bannerTile$: Observable<DiscoverTile>;
  public errorTile$: Observable<DiscoverTile>;
  public campaignTiles$: Observable<DiscoverTile[]>;
  public promotionTiles$: Observable<DiscoverTile[]>;
  public automatedTiles$: Observable<DiscoverTile[][]>;
  public promotionAndCampaignTiles$: Observable<DiscoverTile[][]>;
  public error$: Observable<string>;
  public isSendingDisabled$: Observable<boolean>;
  public isRewardsSendingDisabled$: Observable<boolean>;
  public discoverLoading$: Observable<boolean>;
  public isMobile$: Observable<boolean>;
  public isExtraSmallScreen$: Observable<boolean>;
  public getMeetDisplayFilters$: Observable<any[]>;
  public imageBanner$: Observable<any>;

  public rewardType = DiscoverConstants.reward;
  public emailType = DiscoverConstants.email;
  public automationType = DiscoverConstants.automation;
  public communityCareType = DiscoverConstants.categoryCommunityCare;

  constructor(private analytics: AnalyticsService,
              private sanitizer: DomSanitizer,
              private router: Router,
              private store: Store<fromRoot.State>) {
    window.scroll(0, 0);
    this.bannerTile$ = store.select(fromRoot.getBannerTile).pipe(map((bannerTile) => {
      if (bannerTile) {
        const html = this.sanitizer.bypassSecurityTrustHtml(bannerTile.tileText);
        return { ...bannerTile, tileText: html };
      }
    }));
    this.errorTile$ = store.select(fromRoot.getErrorTile);
    this.generalTiles$ = store.select(fromRoot.getGeneralDiscoverTiles);
    this.campaignTiles$ = store.select(fromRoot.getCampaignDiscoverTiles);
    this.promotionTiles$ = store.select(fromRoot.getPromotionDiscoverTiles);
    this.automatedTiles$ = store.select(fromRoot.getAutomatedDiscoverTiles);
    this.promotionAndCampaignTiles$ = store.select(fromRoot.getCampaignPromotionTiles);
    this.discoverLoading$ = store.select(fromRoot.getDiscoverLoading);
    this.error$ = store.select(fromRoot.getDiscoverError);
    this.isSendingDisabled$ = store.select(fromRoot.getFeatureEnabled(DiscoverConstants.disableSendingFlag));
    this.isRewardsSendingDisabled$ = store.select(fromRoot.getFeatureEnabled(SharedConstants.disableRewardSendFlag));
    this.isMobile$ = this.store.select(fromRoot.getIsMobile);
    this.isExtraSmallScreen$ = this.store.select(fromRoot.getIsExtraSmallMobile);
    this.getMeetDisplayFilters$ = store.select(fromRoot.getMeetFilters);
    this.imageBanner$ = store.select(getBannerById(DiscoverConstants.eventBannerId));
  }

  public calculateOrder(loading, order) {
    const loadingOrder = 1000;
    return loading ? loadingOrder : order;
  }

  public goToMeet(selectedFilter: Filter) {
    if (selectedFilter) {
      this.analytics.sendMParticleEvent(
        'Discover Filter Click',
        { 'Filter ID': selectedFilter.displayName }
      );
      if (selectedFilter.displayName === MeetConstants.cateringView) {
        this.store.dispatch(new meet.ClearFilters({ page: AppConstants.meetPage }));
        this.store.dispatch(new customer.ChangeViewAction(MeetConstants.cateringView));
      } else {
        this.store.dispatch(new customer.ChangeViewAction(MeetConstants.allCustomerView));
        this.store.dispatch(new meet.AddFilterFromDiscover({ filter: selectedFilter }));
      }
      this.router.navigate(['/meet']);
    }
  }

  public send(tile, engagementType) {
    this.analytics.sendMParticleEvent(
      'Discover Flow Initiated',
      {
        'Flow ID': tile.id,
        'Type of Engagement': engagementType
      }
    );

    this.store.dispatch(new engagement.StartOnePageFlow({ tile, engagementType }));
  }

  public learnMore(flowId, url) {
    this.analytics.sendMParticleEvent(
      'Discover Learn More Clicked',
      { 'Flow ID': flowId }
    );
    window.open(url, '_blank');
  }

  public editAutomation(tile) {
    this.store.dispatch(new engagement.StartOnePageFlow({
      tile,
      engagementType: this.automationType,
      engagementMode: DiscoverConstants.edit
    }));
    this.router.navigate(['/discover/edit/edit-details']);
  }

  public dismissBanner() {
    this.store.dispatch(new user.DismissBannerAction(DiscoverConstants.globalBannerProp));
  }

  public stopAutomation(tile) {
    this.store.dispatch(new engagement.OpenCancellationDialog({
      tile,
      engagementType: this.automationType,
      engagementMode: DiscoverConstants.edit
    }));
  }

  public stopCommunityCareEvent(tile) {
    this.store.dispatch(new engagement.OpenCancellationDialog({
      tile,
      engagementType: this.automationType,
      engagementMode: DiscoverConstants.edit
    }));
  }

  public stopPromotion(tile) {
    this.store.dispatch(new engagement.OpenCancellationDialog({
      tile,
      engagementType: this.automationType,
      engagementMode: DiscoverConstants.edit
    }));
  }

  public markResolved(error) {
    this.store.dispatch(new discover.FailedCampaignMarkResolved(error));
  }

  public showFailedCampaignDetails(failedCampaign) {
    switch (failedCampaign.type) {
      case CalendarConstants.offer:
      case CalendarConstants.discover: {
        if (failedCampaign.automatedCampaign) {
          this.store.dispatch(new calendar.FetchAutomatedRewardDetails({ batchIds: failedCampaign.batchIds, type: failedCampaign.type }));
        }
        let rewardId = failedCampaign.type === CalendarConstants.offer ? failedCampaign.campaignId : failedCampaign.treatGroupId;
        let queryParams = {
          type: failedCampaign.type,
          mode: 'review',
          id: rewardId,
          automatedCampaign: failedCampaign.automatedCampaign,
          redirectedTo: 'discover'
        };
        this.router.navigate(['/engage/engage-details'], { queryParams });
        break;
      }

      case CalendarConstants.event: {
        let eventId = failedCampaign.campaignId;
        let queryParams = {
          type: failedCampaign.type,
          mode: 'review',
          id: eventId,
          redirectedTo: 'discover'
        };
        this.router.navigate(['/engage/engage-details'], { queryParams });
        break;
      }

      case CalendarConstants.email: {
        let emailId = failedCampaign.campaignId;
        let queryParams = {
          type: failedCampaign.type,
          mode: 'review',
          id: emailId,
          redirectedTo: 'discover'
        };
        this.router.navigate(['/engage/engage-details'], { queryParams });
      }
    }
  }

  public bannerBtnClick(redirectTo) {
    if (redirectTo.startsWith('/')) {
      this.router.navigate([redirectTo]);
    } else {
      window.open(redirectTo, '_blank');
    }
  }
}
