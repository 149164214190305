import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable()
export class VersionService {
  constructor(
        private meta: Meta
  ) { }

  public getVersion(): string {
    const appVersionTag = this.meta.getTag('name=application-version');
    if (appVersionTag) {
      return appVersionTag.content
    }
    return ''
  }
}
