<spot-nav [title]="'Overlapping Engagements'"
          [isSmallNav]="isMobile$ | async"
          [isMobile]="isMobile$ | async"
          [isBackChevronDT]="true"
          (back)="goBack()">
</spot-nav>
<div *ngIf="(overlappingEngagements$ | async)?.loading"
     class="overlapping__loading-wheel"
     [cfaLoading]="true">
</div>
<error-message *ngIf="(overlappingEngagements$ | async)?.error"
               [error]="(overlappingEngagements$ | async)?.error"
               [page]="'Overlapping Engagements'">
</error-message>
<ng-container *ngIf="!(overlappingEngagements$ | async)?.error &&
                     !(overlappingEngagements$ | async)?.loading">
  <mat-toolbar class="overlapping__treat-name-background">
    <span class="overlapping__treat-name">{{campaignName$ | async}}</span>
  </mat-toolbar>
  <h3 class="overlapping__dates-title">Campaign Dates</h3>
  <h6 class="overlapping__dates">
    <b>{{(selectedEngagement$ | async)?.startDate | date:'MMMM d'}}</b> thru
    <b>{{(selectedEngagement$ | async)?.endDate | date:'MMMM d'}}</b>
  </h6>

  <div *ngIf="(overlappingEngagements$ | async)?.engagements?.offer?.length > 0">
    <h1 class="overlapping__category-title">Overlapping Rewards</h1>
    <mat-list class="overlapping__list">
      <div *ngFor="let offer of (overlappingEngagements$ | async)?.engagements?.offer; first as isFirst">
        <mat-list-item class="overlapping__list-item">
          <img alt="Gift Image"
               class="overlapping__image"
               matListItemAvatar
               src="../../../assets/img/gift.png" />
          <h4 matListItemTitle>
            {{offer.title}}
          </h4>
          <span matListItemLine>
            <b>Start:</b> {{offer.startDate | locTimeZoneDate:'MMM DD, YYYY'}} <b>End:</b> {{offer.endDate | locTimeZoneDate:'MMM DD, YYYY'}}
          </span>
        </mat-list-item>
      </div>
    </mat-list>
  </div>
  <div *ngIf="(overlappingEngagements$ | async)?.engagements?.promotion?.length > 0">
    <h1 class="overlapping__category-title">Overlapping Promotions</h1>
    <mat-list appearance="outline" class="overlapping__list">
      <div *ngFor="let promotion of (overlappingEngagements$ | async)?.engagements?.promotion; first as isFirst">
        <mat-list-item class="overlapping__list-item">
          <img alt="Gift Image"
               class="overlapping__image"
               matListItemAvatar
               src="../../../assets/img/gift.png" />
          <h4 matListItemTitle>
            {{promotion.title}}
          </h4>
          <span matListItemLine>
            <b>Start:</b> {{promotion.startDate | locTimeZoneDate:'MMM DD, YYYY'}} <b>End:</b> {{promotion.endDate | locTimeZoneDate:'MMM DD, YYYY'}}
          </span>
        </mat-list-item>
      </div>
    </mat-list>
  </div>
  <div *ngIf="(overlappingEngagements$ | async)?.engagements?.event?.length > 0">
    <h1 class="overlapping__category-title">Overlapping Events</h1>
    <mat-list appearance= "outline" class="overlapping__list">
      <div *ngFor="let event of (overlappingEngagements$ | async)?.engagements?.event; first as isFirst">
        <mat-list-item class="overlapping__list-item">
          <img alt="Calendar Image"
               class="overlapping__image"
               matListItemAvatar
               src="../../../assets/img/calendar.png" />
          <h4 matListItemTitle>
            {{event.title}}
          </h4>
          <span matListItemLine>
            <b>Start:</b> {{event.startDate | date:'MMM dd, YYYY'}} <b>End:</b> {{event.endDate | locTimeZoneDate:'MMM DD, YYYY'}}
          </span>
        </mat-list-item>
      </div>
    </mat-list>
  </div>
</ng-container>
<br>
<div *ngIf="!(overlappingEngagements$ | async)?.loading"
     [ngClass]="{'overlapping__button-container-mobile' : isMobile$ | async}">
  <button mat-button (click)="goBack()">
    Back
  </button>
</div>
