import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.scss']
})

export class TemplatePreviewComponent {
  @Input() public showEmailPreview: boolean = true;
  @Input() public showAppPreview: boolean = false;
  @Input() public showPromotionAppPreview: boolean = false;
  @Input() public showRsvpPreview: boolean = false;
  @Input() public showBonusPointPreview: boolean = false;
  @Input() public isMobile: boolean = false;
  @Input() public templateHtml: SafeHtml = '';
  @Input() public rsvpHtml: SafeHtml = '';
  @Input() public noRsvpHtml: SafeHtml = '';
  @Input() public changeImageOptions: any = '';
  @Input() public selectedTabIndex: number = 0;
  @Input() public isChangeThemeEnabled: boolean = false;
  @Input() public isChangeImageEnabled: boolean = false;
  @Input() public isChangeCopyEnabled: boolean = false;
  @Input() public showCommunityAppPreview: boolean = false;
  @Input() public showThirdPartyPromotion: boolean = false;
  @Input() public showFreeItemInCartPreview: boolean = false;

  @Output() public changeImageClicked = new EventEmitter();
  @Output() public changeThemeClicked = new EventEmitter();
  @Output() public changeCopyClicked = new EventEmitter();
  @Output() public selectedTabChanged = new EventEmitter();
  @Output() public backBtnClicked = new EventEmitter();

  public additionalImagesAvailable() {
    // Now that we have the ability to filter out image options based on theme we need to handle the case
    // when the selected theme has no images available even though the template itself does
    return this.changeImageOptions && this.changeImageOptions.length > 0
      ? this.changeImageOptions[0].changeImageOptions && this.changeImageOptions[0].changeImageOptions.length > 0
      : false;
  }
}
