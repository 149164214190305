import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { State } from '../../../reducers';
import { browserRefreshed } from '../../../app.component';
import { DiscoverConstants } from '../../discover.constants';
import * as fromRoot from '../../../reducers';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { ActiveRewardDialogComponent } from '../../../shared/active-reward-dialog/active-reward-dialog.component';
import * as engagement from '../../../actions/engagement';
import * as moment from 'moment-timezone';
import {
  CommunityCareConfirmationDialogComponent
} from '../../../shared/community-care-confirmation-dialog/community-care-confirmation-dialog.component';

@Component({
  templateUrl: './email-preview.component.html',
  styleUrls: ['./email-preview.component.scss']
})

export class DiscoverEmailPreviewComponent implements OnInit {
  public customerCount$: Observable<number>;
  public maxCustomers$: Observable<number>;
  public templateHtml$: Observable<SafeHtml>;
  public engagementType$: Observable<string>;
  public engagementMode$: Observable<string>;
  public startDate$: Observable<any>;
  public error$: Observable<string>;
  public loading$: Observable<boolean>;
  public isMobile$: Observable<boolean>;
  public readOnlyUser$: Observable<boolean>;

  public duration = DiscoverConstants.defaultDuration;
  public rewardType = DiscoverConstants.reward;
  public emailType = DiscoverConstants.email;
  public promotionTypes = [DiscoverConstants.promotion, DiscoverConstants.categoryCommunityCare,
    DiscoverConstants.thirdPartyPromotion, DiscoverConstants.freeItemInCartPromotion]

  public promotionType = DiscoverConstants.promotion;
  public categoryCommunityCareType = DiscoverConstants.categoryCommunityCare
  public thirdPartyPromotionType = DiscoverConstants.thirdPartyPromotion
  public freeItemInCartType = DiscoverConstants.freeItemInCartPromotion
  public automationType = DiscoverConstants.automation;
  public editMode = DiscoverConstants.edit;

  constructor(private store: Store<State>,
              private router: Router,
              private dialog: MatDialog,
              private sanitizer: DomSanitizer) {}

  public ngOnInit() {
    window.scroll(0, 0);
    if (browserRefreshed) {
      this.router.navigate(['/discover']);
    } else {
      this.customerCount$ = this.store.select(fromRoot.getEngagementCount);
      this.maxCustomers$ = this.store.select(fromRoot.getDiscoverTileById).pipe(map((tile) => tile.count));
      this.templateHtml$ = this.store.select(fromRoot.getReplacedOnePageFlowHtml).pipe(
        map((html) => this.sanitizer.bypassSecurityTrustHtml(html))
      );
      this.engagementType$ = this.store.select(fromRoot.getEngagementType);
      this.engagementMode$ = this.store.select(fromRoot.getEngagementMode);
      this.startDate$ = this.store.select(fromRoot.getEngagementStartDate);
      this.loading$ = this.store.select(fromRoot.getEngagementLoading);
      this.error$ = this.store.select(fromRoot.getEngagementError);
      this.readOnlyUser$ = this.store.select(fromRoot.getReadOnlyUser);
      this.store.select(fromRoot.getEngagementLength).pipe(take(1)).subscribe((treatLength) => {
        this.duration = treatLength;
      });
      this.isMobile$ = this.store.select(fromRoot.getIsExtraSmallMobile);
    }
  }

  private getDayToAdd$() {
    return this.engagementType$.pipe(map((type) => {
      if (this.isStartASunday() && type !== this.automationType) {
        return Number(this.duration) + 1;
      } else {
        return Number(this.duration);
      }
    }));
  }

  public isStartASunday() {
    return moment().weekday() === 0;
  }

  public sendReward() {
    this.getDayToAdd$().pipe(take(1)).subscribe((daysToAdd) => {
      this.customerCount$.pipe(take(1)).subscribe((count) => {
        let config = new MatDialogConfig();
        let startDate = this.isStartASunday() ? moment().add(1, 'd') : moment();
        config.data = {
          count,
          startDate: startDate,
          endDate: moment().add(daysToAdd, 'd'),
          confirmationOnly: true
        };
        config.panelClass = 'dialog-responsive';
        this.dialog.open(ActiveRewardDialogComponent, config).afterClosed()
          .subscribe((confirm) => {
            if (confirm) {
              this.store.dispatch(new engagement.SendEngagement());
            }
          });
      });
    });
  }

  public sendEmail() {
    this.maxCustomers$.pipe(take(1)).subscribe((count) => {
      let config = new MatDialogConfig();
      config.data = {
        displayText: `You\'ve selected ${count} customer(s) to receive this communication. Would you like to proceed?`,
        confirmText: 'Send Email'
      };
      config.panelClass = 'dialog-responsive';
      this.dialog.open(ConfirmationDialogComponent, config).afterClosed()
        .subscribe((confirm) => {
          if (confirm) {
            this.store.dispatch(new engagement.SendEngagement());
          }
        });
    });
  }

  public sendPromotion() {
    this.startDate$.pipe(take(1)).subscribe((startDate) => {
      const formattedStartDate = startDate.format('dddd, MMMM DD YYYY');
      let config = new MatDialogConfig();
      config.data = {
        title: `You are about to create a promotion that starts on ${formattedStartDate}`,
        confirmText: 'Send',
        displayText: 'Once this promotion has been created, you can edit or stop the automation from discover view.'
      };
      config.panelClass = 'dialog-responsive';
      this.dialog.open(ConfirmationDialogComponent, config).afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.store.dispatch(new engagement.SendEngagement());
        }
      });
    });
  }

  public sendCommunityEvent() {
    this.dialog.open(CommunityCareConfirmationDialogComponent, { panelClass: 'dialog-responsive' }).afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.store.dispatch(new engagement.SendEngagement());
      }
    });
  }

  public sendPromotions() {
    let config = {
      data: {
        title: '',
        displayText: 'Are you sure you want to create this promotion?',
        confirmText: 'Yes, Create Promotion',

      },
      autoFocus:false,
      panelClass: 'dialog-responsive'
    }
    this.dialog.open(ConfirmationDialogComponent, config).afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.store.dispatch(new engagement.SendEngagement());
      }
    });
  }

  public sendAutomation() {
    this.store.select(fromRoot.getDiscoverTileById).pipe(take(1)).subscribe((tile) => {
      let config = new MatDialogConfig();
      config.data = {
        displayText: tile.automationConfirmationText,
        confirmText: 'Automate Reward'
      };
      config.panelClass = 'dialog-responsive';
      this.dialog.open(ConfirmationDialogComponent, config).afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.store.dispatch(new engagement.SendEngagement());
        }
      });
    });
  }

  public update() {
    let config = new MatDialogConfig();
    config.data = {
      displayText: DiscoverConstants.automationWarning,
      confirmText: 'Save Updates'
    };
    config.panelClass = 'dialog-responsive';
    this.dialog.open(ConfirmationDialogComponent, config).afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.store.dispatch(new engagement.EditAutomation());
      }
    });
  }

  public stopAutomation() {
    this.store.select(fromRoot.getDiscoverTileById).pipe(take(1)).subscribe((tile) => {
      let config = new MatDialogConfig();
      let fieldId = tile.tileObjectId ? tile.tileObjectId : '';

      config.data = {
        title: tile.automationCancellationTitle,
        displayText: tile.automationCancellationBody,
        confirmText: 'Stop Automation'
      };
      config.panelClass = 'dialog-responsive';
      this.dialog.open(ConfirmationDialogComponent, config).afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.store.select(fromRoot.getCurrentEngagementId).pipe(take(1)).subscribe((id) => {
            this.store.dispatch(new engagement.StopAutomation({ id, fieldId }));
          });
        }
      });
    });
  }

  public goBack() {
    this.engagementType$.pipe(take(1)).subscribe((type) => {
      if (type === this.promotionType) {
        this.router.navigate(['/discover/edit/edit-promotion-details']);
      } else if (type === this.categoryCommunityCareType) {
        this.router.navigate(['/discover/edit/edit-community-care-detail']);
      } else if (type === this.thirdPartyPromotionType) {
        this.router.navigate(['/discover/edit/edit-third-party-promotion']);
      } else if (type === DiscoverConstants.freeItemInCartPromotion) {
        this.router.navigate(['/discover/edit/edit-free-item-in-cart-details']);
      } else {
        this.router.navigate(['/discover/edit/edit-details']);
      }
    })
  }
}
