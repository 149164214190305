<mat-toolbar *ngIf="!(isMobile$ | async)" color="accent" class="ngx-navbar">
    <br>
    <span routerLink="/">
        <img alt="Spotlight" class="ngx-navbar__logo" src="https://www.cfacdn.com/img/Spotlight/Icons/SpotlightFixNavLogo.svg">
    </span>
    <nav mat-tab-nav-bar [disablePagination]="true" [tabPanel]="tabNavPanel">
        <ng-container *ngFor="let link of navLinks; let i = index">
            <span *ngIf="!link.mobileOnly"
                  mat-tab-link
                  class="ngx-navbar__tab-elements"
                  [routerLink]="link.url"
                  [ngClass]="{'ngx-navbar__active': link.active}"
                  [active]="link.active">
                {{link.label}}
            </span>
        </ng-container>
    </nav>
    <mat-tab-nav-panel #tabNavPanel>
    </mat-tab-nav-panel>
    <span fxFlex></span>
    <mat-form-field appearance="fill" class="ngx-navbar__select">
        <mat-select #locationMenu
                    panelClass="ngx-navbar__panel"
                    [ngModel]="selectedLocation"
                    [disabled]="stateDisabled()"
                    (selectionChange)="updateLocation($event.value)"
                    (closed)="clearLocationFiltering()">
            <mat-select-trigger>
                <div style="margin-top:-7px">
                    <button  mat-icon-button (click)="locationMenu.open()">
                        <mat-icon class="ngx-navbar__view-icon">location_on</mat-icon>
                        <span class="ngx-navbar__location-name">
                            {{selectedLocation.locationName}}
                        </span>
                    </button>
                </div>
            </mat-select-trigger>
            <mat-form-field appearance="fill" *ngIf="isStaffOrContractor" class="ngx-navbar__search">
                    <mat-icon matPrefix class="ngx-navbar__search-icon">search</mat-icon>
                    <input matInput
                           [value]="searchTerm"
                           placeholder="Search Locations"
                           (keyup)="onSearch($event)"
                           (keydown)="$event.stopPropagation()">
            </mat-form-field>
            <mat-option *ngFor="let loc of getDisplayLocations$() | async"
                        [value]="loc"
                        [disabled]="!loc.locationName">
                {{loc.locationName ? loc.locationName : 'N/A'}}
            </mat-option>
            <pagination *ngIf="isStaffOrContractor"
                        [totalElements]="(getFilteredLocations$() | async).length"
                        [pageSize]="pageSize"
                        [currentPage]="currentPage"
                        (pageRight)="pageRight()"
                        (pageLeft)="pageLeft()">
            </pagination>
        </mat-select>
    </mat-form-field>
    <span fxFlex.gt-sm="30px" fxFlex="1px"></span>
    <div class="ngx-navbar__menu-container">
        <button mat-icon-button [matMenuTriggerFor]="menu"
                matBadge = "{{(unreadNotificationCount$ | async) > 0 ? (unreadNotificationCount$ | async)  : ''}}">
            <mat-icon class="ngx-navbar__menu-icon">menu</mat-icon>
        </button>
    </div>
    <mat-menu #menu="matMenu" class="ngx-navbar__menu">
        <button mat-menu-item (click)="openNotifications()">
            <mat-icon>notifications</mat-icon>
            <span>Notifications
                <span *ngIf="(unreadNotificationCount$ | async) > 0" class="ngx-navbar__notification-number">
                    {{unreadNotificationCount$ | async}} new
                </span>
            </span>
        </button>
        <button mat-menu-item (click)="openSettings()">
            <mat-icon>settings</mat-icon>
            <span>Settings</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="logoutUser()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Logout</span>
        </button>
    </mat-menu>
</mat-toolbar>
<mat-toolbar *ngIf="(isMobile$ | async)">
    <mat-toolbar-row class="ngx-navbar-mobile__header" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="ngx-navbar-mobile__location-container"
             [ngClass]="{'ngx-navbar-mobile__location-visibility-hide': !showLocationNumber}"
             (click)="navigateToMenu()">
            <span>
                <img alt="Location Marker Icon"
                     class="ngx-navbar-mobile__location-icon"
                     src="https://www.cfacdn.com/img/Spotlight/Icons/Location_Marker.png">
            </span>
            <span class="ngx-navbar-mobile__location-number">
                {{(selectedLocation$ | async)?.locationNumber}}
            </span>
        </div>
        <div routerLink="/" class="ngx-navbar-mobile__logo-container">
            <img alt="Spotlight"
                 class="ngx-navbar-mobile__logo"
                 src="https://www.cfacdn.com/img/Spotlight/Icons/Spotlight_Logo.png">
        </div>
        <div routerLink="/menu" class="ngx-navbar-mobile__menu-container">
            <img alt="Sandwich Menu Bar Icon"
                 class="ngx-navbar-mobile__menu"
                 src="https://www.cfacdn.com/img/Spotlight/Icons/Mobile_Menu.png">
        </div>
    </mat-toolbar-row>
    <mat-toolbar-row class="ngx-navbar-mobile__subheader"
                     *ngIf="showSelectedLocationName"
                     fxLayout="row" fxLayoutAlign="center center">
        <span (click)="navigateToMenu()">
            <span>
                <img alt="Location Marker Icon"
                     class="ngx-navbar-mobile__location-icon"
                     src="https://www.cfacdn.com/img/Spotlight/Icons/Location_Marker.png">
            </span>
            <span class="ngx-navbar-mobile__location-number ngx-navbar-mobile__location-number-margin">
                {{(selectedLocation$ | async).locationName ? (selectedLocation$ | async).locationName : 'N/A'}}
            </span>
        </span>
    </mat-toolbar-row>
</mat-toolbar>
<navbar-footer *ngIf="isMobile$ | async"
               [navLinks]=navLinks>
</navbar-footer>
