<div *ngIf="loading"
     class="mobile-data-table__spinner"
     >
</div>
<div class="flex-container mobile-data-table__error"
     fxLayout="row"
     fxLayoutAlign="start center"
     *ngIf="!isDataAvailable() && !loading">
  <data-table-error [errorText]="noResultsMessage"
                    [actionableText] = "actionableErrorText"
                    [isVisibleDeepLinkMsg]="isVisibleDeepLinkMsg"
                    [deepLinkMessage]="deepLinkMessage"
                    (deepLinkMessageClick)="onClickDeepLinkMsg()"
                    (textClick)="onErrorTextClick.emit()">
  </data-table-error>
</div>
<div *ngIf="enableMobileCheckbox && !loading && isDataAvailable()"
     class="mobile-data-table__select-all-container"
     fxLayout="row"
     fxLayoutAlign="space-between center">
  <div fxLayoutAlign="start center">
    <mat-checkbox *ngIf="enableMobileCheckbox"
                  class="mobile-data-table__select-all-checkbox"
                  [checked]="isCustomersSelectedOnPage()"
                  [disabled]="disableCheckboxes"
                  (change)="selectAllOnPageOnly($event)">
    </mat-checkbox>
    <div *ngIf="enableMobileCheckbox">Select All</div>
  </div>
  <actions-menu *ngIf="mobileActionMenuMetaData"
                [hidden]="mobileActionMenuMetaData.hidden"
                [canMakeInactive]="mobileActionMenuMetaData.isInactiveView"
                [selectedCustomerIds]="mobileActionMenuMetaData.customerSelections"
                [disableActionMenu]="mobileActionMenuMetaData.disableActionMenu">
  </actions-menu>
</div>
<div *ngIf="(selectedItems?.length !== allCustomerIds?.length) && isCustomersSelectedOnPage() && enableMobileCheckbox && !isOnePage()"
     class="flex-container mobile-data-table__select-all-message"
     fxLayout="column"
     fxLayoutAlign="center center">
  <span>Selected only {{uniqueRowsOnPage.length}} Customers on this page.</span>
  <span class="ngx-data-table__select-all-message-clickable" (click)="selectAllClicked(true)">
      Click here to select all {{allCustomerIds.length}} Customers in your Spotlight
  </span>
</div>
<mat-nav-list *ngIf="isDataAvailable() && !loading">
  <mat-list-item *ngFor="let row of rows; let i = index"
                 [ngClass]="{'mobile-data-table__blue-row-background' : (i % 2  !== 0),
                             'mobile-data-table__white-row-background' : (i % 2  === 0),
                             'mobile-data-table__blue-row-non-interactive-background' : (i % 2  !== 0) && isRowNonInteractive(row)}"
                 [disableRipple]="enableMobileCheckbox || shouldDisableRow(row) || disableRipple"
                 (click)="rowClicked(row)"
                 fxLayout="row"
                 fxLayoutAlign="center center"
                 class="mobile-data-table__list-item">
    <mat-checkbox class="mobile-data-table__checkbox"
                  *ngIf="enableMobileCheckbox"
                  [(ngModel)]="row.state"
                  [disabled]="disableCheckboxes"
                  (click)="$event.stopPropagation(); checkboxClick(row)">
    </mat-checkbox>
    <div *ngIf="mobileDeleteEnabled" fxFlex="15">
      <img alt="" class="mobile-data-table__delete-icon" src="{{deleteIconUrl}}" (click)="$event.stopPropagation(); deleteClicked(row)">
    </div>
    <div *ngIf="useMembershipAsIcon" fxFlex="12">
      <member-status [membership]="getMembership(row)"
                     [displayCfaOne]="getDisplayCfaOne()"
                     [isEnableSignatureMembership]="isEnableSignatureMembership"
                     [shouldDisplayIcon]="true"
                     [shouldDisplayLabel]="false"
                     [isMobile]="true">
      </member-status>
    </div>
    <div fxFlex>
      <div *ngFor="let col of mobileColumns"
           class="mobile-data-table"
           [ngClass]="{'mobile-data-table__row-disabled' : shouldDisableRow(row)}">
        <data-table-cell [row]="row"
                         [col]="col"
                         [isMobile]="true"
                         [mobileTitle]="col.mobileTitle"
                         [mobileTitleBold]="col.mobileTitleBold"
                         [isEnableSignatureMembership]="isEnableSignatureMembership"
                         [shouldDisplayIcon]="shouldDisplayIcon"
                         [shouldDisplayLabel]="shouldDisplayLabel"
                         [shouldDisplayMemberText]="shouldDisplayMemberText"
                         (onExternalLinkClick)="viewSyncReport(row)">
        </data-table-cell>
      </div>
    </div>
    <mat-icon class="mobile-data-table__chevron" fxFlex *ngIf="!shouldHideChevron(row)">keyboard_arrow_right</mat-icon>
    <div *ngIf="row[noteProp] && row[noteProp].length > 0" class="mobile-data-table__note-section">
      <div *ngIf="!row.isNoteOpen"
           (click)="$event.stopPropagation(); noteClicked(row)"
           class="mobile-data-table__note-section-label">
        View Notes
      </div>
      <div *ngIf="row.isNoteOpen"
           (click)="$event.stopPropagation(); noteClicked(row)"
           class="mobile-data-table__note-section-label">
        Hide Notes
      </div>
    </div>
    <div *ngIf="row[noteProp] && row[noteProp].length > 0 && row.isNoteOpen" fxLayout="row wrap" fxFlex="100">
      <div *ngFor="let note of row[noteProp]" fxLayout="row" fxFlex="100">
        <div class="mobile-data-table__note">
          <div>
            <span class="mobile-data-table__note-name">{{note.updatedBy}}</span>
            <span class="mobile-data-table__note-date"> - {{note.updatedAt | locTimeZoneDate:'MMM DD, YYYY hh:mm A'}}</span>
          </div>
          <div class="mobile-data-table__note-text">{{note.note}}</div>
        </div>
      </div>
    </div>
  </mat-list-item>
</mat-nav-list>
<data-table-footer *ngIf="showFooter && !loading"
                   [totalElements]="totalElements"
                   [pageSize]="pageSize"
                   [currentPage]="currentPage"
                   [numberOfPages]="numberOfPages"
                   [isMobile]="true"
                   (onPageChange)="changeCurrentPage($event)">
</data-table-footer>
