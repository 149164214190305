import { Component } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { AnalyticsService } from '../../core/analytics';
import { AppConstants } from '../../app.constants';
import { browserRefreshed } from '../../app.component';
import { MeetConstants } from '../../meet/meet.constants';
import { EngageConstants } from '../../engage/engage.constants';
import { IconConfirmationDialogComponent } from '../icon-confirmation-dialog/icon-confirmation-dialog.component';
import { SaveGroupNameDialogComponent } from '../save-group-name-dialog/save-group-name-dialog.component';
import * as meet from '../../actions/meet';
import * as customer from '../../actions/customer';
import * as engage from '../../actions/engage';

@Component({
  selector: 'filter-display-for-mobile',
  templateUrl: './filter-display-for-mobile.html',
  styleUrls: ['./filter-display-for-mobile.scss'],
  animations: [
    trigger('arrowState', [
      state('desc', style({
        transform: 'rotate(-90deg)',
        opacity: 1.0
      })),
      state('asc', style({
        transform: 'rotate(90deg)',
        opacity: 1.0
      })),
      transition('desc => asc', animate('150ms ease-in-out')),
      transition('asc => desc', animate('150ms ease-in-out')),
    ])
  ]
})

export class FilterDisplayForMobileComponent {
  public loading$: Observable<boolean>;
  public error$: Observable<string>;
  public allCustomerIds$: Observable<string[]>;
  public filters$: Observable<any[]>;
  public filtersStartDate$: Observable<any>;
  public filtersEndDate$: Observable<any>;
  public filtersWithoutSelectedSubOption$: Observable<number>;
  public filtersWithoutSelectedToggle$: Observable<number>;
  public selectedFilters$: Observable<any[]>;
  public includedCustomers$: Observable<any[]>;
  public excludedCustomers$: Observable<any[]>;
  public savedGroups$: Observable<any[]>;
  public savedGroupsLoading$: Observable<boolean>;
  public savedGroupsError$: Observable<string>;
  public meetSavedGroupBeingEdited$: Observable<any>;
  public disableSavedGroupEditAndDelete$: Observable<boolean>;
  public filteredCustomers$: Observable<any[]>;
  public filteredCustomersLoading$: Observable<boolean>;
  public totalCustomers$: Observable<number>;
  public filterDisplayCustomerCount$: Observable<number>;
  public selectedSavedGroups$: Observable<any[]>;
  public displayCustomerLoading$: Observable<boolean>;

  public previousUrl = '';
  public page = '';
  public navTitle = 'Show me customers who...';
  public meetPage = AppConstants.meetPage;
  public engagePage = AppConstants.engagePage;
  public confirmDeleteText = MeetConstants.deleteText;
  public cancelDeleteText = MeetConstants.cancelDeleteText;
  public savedGroupDeleteText = MeetConstants.savedGroupDeleteText;
  public deleteIcon = MeetConstants.deleteIcon;
  public savedGroupDeleteWarningText = MeetConstants.savedGroupDeleteWarningText;

  constructor(private store: Store<fromRoot.State>,
              private analytics: AnalyticsService,
              private activatedRoute: ActivatedRoute,
              public dialog: MatDialog,
              private router: Router) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const { previousRoute, page } = params;
      this.previousUrl = previousRoute ? previousRoute : this.previousUrl;
      this.page = page ? page : this.page;
    });

    // If the user refreshes on this page, redirect them to their starting point (either Engage or Meet)
    if (browserRefreshed) {
      const route = this.page === AppConstants.engagePage ? '/engage/template-selection' : '/meet';
      this.router.navigate([route]);
    }

    this.loading$ = store.select(fromRoot.getFiltersLoading);
    this.error$ = store.select(fromRoot.getCustomersError);
    this.filters$ = store.select(fromRoot.getDisplayFilters(this.page));
    this.allCustomerIds$ = this.store.select(fromRoot.getEngageFilterDisplayCustomerAllCustomerIds);
    this.selectedFilters$ = store.select(fromRoot.getSelectedFiltersByPage(this.page));
    this.filtersStartDate$ = store.select(fromRoot.getFiltersStartDateByPage(this.page));
    this.filtersEndDate$ = store.select(fromRoot.getFiltersEndDateByPage(this.page));
    this.filtersWithoutSelectedSubOption$ = store.select(fromRoot.getFiltersWithoutSelectedSubOption);
    this.filtersWithoutSelectedToggle$ = store.select(fromRoot.getFiltersWithoutSelectedToggle);
    this.selectedSavedGroups$ = this.store.select(fromRoot.getSelectedSavedGroupsByPage(this.page));
    this.includedCustomers$ = store.select(fromRoot.getIncludedCustomersByPage(this.page));
    this.excludedCustomers$ = store.select(fromRoot.getExcludedCustomersByPage(this.page));
    this.savedGroups$ = store.select(fromRoot.getDisplaySavedGroups(this.page));
    this.savedGroupsLoading$ = store.select(fromRoot.getSavedGroupsLoading);
    this.savedGroupsError$ = store.select(fromRoot.getSavedGroupsError);
    this.meetSavedGroupBeingEdited$ = store.select(fromRoot.getMeetSavedGroupBeingEdited);
    this.disableSavedGroupEditAndDelete$ = this.store.select(fromRoot.getFeatureEnabled(MeetConstants.disableSavedGroupEditAndDeleteFlag));
    this.filteredCustomers$ = store.select(fromRoot.getFilterAutocompleteCustomersByPage(this.page));
    this.filteredCustomersLoading$ = store.select(fromRoot.getAutocompleteLoading);
    this.totalCustomers$ = store.select(fromRoot.getTotalCountOfCustomers);
    this.filterDisplayCustomerCount$ = store.select(fromRoot.getEngageFilterDisplayCustomerCount);
    this.displayCustomerLoading$ = this.store.select(fromRoot.getEngageFilterDisplayCustomerLoading);
    this.store.dispatch(new meet.LoadFilters({ page: this.page }));
  }

  public categoryToggled(category) {
    this.store.dispatch(new meet.ToggleCategoryExpansion({ category, page: this.page }));
  }

  public addSelectedFilter(filter) {
    this.analytics.sendMParticleEvent(
      'Filter Added',
      {
        'Id': filter.id,
        'Filter Name': filter.name,
        'Filter Category': filter.parentCategory,
        'Page': this.page
      }
    );
    this.store.dispatch(new meet.AddSelectedFilter({ filter, page: this.page }));
  }

  public removeSelectedFilter(filter) {
    this.analytics.sendMParticleEvent(
      'Filter Removed',
      {
        'Id': filter.id,
        'Filter Name': filter.name,
        'Filter Category': filter.parentCategory,
        'Page': this.page
      }
    );
    this.store.dispatch(new meet.RemoveSelectedFilter({ filter, page: this.page }));
  }

  public onCustomerSearch(term) {
    this.store.dispatch(new customer.AutocompleteSearch(term));
  }

  public onCustomerSelection(customerSelectionObject) {
    const customer = customerSelectionObject.customer;
    const filter = customerSelectionObject.filter;

    if (filter.id === MeetConstants.includeFilterId) {
      this.analytics.sendMParticleEvent(
        'Include Filter Individual Customer Added',
        {
          'Filter Id': filter.id,
          'Customer Id': customer.id,
          'Page': this.page
        }
      );
      this.store.dispatch(new meet.AddIndividualIncludeCustomer({ customer, page: this.page }));
    } else {
      this.analytics.sendMParticleEvent(
        'Exclude Filter Individual Customer Added',
        {
          'Filter Id': filter.id,
          'Customer Id': customer.id,
          'Page': this.page
        }
      );
      this.store.dispatch(new meet.AddIndividualExcludeCustomer({ customer, page: this.page }));
    }
  }

  public buttonToggled(buttonToggledObject) {
    const categoryId = buttonToggledObject.categoryId;
    const filter = buttonToggledObject.filter;
    const valueId = buttonToggledObject.valueId;

    this.analytics.sendMParticleEvent(
      'Filter Toggle Button Updated',
      {
        filterName: filter.name,
        newValue: valueId,
        page: this.page
      }
    );

    const filterPayload = { categoryId, filter, valueId };
    this.store.dispatch(new meet.UpdateButtonToggleAttribute({ ...filterPayload, page: this.page }));
  }

  public buttonChipToggled(buttonChipToggledObject) {
    const chip = buttonChipToggledObject.chip;
    const filter = buttonChipToggledObject.filter;
    const chipSection = buttonChipToggledObject.chipSection;

    this.analytics.sendMParticleEvent(
      'Button Chip Toggled',
      {
        filterName: filter.name,
        sectionName: chipSection.name,
        chipName: chip.name,
        chipValue: !chip.selected,
        page: this.page
      }
    );

    const chipPayload = { chip, filter, chipSection };
    this.store.dispatch(new meet.UpdateButtonChipAttribute({ ...chipPayload, page: this.page }));
  }

  public subOptionToggled(subOptionToggledObject) {
    const filter = subOptionToggledObject.filter;
    const subOption = subOptionToggledObject.subOption;

    this.analytics.sendMParticleEvent(
      'Button Chip Toggled',
      {
        filterName: filter.name,
        subOptionName: subOption.name,
        subOptionValue: !subOption.selected,
        page: this.page
      }
    );

    const subOptionPayload = { filter, subOption };
    this.store.dispatch(new meet.UpdateSubOptionAttribute({ ...subOptionPayload, page: this.page }));
  }

  public addSavedGroup(savedGroup) {
    this.analytics.sendMParticleEvent(
      'Saved Group Added',
      {
        'Id': savedGroup.id,
        'Filter Name': savedGroup.name,
        'Page': this.page
      }
    );
    this.store.dispatch(new meet.AddSavedGroup({ savedGroup, page: this.page }));
  }

  public removeSavedGroup(savedGroup) {
    this.analytics.sendMParticleEvent(
      'Saved Group Removed',
      {
        'Id': savedGroup.id,
        'Filter Name': savedGroup.name,
        'Page': this.page
      }
    );
    this.store.dispatch(new meet.RemoveSavedGroup({ savedGroup, page: this.page }));
  }

  public updateFilterDateRangeOption(dates) {
    this.store.dispatch(new meet.UpdateFilterDateRangeOption({ dates, page: this.page }));
  }

  public updateFilterDates(dates) {
    if (dates) {
      this.analytics.sendMParticleEvent(
        'Filter Dates Updated',
        {
          'Start Date': dates.startDate,
          'End Date': dates.endDate
        }
      );
    }
    this.store.dispatch(new meet.UpdateFilterDates({ dates, page: this.page }));
  }

  public sliderValueUpdated(payload) {
    this.analytics.sendMParticleEvent(
      'Filter Slider Updated',
      {
        'Filter ID': payload.id,
        'Value': payload.value
      }
    );
    this.store.dispatch(new meet.UpdateSliderValue({ ...payload, page: this.page }));
  }

  public twoVariableSliderValueUpdated(payload) {
    this.analytics.sendMParticleEvent(
      'Filter Two Variable Slider Updated',
      {
        'Filter ID': payload.id,
        'Low Value': payload.value,
        'High Value': payload.highValue
      }
    );
    this.store.dispatch(new meet.UpdateTwoVariableSliderValue({ ...payload, page: this.page }));
  }

  public goBack() {
    this.store.select(fromRoot.getMeetSavedGroupBeingEdited).pipe(take(1)).subscribe(groupBeingEdited => {
      if (groupBeingEdited) {
        this.navTitle = 'Show me customers who...';
        this.store.dispatch(new meet.ExitSavedGroupEdit());
      } else {
        this.router.navigate([this.previousUrl]);
      }
    });
  }

  public goBackWithUnsavedCheck() {
    if (this.page === AppConstants.engagePage) {
      this.store.select(fromRoot.areNewEngageFiltersSelected).pipe(take(1)).subscribe(areNewFiltersSelected => {
        if (areNewFiltersSelected) {
          let config: MatDialogConfig = {
            data: {
              title: EngageConstants.unsavedChangesTitle,
              displayText: EngageConstants.filtersUnsavedChangesText,
              iconUrl: EngageConstants.unsavedChangesIcon,
              confirmText: EngageConstants.filtersUnsavedChangesConfirmButton,
              cancelText: EngageConstants.filtersUnsavedChangesCancelButton,
              isMobile: true
            },
            autoFocus: false
          }
          this.dialog.open(IconConfirmationDialogComponent, config).afterClosed().subscribe((stayOnPage) => {
            if (!stayOnPage && stayOnPage !== undefined) {
              this.goBack();
            }
          });
        } else {
          this.goBack();
        }
      });
    } else {
      this.goBack();
    }
  }

  public selectAllCustomers() {
    this.analytics.sendMParticleEvent('Engage Filter Select All Customers Clicked', { });

    this.store.select(fromRoot.areEngageFiltersSelected).pipe(take(1)).subscribe(areFiltersSelected => {
      if (areFiltersSelected) {
        let config: MatDialogConfig = {
          data: {
            title: EngageConstants.selectAllUnsavedChangesTitle,
            displayText: EngageConstants.selectAllUnsavedChangesText,
            iconUrl: EngageConstants.unsavedChangesIcon,
            confirmText: EngageConstants.selectAllUnsavedChangesConfirmButton,
            cancelText: EngageConstants.selectAllUnsavedChangesCancelButton
          },
          autoFocus: false
        }

        this.dialog.open(IconConfirmationDialogComponent, config).afterClosed().subscribe((confirm) => {
          if (confirm) {
            this.analytics.sendMParticleEvent('Engage Filter Select All Customers Confirmed', { });
            this.selectAllCustomersAction();
          } else {
            this.analytics.sendMParticleEvent('Engage Filter Select All Customers Cancelled', { });
          }
        });
      } else {
        this.selectAllCustomersAction();
      }
    });
  }

  public selectAllCustomersAction() {
    this.allCustomerIds$.pipe(take(1)).subscribe(allIds => {
      this.store.dispatch(new engage.SelectAllCustomers(allIds));
      this.router.navigate(['/engage/engage-details']);
    });
  }

  public editSelectedSavedGroup(selectedSavedGroup) {
    this.navTitle = selectedSavedGroup.name;
    this.store.dispatch(new meet.EditSavedGroup(selectedSavedGroup));
  }

  public deleteSelectedSavedGroup(selectedSavedGroup) {
    let config: MatDialogConfig = {
      data: {
        title: this.savedGroupDeleteText,
        displayText: this.savedGroupDeleteWarningText,
        iconUrl: this.deleteIcon,
        cancelText: this.cancelDeleteText,
        confirmText: this.confirmDeleteText
      },
      autoFocus: false
    }

    this.dialog.open(IconConfirmationDialogComponent, config).afterClosed().subscribe((shouldDelete) => {
      if (shouldDelete) {
        this.analytics.sendMParticleEvent(
          'Meet Saved Group Deleted',
          { 'Saved Group Name': selectedSavedGroup.name }
        );
        this.store.dispatch(new meet.DeleteSavedGroup(selectedSavedGroup));
      }
    });
  }

  public renameSavedGroup() {
    this.dialog.open(SaveGroupNameDialogComponent);
  }

  public updateSavedGroup() {
    this.navTitle = 'Show me customers who...';
    this.meetSavedGroupBeingEdited$.pipe(take(1)).subscribe((savedGroup) => {
      this.analytics.sendMParticleEvent(
        'Meet Saved Group Updated',
        { 'Saved Group Name': savedGroup.name }
      );
      this.store.dispatch(new meet.UpdateSavedGroup(savedGroup.name));
    });
  }

  public apply() {
    this.store.select(fromRoot.getAllEngageSelectedFilterTypes).pipe(take(1)).subscribe(allFilterTypes => {
      this.store.dispatch(new engage.ApplyFilters(allFilterTypes));
      this.router.navigate(['/engage/engage-details']);
    });
  }

  public showAppliedFilters() {
    const queryParams = { previousRoute: '/filter/filter-display-for-mobile', page: this.page };
    this.router.navigate(['/filter/applied-filters'], { queryParams });
  }

  public showCustomersList() {
    const queryParams = { previousRoute: '/filter/filter-display-for-mobile', page: this.page };
    this.router.navigate(['/filter/show-customers-list'], { queryParams });
  }
}
