import * as fromProfile from '../actions/profile';
import * as customer from '../actions/customer';
import * as user from '../actions/user';
import * as moment from 'moment-timezone';
import { clone, countBy, findIndex, propEq } from 'ramda';
import { ProfileConstants } from '../customer-profile/customer-profile.constants';

// Customer profiles will be added to the state by the customer's CFA ID
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State {}

export const initialState: State = {};

export const dataInitialState = {
  data: { },
  error: null,
  loading: true
};

export const profileInitialState = {
  actionHistory: { ...dataInitialState },
  actionMenuLoading: true,
  caresData: { ...dataInitialState },
  cateringTransactionHistory: { ...dataInitialState },
  cemData: { ...dataInitialState },
  customerInformation: { ...dataInitialState },
  customerOffers: { ...dataInitialState },
  membershipDetails: { ...dataInitialState },
  digitalRefundsData: { ...dataInitialState },
  doorDashRecoveryData: { ...dataInitialState },
  recoverEmail: { ...dataInitialState },
  transactionHistory: { ...dataInitialState },
  transactionStats: { ...dataInitialState }
};

function getDayPart(transactionObject) {
  return transactionObject.transDayPart;
}

function formatOfferName(offerName, left, total) {
  return (total > 0) ? (offerName + ' (' + left + ' of ' + total + ')') : offerName;
}

export function reducer(
  state = initialState,
  action: fromProfile.Actions | customer.Actions | user.Actions
) {
  switch (action.type) {
    case customer.ActionTypes.MAKE_ACTIVE_SUCCESS:
    case customer.ActionTypes.MAKE_INACTIVE_SUCCESS:
    case fromProfile.ActionTypes.CREATE_NOTE:
    case fromProfile.ActionTypes.CUSTOMER_PROFILE_FETCH:
    case fromProfile.ActionTypes.DELETE_NOTE:
    case fromProfile.ActionTypes.DOORDASH_RECOVERY_FETCH_SUCCESS:
    case fromProfile.ActionTypes.ERROR:
    case fromProfile.ActionTypes.FETCH_CATERING_DATA:
    case fromProfile.ActionTypes.FETCH_OFFERS_SUCCESS:
    case fromProfile.ActionTypes.FETCH_MEMBERSHIP_SUCCESS:
    case fromProfile.ActionTypes.FETCH_TRANSACTION_DATA:
    case fromProfile.ActionTypes.FETCH_TRANSACTION_HISTORY_SUCCESS:
    case fromProfile.ActionTypes.FOUND_DATA:
    case fromProfile.ActionTypes.NOTE_ACTION_COMPLETE:
    case fromProfile.ActionTypes.NOTE_ERROR:
    case fromProfile.ActionTypes.UPDATE_NOTE:
    case fromProfile.ActionTypes.UPDATE_SORTED_TRANSACTION_DETAILS_DATA: {
      const payload = action.payload;
      return { ...state, [payload.id]: profile(state[payload.id], action) };
    }

    case fromProfile.ActionTypes.CLOSE_NOTE: {
      const payload = action.payload;
      let updatedState = clone(state);
      let notes = clone(state[payload.customerId].customerInformation.data.notes);

      // If it is a new note they are canceling then remove it. Otherwise, set isEditMode to false
      if (notes && notes[0] && notes[0].isNewNote) {
        notes = notes.slice(1, notes.length);
      } else {
        notes.map((note) => {
          note.isEditMode = false;
        });
      }

      updatedState[payload.customerId].customerInformation.data.notes = notes;
      // Make sure the user stays on the same page as the note they closed
      const startingIndex = payload.pageSize * payload.currentPage;
      const remainingNotes = notes.slice(startingIndex, notes.length);
      const pageSize = remainingNotes.length >= payload.pageSize ? payload.pageSize : remainingNotes.length;
      updatedState[payload.customerId].customerInformation.data.displayNotes =
        notes.slice(startingIndex, startingIndex + pageSize);

      return { ...updatedState };
    }

    case fromProfile.ActionTypes.EDIT_NOTE: {
      const payload = action.payload;
      let updatedState = clone(state);
      let notes = clone(state[payload.customerId].customerInformation.data.notes);

      // If a new note is already open remove it, they chose to discard unsaved changes
      // Set isEditMode to false for any note except for the new one being edited
      notes = notes.reduce((result,note) => {
        if (!note.isNewNote) {
          if (note.isEditMode) {
            note.isEditMode = false;
          } else if (note.noteId === payload.noteId) {
            note.isEditMode = true;
            note.updatedText = note.note;
          }
          result.push(note)
        }
        return result;
      },[]);

      updatedState[payload.customerId].customerInformation.data.notes = notes;
      // Make sure the user stays on the same page as the note they are editing
      const startingIndex = payload.pageSize * payload.currentPage;
      const remainingNotes = notes.slice(startingIndex, notes.length);
      const pageSize = remainingNotes.length >= payload.pageSize ? payload.pageSize : remainingNotes.length;
      updatedState[payload.customerId].customerInformation.data.displayNotes =
        notes.slice(startingIndex, startingIndex + pageSize);
      return { ...updatedState };
    }

    case fromProfile.ActionTypes.OPEN_NOTE: {
      const payload = action.payload;
      let updatedState = clone(state);
      let notes = clone(state[payload.customerId].customerInformation.data.notes);

      // If a new note is already open remove it, they chose to discard unsaved changes
      // Set isEditMode to false for any note currently being edited
      notes = notes.filter((note) => {
        if (!note.isNewNote) {
          if (note.isEditMode) {
            note.isEditMode = false;
          }
          return true;
        }
        return false;
      });

      // Add a new empty note to the beginning of the array
      notes.unshift({
        note: '',
        cfaUid: payload.customerId,
        updatedBy: payload.userName,
        updatedText: '',
        isNewNote: true,
        isEditMode: true
      });

      updatedState[payload.customerId].customerInformation.data.notes = notes;
      // Make sure there are still only the max notes per page being displayed
      const firstPageSize = notes.length >= payload.pageSize ? payload.pageSize : notes.length;
      updatedState[payload.customerId].customerInformation.data.displayNotes = notes.slice(0, firstPageSize);

      return { ...updatedState };
    }

    case user.ActionTypes.SELECT_LOCATION: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
}

export function profile(
  state = profileInitialState,
  action: fromProfile.Actions | customer.Actions | user.Actions
) {
  switch (action.type) {
    case customer.ActionTypes.MAKE_ACTIVE_SUCCESS:
    case customer.ActionTypes.MAKE_INACTIVE_SUCCESS:
    case fromProfile.ActionTypes.CREATE_NOTE:
    case fromProfile.ActionTypes.DELETE_NOTE:
    case fromProfile.ActionTypes.DOORDASH_RECOVERY_FETCH_SUCCESS:
    case fromProfile.ActionTypes.ERROR:
    case fromProfile.ActionTypes.FETCH_TRANSACTION_HISTORY_SUCCESS:
    case fromProfile.ActionTypes.FETCH_OFFERS_SUCCESS:
    case fromProfile.ActionTypes.FETCH_MEMBERSHIP_SUCCESS:
    case fromProfile.ActionTypes.FOUND_DATA:
    case fromProfile.ActionTypes.NOTE_ACTION_COMPLETE:
    case fromProfile.ActionTypes.NOTE_ERROR:
    case fromProfile.ActionTypes.UPDATE_NOTE:
    case fromProfile.ActionTypes.UPDATE_SORTED_TRANSACTION_DETAILS_DATA: {
      const payload = action.payload;
      let actionMenuLoading = payload.actionMenuLoading !== undefined ?
        payload.actionMenuLoading : state.actionMenuLoading;
      return { ...state, actionMenuLoading, [payload.type]: dataType(state[payload.type], action) };
    }

    case fromProfile.ActionTypes.FETCH_CATERING_DATA: {
      return {
        ...state,
        cateringTransactionHistory: { ...dataInitialState }
      };
    }

    case fromProfile.ActionTypes.FETCH_TRANSACTION_DATA: {
      return {
        ...state,
        transactionHistory: { ...dataInitialState },
        transactionStats: { ...dataInitialState }
      };
    }

    default: {
      return state;
    }
  }
}

export function dataType(
  state = dataInitialState,
  action: fromProfile.Actions | customer.Actions | user.Actions
) {
  switch (action.type) {
    case customer.ActionTypes.MAKE_ACTIVE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state['data'],
          inactive: false
        }
      };
    }

    case customer.ActionTypes.MAKE_INACTIVE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state['data'],
          inactive: true
        }
      };
    }

    case fromProfile.ActionTypes.CREATE_NOTE:
    case fromProfile.ActionTypes.DELETE_NOTE:
    case fromProfile.ActionTypes.UPDATE_NOTE: {
      return { ...state, loading: true };
    }

    case fromProfile.ActionTypes.DOORDASH_RECOVERY_FETCH_SUCCESS: {
      const data = action.payload.data;
      return {
        ...state,
        data,
        loading: false
      };
    }

    case fromProfile.ActionTypes.ERROR: {
      const payload = action.payload;
      return {
        ...state,
        loading: false,
        error: payload.err
      };
    }

    case fromProfile.ActionTypes.FETCH_MEMBERSHIP_SUCCESS: {
      let data = action.payload.data;
      return {
        ...state,
        ...data,
        error: null,
        loading: false
      };
    }

    case fromProfile.ActionTypes.FETCH_OFFERS_SUCCESS: {
      let data = action.payload.data;

      if (data.currentOffers && data.currentOffers.length > 0) {
        data.currentOffers.map((currentOffer) => {
          currentOffer.offerName = formatOfferName(currentOffer.offerName,
            currentOffer.redemptionsLeft, currentOffer.totalNumberOfOffers);
          currentOffer.formattedEndDate = moment(currentOffer.endDate).format('MM/DD/YYYY');
        });
      }

      if (data.expiredOffers && data.expiredOffers.length > 0) {
        data.expiredOffers.map((expiredOffer) => {
          expiredOffer.offerName = formatOfferName(expiredOffer.offerName,
            expiredOffer.redemptionsLeft, expiredOffer.totalNumberOfOffers);
          expiredOffer.formattedEndDate = moment(expiredOffer.endDate).format('MM/DD/YYYY');
        });
      }

      if (data.redeemedOffers && data.redeemedOffers.length > 0) {
        data.redeemedOffers.map((redeemedOffer) => {
          redeemedOffer.offerName = formatOfferName(redeemedOffer.offerName,
            redeemedOffer.redemptionsLeft, redeemedOffer.totalNumberOfOffers);
          redeemedOffer.formattedRedeemedDate = moment(redeemedOffer.redeemedDate).format('MM/DD/YYYY');
        });
      }

      return {
        ...state,
        data,
        loading: false
      };
    }

    case fromProfile.ActionTypes.FETCH_TRANSACTION_HISTORY_SUCCESS: {
      let data = action.payload.data;
      if (data && data.transactionHistory) {
        for (let i = 0; i < data.transactionHistory.length - 1; i++) {
          data.transactionHistory[i].bottomBorder =
            moment(data.transactionHistory[i].transactionDate).format('M/D/YYYY')
            !== moment(data.transactionHistory[i + 1].transactionDate).format('M/D/YYYY');
        }
      }
      data.dayPartCount = countBy(getDayPart, data.transactionHistory);
      return {
        ...state,
        data,
        loading: false
      };
    }

    case fromProfile.ActionTypes.FOUND_DATA: {
      const data = action.payload.data;
      if (data && data.transactionHistory) {
        for (let i = 0; i < data.transactionHistory.length - 1; i++) {
          data.transactionHistory[i].bottomBorder =
            moment(data.transactionHistory[i].transactionDate).format('M/D/YYYY')
            !== moment(data.transactionHistory[i + 1].transactionDate).format('M/D/YYYY');
        }
      } else if (data && data.notes) {
        const size = data.notes.length >= 5 ? 5 : data.notes.length;
        data.displayNotes = data.notes.slice(0, size);
      } else if (data && data.comments) {
        data.comments.map(curData => {
          curData.dateOfReport = moment(curData.dateOfReport, 'M/D/YYYY h:mm:ss A').unix();
          return curData;
        });
      } else if (data && data[0] && data[0].recoveryTypes) {
        data.forEach((row) => {
          if (row && row.recoveryTypes) {
            row.recoveryTypes.forEach((recoveryType: string, index: number) => {
              if (recoveryType === ProfileConstants.RecoveryTypeFilterRewardsRaw) {
                row.recoveryTypes[index] = ProfileConstants.RecoveryTypeFilterRewardsDisplay;
              } else if (recoveryType === ProfileConstants.RecoveryTypeFilterPointsRaw) {
                row.recoveryTypes[index] = ProfileConstants.RecoveryTypeFilterPointsDisplay;
              }
            });
          }
        });
      }

      return {
        ...state,
        data,
        loading: false
      };
    }

    case fromProfile.ActionTypes.NOTE_ACTION_COMPLETE: {
      let { noteId } = action.payload;
      let data: any = state.data;
      let selectedIndex = findIndex(propEq('noteId', noteId))(data.notes);
      if (selectedIndex > -1) {
        // Clear any errors on that particular note
        data.notes[selectedIndex].error = null;
      }

      return {
        ...state,
        data: {
          ...state['data'],
          notes: data.notes
        }
      };
    }

    case fromProfile.ActionTypes.NOTE_ERROR: {
      let { noteId, err } = action.payload;
      let data: any = state.data;
      let selectedIndex = findIndex(propEq('noteId', noteId))(data.notes);
      if (data.notes[selectedIndex]) {
        data.notes[selectedIndex].error = err;
      }

      return {
        ...state,
        loading: false,
        data: {
          ...state['data'],
          notes: data.notes
        }
      };
    }

    case fromProfile.ActionTypes.UPDATE_SORTED_TRANSACTION_DETAILS_DATA: {
      const payload = action.payload;
      return {
        ...state,
        data: {
          ...state['data'],
          sortedTransactionDetailsData: payload.sortedTransactionDetailsData,
        }
      };
    }

    default: {
      return state;
    }
  }
}
