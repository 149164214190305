<div [ngClass]="{'email-copy__header': !isRestyle, 'email-copy__customize-header': isRestyle}">
    {{headerText}}
</div>
<mat-list class="email-copy__list">
    <mat-list-item [attr.data-testid]="emailCopyText + 'email'"
                   (click)="goToEmailCopy()">
        <h4 matListItemTitle *ngIf="emailCopyText" class ='email-copy__copy-text'>
            {{emailCopyText}}
        </h4>
        <span matListItemLine *ngIf="emailCopy" class="email-copy__copy">
            <span>{{emailCopy}} </span>
         </span>
        <button mat-icon-button matListItemMeta>
            <img alt="Chevron Icon"
                 class="email-copy__chevron-icon"
                 src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png"/>
        </button>
    </mat-list-item>
    <mat-divider class="email-copy__divider" *ngIf="displayAppOption"></mat-divider>
    <mat-list-item
            [attr.data-testid]="appCopyText + 'app-copy'"
            (click)="goToAppCopy()" *ngIf="displayAppOption">
        <h4 matListItemTitle class ='email-copy__copy-text'>
            {{appCopyText}}
        </h4>
        <span matListItemLine *ngIf="appCopy" class="email-copy__copy">
            {{appCopy}}
        </span>
        <button mat-icon-button matListItemMeta>
            <img alt="Chevron Icon"
                 class="email-copy__chevron-icon"
                 src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png"/>
        </button>
    </mat-list-item>
    <mat-divider class="email-copy__divider" *ngIf="displayAppOption && displayPushMessageOption"></mat-divider>
    <mat-list-item data-testid="push-message-copy"
                   (click)="goToPushMessageCopy()"
                   *ngIf="displayAppOption && displayPushMessageOption">
        <h4 matListItemTitle class ='email-copy__copy-text'>
            {{pushMessageText}}
        </h4>
        <span matListItemLine *ngIf="pushMessageCopy" class="email-copy__copy">
            {{pushMessageCopy}}
        </span>
        <button mat-icon-button matListItemMeta>
            <img alt="Chevron Icon"
                 class="email-copy__chevron-icon"
                 src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png"/>
        </button>
    </mat-list-item>
</mat-list>
