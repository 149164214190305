<div *ngIf="!isBorderStyling">
    <div fxLayout="row" fxLayoutAlign="center">
        <mat-icon id="searchIcon" matPrefix>search</mat-icon>
        <mat-form-field class="autocomplete__input-container">
            <input matInput #search
                   placeholder="{{placeholder}}"
                   [matAutocomplete]="auto"
                   [formControl]="formCtrl"
                   (keyup)="onSearch($event.target.value)"/>
        </mat-form-field>
    </div>
    <mat-autocomplete #auto="matAutocomplete"
                      (optionSelected)="selectOption($event.option.value)"
                      class="autocomplete__results-container">
        <ng-container *ngIf="!loading">
            <mat-option *ngFor="let item of items" [value]="item">
                {{ item.fullName || item.name }}
            </mat-option>
            <mat-option *ngIf="items.length < 1" [disabled]="true">
                {{noResultsText}}
            </mat-option>
        </ng-container>
        <mat-option *ngIf="loading"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="autocomplete__loading-container"
                    [disabled]="true" [cfaLoading]="loading">
        </mat-option>
    </mat-autocomplete>
</div>
<div *ngIf="isBorderStyling" style = "background-color: white !important;">
    <div class="autocomplete__header-text" *ngIf="headerText">{{headerText}}</div>
    <div matAutocompleteOrigin
         #origin="matAutocompleteOrigin"
         [ngClass]="{'autocomplete__search': !isWithDropdown,
                     'autocomplete__search-with-dropdown': isWithDropdown,
                     'autocomplete__search-with-dropdown-desktop': isWithDropdown && !isMobile,
                     'autocomplete__search-with-dropdown-mobile': isWithDropdown && isMobile,
                     'autocomplete__border-on-autocomplete-open': !isWithDropdown && isAutoCompleteOpen,
                     'autocomplete__border-on-autocomplete-close': !isWithDropdown && !isAutoCompleteOpen,
                     'autocomplete__with-dd-border-on-autocomplete-open': isWithDropdown && isAutoCompleteOpen,
                     'autocomplete__with-dd-border-on-autocomplete-close': isWithDropdown && !isAutoCompleteOpen}"
         fxLayout="row"
         fxLayoutAlign="start center">
        <mat-icon id="borderedSearchIcon" matPrefix class="autocomplete__search-icon">search</mat-icon>
        <input type="text"
               #trigger="matAutocompleteTrigger"
               [ngClass]="{'autocomplete__search-input': !isWithDropdown, 'autocomplete__search-input-with-dropdown': isWithDropdown}"
               [placeholder]="placeholder"
               [formControl]="formCtrl"
               [matAutocomplete]="auto"
               [matAutocompleteConnectedTo]="origin"
               [matAutocompletePosition]="'below'"
               (keyup)="onSearch($event.target.value)">
            <mat-icon *ngIf="formCtrl.value && shouldMaintainSelectionText" matSuffix class="autocomplete__search-close" (click)="removeSearchTerm()">close</mat-icon>
        <mat-autocomplete #auto="matAutocomplete"
                          class="autocomplete__results-container"
                          (optionSelected)="selectOption($event.option.value)"
                          (opened)="isAutoCompleteOpen = true"
                          (closed)="isAutoCompleteOpen = false">
            <ng-container *ngIf="!loading">
                <mat-option *ngFor="let item of items" [value]="item">
                    {{ item.fullName || item.name }}
                </mat-option>
                <mat-option *ngIf="items.length < 1" [disabled]="true">
                    {{noResultsText}}
                </mat-option>
            </ng-container>
            <mat-option *ngIf="loading"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="autocomplete__loading-container"
                        [disabled]="true"
                        [cfaLoading]="loading">
            </mat-option>
        </mat-autocomplete>
    </div>
</div>
