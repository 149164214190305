
<div *ngIf="helpText" fxLayout="row" fxLayoutAlign="start start">
    <mat-chip-set>
        <ng-container *ngIf="filters.length > 0">
            <mat-chip-set>
                <mat-chip-option
                        class="filter-chips__chip"
                        *ngFor="let filter of filters"
                        [selected]="filter.selected"
                        (select)="selectChip(filter)"
                        (click)="onFilterSelection.emit(filter)">
                            {{filter.filterName}}
                </mat-chip-option>
            </mat-chip-set>
        </ng-container>
    </mat-chip-set>
    <help-text
            [helpText]="helpText"
            [displayOnLeft]="true">
    </help-text>
</div>
