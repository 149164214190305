import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MaterialModule } from '../material.module';
import { QuicklinkModule } from 'ngx-quicklink';
import { QuillModule } from 'ngx-quill';
import { RouterModule } from '@angular/router';

// Components
import { SpotFooterComponent } from './footer/spot-footer.component';
import { ContentPlaceholderComponent } from './content-placeholder/content-placeholder.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { MemberStatusComponent } from './data-table/member-status';
import { OfferTypeComponent } from './data-table/offer-type';
import { PersonalInformationComponent } from './personal-information-card';
import { ProfileSectionComponent } from './profile-section/profile-section.component';
import { CollapsibleCardComponent } from './collapsible-card/collapsible-card.component';
import { BasicCardComponent } from './basic-card/basic-card.component';
import { DataFilterChipsComponent } from './data-filter-chips/data-filter-chips.component';
import { ChartComponent } from './chart/chart.component';
import { DayRangePickerComponent } from './day-range-picker/day-range-picker.component';
import { TreatSummaryComponent } from './treat-summary/treat-summary.component';
import { TemplateSummaryComponent } from './template-summary/template-summary.component';
import { EmailCopyComponent } from './email-copy/email-copy.component';
import { RewardCardComponent } from './reward-card/reward-card.component';
import { TemplateCardComponent } from './template-card/template-card.component';
import { TemplatePreviewComponent } from './template-preview/template-preview.component';
import { DataCardComponent } from '../customer-profile/data-card/data-card.component';
import { TextBoxComponent } from './text-box/text-box.component';
import { ActionsMenuComponent } from './actions-menu/actions-menu.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { SimpleNavComponent } from './simple-nav/simple-nav.component';
import { MyDatePipe } from './date/my-date.pipe';
import { ActiveRewardDialogComponent } from './active-reward-dialog/active-reward-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { IconConfirmationDialogComponent } from './icon-confirmation-dialog/icon-confirmation-dialog.component';
import { BottomSheetComponent } from './bottom-sheet/bottom-sheet.component';
import { HelpTextComponent } from './help-text/help-text.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { PreviewSelectionComponent } from './preview-selection/preview-selection.component';
import { ActivityHistoryComponent } from './activity-history/activity-history.component';
import { ActivityHistoryWithNotesComponent } from './activity-history-with-notes/activity-history-with-notes.component';
import { PaginationComponent } from './pagination/pagination.component';
import { DataTableComponent } from './data-table/data-table.component';
import { MobileDataTableComponent } from './data-table/mobile-data-table/mobile-data-table.component';
import { MobileDataTableFullComponent } from './data-table/mobile-data-table/mobile-data-table-full/mobile-data-table-full.component';
import { DataTableFooterComponent } from './data-table/data-table-footer';
import { DataTableHeaderComponent } from './data-table/data-table-header';
import { DataTableBodyComponent } from './data-table/data-table-body';
import { DataTableCellComponent } from './data-table/data-table-cell/data-table-cell.component';
import { DataTableErrorComponent } from './data-table/data-table-error/data-table-error.component';
import { IncrementStepperComponent } from './increment-stepper/increment-stepper.component';
import { ThemeCardComponent } from './theme-card/theme-card.component';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { DropdownSearchComponent } from './dropdown-search/dropdown-search.component';
import { NoteComponent } from './note/note.component';
import { NavBarComponent } from './navbar/navbar.component';
import { NavBarFooterComponent } from './navbar/navbar-footer/navbar-footer.component';
import { NoteSectionComponent } from './note-section/note-section.component';
import { FilterListComponent } from './filter-list/filter-list.component';
import { DoubleSliderComponent } from './filter-list/double-slider/double-slider.component';
import { FilterChipComponent } from './filter-chip/filter-chip.component';
import { FilterChipBoxComponent } from './filter-chip-box/filter-chip-box.component';
import { FilterDisplayForMobileComponent } from './filter-display-for-mobile/filter-display-for-mobile';
import { ButtonToggleComponent } from './button-toggle/button-toggle.component';
import { FilterSubOptionComponent } from './filter-sub-option/filter-sub-option.component';
import { ButtonChipSectionComponent } from './button-chips-section/button-chip-section.component';
import { AdditionalFilterCustomersDialogComponent }
  from './additional-filter-customers-dialog/additional-filter-customers-dialog.component';
import { SaveGroupNameDialogComponent } from './save-group-name-dialog/save-group-name-dialog.component';
import { SendConfirmationDialogComponent }
  from './send-confirmation-dialog/send-confirmation-dialog.component';
import { AdditionalCustomersFilterChipsComponent }
  from './additional-customers-filter-chips/additional-customers-filter-chips.component';
import { StatCardComponent } from './stat-card/stat-card.component';
import { BarLineChartComponent } from './sections-layout/sections/bar-line-chart/bar-line-chart.component';
import { SectionsComponent } from './sections-layout/sections/sections.component';
import { HorizontalBarChartComponent } from './sections-layout/sections/horizontal-bar-chart/horizontal-bar-chart.component';
import { SectionsLayoutComponent } from './sections-layout/sections-layout.component';
import { NumberDisplayComponent } from './sections-layout/sections/number-display/number-display.component';
import { DoughnutChartComponent } from './sections-layout/sections/doughnut-chart/doughnut-chart.component';
import { DoubleBarLineChartComponent } from './sections-layout/sections/double-bar-line-chart/double-bar-line-chart.component';
import { LineChartComponent } from './sections-layout/sections/line-chart/line-chart.component';
import { VerticalBarComponent } from './sections-layout/sections/vertical-bar/vertical-bar.component';
import { SingleSliderComponent } from './filter-list/single-slider/single-slider.component';
import { DropdownByIdComponent } from './dropdown-by-id/dropdown-by-id.component';
import { CommunityCareMultipleEventModalComponent }
  from './community-care-multiple-event-modal/community-care-multiple-event-modal.component';

// Pipes
import { TruncatePipe } from '../pipes/truncate.pipe';
import { LocTimeZoneDatePipe } from './loc-time-zone-date/loc-time-zone-date.pipe';

// Services
import { ActiveRewardDialogService } from './active-reward-dialog/active-reward-dialog.service';
import { MAT_BOTTOM_SHEET_DEFAULT_OPTIONS } from '@angular/material/bottom-sheet';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommunityCareDetailsComponent } from './community-care-details/community-care-details.component';
import { OverlappingEngagementSummaryComponent } from './overlapping-engagement-summary/overlapping-engagement-summary.component';
import {
  CommunityCareConfirmationDialogComponent
} from './community-care-confirmation-dialog/community-care-confirmation-dialog.component';
import { BonusPointsPromotionComponent } from './bonus-points-promotion/bonus-points-promotion.component';
import { ReadMoreComponent } from './read-more/read-more.component';
import { ThirdPartyPromotionComponent } from './third-party-promotion/third-party-promotion.component';
import { MatTableModule } from '@angular/material/table';
import { FreeItemInCartDetailsComponent } from './free-item-in-cart-details/free-item-in-cart-details.component';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { MatLine } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatBadge } from '@angular/material/badge';
import { CfaLoadingModule } from '../cfa-control-modules/cfa-loading/cfa-loading-module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatMomentDateModule,
    CfaLoadingModule,
    QuicklinkModule,
    QuillModule,
    RouterModule,
    NgxSliderModule,
    MatTableModule,
    FlexLayoutModule,
    MatLine,
    MatBadge
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ],
  declarations: [
    SpotFooterComponent,
    AutocompleteComponent,
    ErrorMessageComponent,
    ContentPlaceholderComponent,
    CollapsibleCardComponent,
    BasicCardComponent,
    PersonalInformationComponent,
    ProfileSectionComponent,
    DataTableComponent,
    MobileDataTableComponent,
    MobileDataTableFullComponent,
    DataTableHeaderComponent,
    DataTableBodyComponent,
    DataTableFooterComponent,
    DataTableCellComponent,
    DataTableErrorComponent,
    DataCardComponent,
    TextBoxComponent,
    TreatSummaryComponent,
    TemplateSummaryComponent,
    EmailCopyComponent,
    RewardCardComponent,
    TemplateCardComponent,
    TemplatePreviewComponent,
    MemberStatusComponent,
    OfferTypeComponent,
    DataFilterChipsComponent,
    ChartComponent,
    DayRangePickerComponent,
    ActionsMenuComponent,
    SimpleNavComponent,
    ActiveRewardDialogComponent,
    ConfirmationDialogComponent,
    IconConfirmationDialogComponent,
    BottomSheetComponent,
    HelpTextComponent,
    DateRangePickerComponent,
    DatePickerComponent,
    TimePickerComponent,
    ActivityHistoryComponent,
    ActivityHistoryWithNotesComponent,
    PaginationComponent,
    PreviewSelectionComponent,
    IncrementStepperComponent,
    ThemeCardComponent,
    SnackbarComponent,
    DropdownComponent,
    DropdownSearchComponent,
    NoteComponent,
    NavBarComponent,
    NavBarFooterComponent,
    NoteSectionComponent,
    FilterListComponent,
    DoubleSliderComponent,
    FilterChipComponent,
    FilterChipBoxComponent,
    FilterDisplayForMobileComponent,
    ButtonToggleComponent,
    FilterSubOptionComponent,
    ButtonChipSectionComponent,
    AdditionalFilterCustomersDialogComponent,
    SaveGroupNameDialogComponent,
    SendConfirmationDialogComponent,
    AdditionalCustomersFilterChipsComponent,
    StatCardComponent,
    SectionsComponent,
    HorizontalBarChartComponent,
    SectionsLayoutComponent,
    NumberDisplayComponent,
    DoughnutChartComponent,
    VerticalBarComponent,
    LineChartComponent,
    DoubleBarLineChartComponent,
    BarLineChartComponent,
    SingleSliderComponent,
    DropdownByIdComponent,
    OverlappingEngagementSummaryComponent,
    CommunityCareDetailsComponent,
    CommunityCareConfirmationDialogComponent,
    BonusPointsPromotionComponent,
    ReadMoreComponent,
    ThirdPartyPromotionComponent,
    CommunityCareMultipleEventModalComponent,
    FreeItemInCartDetailsComponent,
    // pipes
    TruncatePipe,
    MyDatePipe,
    LocTimeZoneDatePipe,

  ],
  exports: [
    // modules
    CommonModule,
    FormsModule,
    MatMomentDateModule,
    MaterialModule,
    CfaLoadingModule,
    QuicklinkModule,

    // components
    SpotFooterComponent,
    AutocompleteComponent,
    ErrorMessageComponent,
    ContentPlaceholderComponent,
    CollapsibleCardComponent,
    BasicCardComponent,
    PersonalInformationComponent,
    ProfileSectionComponent,
    DataTableComponent,
    MobileDataTableComponent,
    MobileDataTableFullComponent,
    DataTableHeaderComponent,
    DataTableBodyComponent,
    DataTableFooterComponent,
    DataTableCellComponent,
    DataTableErrorComponent,
    DataCardComponent,
    TextBoxComponent,
    TreatSummaryComponent,
    TemplateSummaryComponent,
    EmailCopyComponent,
    RewardCardComponent,
    TemplateCardComponent,
    TemplatePreviewComponent,
    MemberStatusComponent,
    DataFilterChipsComponent,
    ChartComponent,
    DayRangePickerComponent,
    ActionsMenuComponent,
    SimpleNavComponent,
    HelpTextComponent,
    DateRangePickerComponent,
    DatePickerComponent,
    TimePickerComponent,
    ActivityHistoryComponent,
    ActivityHistoryWithNotesComponent,
    PaginationComponent,
    PreviewSelectionComponent,
    IncrementStepperComponent,
    ActiveRewardDialogComponent,
    ConfirmationDialogComponent,
    IconConfirmationDialogComponent,
    BottomSheetComponent,
    ThemeCardComponent,
    SnackbarComponent,
    DropdownComponent,
    DropdownSearchComponent,
    NoteComponent,
    NavBarComponent,
    NavBarFooterComponent,
    NoteSectionComponent,
    FilterListComponent,
    DoubleSliderComponent,
    FilterChipComponent,
    FilterChipBoxComponent,
    FilterDisplayForMobileComponent,
    ButtonToggleComponent,
    FilterSubOptionComponent,
    ButtonChipSectionComponent,
    AdditionalFilterCustomersDialogComponent,
    SaveGroupNameDialogComponent,
    SendConfirmationDialogComponent,
    AdditionalCustomersFilterChipsComponent,
    StatCardComponent,
    SectionsComponent,
    HorizontalBarChartComponent,
    SectionsLayoutComponent,
    NumberDisplayComponent,
    DoughnutChartComponent,
    VerticalBarComponent,
    LineChartComponent,
    DoubleBarLineChartComponent,
    BarLineChartComponent,
    SingleSliderComponent,
    DropdownByIdComponent,
    CommunityCareDetailsComponent,
    BonusPointsPromotionComponent,
    OverlappingEngagementSummaryComponent,
    ThirdPartyPromotionComponent,
    CommunityCareMultipleEventModalComponent,
    FreeItemInCartDetailsComponent,
    FlexLayoutModule,
    // pipes
    TruncatePipe,
    MyDatePipe,
    LocTimeZoneDatePipe,

  ],
  providers: [
    {
      provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS,
      useValue: { panelClass : 'bottom-sheet-container', hasBackdrop : true }
    },{
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        subscriptSizing: 'dynamic',

      }
    },
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ActiveRewardDialogService
      ]
    };
  }
}
