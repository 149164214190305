import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterOutlet, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { map, take, filter } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { AnalyticsService } from './core/analytics';
import * as fromRoot from './reducers';
import { routeTransitionAnimations } from './route-animations';
import { SharedConstants } from './shared/shared.constants';
import { AppConstants } from './app.constants';
import { MediaObserver } from 'ngx-flexible-layout';
import * as layout from './actions/layout';

export let browserRefreshed = false;

@Component({
  selector: 'spotlight-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeTransitionAnimations]
})
export class AppComponent implements OnDestroy {

  public refreshSubscription: Subscription;
  public acceptedEula$: Observable<boolean>;
  public maintenance$: Observable<boolean>;
  public disableDiscoverTab$: Observable<boolean>;
  public disableMeetTab$: Observable<boolean>;
  public disableRecoverTab$: Observable<boolean>;
  public disableEngageTab$: Observable<boolean>;
  public disableCalendarTab$: Observable<boolean>;
  public locationLoading$: Observable<boolean>;
  public isMobile$: Observable<boolean>;
  public minimumPageHeight$: Observable<number>;
  public userError$: Observable<string>;
  public locationError$: Observable<string>;
  public eulaError$: Observable<boolean>;
  public isBlueBackground: boolean = false;
  private mediaSubscription: Subscription;
  constructor(private router: Router,
              private store: Store<fromRoot.State>,
              private analytics: AnalyticsService,
              private activatedRoute: ActivatedRoute,
              private mediaObserver: MediaObserver) {
    this.acceptedEula$ = this.store.select(fromRoot.getEulaAgree);
    this.maintenance$ = this.store.select(fromRoot.getFeatureEnabled(AppConstants.maintenanceFlag));
    this.disableDiscoverTab$ = this.store.select(fromRoot.getFeatureEnabled(SharedConstants.disableDiscoverTabFlag));
    this.disableMeetTab$ = this.store.select(fromRoot.getFeatureEnabled(SharedConstants.disableMeetTabFlag));
    this.disableRecoverTab$ = this.store.select(fromRoot.getFeatureEnabled(SharedConstants.disableRecoverTabFlag));
    this.disableEngageTab$ = this.store.select(fromRoot.getFeatureEnabled(SharedConstants.disableEngageTabFlag));
    this.disableCalendarTab$ = this.store.select(fromRoot.getFeatureEnabled(SharedConstants.disableCalendarTabFlag));
    this.userError$ = this.store.select(fromRoot.getUserError);
    this.locationError$ = this.store.select(fromRoot.getLocationError);
    this.eulaError$ = this.store.select(fromRoot.getEulaError);
    this.locationLoading$ = this.store.select(fromRoot.getUserLoading);
    this.isMobile$ = this.store.select(fromRoot.getIsMobile);

    // If it's mobile, set minimum height to allow room for the full route animation
    this.minimumPageHeight$ = this.isMobile$.pipe(map((isMobile) => {
      return isMobile ? window.screen.height : 400;
    }));

    this.refreshSubscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefreshed = !router.navigated;
      }
      if (event instanceof NavigationEnd) {
        let url = event.urlAfterRedirects;
        this.routingLogic(url)
      }
    })
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
      .forEach(() => {
        this.isBlueBackground = this.activatedRoute.root.firstChild.firstChild
          ? this.activatedRoute.root.firstChild.firstChild.snapshot.data.isBlueBackground : false ;
      });

  }

  ngOnInit() {
    this.mediaSubscription = this.mediaObserver
      .asObservable()
      .subscribe((change) => {
        this.store.dispatch(new layout.ChangedViewSizeAction(change[0].mqAlias))
      });
  }

  public routingLogic(url) {
    this.acceptedEula$.pipe(take(1)).subscribe((accepted) => {
      this.maintenance$.pipe(take(1)).subscribe((activeMaintenance) => {
        if (!accepted) {
          this.router.navigate(['/eula']);
        } else if (activeMaintenance && !(url.includes('/care')
          || url.includes('/menu') || url.includes('/settings') || url.includes('/notifications'))) {
          this.router.navigate(['/maintenance']);
        } else if ((accepted && url === '/eula') || url === '/maintenance') {
          this.router.navigate(['/discover']);
        }
      });

      // Only check disabled tabs if the user has accepted the EULA
      if (accepted) {
        // If a tab is disabled then redirect the user to the next enabled one
        this.disableDiscoverTab$.pipe(take(1)).subscribe((disableDiscoverTab) => {
          this.disableMeetTab$.pipe(take(1)).subscribe((disableMeetTab) => {
            this.disableRecoverTab$.pipe(take(1)).subscribe((disableRecoverTab) => {
              this.disableEngageTab$.pipe(take(1)).subscribe((disableEngageTab) => {
                this.disableCalendarTab$.pipe(take(1)).subscribe((disableCalendarTab) => {
                  if (disableDiscoverTab && !disableMeetTab && url === '/discover') {
                    this.router.navigate(['/meet']);
                  } else if (disableDiscoverTab && disableMeetTab && !disableRecoverTab && url === '/discover') {
                    this.router.navigate(['/care']);
                  } else if (disableDiscoverTab && disableMeetTab
                    && disableRecoverTab && !disableEngageTab && url === '/discover') {
                    this.router.navigate(['/engage/template-selection']);
                  } else if (disableDiscoverTab && disableMeetTab
                    && disableRecoverTab && disableEngageTab && !disableCalendarTab && url === '/discover') {
                    this.router.navigate(['/calendar']);
                  } else if (disableDiscoverTab && disableMeetTab
                    && disableRecoverTab && disableEngageTab && disableCalendarTab) {
                    // If every tab is disabled, route to the maintenance page
                    this.router.navigate(['/maintenance']);
                  } else if (disableDiscoverTab && disableMeetTab && disableEngageTab && disableCalendarTab
                      && !(url.includes('/care') || url.includes('/customer'))) {
                    if (!url.includes('/menu') || url.includes('/settings')
                      || url.includes('/notifications')) {
                      this.router.navigate(['/care']);
                    }
                  }
                });
              });
            });
          });
        });
      }
    });

    this.analytics.logPageView(url, false);
  }

  public prepareRoute$(outlet: RouterOutlet) {
    return this.isMobile$.pipe(map(isMobile => {
      return isMobile &&
        outlet &&
        outlet.activatedRouteData &&
        outlet.activatedRouteData['animationState'];
    }));
  }

  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
    this.mediaSubscription.unsubscribe();
  }
}
