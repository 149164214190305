<mat-card class="card">
  <ng-container *ngIf="tile.loading">
    <div class="card__loading">
      <content-placeholder></content-placeholder>
    </div>
  </ng-container>
  <ng-container *ngIf="!tile.loading">
    <mat-card-header class="card__header" fxLayout="row" fxLayoutAlign="space-between center">
      <img mat-card-avatar
           class="card__avatar"
           [src]="tile.imageUrl"
           alt=""
           [attr.data-testid]="tile.title + '-card-header-img'">
      <mat-card-title class="card__title" [attr.data-testid]="tile.title + '-card-title'">
        {{tile.title}}
      </mat-card-title>
      <div fxFlex></div>
      <help-text *ngIf="tile.helpText"
                 [attr.data-testid]="tile.title + '-card-helpText'"
                 [helpText]="tile.helpText.audienceText"
                 [filterText]="tile.helpText.filterText"
                 [strategicGuidanceText]="tile.helpText.strategicGuidanceText"
                 [displayOnLeft]="true">
      </help-text>
    </mat-card-header>
    <mat-card-content *ngIf="tile.error" class="card__error-content" [attr.data-testid]="tile.title + '-card-error-content'">
      <error-message class="card__error"
                     [attr.data-testid]="tile.title + '-card-error'"
                     [error]="tile.errorText"
                     [page]="getPageTitle()">
      </error-message>
    </mat-card-content>
    <div *ngIf="!tile.error">
      <mat-card-content *ngIf="!tile.automated"
                        class="card__content"
                        [attr.data-testid]="tile.title + '-card-content'"
                        [ngClass]="{'card__campaign-content': (tile.category === 'campaign' || tile.category === 'promotion' ||
                                     tile.category === communityCareEvent || tile.category === 'third-party-promotion'
                                     || tile.category == freeItemInCartPromotion)}"
                        fxLayout="column"
                        fxLayoutAlign="space-between stretch">
        <ng-content></ng-content>
        <div *ngIf="tile.automationEnabled && !isSendingDisabled && !isRewardsSendingDisabled"
             class="card__automation-container"
             fxLayout="row"
             fxLayoutAlign="space-between center"
             (click)="setUpAutomation.emit()">
          <div class="card__automation-text" [attr.data-testid]="tile.title + '-automation-text'">
            Automatically send this reward to eligible customers
          </div>
          <img class="card__chevron-icon" src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png" alt="">
        </div>
      </mat-card-content>

      <mat-card-content *ngIf="tile.automated" fxLayout="row" fxLayoutAlign="start center" class="card__automation-content">
        <mat-icon class="card__automation-icon">autorenew</mat-icon>
        <div *ngIf="tile.category !== communityCareEvent" class="card__automated-text" data-testid="discover-card-automated-text">Campaign Automated</div>
        <div *ngIf="tile.category === communityCareEvent" class="card__automated-text" data-testid="discover-card-automated-text">Event Created</div>
      </mat-card-content>

      <div fxLayout="row" fxLayoutAlign="end">
        <mat-card-actions *ngIf="tile.learnMore">
          <button mat-button (click)="learnMore.emit()"
                  [attr.data-testid]="tile.title + '-learn-more-button'">
            LEARN MORE <mat-icon class="card__learn-more">open_in_new</mat-icon>
          </button>
        </mat-card-actions>
        <mat-card-actions *ngIf="tile.sendEmail && !tile.automated">
          <button mat-button (click)="sendEmail.emit()"
                  [disabled]="tile.disableEngagement || isSendingDisabled"
                  [attr.data-testid]="tile.title + '-send-emails-button'">
            Send Emails
          </button>
        </mat-card-actions>
        <mat-card-actions *ngIf="tile.sendTreat && !tile.automated && !tile.rewardDisable && tile.category !== communityCareEvent">
          <button mat-button (click)="sendReward.emit()"
                  [disabled]="tile.disableEngagement || isSendingDisabled || isRewardsSendingDisabled"
                  [attr.data-testid]="tile.title + '-send-rewards-button'">
            Send Rewards
          </button>
        </mat-card-actions>
        <mat-card-actions *ngIf="tile.sendPromotion && !tile.automated">
          <button mat-button (click)="sendPromotion.emit()"
                  [disabled]="tile.disableEngagement || isSendingDisabled || isRewardsSendingDisabled"
                  [attr.data-testid]="tile.title + '-create-promotion-button'">
            Create Promotion
          </button>
        </mat-card-actions>
        <mat-card-actions *ngIf="tile.sendEvent && !tile.automated && tile.category === communityCareEvent">
          <button mat-button (click)="sendCommunityCare.emit()"
                  [disabled]="tile.disableEngagement || isSendingDisabled || isRewardsSendingDisabled"
                  [attr.data-testid]="tile.title + '-create-event-button'">
            Create Event
          </button>
        </mat-card-actions>
        <ng-container *ngIf="tile.automated">
          <mat-card-actions *ngIf="!tile.sendPromotion && tile.category !== communityCareEvent" class="card__actions">
            <button mat-button (click)="editAutomation.emit()" [attr.data-testid]="tile.title + '-edit-details-button'">Edit Details</button>
          </mat-card-actions>
          <mat-card-actions *ngIf="tile.category !== communityCareEvent && tile.category !== thirdPartyPromotion && tile.category !== freeItemInCartPromotion" class="card__actions">
            <button mat-button (click)="stopAutomation.emit()" [attr.data-testid]="tile.title + '-stop-automation-button'">Stop Automation</button>
          </mat-card-actions>
          <mat-card-actions *ngIf="tile.category === communityCareEvent && tile.multipleSendsEnabled" class="card__actions">
            <button mat-button (click)="sendCommunityCare.emit()"
                    [disabled]="tile.disableEngagement || isSendingDisabled || isRewardsSendingDisabled"
                    [attr.data-testid]="tile.title + '-create-another-event-button'">
              Create Another Event
            </button>
          </mat-card-actions>
          <mat-card-actions *ngIf="tile.category === communityCareEvent && !tile.multipleSendsEnabled" class="card__actions">
            <button mat-button (click)="stopCommunityCareEvent.emit()" [attr.data-testid]="tile.title + '-stop-event-button'">Stop Event</button>
          </mat-card-actions>
          <mat-card-actions *ngIf="tile.category === thirdPartyPromotion || tile.category === freeItemInCartPromotion" class="card__actions">
            <button mat-button (click)="stopPromotion.emit()" [attr.data-testid]="tile.title + '-stop-promotion-button'">Stop Promotion</button>
          </mat-card-actions>
        </ng-container>
      </div>
    </div>
  </ng-container>
</mat-card>
