<div mat-dialog-content *ngIf="recoverEligibilityLoading">
  <div class="action-selection__loading"
       [cfaLoading]="true">
  </div>
</div>
<div class="action-selection"
     [ngClass]="{'action-selection__desktop': !isMobile, 'action-selection__mobile': isMobile}"
     *ngIf="!recoverEligibilityLoading && (getEligibleForRecovery() | async)">
  <div mat-dialog-title fxLayout="column" fxLayoutAlign="center center">
    <div class="action-selection__title">{{name}}</div>
    <div class="action-selection__title">{{priority}}</div>
    <div class="action-selection__subtitle">What action do you want to take on this item?</div>
  </div>
  <div mat-dialog-content *ngIf="actionOptions">
    <mat-radio-group [(ngModel)]="selectedValue">
      <div *ngFor="let section of actionOptions" fxLayout="column" fxLayoutAlign="space-around start">
        <div fxLayout="row">
          <div class="action-selection__section-title"
               [ngClass]="{'action-selection__disabled-title': (section.sendResponseToGuest && !eligibleForSpotEngagement)}">
            {{section.title}}
          </div>
          <help-text [helpText]="section.helpText" [displayOnLeft]="isMobile"></help-text>
        </div>
        <div *ngIf="section.sendResponseToGuest && isSendDisabled" class="action-selection__send-disabled-text">
          {{allSendDisabledText}}
        </div>
        <div *ngFor="let subsection of section.category" class="action-selection__subsection">
          <mat-radio-button *ngFor="let option of subsection.options"
                            [value]="option.title"
                            [disabled]="section.sendResponseToGuest && (!eligibleForSpotEngagement || isSendDisabled)"
                            (change)="optionChanged(subsection.subType, section.sendResponseToGuest)"
                            class="action-selection__option"
                            fxLayout="column"
                            fxLayoutAlign="space-around start">
            {{option.title}}
          </mat-radio-button>
        </div>
        <div *ngIf="section.sendResponseToGuest && !eligibleForSpotEngagement"
             class="action-selection__priority-send-disabled">
          {{prioritySendDisabledText}}
        </div>
      </div>
    </mat-radio-group>
    <div *ngIf="selectedSubtype === closedSubtype || selectedSubtype === resolvedSubtype" class="action-selection__resolve-warning-text">
      {{this.getWarningText()}}
    </div>
    <div *ngIf="type === spotCustomer && this.selectedValue !== null">
      <div class="action-selection__restaurant-recovery-title">Add a Note</div>
      <div class="action-selection__restaurant-recovery-body">
        This will be your only opportunity to add notes for Chick-fil-A One Contacts. These cannot be edited or deleted later.
      </div>
    </div>
    <div *ngIf="this.selectedValue !== null">
      <mat-form-field floatLabel="never" class="action-selection__note-container">
        <mat-placeholder class="action-selection__placeholder">Add a note (optional)</mat-placeholder>
        <textarea matInput
                  cdkTextareaAutosize
                  maxlength="500"
                  class="action-selection__text-area"
                  [ngModel]="note.updatedText"
                  (keyup)="updateText($event)">
        </textarea>
        <mat-hint class="action-selection__disclaimer" align="left">
          *Do not enter sensitive guest details such as credit card numbers, emails, etc.
        </mat-hint>
        <mat-hint class="action-selection__character-count" align="end">{{note.updatedText.length}} / 500</mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-content *ngIf="!actionOptions">
    <div class="action-selection__loading"
         [cfaLoading]="true">
    </div>
  </div>
  <div [ngClass]="{'action-selection__mobile-footer-sticky': isMobile}">

    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around center" class="action-selection__buttons">
      <button mat-button (click)="cancelClicked.emit()" class="action-selection__cancel-button">Cancel</button>
      <button mat-button
              [ngClass]="this.selectedValue === null ? 'action-selection__send-button-disabled' : 'action-selection__send-button'"
              (click)="startRespondFlow()"
              [disabled]="this.selectedValue === null">
        Continue
      </button>
    </div>
  </div>
</div>

<div *ngIf="!recoverEligibilityLoading && !(getEligibleForRecovery() | async)">
  <mat-icon class="action-selection__warning-icon">warning</mat-icon>
  <div mat-dialog-title fxLayout="column" fxLayoutAlign="center center">
    <div class="action-selection__ineligible-title">{{ineligibleHeaderText}}</div>
  </div>
  <div class="action-selection__ineligible-body-text">{{ineligibleBodyText}}</div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around center" class="action-selection__buttons">
    <button mat-button (click)="cancelClicked.emit()" class="action-selection__ineligible-button">{{ineligibleButtonText}}</button>
  </div>
</div>
