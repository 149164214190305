import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { take } from 'rxjs/operators';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';

@Component({
  selector: 'dropdown-by-id',
  templateUrl: './dropdown-by-id.component.html',
  styleUrls: ['./dropdown-by-id.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropdownByIdComponent {
  @Input() public categories: any = [];
  @Input() public disabled: boolean = false;
  @Input() public dropdownLabel: any = '';
  @Input() public enableMultiSelect: boolean = false;
  @Input() public isMobile: any;
  @Input() public removeTopBorder: boolean = false;
  @Input() public selectedCategoryId: any = '';
  @Input() public selectedCategoryIdsForMultiSelect: any = [];

  @Output() public onUpdateCategory = new EventEmitter<any>();
  @Output() public onUpdateMultiSelectCategories = new EventEmitter<any>();
  @Output() public onUpdateCategoryMobile = new EventEmitter<any>();

  public bottomSheetData = {
    'list': [],
    'title': this.dropdownLabel,
    'isCancel': true,
    'selectedItem': this.selectedCategoryId
  };

  constructor(private bottomSheet: MatBottomSheet) {}

  public updateCategory(selectedCategory) {
    this.onUpdateCategory.emit(selectedCategory);
  }

  public updateMultiSelectCategories(selectedCategories) {
    this.onUpdateMultiSelectCategories.emit(selectedCategories);
  }

  public openMobileSelectDropdown() {
    if (!this.disabled) {
      this.bottomSheetData.list = this.categories.map((category) => {
        return { name: category.name, value: category.id, disabled: category.disabled };
      });

      const bottomSheetRef = this.bottomSheet.open(BottomSheetComponent, {
        data: { content: this.bottomSheetData }
      });

      bottomSheetRef.afterDismissed().pipe(take(1)).subscribe((dataFromBottomSheet) => {
        // Only make a new call if the category has been changed
        if (dataFromBottomSheet && dataFromBottomSheet.value && this.selectedCategoryId !== dataFromBottomSheet.value) {
          this.selectedCategoryId = dataFromBottomSheet.value;
          this.onUpdateCategoryMobile.emit(dataFromBottomSheet.value);
        }
      });
    }
  }
}
