import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'filters',
  styleUrls: ['./filters.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  template: `
    <div class="filters">
      <mat-nav-list>
        <ng-container *ngFor="let filter of filters; let i = index">
          <mat-list-item (click)="filterClick.emit(filter.filterText)">
            <h4 matListItemLine>
              <span class="badge filters__badge"
                    [attr.data-testid]="filter.name + '-filter-badge'">{{filter.count}}</span>
              <span matTooltip="{{filter.name}}" matTooltipPosition="above">{{filter.name}}</span>
            </h4>
            <button matListItemMeta mat-icon-button>
              <img class="filters__chevron-icon" src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png"/>
            </button>
          </mat-list-item>
          <mat-divider class="filters__divider" *ngIf="shouldShowDivider(i)"></mat-divider>
        </ng-container>
      </mat-nav-list>
    </div>
  `,
})
export class FiltersComponent {
  @Input() public filters;

  @Output() public filterClick = new EventEmitter();

  public shouldShowDivider(index) {
    return index < this.filters.length - 1;
  }
}
