import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { NavigationService } from './navigation.service'

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(
      private navigationService: NavigationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let roles = route.data.roles as Array<string>;
    for (let i = 0; i < roles.length; i++) {
      if (!this.navigationService.checkWasPerm(roles[i])) {
        return false;
      }
    }
    return true;
  }
}
