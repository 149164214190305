<error-message *ngIf="locationError$ | async"
               [error]="locationError$ | async"
               [page]="'Location'">
</error-message>
<error-message *ngIf="userError$ | async"
               [error]="userError$ | async"
               [page]="'User'">
</error-message>

<div class="spotlight-app__router-wrapper"
     [cfaLoading]="locationLoading$ | async"
     [ngClass]="{'spotlight-app__router-wrapper-blue' : isBlueBackground}"
     [ngStyle]="{'min-height': (minimumPageHeight$ | async) + 'px' }"
     [@slideUpAnimation]="prepareRoute$(outlet) | async">
  <div [hidden]="(locationLoading$ | async) || (userError$ | async) || (locationError$ | async)">
    <mat-tab-nav-panel #tabPanel>
      <router-outlet #outlet="outlet"></router-outlet>
    </mat-tab-nav-panel>
  </div>
</div>

<spot-footer></spot-footer>
