import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';

@Pipe({ name: 'myDate' })
export class MyDatePipe implements PipeTransform {
  public transform(value: any, formatType: string = 'MMMM Do', inputFormat: string = null): string {
    let date :Moment;
    if (inputFormat === 'unix') {
      date = moment.unix(value)
    } else {
      date = moment(value, inputFormat);
    }

    if (formatType === 'relative') {
      return date.fromNow();
    } else if (formatType ==null) {
      return ''
    } else {
      return date.format(formatType);
    }
  }
}
