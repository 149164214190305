import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CfaLoadingDirective } from './cfa-loading-directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    CfaLoadingDirective
  ],
  exports: [
    CfaLoadingDirective
  ]
})
export class CfaLoadingModule {}
