import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import Chart from 'chart.js';

@Component({
  selector: 'chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements AfterViewInit {
  @Input() public type: any;
  @Input() public data: any;
  @Input() public options: any;
  @Input() public width: any = 275;
  @Input() public height: any = 275;
  @Input() public margin: any;
  @Input() public backgroundImageUrl: string;

  @ViewChild('myChart') public el: ElementRef;

  public ngAfterViewInit() {
    let helpers = Chart.helpers;

    // Define other chart types
    Chart.controllers.lineOffsetLeft = Chart.controllers.line.extend({
      // Can't change from function since the 'this' keyword
      // will no longer define the chartElement Object
      updateElement: function(point, index, reset) {
        let me = this;
        let meta = me.getMeta();
        let custom = point.custom || {};
        let dataset = me.getDataset();
        let datasetIndex = me.index;
        let value = dataset.data[index];
        let yScale = me.getScaleForId(meta.yAxisID);
        let xScale = me.getScaleForId(meta.xAxisID);
        let pointOptions = me.chart.options.elements.point;
        let x;
        let y;

        x = xScale.getPixelForValue(
          typeof value === 'object' ? value : NaN, index, datasetIndex, true
        );
        y = reset ? yScale.getBasePixel() : me.calculatePointY(value, index, datasetIndex);

        // Move x-axis point back one fourth of the width covered
        // by the bars in between two x axis ticks
        let xAxisWidth = xScale.chart.chart.width - (xScale.margins.left + xScale.margins.right);
        let segmentWidth = xAxisWidth / xScale.ticks.length;

        // Only 80% of the space between two x axis ticks is filled by the bars
        let totalBarWidth = segmentWidth * .8;
        x = x - totalBarWidth / 4;

        // Utility
        point._xScale = xScale;
        point._yScale = yScale;
        point._datasetIndex = datasetIndex;
        point._index = index;

        // Desired view properties
        point._model = {
          x,
          y,
          skip: custom.skip || isNaN(x) || isNaN(y),
          // Appearance
          radius: custom.radius || helpers.getValueAtIndexOrDefault(
            dataset.pointRadius, index, pointOptions.radius
          ),
          pointStyle: 'circle',
          backgroundColor: me.getPointBackgroundColor(point, index),
          borderColor: me.getPointBorderColor(point, index),
          borderWidth: me.getPointBorderWidth(point, index),
          tension: meta.dataset._model ? meta.dataset._model.tension : 0,
          steppedLine: meta.dataset._model ? meta.dataset._model.steppedLine : false,
          hitRadius: custom.hitRadius || helpers.getValueAtIndexOrDefault(
            dataset.pointHitRadius, index, pointOptions.hitRadius
          )
        };
      },
    });

    Chart.controllers.lineOffsetRight = Chart.controllers.line.extend({
      // Can't change from function since the 'this' keyword
      // will no longer define the chartElement Object
      updateElement: function(point, index, reset) {
        let me = this;
        let meta = me.getMeta();
        let custom = point.custom || {};
        let dataset = me.getDataset();
        let datasetIndex = me.index;
        let value = dataset.data[index];
        let yScale = me.getScaleForId(meta.yAxisID);
        let xScale = me.getScaleForId(meta.xAxisID);
        let pointOptions = me.chart.options.elements.point;
        let x;
        let y;

        // Compatibility: If the properties are defined with only the old name, use those values
        if ((dataset.radius !== undefined) && (dataset.pointRadius === undefined)) {
          dataset.pointRadius = dataset.radius;
        }
        if ((dataset.hitRadius !== undefined) && (dataset.pointHitRadius === undefined)) {
          dataset.pointHitRadius = dataset.hitRadius;
        }

        x = xScale.getPixelForValue(
          typeof value === 'object' ? value : NaN, index, datasetIndex, true
        );
        y = reset ? yScale.getBasePixel() : me.calculatePointY(value, index, datasetIndex);

        // Move x-axis point back one fourth of the width covered
        // by the bars in between two x axis ticks
        let xAxisWidth = xScale.chart.chart.width - (xScale.margins.left + xScale.margins.right);
        let segmentWidth = xAxisWidth / xScale.ticks.length;

        // Only 80% of the space between two x axis ticks is filled by the bars
        let totalBarWidth = segmentWidth * .8;
        x = x + totalBarWidth / 4;

        // Utility
        point._xScale = xScale;
        point._yScale = yScale;
        point._datasetIndex = datasetIndex;
        point._index = index;

        // Desired view properties
        point._model = {
          x,
          y,
          skip: custom.skip || isNaN(x) || isNaN(y),
          // Appearance
          radius: custom.radius || helpers.getValueAtIndexOrDefault(
            dataset.pointRadius, index, pointOptions.radius
          ),
          pointStyle: 'circle',
          backgroundColor: me.getPointBackgroundColor(point, index),
          borderColor: me.getPointBorderColor(point, index),
          borderWidth: me.getPointBorderWidth(point, index),
          tension: meta.dataset._model ? meta.dataset._model.tension : 0,
          steppedLine: meta.dataset._model ? meta.dataset._model.steppedLine : false,
          hitRadius: custom.hitRadius || helpers.getValueAtIndexOrDefault(
            dataset.pointHitRadius, index, pointOptions.hitRadius
          )
        };
      },
    });
    let myChart = new Chart(this.el.nativeElement, {
      type: this.type,
      data: this.data,
      options: this.options
    });
  }
}
