<div *ngIf="error$ | async" fxLayout="column" fxLayoutAlign="space-around center">
  <error-message *ngIf="error$ | async"
                 [error]="error$ | async"
                 [page]="'Active Rewards'">
  </error-message>
  <button mat-button (click)="closeDialog(false)">Close</button>
</div>

<ng-container *ngIf="!(error$ | async)">
  <div mat-dialog-content>
    <ng-container *ngIf="loading$ | async">
      <content-placeholder></content-placeholder>
    </ng-container>

    <ng-container *ngIf="!(loading$ | async)">
      <ng-container>
        <div *ngIf="(activeRewardCustomers$ | async)?.length > 0">
        <div *ngIf="!isDynamicGroupSend">
            <b>{{(activeRewardCustomers$ | async)?.length}} of the guests</b> you've selected already have
            an active reward, for any menu item, available during this reward length. (As of yesterday)
        </div>
        <div *ngIf="isDynamicGroupSend">
            If customers in this group already have an active reward available to them, do you want to send them another reward?
        </div>
        <br>
          <div fxLayout="column" fxLayoutAlign="center start">
            <div fxFlex class="confirmation__question" *ngIf="!isDynamicGroupSend"><i>Would you like to send them another reward?</i></div>
            <mat-slide-toggle fxFlex
                              color="primary"
                              class="confirmation__active-reward-toggle"
                              [checked]="false"
                              (change)="slideToggled($event)">
              Include Customers with active reward(s) available for any menu item(s).
            </mat-slide-toggle>
            <span>
              NOTE: The Spotlight Duplicate Reward Check is performed automatically regardless of the selection above.
              This check prevents the same reward, for the same duration, being loaded on the same Customer account.
              This check is bypassed if either the <b>Sorry for the snag</b> or <b>We apologize</b> reward themes on Engage is selected for recovery effort.
            </span>
            <div class="confirmation__divider"></div>
          </div>
        </div>

        <b>Reward Summary</b>
        <br>
        <div class="confirmation__reward-summary">
          <div *ngIf="!isDynamicGroupSend">
            {{count}} customer(s)
          </div>
          <div *ngIf="isDynamicGroupSend">
            TBD customer(s)
          </div>
          {{rewardLength}} day(s), expiring on {{endDate | locTimeZoneDate:'MMM DD, YYYY'}}.
        </div>
        <br>
      </ng-container>

      <b><i>
        <span>When immediately sending a reward, you can NOT cancel the offer after it is sent.</span>
        <span *ngIf="isNotImmediateSend">Only a reward scheduled to send on a future date may be cancelled, prior to the start date, from Spotlight Calendar.</span>
      </i></b>
    </ng-container>
  </div>
  <div mat-dialog-actions class="confirmation__wrapper">
    <button mat-button class="confirmation__cancel-button"
            [disabled] = "loading$ | async"
            (click)="closeDialog(false)" >
      Cancel
    </button>
    <button mat-button
            (click)="closeDialog(true)"
            [disabled]="sendDisabled(loading$ | async, count)"
            [ngClass]="{
                'confirmation__send-button': !sendDisabled(loading$ | async, count),
                'confirmation__send-button-disabled': sendDisabled(loading$ | async, count)
            }">
      SEND
    </button>
  </div>
</ng-container>
