import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../reducers';
import { Observable } from 'rxjs';
import { CareConstants } from '../care.constants';

@Component({
  selector: 'action-selection-dialog',
  templateUrl: 'action-selection-dialog.component.html',
  styleUrls: ['./action-selection-dialog.component.scss']
})

export class ActionSelectionDialogComponent implements OnInit {
  public name;
  public type;
  public priority;
  public eligibleForSpotEngagement;
  public isRecoverySendingDisabled$: Observable<boolean>;
  public recoverEligibilityLoading$: Observable<boolean>;
  public actionOptions$: Observable<any>;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
              public store: Store<fromRoot.State>,
              public dialogRef: MatDialogRef<ActionSelectionDialogComponent>) {
    this.isRecoverySendingDisabled$ = store.select(fromRoot.getFeatureEnabled(CareConstants.disableSendingFlag));
    this.recoverEligibilityLoading$ = store.select(fromRoot.getCareRecoverEligibilityLoading);
  }

  public ngOnInit() {
    this.name = this.dialogData.fullName;
    this.type = this.dialogData.type;
    this.priority = this.dialogData.priority;
    this.eligibleForSpotEngagement = this.dialogData.eligibleForSpotEngagement;
    if (this.type === CareConstants.spotCustomer) {
      this.actionOptions$ = this.store.select(fromRoot.getSpotStatusOptions);
    } else {
      this.actionOptions$ = this.store.select(fromRoot.getCareStatusOptions);
    }
  }
}
