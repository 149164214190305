<div class="edit-free-item-in-cart__form">
  <template-summary [templateName]="tile.descriptionText.summary"
                    [type]="campaignType"
                    [imageURL]="tile.displayImageUrl"
                    [details]="tile.descriptionText.suggestedUseText"
                    [isMobile]="isMobile"
                    [isDisabled]="true"
                    [isRoundedBorder]="true">
  </template-summary>
  <div fxLayout="column" fxLayoutAlign="center start" class="edit-free-item-in-cart__section-container">
    <div class="edit-free-item-in-cart__header">Free Menu Item Selection</div>
    <ng-container>
      <mat-form-field  appearance="outline" class="edit-free-item-in-cart__reward-dropdown">
        <mat-select
                    [formControl]="selectedRewardFormControl"
                    [value]="selectedReward"
                    (selectionChange)="updateReward($event.value)"
                    (openedChange)="isOpened()">
          <mat-select-trigger>
            <div fxLayout="row" fxLayoutAlign="start center">
              <img fxFlex class="edit-free-item-in-cart__reward-image" src="{{selectedReward.offerImage}}" alt="">
              <span fxFlex class="edit-free-item-in-cart__reward-title" [ngClass]="{'edit-free-item-in-cart__reward-disabled': isReview}">{{selectedReward.name}}</span>
            </div>
          </mat-select-trigger>
          <div class="edit-free-item-in-cart__reward-category">Select the Reward you'd like to send</div>
            <mat-option *ngFor="let reward of rewardList" [value]="reward" class="edit-free-item-in-cart__reward-option">
              <div fxLayout="row" fxLayoutAlign="start center">
                <img fxFlex class="edit-free-item-in-cart__reward-image" src="{{reward.offerImage}}" alt="">
                <span fxFlex class="edit-free-item-in-cart__reward-title">{{reward.name}}</span>
              </div>
            </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
  <ng-container>
    <div class="edit-free-item-in-cart__header"
         [ngClass]="{'edit-free-item-in-cart__error-missing-field-header': (campaignNameFormControl && campaignNameFormControl.invalid)}">
      Internal Campaign Name
    </div>
    <mat-form-field class="edit-free-item-in-cart__name-input">
      <input matInput
             maxlength="75"
             [formControl]="campaignNameFormControl"
             [value]="campaignName"
             (keyup)="onCampaignNameChange($event)"
             [readonly]="isReview">
      <mat-error>
        Internal Campaign Name is required
      </mat-error>
    </mat-form-field>
  </ng-container>
  <ng-container>
    <div class="edit-free-item-in-cart__header">
      Promotion Criteria
    </div>
    <dropdown-by-id id="fic-destination"
                    [categories]="destinations"
                    [disabled]="isReview"
                    [dropdownLabel]="'Destination'"
                    [enableMultiSelect]="true"
                    [isMobile]="isMobile"
                    [selectedCategoryId]="selectedDestinationId"
                    [selectedCategoryIdsForMultiSelect]="selectedDestinationIdsForMultiSelect"
                    (onUpdateCategory)="updateDestination($event)"
                    (onUpdateMultiSelectCategories)="updateMultiSelectDestination($event)"
                    (onUpdateCategoryMobile)="updateDestination($event)">
    </dropdown-by-id>
    <dropdown-by-id id="fic-time-of-day"
                    [categories]="timeOfDayOptions"
                    [disabled] = "isReview"
                    [dropdownLabel]="'Time of Day'"
                    [isMobile]="isMobile"
                    [removeTopBorder]="true"
                    [selectedCategoryId]="selectedTimeOfDay"
                    (onUpdateCategory)="updateTimeOfDay($event)"
                    (onUpdateCategoryMobile)="updateTimeOfDay($event)">
    </dropdown-by-id>
  </ng-container>
  <div *ngIf="(selectedTimeOfDay) === customTimeOfDay"
       class="edit-free-item-in-cart__time-option-container"
       fxLayout="row wrap"
       fxLayoutAlign="space-between center">
    <div fxFlex="40" fxFlex.gt-sm="30">
      <time-picker placeholder="Start Time"
                   [disabled]="isReview"
                   [endTimeRestriction]="startTimeEndRestriction"
                   [startTimeRestriction]="startTimeRestriction"
                   [time]="startTime"
                   [validationControl]="startTimeFormControl"
                   (timeSelected)="startTimeChanged($event)">
      </time-picker>
      <span>{{(selectedLocation).locationTimeZoneForDisplay}}</span>
    </div>
    <div fxFlex>
      <time-picker placeholder="End Time"
                   [disabled]="isReview"
                   [endTimeRestriction]="endTimeEndRestriction"
                   [startTimeRestriction]="endTimeRestriction"
                   [time]="endTime"
                   [validationControl]="endTimeFormControl"
                   (timeSelected)="endTimeChanged($event)">
      </time-picker>
      <span>{{(selectedLocation).locationTimeZoneForDisplay}}</span>
    </div>
  </div>
  <ng-container>
    <div fxLayout="column" class="edit-free-item-in-cart__section-container">
      <span class="edit-free-item-in-cart__header">Customer Types</span>
      <span class="edit-free-item-in-cart__sub-header">
        Refine who will qualify for the promotion by targeting Chick-fil-A® One customers.
      </span>
      <br>
      <div fxLayout="column" fxLayoutAlign="space-between start">
        <div *ngFor="let customerType of customerTypes" fxLayout="row">
          <mat-slide-toggle [checked]="customerType.selected"
                            [disabled]="isReview"
                            (change)="customerTypeUpdated(customerType)">
          </mat-slide-toggle>
          <span class="edit-free-item-in-cart__bold-text">{{' ' + customerType.displayTitle + ' '}}</span>
          <span class="edit-free-item-in-cart__text">{{customerType.subTitle + ' '}}</span>
          <help-text *ngIf="customerType.info"
                     [blockDisplay]="true"
                     [displayOnLeft]="true"
                     [helpText]="customerType.info"
                     [isSmallIcon]="isMobile">
          </help-text>
        </div>
        <mat-error *ngIf="submitted && getNoCustomerTypeSelected()" class="edit-third-party-promotion-details__error-text">
          Please select customer type.
        </mat-error>
      </div>
    </div>
  </ng-container>
  <div fxLayout="column" class="edit-free-item-in-cart__section-container">
    <div class="edit-free-item-in-cart__header">I would like this promotion to</div>
    <ng-container>
      <div class="edit-free-item-in-cart__toggle-btns">
        <button-toggle [disabled]="areDatesWithinSevenDays || isReview"
                       [buttons]="(repeatThisPromotion)"
                       [isFullWidth]="true"
                       [isMobile]="isMobile"
                       (buttonToggled)="updateRepeatPromotion($event)">
        </button-toggle>
      </div>
    </ng-container>
    <ng-container *ngIf="(selectedRepeatPromotion) === weeklyRepeatPromotion">
      <div class="edit-free-item-in-cart__header">
        On every
      </div>
      <div class="edit-free-item-in-cart__toggle-btns">
        <button-toggle [disabled]="areDatesWithinSevenDays || isReview"
                       [buttons]="(repeatPromotionButtons)"
                       [isFullWidth]="true"
                       [isMobile]="isMobile"
                       (buttonToggled)="updateRepeatPromotionDay($event)">
        </button-toggle>
      </div>
    </ng-container>
  </div>
  <div class="edit-free-item-in-cart__date-selection">
    <date-range-picker [campaignType]="campaignType"
                       [disabled]="isReview || allDaysDisabled"
                       [disabledDays]="disabledDays"
                       [disableSundays]="true"
                       [allDaysDisabled]="allDaysDisabled"
                       [endDate]="endDate"
                       [endDatePlaceholder]="'End on'"
                       [endDateRestriction]="endDateRestriction"
                       [futureDatesOnly]="true"
                       [isMaxDayRestrictionDynamic]="false"
                       [isMobile]="isMobile"
                       [isStartDateRestriction]="true"
                       [startDate]="startDate"
                       [startDatePlaceholder]="'Start on'"
                       [stopDate]="stopDate"
                       (datesChanged)="updateDate($event)">
    </date-range-picker>
  </div>
  <ng-container *ngIf="isReview && !statsLoading && stats">
    <mat-nav-list [ngClass]="isMobile ? 'edit-free-item-in-cart__list-mobile' : 'edit-free-item-in-cart__list'">
      <h4 class="edit-free-item-in-cart__analytics-title">
        Analytics Summary
      </h4>
      <div class="edit-free-item-in-cart__grid">
        <div fxLayout="row" class="edit-free-item-in-cart__analytics-label">
          Total Number of Customers
          <help-text class="edit-free-item-in-cart__help-text" [helpText]="FICTotalCustomersHelp" [is3pSize]="true"></help-text>:
        </div>
        <div class="edit-free-item-in-cart__analytics-data">{{stats.totalCustomers}}</div>
        <div fxLayout="row" class="edit-free-item-in-cart__analytics-label">
          Promotional Sales
          <help-text class="edit-free-item-in-cart__help-text" [helpText]="FICSalesHelp" [displayOnLeft]="true" [is3pSize]="true"></help-text>:
        </div>
        <div class="edit-free-item-in-cart__analytics-data">${{stats.totalSales | number : '1.2-2'}}</div>
        <div fxLayout="row" class="edit-free-item-in-cart__analytics-label">
          Promotional Transactions
          <help-text class="edit-free-item-in-carts__help-text" [helpText]="FICTransactionsHelp" [is3pSize]="true"></help-text>:
        </div>
        <div class="edit-free-item-in-cart__analytics-data">{{stats.totalTransactions}}</div>
        <div fxLayout="row" class="edit-free-item-in-cart__analytics-label">
          Check Average
          <help-text class="edit-free-item-in-cart__help-text" [helpText]="FICAverageCheckHelp" [displayOnLeft]="true" [is3pSize]="true"></help-text>:
        </div>
        <div class="edit-free-item-in-cart__analytics-data">${{stats.salesAverage | number : '1.2-2'}}</div>
      </div>
    </mat-nav-list>
  </ng-container>
  <ng-container *ngIf="isReview && !statsLoading && !stats">
    <div class ="edit-free-item-in-cart__stats-error">
      <mat-icon class="edit-free-item-in-cart__report-icon">report</mat-icon>
      <p class="edit-free-item-in-cart__stats-error-text"> We’re having issues loading the analytics summary. Try again later.</p>
    </div>
  </ng-container>
  <ng-container>
    <div class="edit-free-item-in-cart__section-container">
      <span class="edit-free-item-in-cart__bold-text">Note: </span>
      <span class="edit-free-item-in-cart__text">Limit one item per person per day. Subject to availability.
      </span>
    </div>
  </ng-container>

  <div *ngIf="!isReview && !isMobile" fxLayout="row" fxLayoutAlign="center">
    <button class="edit-free-item-in-cart__button-mobile"
            id="edit-free-item-in-cart__create-promotion-button"
            mat-raised-button
            [disabled] ="disableSubmit() || allDaysDisabled"
            (click)="goToConfirmation()">
      Create Promotion
    </button>
  </div>


  <div *ngIf="isMobile && !isReview" fxLayout="column" fxLayoutAlign="space-between">
    <button mat-raised-button
            class="edit-free-item-in-cart__button-mobile"
            fxFlex="45"
            fxFlexAlign="center"
            [disabled]="disableSubmit() || editForm.invalid"
            (click)="goToPreviewPage.emit()">
      Preview
    </button>
    <button mat-raised-button
            class="edit-free-item-in-cart__cancel-button-mobile"
            fxFlex="45"
            fxFlexAlign="center"
            (click)="goToDiscover.emit()">
      Back
    </button>
  </div>
  <div *ngIf="isReview && !(isMobile)" fxLayout="row" fxLayoutAlign="space-between">
    <button mat-raised-button
            class="edit-free-item-in-cart__button"
            fxFlex="45"
            fxFlexAlign="end"
            (click)="onGoToCalendar.emit()">
      Back to Calendar
    </button>
    <button mat-raised-button
            class="edit-free-item-in-cart__cancel-button"
            [ngClass] = "{'edit-free-item-in-cart__cancel-button-disabled':isPromotionPastOrCancelled()}"
            fxFlex="45"
            fxFlexAlign="end"
            [disabled]="isPromotionPastOrCancelled()"
            (click)="cancelScheduledPromotion()">
      Stop Promotion
    </button>
  </div>
  <div *ngIf="isReview && isMobile" fxLayout="column" fxLayoutAlign="space-between">
    <button mat-raised-button
            class="edit-free-item-in-cart__button-mobile"
            fxFlex="45"
            fxFlexAlign="center"
            (click)="onGoToCalendar.emit()">
      Back to Calendar
    </button>
    <button mat-raised-button
            class="edit-free-item-in-cart__cancel-button-mobile"
            [ngClass] = "{'edit-free-item-in-cart__cancel-button-mobile-disabled':isPromotionPastOrCancelled()}"
            fxFlex="45"
            fxFlexAlign="center"
            [disabled]="isPromotionPastOrCancelled()"
            (click)="cancelScheduledPromotion()">
      Stop Promotion
    </button>
  </div>
</div>

