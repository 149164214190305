<mat-form-field appearance="fill" (click)="datePicker.open()" class="date-picker__picker-container">
<!--    Need two separate inputs, one for form control and one for no form control-->
    <input *ngIf="!validationControl"
           matInput
           readonly
           [disabled]="disabled"
           [value]="date"
           [ngClass]= "{'date-picker__disabled': disabled, 'date-picker__enabled': !disabled}"
           [matDatepicker]="datePicker"
           [matDatepickerFilter]="dayFilter"
           [min]="minDate"
           [max]="maxDate"
           [placeholder]="placeholder"
           (dateChange)="onDateChange($event)">
    <input *ngIf="validationControl"
           matInput
           readonly
           [value]="date"
           [ngClass]= "{'date-picker__disabled': disabled, 'date-picker__enabled': !disabled}"
           [matDatepicker]="datePicker"
           [matDatepickerFilter]="dayFilter"
           [min]="minDate"
           [max]="maxDate"
           [placeholder]="placeholder"
           [formControl]="validationControl"
           (dateChange)="onDateChange($event)">
    <mat-datepicker-toggle matSuffix [for]="datePicker" [disabled]="disabled"></mat-datepicker-toggle>
    <mat-datepicker #datePicker [disabled]="disabled" [touchUi]="isMobile" (opened)="onOpen()"></mat-datepicker>
    <mat-error>{{placeholder}} is required</mat-error>
</mat-form-field>
