<div class="action-selection-dialog__form" fxLayout="column">
  <action-selection [actionOptions]="actionOptions$ | async"
                    [name]="name"
                    [type]="type"
                    [priority]="priority"
                    [eligibleForSpotEngagement]="eligibleForSpotEngagement"
                    [isSendDisabled]="isRecoverySendingDisabled$ | async"
                    [isMobile]="false"
                    [recoverEligibilityLoading]="recoverEligibilityLoading$ | async"
                    (startFlowClicked)="dialogRef.close($event)"
                    (cancelClicked)="dialogRef.close()">
  </action-selection>
</div>
