<div *ngIf="!isMobile; else mobile" class="ngx-data-table__card">
  <div class="ngx-data-table"
       [ngClass]="{'ngx-data-table__disabled-click' : isLoading}">
    <data-table-header [rowIdProp]="rowIdProp"
                       [isLoading]="isLoading"
                       [columns]="columns"
                       [currentSort]="currentSort"
                       [rows]="rows"
                       [showCheckboxes]="showCheckboxes"
                       [selectedItems]="selectedItems"
                       [allCustomerIds]="allCustomerIds"
                       [canSelectAll]="canSelectAll"
                       [disableIndividualRowAttribute]="disableIndividualRowAttribute"
                       [disableCheckbox]="disableCheckbox"
                       [useSmallRowSize]="useSmallRowSize"
                       [isAllCustomersNonSpotlight]="checkAllCustomerNonSpotLight()"
                       (onSortChange)="sortChange($event)"
                       (onSelectAllOnPageOnly)="selectAllRowsOnPageOnly($event)"
                       (onSelectAllCustomers)="onSelectAllCustomers($event)">
    </data-table-header>
    <data-table-body [rowIdProp]="rowIdProp"
                     [rows]="rows"
                     [columns]="columns"
                     [isLoading]="isLoading"
                     [isDetailView]="isDetailView"
                     [actionableErrorText]="actionableErrorText"
                     [showCheckboxes]="showCheckboxes"
                     [noResultsMessage]="noResultsMessage"
                     [isVisibleDeepLinkMsg]="isVisibleDeepLinkMsg"
                     [deepLinkMessage]="deepLinkMessage"
                     [detailProp]="detailProp"
                     [detailColumns]="detailColumns"
                     [disableIndividualRowAttribute]="disableIndividualRowAttribute"
                     [disableCheckbox]="disableCheckbox"
                     [disableDeleteIcon]="disableDeleteIcon"
                     [disableSelect]="disableSelect"
                     [isEnableSignatureMembership]="isEnableSignatureMembership"
                     [enableCaresOVCC]="enableCaresOVCC"
                     [hasOnClickListeners]="hasOnClickListeners"
                     [isRowNotInteractable]="isRowNotInteractable"
                     [useSmallRowSize]="useSmallRowSize"
                     [detailViewNoteText]="detailViewNoteText"
                     [rowId]="rowId"
                     [openRowSubject$]="openRowSubject$"
                     [noteProp]="noteProp"
                     (onRowClick)="rowClicked($event)"
                     (onCheckboxClick)="rowSelected($event)"
                     (errorTextClick)="errorTextClicked()"
                     (onButtonClick)="buttonClicked($event)"
                     (onExternalLinkClick)="externalLinkClicked($event)"
                     (onDeleteClick)="deleteClicked($event)"
                     (deepLinkMessageClick)="onClickDeepLinkMsg()"
                     (onOrderNumberClicked)="orderNumberClicked($event)">
    </data-table-body>
    <data-table-footer *ngIf="showFooter"
                       [totalElements]="totalElements"
                       [pageSizeOptions]="pageSizeOptions"
                       [pageSize]="pageSize"
                       [currentPage]="currentPage"
                       [numberOfPages]="numberOfPages"
                       [isMobile]="isMobile"
                       (onPageSizeChange)="pageSizeChange($event)"
                       (onPageChange)="pageChange($event)">
    </data-table-footer>
  </div>
</div>
<ng-template #mobile>
  <mobile-data-table-full *ngIf="useFullMobileTable"
                          [totalElements]="totalElements"
                          [rowIdProp]="rowIdProp"
                          [isLoading]="isLoading"
                          [columns]="columns"
                          [rows]="rows"
                          [currentSort]="currentSort"
                          [isDetailView]="isDetailView"
                          [noResultsMessage]="noResultsMessage"
                          [detailProp]="detailProp"
                          [detailColumns]="detailColumns"
                          [useSmallRowSize]="useSmallRowSize"
                          [pageSize]="pageSize"
                          [currentPage]="currentPage"
                          [numberOfPages]="numberOfPages"
                          [detailViewNoteText]="detailViewNoteText"
                          [rowId]="rowId"
                          [isRowNotInteractable]="isRowNotInteractable"
                          [openRowSubject$]="openRowSubject$"
                          [disableIndividualRowAttribute]="disableIndividualRowAttribute"
                          (onRowClick)="rowClicked($event)"
                          (onSortChange)="sortChange($event)"
                          (onPageChange)="pageChange($event)"
                          (onOrderNumberClicked)="orderNumberClicked($event)">
  </mobile-data-table-full>
  <mobile-data-table *ngIf="!useFullMobileTable"
                     [totalElements]="totalElements"
                     [useMembershipAsIcon]="useMembershipAsIcon"
                     [columns]="columns"
                     [rows]="rows"
                     [rowIdProp]="rowIdProp"
                     [allCustomerIds]="allCustomerIds"
                     [selectedItems]="selectedItems"
                     [noResultsMessage]="noResultsMessage"
                     [isVisibleDeepLinkMsg]="isVisibleDeepLinkMsg"
                     [deepLinkMessage]="deepLinkMessage"
                     [currentPage]="currentPage"
                     [numberOfPages]="numberOfPages"
                     [pageSize]="pageSize"
                     [showFooter]="showFooter"
                     [mobileDeleteEnabled]="mobileDeleteEnabled"
                     [enableMobileCheckbox]="enableMobileCheckbox"
                     [disableRipple]="disableRipple"
                     [disableCheckboxes]="disableCheckbox"
                     [disableIndividualRowAttribute]="disableIndividualRowAttribute"
                     [disableSelect]="disableSelect"
                     [isEnableSignatureMembership]="isEnableSignatureMembership"
                     [shouldDisplayIcon]="shouldDisplayIcon"
                     [shouldDisplayLabel]="shouldDisplayLabel"
                     [shouldDisplayMemberText]="shouldDisplayMemberText"
                     [hasOnClickListeners]="hasOnClickListeners"
                     [isRowNotInteractable]="isRowNotInteractable"
                     [loading]="isLoading"
                     [mobileActionMenuMetaData]="mobileActionMenuMetaData"
                     [noteProp]="noteProp"
                     (onPageChange)="pageChange($event)"
                     (onRowClick)="rowClicked($event)"
                     (onCheckboxClick)="rowSelected($event)"
                     (onDeleteClick)="deleteClicked($event)"
                     (onSelectAllOnPageOnly)="selectAllRowsOnPageOnly($event)"
                     (onSelectAllCustomers)="onSelectAllCustomers($event)"
                     (deepLinkMessageClick)="onClickDeepLinkMsg()">
  </mobile-data-table>
</ng-template>
