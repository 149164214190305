import { createSelector } from 'reselect';
import { MetaReducer } from '@ngrx/store';

import * as fromActiveRewards from './active-rewards';
import * as fromCalendar from './calendar';
import * as fromCare from './care';
import * as fromCustomer from './customer';
import * as fromDiscover from './discover';
import * as fromEngage from './engage';
import * as fromEngagement from './engagement';
import * as fromError from './error';
import * as fromEula from './eula';
import * as fromLayout from './layout';
import * as fromManageAttendees from './manage-attendees';
import * as fromMeet from './meet';
import * as fromNotifications from './notifications';
import * as fromProfile from './profile';
import * as fromSettings from './settings';
import * as fromUnderstand from './understand';
import * as fromUser from './user';

import * as R from 'ramda';
import * as moment from 'moment-timezone';
import {
  addExtraFilters, getAllFormattedSelectedFilters, getFormattedSelectedFilters, getMatchingCustomer, replaceCareHtml, replaceMGHtml
} from './util';
import { AppConstants } from '../app.constants';
import { CalendarConstants } from '../calendar/calendar.constants';
import { CareConstants } from '../care/care.constants';
import { DiscoverConstants } from '../discover/discover.constants';
import { EngageConstants } from '../engage/engage.constants';
import { Inactives } from '../models/shared/inactive-flag.model';
import { MeetConstants } from '../meet/meet.constants';
import { NavMenuConstants } from '../nav-menu/nav-menu.constants';
import { NotificationListConstants } from '../notifications/notification-list.constants';
import { ProfileConstants } from '../customer-profile/customer-profile.constants';
import { SharedConstants } from '../shared/shared.constants';
import { Tile } from '../models/understand/tile.model';

export const metaReducers: MetaReducer<State>[] = [];

export interface State {
  activeRewards: fromActiveRewards.State;
  calendar: fromCalendar.State;
  care: fromCare.State;
  customer: fromCustomer.State;
  discover: fromDiscover.State;
  engage: fromEngage.State;
  engagement: fromEngagement.State;
  error: fromError.State;
  eula: fromEula.State;
  layout: fromLayout.State;
  manageAttendees: fromManageAttendees.State;
  meet: fromMeet.State;
  notifications: fromNotifications.State;
  profile: fromProfile.State;
  settings: fromSettings.State;
  understand: fromUnderstand.State;
  user: fromUser.State;
}

export const reducers = {
  activeRewards: fromActiveRewards.reducer,
  calendar: fromCalendar.reducer,
  care: fromCare.reducer,
  customer: fromCustomer.reducer,
  discover: fromDiscover.reducer,
  engage: fromEngage.reducer,
  engagement: fromEngagement.reducer,
  error: fromError.reducer,
  eula: fromEula.reducer,
  layout: fromLayout.reducer,
  manageAttendees: fromManageAttendees.reducer,
  meet: fromMeet.reducer,
  notifications: fromNotifications.reducer,
  profile: fromProfile.reducer,
  settings: fromSettings.reducer,
  understand: fromUnderstand.reducer,
  user: fromUser.reducer
};

/**
 * ActiveRewards state root selector
 */
export const getActiveRewardsState = (state) => state.activeRewards;

/**
 * ActiveRewards state children selectors
 */
export const getActiveRewardCustomers = createSelector(getActiveRewardsState, fromActiveRewards.getActiveRewardCustomers);

export const getActiveRewardLoading = createSelector(getActiveRewardsState, fromActiveRewards.getLoading);

export const getActiveRewardError = createSelector(getActiveRewardsState, fromActiveRewards.getError);

export const getIncludeActiveCustomers = createSelector(getActiveRewardsState, fromActiveRewards.getIncludeActiveCustomers);

/**
 * Layout state root selector
 */
export const getLayoutState = (state: State) => state.layout;

/**
 * Layout state children selectors
 */
export const getScreenSize = createSelector(getLayoutState, fromLayout.getScreenSize);

export const getIsMobile = createSelector(getScreenSize , (size) => {
  return size === AppConstants.extraSmall || size === AppConstants.small;
});

export const getIsExtraSmallMobile = createSelector(getScreenSize , (size) => {
  return size === AppConstants.extraSmall;
});

export const getIsSmallMobile = createSelector(getScreenSize , (size) => {
  return size === AppConstants.small;
});

/**
 * User root selector
 */
export const getUserState = (state: State) => state.user;

/*
 * User child reducers
 */

export const getUserName = createSelector(getUserState, fromUser.getUserName);

export const getUserLocations = createSelector(getUserState, fromUser.getLocations);

export const getInitialLocation = createSelector(getUserState, fromUser.getInitialLocation);

export const getUserLoading = createSelector(getUserState, fromUser.getLoading);

export const getUserError = createSelector(getUserState, fromUser.getError);

export const getLocationError = createSelector(getUserState, fromUser.getLocationError);

export const getShowWelcomeModalFromBanner = createSelector(getUserState, fromUser.getWelcomeModal);

export const getSavedDefaultLocation = createSelector(getUserState, fromUser.getDefaultLocation);

export const getSelectedLocation = createSelector(getUserState, fromUser.getSelectedLocation);
export const getLDFlags = createSelector(getUserState, fromUser.getLDFlags);

export const getAdminFull = createSelector(getUserState, fromUser.getAdminFull);

export const getAdminView = createSelector(getUserState, fromUser.getAdminView);

export const getVersionNumber = createSelector(getUserState, fromUser.getVersionNumber);

export const getReadOnlyUser = createSelector(getAdminFull, getAdminView,
  (adminFull, adminView) => adminView && !adminFull);

export const isAdminUser = createSelector(getAdminFull, getAdminView,
  (adminFull, adminView) => adminFull || adminView);

export const getFeatureEnabled = (featureName) => {
  return createSelector(getSelectedLocation, getLDFlags,(loc, LDFlags) => {
    if (LDFlags[featureName] != undefined) {
      let feature = LDFlags[featureName];
      // Feature flags that are not location specific will be just booleans
      if (typeof feature == 'boolean') {
        return feature;
      }
      // Check if all locations are enabled or if the current location is in the locations list
      return feature.enabledForAllLocations || (feature.enabledForLocations && feature.enabledForLocations.includes(loc.locationNumber));
    }
    return false;
  });
};

export const getThirdPartyEnabled = () => {
  return createSelector(getSelectedLocation, (location) => {
    const enabled = getFeatureEnabled('thirdPartyPromotion');
    if (enabled) {
      return location.hasThirdParty;
    }
  });
};

export const getMaintenanceText = () => {
  return createSelector(getLDFlags, (flags) => {
    const maintenanceFeatureFlag = flags[AppConstants.maintenanceFlag];
    return maintenanceFeatureFlag.maintenanceText;
  });
}

export const getNavMenuOptions = createSelector(
  isAdminUser, getSelectedLocation, getUserLocations, getFeatureEnabled(SharedConstants.disableCalendarTabFlag),
  (adminUser, selectedLoc, locations, disableCalendarTab) => {
    let menuOptions = disableCalendarTab ? NavMenuConstants.navMenuOptionsWithoutCalendar : NavMenuConstants.navMenuOptions;
    if (adminUser) {
      menuOptions.forEach(menuItem => {
        if (menuItem.isLocationMenu) {
          menuItem.options[0].label = selectedLoc.locationName;
          menuItem.options[0].locationNumber = selectedLoc.locationNumber;
          menuItem.options[0].imageURL = selectedLoc.imageUrl;
        }
      });
    } else {
      menuOptions.forEach(menuItem => {
        if (menuItem.isLocationMenu) {
          let locMenu = locations.map(loc => {
            let obj : any = {
              id: 'location',
              label: loc.locationName,
              imageURL: loc.imageUrl,
              locationNumber: loc.locationNumber
            };
            return obj;
          });
          if (locMenu.length === 1) {
            locMenu[0].customClass = true;
            menuItem.title = 'Your Restaurant';
          }
          menuItem.options = locMenu;
        }
      });
    }
    return menuOptions;
  });

export const getMeetSelectedIndex = createSelector(
  getFeatureEnabled(SharedConstants.disableDiscoverTabFlag),
  (isDiscoverTabDisabled) => isDiscoverTabDisabled ? 0 : 1);

export const getRecoverSelectedIndex = createSelector(
  getFeatureEnabled(SharedConstants.disableDiscoverTabFlag), getFeatureEnabled(SharedConstants.disableMeetTabFlag),
  (isDiscoverTabDisabled, isMeetTabDisabled) => {
    let recoverIndex = 2;
    if (isDiscoverTabDisabled) {
      recoverIndex--;
    }
    if (isMeetTabDisabled) {
      recoverIndex--;
    }
    return recoverIndex;
  });

export const getEngageSelectedIndex = createSelector(
  getFeatureEnabled(SharedConstants.disableDiscoverTabFlag), getFeatureEnabled(SharedConstants.disableMeetTabFlag),
  getFeatureEnabled(SharedConstants.disableRecoverTabFlag),
  (isDiscoverTabDisabled, isMeetTabDisabled, isRecoverTabDisabled) => {
    let engageIndex = 3;
    if (isDiscoverTabDisabled) {
      engageIndex--;
    }
    if (isMeetTabDisabled) {
      engageIndex--;
    }
    if (isRecoverTabDisabled) {
      engageIndex--;
    }
    return engageIndex;
  });

export const getCalendarSelectedIndex = createSelector(
  getFeatureEnabled(SharedConstants.disableDiscoverTabFlag), getFeatureEnabled(SharedConstants.disableMeetTabFlag),
  getFeatureEnabled(SharedConstants.disableRecoverTabFlag), getFeatureEnabled(SharedConstants.disableEngageTabFlag),
  (isDiscoverTabDisabled, isMeetTabDisabled, isRecoverTabDisabled, isEngageTabDisabled) => {
    let calendarIndex = 4;
    if (isDiscoverTabDisabled) {
      calendarIndex--;
    }
    if (isMeetTabDisabled) {
      calendarIndex--;
    }
    if (isRecoverTabDisabled) {
      calendarIndex--;
    }
    if (isEngageTabDisabled) {
      calendarIndex--;
    }
    return calendarIndex;
  });

export const getDefaultLocation = createSelector(
  getSavedDefaultLocation,
  getUserLocations,
  (defaultLoc, userLocations) => {
    return defaultLoc ? defaultLoc : userLocations[0].locationNumber;
  });

export const getBannerById = (id) => {
  return createSelector(getSelectedLocation, (selectedLocation) => {
    if (selectedLocation && selectedLocation.notificationBanners) {
      return R.find(R.propEq('id', id))(selectedLocation.notificationBanners);
    }
  });
};

/**
 * Eula state root selector
 */
export const getEulaState = (state) => state.eula;

/**
 * Eula state children selectors
 */

export const getShowWelcomeModalFromEula = createSelector(getEulaState, fromEula.getDisplayWelcomeModal);

export const getEulaLoading = createSelector(
  getEulaState, fromEula.getLoading);

export const getEulaError = createSelector(
  getEulaState, fromEula.getError);

export const getEulaAgree = createSelector(
  getEulaState, fromEula.getAgree);

export const getEulaHtml = createSelector(
  getEulaState, fromEula.getHtml);

export const getShowWelcomeModal = createSelector(
  getShowWelcomeModalFromBanner, getShowWelcomeModalFromEula, (
    showWelcomeModalBanner, showWelcomeModalEula) =>{
    return showWelcomeModalEula || showWelcomeModalBanner;
  });
/**
 * Meet root selector
 */
export const getMeetState = (state: State) => state.meet;

/**
 * Meet Child reducers
 */
export const getSearchTerm = createSelector(getMeetState, fromMeet.getSearchTerm);

export const getSearchBy = createSelector(getMeetState, fromMeet.getSearchBy);

export const getDisplayErrorText = createSelector(getMeetState, fromMeet.getDisplayErrorText);

export const getPageLimit = createSelector(getMeetState, fromMeet.getPageLimit);

export const getPage = createSelector(getMeetState, fromMeet.getPage);

export const getSort = createSelector(getMeetState, fromMeet.getSort);

export const getSortOrder = createSelector(getMeetState, fromMeet.getSortOrder);

export const getMeetColumns = createSelector(getMeetState, fromMeet.getColumns);

export const getMeetColumnsWithFlag = createSelector(getMeetState, fromMeet.getColumnsWithFlag);

export const getAllFilters = createSelector(getMeetState, fromMeet.getAllFilters);

export const getFiltersLoading = createSelector(getMeetState, fromMeet.getFiltersLoading);

export const getFiltersWithoutSelectedSubOption = createSelector(getMeetState, fromMeet.getFiltersWithoutSelectedSubOption);

export const getFiltersWithoutSelectedToggle = createSelector(getMeetState, fromMeet.getFiltersWithoutSelectedToggle);

export const getMeetFiltersStartDate = createSelector(getMeetState, fromMeet.getMeetFiltersStartDate);

export const getEngageFiltersStartDate = createSelector(getMeetState, fromMeet.getEngageFiltersStartDate);

export const getFiltersStartDateByPage = (page) => {
  return createSelector(getMeetFiltersStartDate, getEngageFiltersStartDate, (meetStartDate, engageStartDate) => {
    if (page === AppConstants.meetPage) {
      return meetStartDate;
    } else if (page === AppConstants.engagePage) {
      return engageStartDate;
    }
  });
};

export const getMeetFiltersEndDate = createSelector(getMeetState, fromMeet.getMeetFiltersEndDate);

export const getEngageFiltersEndDate = createSelector(getMeetState, fromMeet.getEngageFiltersEndDate);

export const getFiltersEndDateByPage = (page) => {
  return createSelector(getMeetFiltersEndDate, getEngageFiltersEndDate, (meetEndDate, engageEndDate) => {
    if (page === AppConstants.meetPage) {
      return meetEndDate;
    } else if (page === AppConstants.engagePage) {
      return engageEndDate;
    }
  });
};

export const getMeetFiltersOnBetweenId = createSelector(getMeetState, fromMeet.getMeetFiltersOnBetweenId);

export const getEngageFiltersOnBetweenId = createSelector(getMeetState, fromMeet.getEngageFiltersOnBetweenId);

export const getFiltersOnBetweenIdByPage = (page) => {
  return createSelector(getMeetFiltersOnBetweenId, getEngageFiltersOnBetweenId, (meetOnBetweenId, engageOnBetweenId) => {
    if (page === AppConstants.meetPage) {
      return meetOnBetweenId;
    } else if (page === AppConstants.engagePage) {
      return engageOnBetweenId;
    }
  });
};

export const getFiltersError = createSelector(getMeetState, fromMeet.getFiltersError);

export const getMeetFilters = createSelector(getMeetState, fromMeet.getMeetDisplayFilters);

export const getEngageFilters = createSelector(getMeetState, fromMeet.getEngageDisplayFilters);

export const areFiltersLoaded = createSelector(getAllFilters, (filters) => filters.length > 0);

export const getFiltersByPage = (page) => {
  return createSelector(getMeetFilters, getEngageFilters, (meetFilters, engageFilters) => {
    if (page === AppConstants.meetPage) {
      return meetFilters;
    } else if (page === AppConstants.engagePage) {
      return engageFilters;
    }
  });
};

export const getIfFiltersDateIsNull = (page) => {
  return createSelector(getFiltersStartDateByPage(page), getFiltersEndDateByPage(page), (startDate, endDate) => !startDate || !endDate)
};

export const getMeetSelectedFilters = createSelector(getMeetState, fromMeet.getMeetSelectedFilters);

export const getEngageSelectedFilters = createSelector(getMeetState, fromMeet.getEngageSelectedFilters);

export const getSelectedFiltersByPage = (page) => {
  return createSelector(getMeetSelectedFilters, getEngageSelectedFilters, (meetSelectedFilters, engageSelectedFilters) => {
    if (page === AppConstants.meetPage) {
      return meetSelectedFilters;
    } else if (page === AppConstants.engagePage) {
      return engageSelectedFilters;
    }
  });
};

export const getSelectedFiltersIdsByPage = (page) => {
  return createSelector(getSelectedFiltersByPage(page), (selectedFilters) => {
    return R.pluck('id', selectedFilters);
  });
};

export const getAllSavedGroups = createSelector(getMeetState, fromMeet.getAllSavedGroups);

export const getMeetSavedGroups = createSelector(getMeetState, fromMeet.getMeetDisplaySavedGroups);

export const getEngageSavedGroups = createSelector(getMeetState, fromMeet.getEngageDisplaySavedGroups);

export const getSavedGroupsByPage = (page) => {
  return createSelector(getMeetSavedGroups, getEngageSavedGroups, (meetSavedGroups, engageSavedGroups) => {
    if (page === AppConstants.meetPage) {
      return meetSavedGroups;
    } else if (page === AppConstants.engagePage) {
      return engageSavedGroups;
    }
  });
};

export const getMeetSelectedSavedGroups = createSelector(getMeetState, fromMeet.getMeetSelectedSavedGroups);

export const getEngageSelectedSavedGroups = createSelector(getMeetState, fromMeet.getEngageSelectedSavedGroups);

export const getMeetSavedGroupBeingEdited = createSelector(getMeetState, fromMeet.getMeetSavedGroupBeingEdited);

export const getSelectedSavedGroupsByPage = (page) => {
  return createSelector(getMeetSelectedSavedGroups, getEngageSelectedSavedGroups,
    (meetSelectedSavedGroups, engageSelectedSavedGroups) => {
      let formattedSavedGroups = [];
      if (page === AppConstants.meetPage) {
        formattedSavedGroups = meetSelectedSavedGroups;
      } else if (page === AppConstants.engagePage) {
        formattedSavedGroups = engageSelectedSavedGroups;
      }

      if (formattedSavedGroups.length > 0) {
        formattedSavedGroups = formattedSavedGroups.map(savedGroup => {
          let formattedName = savedGroup.name ? 'Are Part of ' + savedGroup.name : 'N/A';
          return { ...savedGroup, formattedName };
        });
      }

      return formattedSavedGroups;
    });
};

export const getSavedGroupsLoading = createSelector(getMeetState, fromMeet.getSavedGroupsLoading);

export const getSavedGroupsError = createSelector(getMeetState, fromMeet.getSavedGroupsError);

export const getModifySavedGroupsLoading = createSelector(getMeetState, fromMeet.getModifySavedGroupsLoading);

export const getDisplaySavedGroups = (page) => {
  return createSelector(getSavedGroupsByPage(page), getSelectedSavedGroupsByPage(page),
    (allSavedGroups, selectedSavedGroups) => {
      let displaySavedGroups = allSavedGroups;
      displaySavedGroups.forEach(displaySavedGroup => {
        const selectedGroupIndex = R.findIndex(R.propEq('id', displaySavedGroup.id))(selectedSavedGroups);
        displaySavedGroup.isSelected = selectedGroupIndex > -1;
      });
      return displaySavedGroups;
    });
};

export const isSavedGroupsCategoryOpen = createSelector(getMeetFilters, (allFilters) => {
  let savedGroupCategory = R.find(R.propEq('id', MeetConstants.savedGroupsFilterId))(allFilters);
  return savedGroupCategory && savedGroupCategory.open;
});

export const getMeetIncludedCustomers = createSelector(getMeetState, fromMeet.getMeetIncludedCustomers);

export const getEngageIncludedCustomers = createSelector(getMeetState, fromMeet.getEngageIncludedCustomers);

export const getMeetExcludedCustomers = createSelector(getMeetState, fromMeet.getMeetExcludedCustomers);

export const getEngageExcludedCustomers = createSelector(getMeetState, fromMeet.getEngageExcludedCustomers);

export const getEngageExcludedCustomersIds = createSelector(getEngageExcludedCustomers,
  (excludedCustomers) => R.pluck('id', excludedCustomers));

export const getSelectedSavedGroupId = createSelector(getMeetState, fromMeet.getSelectedSavedGroupId);

export const getSelectedSavedGroupCustomersLoading = createSelector(getMeetState, fromMeet.getSelectedSavedGroupCustomersLoading);

export const getSelectedSavedGroupCustomersError = createSelector(getMeetState, fromMeet.getSelectedSavedGroupCustomersError);

export const getSelectedSavedGroupCustomers = createSelector(getMeetState, fromMeet.getSelectedSavedGroupCustomers);

export const getIsSelectedSavedGroupLarge = createSelector(getMeetState, fromMeet.isSelectedSavedGroupLarge);

export const areMeetFiltersSelected = createSelector(
  getMeetSelectedFilters, getMeetSelectedSavedGroups, getMeetIncludedCustomers, getMeetExcludedCustomers,
  (selectedFilters, selectedSavedGroups, includedCustomers, excludedCustomers) => {
    return (selectedFilters.length + selectedSavedGroups.length + excludedCustomers.length + includedCustomers.length) > 0;
  });

export const areEngageFiltersSelected = createSelector(
  getEngageSelectedFilters, getEngageSelectedSavedGroups, getEngageIncludedCustomers, getEngageExcludedCustomers,
  (selectedFilters, selectedSavedGroups, includedCustomers, excludedCustomers) => {
    return (selectedFilters.length + selectedSavedGroups.length + excludedCustomers.length + includedCustomers.length) > 0;
  });

export const getSelectedCustomerCountByPage = (page) => {
  return createSelector(getTotalCountOfCustomers, getEngageFilterDisplayCustomerCount, (meetTotalCustomers, engageTotalCustomers) => {
    if (page === AppConstants.meetPage) {
      return meetTotalCustomers;
    } else if (page === AppConstants.engagePage) {
      return engageTotalCustomers;
    }
  });
};

export const getIndividualSelectedFilterIdsByPage = (page) => {
  return createSelector(getIncludedCustomersByPage(page), getExcludedCustomersByPage(page),
    (includedCustomers, excludedCustomers) => {
      let selectedFilterIds = [];
      if (!R.isEmpty(includedCustomers)) {
        selectedFilterIds.push(MeetConstants.includeFilterId);
      }
      if (!R.isEmpty(excludedCustomers)) {
        selectedFilterIds.push(MeetConstants.excludeFilterId);
      }
      return selectedFilterIds;
    });
};

export const getIncludedCustomersByPage = (page) => {
  return createSelector(getMeetIncludedCustomers, getEngageIncludedCustomers,
    (meetIncludedCustomers, engageIncludedCustomers) => {
      if (page === AppConstants.meetPage) {
        return meetIncludedCustomers;
      } else if (page === AppConstants.engagePage) {
        return engageIncludedCustomers;
      }
    });
};

export const getExcludedCustomersByPage = (page) => {
  return createSelector(getMeetExcludedCustomers, getEngageExcludedCustomers,
    (meetExcludedCustomers, engageExcludedCustomers) => {
      if (page === AppConstants.meetPage) {
        return meetExcludedCustomers;
      } else if (page === AppConstants.engagePage) {
        return engageExcludedCustomers;
      }
    });
};

export const getDisplayFilters = (page) => {
  return createSelector(
    getFiltersByPage(page), getSelectedFiltersIdsByPage(page),
    getIndividualSelectedFilterIdsByPage(page), getSelectedSavedGroupsByPage(page),
    getFiltersOnBetweenIdByPage(page),
    (allFilterCategories, selectedFilterIds, individualSelectedFilterIds, selectedGroups, onBetweenFilterIds) => {
      let displayFilters = R.clone(allFilterCategories);
      allFilterCategories.forEach((category, categoryIndex) => {
        category.subFilters.forEach((filter, filterIndex) => {
          if (R.contains(filter.id, selectedFilterIds)) {
            displayFilters[categoryIndex].subFilters[filterIndex].isSelected = true;
            displayFilters[categoryIndex].hasAFilterSelected = true;
          }

          if (R.contains(filter.id, individualSelectedFilterIds)) {
            displayFilters[categoryIndex].hasAFilterSelected = true;
          }

          if (filter.id === SharedConstants.filtersCustomDateSubFilterId && filter.isSelected) {
            displayFilters[categoryIndex].hasAFilterSelected = true;
          }
        });

        if (category.savedGroupsCategory && selectedGroups.length > 0) {
          displayFilters[categoryIndex].hasAFilterSelected = true;
        }

        if (category.id === SharedConstants.filtersDateRangeId && onBetweenFilterIds !== SharedConstants.onBetweenDefaultId) {
          displayFilters[categoryIndex].hasAFilterSelected = true;
        }
      });
      return displayFilters;
    });
};

export const getTotalFilterAppliedCount = (page) => {
  return createSelector(getSelectedFiltersByPage(page), getIncludedCustomersByPage(page),
    getExcludedCustomersByPage(page), getSelectedSavedGroupsByPage(page),
    (selectedFilters, includedCustomers, excludedCustomers, selectedSavedGroups) => {
      let appliedFilters = 0;
      if (selectedFilters && selectedFilters.length > 0) {
        appliedFilters = selectedFilters.length;
      }
      if (includedCustomers && includedCustomers.length > 0) {
        appliedFilters = appliedFilters + 1;
      }
      if (excludedCustomers && excludedCustomers.length > 0) {
        appliedFilters = appliedFilters + 1;
      }
      if (selectedSavedGroups && selectedSavedGroups.length > 0) {
        appliedFilters = appliedFilters + selectedSavedGroups.length;
      }
      return appliedFilters;
    }
  )
};

export const getAllEngageSelectedFilterTypes = createSelector(
  getDisplayFilters(AppConstants.engagePage), getEngageSelectedFilters, getEngageIncludedCustomers, getEngageExcludedCustomers,
  getEngageSelectedSavedGroups, getEngageFiltersOnBetweenId, getEngageFiltersStartDate, getEngageFiltersEndDate,
  (allFilters, selectedFilters, includedCustomers, excludedCustomers, selectedSavedGroups, onBetweenId, startDate, endDate) => {
    let formattedSelectedFilters = getFormattedSelectedFilters(allFilters, selectedFilters);

    return {
      selectedFilters: formattedSelectedFilters,
      includedCustomers,
      excludedCustomers,
      selectedSavedGroups,
      onBetweenId,
      startDate,
      endDate
    };
  }
)

export const isMobileWithFiltersCount = (page) => {
  return createSelector(getTotalFilterAppliedCount(page), getIsMobile, (filtersCount, isMobile) => {
    return (filtersCount < 1) && isMobile;
  })
};

export const getView = createSelector(getMeetState, fromMeet.getView);

export const getFeaturedMeetColumns = createSelector(getMeetColumns, getMeetColumnsWithFlag, getView,
  getFeatureEnabled(AppConstants.signatureMembership), getFeatureEnabled(AppConstants.digitalRefundsFlag),
  (meetTableColumns, meetTableColumnsWithFlag, meetView, signatureMembershipEnabled, digitalRefundsEnabled) => {
    let columns = meetView === MeetConstants.allCustomerView && digitalRefundsEnabled ? meetTableColumnsWithFlag : meetTableColumns;
    let membershipColumn = R.find(R.propEq('prop', 'membership'))(columns);
    membershipColumn.sort = signatureMembershipEnabled;
    return columns;
  }
);

export const getSortObj = createSelector(getSort, getSortOrder, (prop, order) => ({ prop, order }));

/**
 * Autocomplete state children selectors
 */
export const getAutocompleteCustomers = createSelector(
  getMeetState, fromMeet.getCustomers);

export const getAutocompleteError = createSelector(
  getMeetState, fromMeet.getError);

export const getAutocompleteLoading = createSelector(
  getMeetState, fromMeet.getLoading);

export const getFilterAutocompleteCustomersByPage = (page) => {
  return createSelector(getAutocompleteCustomers, getIncludedCustomersByPage(page), getExcludedCustomersByPage(page),
    (customers, includeFilter, excludeFilter) => {
      let alreadyAddedIds = [];
      if (!R.isEmpty(excludeFilter)) {
        alreadyAddedIds = R.pluck('id', excludeFilter);
      }
      if (!R.isEmpty(includeFilter)) {
        alreadyAddedIds = alreadyAddedIds.concat(R.flatten(R.pluck('id', includeFilter)));
      }
      let alreadyAdded = (cust) => !R.contains(cust.id, alreadyAddedIds);

      return R.filter(alreadyAdded, customers);
    });
};

/**
 * Customers state root selector
 */
export const getCustomerState = (state: State) => state.customer;

/**
 * Customer profile state root selector
 */
export const getProfileState = (state: State) => state.profile;

export const getProfileById = (id) => {
  return createSelector(getProfileState, (profiles) => {
    return profiles[id];
  });
};

export const getCemLoadingById = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile.cemData.loading;
    }
  });
};

export const getCemErrorById = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile.cemData.error;
    }
  });
};

export const getCemDataById = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile.cemData.data.comments;
    }
  });
};

export const getCaresLoadingById = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile.caresData.loading;
    }
  });
};

export const getCaresErrorById = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile.caresData.error;
    }
  });
};

export const getCaresDataById = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile.caresData.data;
    }
  });
};

export const getActionHistoryLoadingById = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile.actionHistory.loading;
    }
  });
};

export const getActionHistoryErrorById = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile.actionHistory.error;
    }
  });
};

export const getActionHistoryDataById = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return R.isEmpty(profile.actionHistory.data) ? [] : profile.actionHistory.data;
    }
  });
};

export const getDigitalRefundsLoadingById = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile && profile.digitalRefundsData) {
      return profile.digitalRefundsData.loading;
    }
  });
};

export const getDigitalRefundsErrorById = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile && profile.digitalRefundsData) {
      return profile.digitalRefundsData.error;
    }
  });
};

export const getDigitalRefundsDataById = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile && profile.digitalRefundsData) {
      return R.isEmpty(profile.digitalRefundsData.data) ? [] : profile.digitalRefundsData.data;
    }
  });
};

export const getRecoverEmailLoadingById = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile.recoverEmail.loading;
    }
  });
};

export const getRecoverEmailErrorById = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile.recoverEmail.error;
    }
  });
};

export const getRecoverEmailDataById = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile.recoverEmail.data;
    }
  });
};

export const getCemByCommentId = (customerId, cemUid) => {
  return createSelector(getProfileById(customerId), (profile) => {
    if (profile && profile.cemData && profile.cemData.data.comments) {
      let cemData = profile.cemData.data;
      let baseComment = R.find(R.propEq('cemUid', cemUid))(cemData.comments);
      return {
        ...baseComment,
        address: cemData.address,
        email: cemData.email,
        name: cemData.name
      };
    }
  });
};

export const getCustomerInfoLoadingById = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile.customerInformation.loading;
    }
  });
};

export const getCustomerInfoErrorById = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile.customerInformation.error;
    }
  });
};

export const getCustomerInfoById = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile.customerInformation.data;
    }
  });
};

export const getCustomerName = (id) => {
  return createSelector(getCustomerInfoById(id), (info) => {
    return (info && !R.isEmpty(info) && info.displayName) ? info.displayName : '';
  });
};

export const getActionMenuLoading = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    return profile ? profile.actionMenuLoading : false;
  });
};

export const getIdMeStatus = (id) => {
  return createSelector(getCustomerInfoById(id), (info) => {
    return info && !R.isEmpty(info) ? info.idMeStatus : null;
  });
};

export const getCustomerNotes = (id) => {
  return createSelector(getCustomerInfoById(id), (info) => {
    return (info && !R.isEmpty(info) && info.notes) ? info.notes : [];
  });
};

export const getDisplayNotes = (id) => {
  return createSelector(getCustomerInfoById(id), (info) => {
    return (info && !R.isEmpty(info) && info.displayNotes) ? info.displayNotes : [];
  });
};

export const getFavoritedLocationFlag = (id) => {
  return createSelector(getCustomerInfoById(id), (info) => {
    return (info && !R.isEmpty(info)) ? info.isFavoriteLocation : false;
  });
};

export const getCustomerCateringObject = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile[ProfileConstants.cateringData];
    }
  });
};

export const getCustomerTransactionStatObject = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile[ProfileConstants.transactionStats];
    }
  });
};

export const getCustomerTransactionHistoryObject = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile[ProfileConstants.transactionHistory];
    }
  });
};

export const getCustomerOffersObject = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile[ProfileConstants.offers];
    }
  });
};

export const getMembershipDetailsObject = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile[ProfileConstants.membershipDetails];
    }
  });
};

export const getCustomerCemObject = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile[ProfileConstants.cemData];
    }
  });
};

export const getDoorDashRecoveryLoadingById = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile.doorDashRecoveryData.loading;
    }
  });
};

export const getDoorDashRecoveryErrorById = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile.doorDashRecoveryData.error;
    }
  });
};

export const getDoorDashRecoveryDataById = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile && profile.transactionHistory && profile.doorDashRecoveryData && profile.doorDashRecoveryData.data
        && profile.transactionHistory.data && profile.transactionHistory.data.transactionHistory && !profile.transactionHistory.error) {
      return Array.from(profile.doorDashRecoveryData.data).map((doorDashRecoverData: any) => {
        let transID = ProfileConstants.idAttributeForTransactionDetails;
        if (doorDashRecoverData[transID]) {
          doorDashRecoverData.isSelectable = true;
          let rowDataIndex =
            R.findIndex(R.propEq(transID, doorDashRecoverData[transID]))(profile.transactionHistory.data.transactionHistory);
          if (rowDataIndex === -1) {
            doorDashRecoverData.isSelectable = false;
          }
        }
        return doorDashRecoverData;
      });
    } else if (profile && profile.transactionHistory && profile.doorDashRecoveryData && profile.doorDashRecoveryData.data
        && profile.transactionHistory.error) {
      return Array.from(profile.doorDashRecoveryData.data).map((doorDashRecoverData: any) => {
        doorDashRecoverData.isSelectable = false;
        return doorDashRecoverData;
      });
    }
  });
};

export const getSortedTransactionDetailsData = (id) => {
  return createSelector(getProfileById(id), (profile) => {
    if (profile) {
      return profile[ProfileConstants.transactionHistory].data.sortedTransactionDetailsData;
    }
  });
};

/**
 * Customers state children selectors
 */
export const getCustomers = createSelector(getCustomerState, fromCustomer.getCustomers);

export const getSelectedCustomers = createSelector(getCustomerState, fromCustomer.getSelectedCustomers);

export const getCustomersError = createSelector(getCustomerState, fromCustomer.getCustomersError);

export const getCustomerActionError = createSelector(getCustomerState, fromCustomer.getCustomerActionError);

export const getNumberOfPages = createSelector(getCustomerState, fromCustomer.getNumberOfPages);

export const getTotalCountOfCustomers = createSelector(getCustomerState, fromCustomer.getTotalCountOfCustomers);

export const getAllCustomerIds = createSelector(getCustomerState, fromCustomer.getAllCustomerIds);

export const getFullCustomerIdList = createSelector(getCustomerState, fromCustomer.getFullCustomerIdList);

export const getLoading = createSelector(getCustomerState, fromCustomer.getLoading);

export const getFormattedFiltersWithDateAndIndividuals = (page) => {
  return createSelector(
    getSelectedFiltersByPage(page),
    getDisplayFilters(page),
    getIncludedCustomersByPage(page),
    getExcludedCustomersByPage(page),
    getFiltersStartDateByPage(page),
    getFiltersEndDateByPage(page),
    getFiltersOnBetweenIdByPage(page),
    getMeetSavedGroupBeingEdited,
    (
      selectedFilters,
      allFilters,
      includedCustomers,
      excludedCustomers,
      startDate,
      endDate,
      onBetweenId,
      meetSavedGroupBeingEdited
    ) => {
      return getAllFormattedSelectedFilters(
        allFilters, selectedFilters, includedCustomers, excludedCustomers, onBetweenId, startDate, endDate, meetSavedGroupBeingEdited
      );
    });
};

export const getCustomerAPIRequestByPage = (page) => {
  return createSelector(
    getMeetState,
    getEngageState,
    getFormattedFiltersWithDateAndIndividuals(page),
    getSelectedSavedGroupsByPage(page),
    (
      meetState: any,
      engageState: any,
      selectedFilters,
      selectedSavedGroups,
    ) => {
      let state = page === AppConstants.meetPage ? meetState : engageState;
      let searchBy = page === AppConstants.meetPage ? state.searchBy : '';
      let searchTerm = page === AppConstants.meetPage ? state.searchTerm : '';
      let limit = page === AppConstants.meetPage ? state.limit : state.filterDisplayCustomerLimit;
      let selectedPage = page === AppConstants.meetPage ? state.page : state.filterDisplayCustomerPage;
      let sort = page === AppConstants.meetPage ? state.sort : state.filterDisplayCustomerSort;
      let sortOrder = page === AppConstants.meetPage ? state.sortOrder : state.filterDisplayCustomerSortOrder;
      let isCatering = page === AppConstants.meetPage ? state.isCatering : false;
      let isFeedback = page === AppConstants.meetPage ? state.isFeedback : false;
      let inactiveFlag = page === AppConstants.meetPage ? state.inactiveFlag : Inactives.EXCLUDE_INACTIVES;

      let customGroupIds = R.flatten(R.pluck('id', selectedSavedGroups));
      let selectedFilterTemp = [...selectedFilters];

      selectedFilters.forEach((filter) => {
        // Remove "Placed an Individual Order" filter from the /customers call if there is no selected button toggle
        if (filter.selectedButtonToggle === undefined && filter.id === SharedConstants.filterPlaceIndividualOrder) {
          let index = R.findIndex(R.propEq('id', filter.id))(selectedFilterTemp);
          selectedFilterTemp.splice(index, 1);
        }
        // Remove "Specify Membership" filter from the /customers call if there is no selected sub option
        if (filter.id === SharedConstants.filterSpecifyMembership) {
          let subOptionSelected = false;
          filter.subOptions.forEach((subOption) => {
            if (subOption.selected === true) {
              subOptionSelected = true;
            }
          });
          if (!subOptionSelected) {
            let index = R.findIndex(R.propEq('id', filter.id))(selectedFilterTemp);
            selectedFilterTemp.splice(index, 1);
          }
        }
      });

      selectedFilters = [...selectedFilterTemp];

      return {
        selectedFilters,
        customGroupIds,
        searchBy,
        searchTerm,
        limit,
        page: selectedPage,
        sort,
        sortOrder,
        isCatering,
        isFeedback,
        inactiveFlag
      };
    });
};

export const getCustomerAPIRequestForAutocompleteByPage = (page) => {
  return createSelector(
    getMeetState,
    getEngageState,
    getFormattedFiltersWithDateAndIndividuals(page),
    getSelectedSavedGroupsByPage(page),
    (
      meetState: any,
      engageState: any,
    ) => {
      let state = page === AppConstants.meetPage ? meetState : engageState;
      let searchBy = MeetConstants.customerNameSearchBy;
      let searchTerm = page === AppConstants.meetPage ? state.searchTerm : '';
      let limit = page === AppConstants.meetPage ? state.limit : state.filterDisplayCustomerLimit;
      let selectedPage = page === AppConstants.meetPage ? state.page : state.filterDisplayCustomerPage;
      let sort = page === AppConstants.meetPage ? state.sort : state.filterDisplayCustomerSort;
      let sortOrder = page === AppConstants.meetPage ? state.sortOrder : state.filterDisplayCustomerSortOrder;

      // state.autocompleteLoading signifies the use of the autocomplete search bar
      limit = state.autocompleteLoading ? 10 : limit;
      searchTerm = state.autocompleteLoading ? state.autocomplete : searchTerm;

      return {
        searchBy,
        searchTerm,
        limit,
        page: selectedPage,
        sort,
        sortOrder
      };
    });
};

export const getCreateGroupRequest = createSelector(
  getFormattedFiltersWithDateAndIndividuals(AppConstants.meetPage), getSelectedSavedGroupsByPage(AppConstants.meetPage),
  (selectedFilters, savedGroups) => {
    let selectedFilterTemp = [...selectedFilters];
    selectedFilters.forEach((filter) => {
    // Remove "Placed an Individual Order" filter from the call to create a saved filter if there is no selected button toggle
      if (filter.selectedButtonToggle === undefined && filter.id === SharedConstants.filterPlaceIndividualOrder) {
        let index = R.findIndex(R.propEq('id', filter.id))(selectedFilterTemp);
        selectedFilterTemp.splice(index, 1);
      }});
    selectedFilters = [...selectedFilterTemp];
    return { selectedFilters, savedGroups };
  });

/**
 * Discover/Engagement state root selector
 * Have together since they are dependent on each other
 */
export const getDiscoverState = (state) => state.discover;

export const getEngagementState = (state) => state.engagement;

/**
 * Discover/Engagement state children selectors
 */
export const getDiscoverTiles = createSelector(getDiscoverState, fromDiscover.getDiscoverTiles);

// Only returns campaign tiles so that they can be displayed as a half card column
export const getCampaignDiscoverTiles = createSelector(getDiscoverTiles, (tiles) => {
  return R.filter((tile) => tile.category === 'campaign', tiles);
});

// Only returns promotion tiles so that they can be displayed as a half card column
export const getPromotionDiscoverTiles = createSelector(getDiscoverTiles, getFeatureEnabled(CalendarConstants.bonusPointPromotionsFlag),
  (tiles, promotionFeatureFlag) => {
    if (!promotionFeatureFlag) {
      return R.filter((tile) => tile.id !== DiscoverConstants.bonusPoint && tile.category === DiscoverConstants.promotion, tiles);
    } else {
      return R.filter((tile) => tile.category === DiscoverConstants.promotion && !tile.automated, tiles);
    }
  });

// Returns an array of 2 item arrays of the automated tiles. This is done so that
// the discover page can display them in a column format since Flexbox is a one dimensional
// layout technology and doesn't allow the mixture of columns and rows needed to display the
// stacked column automated half tiles in the current row layout
export const getAutomatedDiscoverTiles = createSelector(getDiscoverTiles, (tiles) => {
  let automatedTiles = R.filter((tile) => tile.automated && !(tile.stayInPlaceAfterAutomating), tiles);
  let sortedAutomatedTiles = R.sortBy(R.prop('order'), automatedTiles);

  let neededArrays = Math.ceil(sortedAutomatedTiles.length / 2);
  let automatedTileArrays = [];
  for (let i = 0; i < neededArrays; i++) {
    let subArray = sortedAutomatedTiles.slice(i * 2, i * 2 + 2);
    automatedTileArrays.push(subArray);
  }

  return automatedTileArrays;
});

export const getCampaignPromotionTiles = createSelector(
  getPromotionDiscoverTiles, getDiscoverTiles,
  getFeatureEnabled(DiscoverConstants.communityCareEventsFlag),
  getThirdPartyEnabled(), getFeatureEnabled(SharedConstants.MultipleCommunityCareEvents),
  getFeatureEnabled(DiscoverConstants.freeItemInCartPromotionFlag),
  (promotionTiles, discoverTiles,
    communityCareEventsFlag,thirdPartyPromotionFlag,
    multipleCommunityCareEventsEnabled, freeItemInCartEnabled) => {
    let sortedTiles = [];
    let discoverTilesArr = R.filter((tile) =>
      ((tile.category === DiscoverConstants.categoryCampaign ||
          (tile.category === DiscoverConstants.thirdPartyPromotion && thirdPartyPromotionFlag) ||
          (tile.category === DiscoverConstants.categoryCommunityCare && communityCareEventsFlag) ||
          (tile.category === DiscoverConstants.freeItemInCartPromotion && freeItemInCartEnabled))
          && !tile.automated) ||
      (tile.category === DiscoverConstants.categoryCampaign && tile.stayInPlaceAfterAutomating && tile.automated) ||
      (tile.stayInPlaceAfterAutomating && tile.automated), discoverTiles);
    discoverTiles.forEach(tile => {
      if (tile.category === DiscoverConstants.categoryCommunityCare) {
        tile.multipleSendsEnabled = multipleCommunityCareEventsEnabled;
      }
    });
    sortedTiles = R.sortBy(R.prop('order'), discoverTilesArr);
    // add promotion tile at first level in array
    if (promotionTiles.length > 0) {
      promotionTiles.forEach(tile => {
        sortedTiles.unshift(tile);
      });
    }
    let neededArrays = Math.ceil(sortedTiles.length / 2);
    let promotionAndCampaignTileArrays = [];
    for (let i = 0; i < neededArrays; i++) {
      let subArray = sortedTiles.slice(i * 2, i * 2 + 2);
      promotionAndCampaignTileArrays.push(subArray);
    }
    return promotionAndCampaignTileArrays;
  });

// Only returns non-automated and non-campaign tiles
export const getGeneralDiscoverTiles = createSelector(getDiscoverTiles, (tiles) => {
  const isGeneralTile = tile => (
    (tile.category !== 'campaign' && tile.category !== 'banner' && tile.category !== 'error' &&
     tile.category !== 'promotion' && tile.category !== DiscoverConstants.categoryCommunityCare
        && tile.category !== DiscoverConstants.thirdPartyPromotion && tile.category != DiscoverConstants.freeItemInCartPromotion)
    && !tile.automated
  );
  return R.filter(isGeneralTile, tiles);
});

export const getBannerTile = createSelector(getDiscoverTiles, (tiles) => {
  let bannerTiles = R.filter((tile) => tile.type === 'text' && tile.category === 'banner', tiles);
  return bannerTiles[0];
});

export const getErrorTile = createSelector(getDiscoverTiles, (tiles) => {
  let errorTile = R.filter((tile) => tile.type === 'text' && tile.category === 'error' , tiles);
  return errorTile[0];
});

export const getDiscoverLoading = createSelector(getDiscoverState, fromDiscover.getDiscoverLoading);

export const getDiscoverError = createSelector(getDiscoverState, fromDiscover.getDiscoverError);

export const getEngagementEmail = createSelector(getEngagementState, fromEngagement.getEngagementEmail);

export const getCurrentEngagementId = createSelector(getEngagementState, fromEngagement.getEngagementFlowId);

export const getDiscoverTileById = createSelector(
  getDiscoverState,
  getCurrentEngagementId,
  (discover, id) => discover.tiles[id] || {});

export const getEngagementLoading = createSelector(getEngagementState,
  fromEngagement.getEngagementLoading);

export const getEngagementCount = createSelector(getEngagementState,
  fromEngagement.getEngagementCount);

export const getEngagementRewards = createSelector(getEngagementState,
  fromEngagement.getEngagementRewards);

export const getCategorizedEngagementRewards = createSelector(
  getEngagementRewards,
  (rewards) => {
    let groupedRewards = R.groupBy(R.prop('treatCategory'), rewards);
    let categories = R.keys(groupedRewards);
    return categories.map((category) => {
      return { category, rewards: groupedRewards[category] };
    });
  });

export const getEngagementMenuItems = createSelector(getEngagementState,
  fromEngagement.getEngagementMenuItems);

export const getIsEngagementRewardsAvailable = createSelector(
  getCategorizedEngagementRewards,
  (rewardCategories) => {
    return rewardCategories && rewardCategories[0] && (rewardCategories[0].rewards.length > 1 || rewardCategories.length > 1);
  });

export const getEngagementType = createSelector(getEngagementState,
  fromEngagement.getEngagementType);

export const getEngagementMode = createSelector(getEngagementState,
  fromEngagement.getEngagementMode);

export const getEngagementIncludeActiveTreats = createSelector(getEngagementState,
  fromEngagement.getIncludeActiveTreats);

export const getEngagementError = createSelector(getEngagementState,
  fromEngagement.getEngagementError);

export const getEngagementCampaignName = createSelector(getEngagementState,
  fromEngagement.getEngagementCampaignName);

export const getEngagementAllDaysBlocked = createSelector(getEngagementState,
  fromEngagement.getEngagementAllDaysBlocked);

export const getEngagementOrganizations = createSelector(getEngagementState, fromEngagement.getEngagementOrganizations);

export const getEngagementOptInOptions = createSelector(getEngagementState, fromEngagement.getEngagementOptInOptions);

export const getEngagementOptInOptionButtons = createSelector(getEngagementOptInOptions,
  (optInOptions) => {
    if (optInOptions) {
      optInOptions.forEach(toggle => toggle.selected = toggle.defaultToggle);
      return optInOptions;
    }
  });

export const getCurrentCommunityCareEvents = createSelector(getEngagementState, fromEngagement.getCurrentCommunityCareEvents);

export const getSortedAndShortenedCommunityCareEvents = createSelector(getCurrentCommunityCareEvents, (events) => {
  events.sort((a, b) => {
    return moment.unix(a.start).isBefore(moment.unix(b.start)) ? -1 : 1;
  });
  return events.slice(0, 5);
});

export const getEngagementPlatforms = createSelector(getEngagementState, fromEngagement.getEngagementPlatforms);

export const getEngagementCustomerTypes = createSelector(getEngagementState, fromEngagement.getEngagementCustomerTypes);

export const getEngagementSelectedOptIn = createSelector(getEngagementState, fromEngagement.getEngagementSelectedOptIn);

export const getEngagementMessage = createSelector(getEngagementState, fromEngagement.getEngagementMessage);

export const getEngagementStrippedMessage = createSelector(getEngagementMessage,
  (copy) => {
    let newCopy = copy ? copy.replace(/<[^>]*>?/gm, '') : '';
    return newCopy.replace(/&nbsp;/gm, ' ');
  });
//
export const getEngagementDefaultMessageForApp = createSelector(getEngagementState,
  fromEngagement.getEngagementDefaultMessageForApp);

export const getEngagementPushMessageTitle = createSelector(getEngagementState,
  fromEngagement.getEngagementPushMessageTitle);

export const getEngagementPushMessageText = createSelector(getEngagementState,
  fromEngagement.getEngagementPushMessageText);

export const getEngagementSubject = createSelector(getEngagementState,
  fromEngagement.getEngagementSubject);

export const getEngagementStartDate = createSelector(getEngagementState,
  fromEngagement.getStartDate);

export const getEngagementEndDate = createSelector(getEngagementState,
  fromEngagement.getEndDate);

export const getEngagementMaxDaysAllowed = createSelector(getEngagementState,
  fromEngagement.getMaxDaysAllowed);

export const getEngagementDisabledDays = createSelector(getEngagementState,
  fromEngagement.getDisabledDays);

export const getEngagementFailedPlatforms = createSelector(
  getEngagementState, fromEngagement.getEngagementFailedPlatforms);

export const getEngagementSuccessfulPlatforms = createSelector(
  getEngagementState, fromEngagement.getEngagementSuccessfulPlatforms);

export const getEngagementThirdPartyPromoCreatedId = createSelector(
  getEngagementState, fromEngagement.getEngagementThirdPartyPromoCreatedId);

export const getEngagementDisabledTimes = createSelector(getEngagementState, fromEngagement.getDisabledTimes);

export const getEngagementCCEModalAlreadyShown = createSelector(getEngagementState, fromEngagement.getCCEModalAlreadyShown);

export const getEngagementEndDateRestriction = createSelector(getEngagementState,
  fromEngagement.getEndDateRestriction);

export const getEngagementSelectedCustomers = createSelector(getEngagementState,
  fromEngagement.getEngagementSelectedCustomers);

export const getEngagementDetailRequest = createSelector(getEngagementState,
  fromEngagement.getEngagementDetailRequest);

export const getEngagementLength = createSelector(getEngagementState,
  fromEngagement.getTreatLength);

export const getEngagementSelectedReward = createSelector(getEngagementState,
  fromEngagement.getSelectedReward);

export const getEngagementDestinationOptions = createSelector(getEngagementState,
  fromEngagement.getDestinationOptions);

export const getEngagementTimeOfDayOptions = createSelector(getEngagementState,
  fromEngagement.getTimeOfDayOptions);

export const getEngageRepeatPromotionOptions = createSelector(getEngagementState,
  fromEngagement.getRepeatThisPromotion);

export const getEngagementSelectedDestinationId = createSelector(getEngagementState,
  fromEngagement.getSelectedDestinationId);

export const getEngagementSelectedDestinationIdsForMultiSelect = createSelector(getEngagementState,
  fromEngagement.getSelectedDestinationIdsForMultiSelect);

export const getEngagementSelectedTimeOfDay = createSelector(getEngagementState,
  fromEngagement.getSelectedTimeOfDay);

export const getEngagementSendError = createSelector(getEngagementState,
  fromEngagement.getSendError);

export const getEngagementSendLoading = createSelector(getEngagementState,
  fromEngagement.getSendLoading);

export const getEngagementAutomationAction = createSelector(getEngagementState,
  fromEngagement.getAutomationAction);
export const getEngagementSelectedRepeatPromotion = createSelector(getEngagementState,
  fromEngagement.getSelectedRepeatPromotion);

export const getEngageWeeklyButtonsRepeatPromotion = createSelector(getEngageRepeatPromotionOptions,
  (repeatPromotions) => {
    if (repeatPromotions) {
      let subOptions = [];
      repeatPromotions.forEach((repeatPromotion) => {
        if (R.contains(repeatPromotion.id, SharedConstants.weeklyRepeatPromotion)) {
          subOptions = repeatPromotion.subOptions;
        }
      });
      subOptions.forEach(toggle => toggle.selected = toggle.defaultToggle);
      return subOptions;
    }
  });

export const getEngageRepeatPromotionButtons = createSelector(getEngageRepeatPromotionOptions,
  (repeatPromotions) => {
    if (repeatPromotions) {
      repeatPromotions.forEach(toggle => toggle.selected = toggle.defaultToggle);
      return repeatPromotions;
    }
  });

export const getEngagementStartTime = createSelector(getEngagementState,
  fromEngagement.getStartTime);

export const getEngagementEndTime = createSelector(getEngagementState,
  fromEngagement.getEndTime);

export const getEngagementStartTimeRestriction = createSelector(getEngagementState,
  fromEngagement.getStartTimeRestriction);

export const getEngagementStartTimeEndRestriction = createSelector(getEngagementState,
  fromEngagement.getStartTimeEndRestriction);

export const getEngagementEndTimeRestriction = createSelector(getEngagementState,
  fromEngagement.getEndTimeRestriction);

export const getEngagementEndTimeEndRestriction = createSelector(getEngagementState,
  fromEngagement.getEndTimeEndRestriction);

export const getEngagementDatesAndTimes = createSelector(getEngagementStartDate, getEngagementEndDate, getEngagementStartTime,
  getEngagementEndTime, getEngagementStartTimeRestriction, getEngagementEndTimeRestriction, getEngagementEndDateRestriction,
  (startDate, endDate, startTime, endTime, startTimeRestriction, endTimeRestriction, endDateRestriction) => {
    return { startDate, endDate, startTime, endTime, startTimeRestriction, endTimeRestriction, endDateRestriction };
  })

export const getEngagementAreDatesWithinSevenDays = createSelector(getEngagementState,
  fromEngagement.getAreDatesWithinSevenDays);

export const getOverlappingEngagementType = createSelector(getEngagementState,
  fromEngagement.getOverlappingEngagementType);

export const getReplacedOnePageFlowHtml = createSelector(getEngagementState, (eng: fromEngagement.State) => {
  let merge;
  const html = eng.previewHtml;

  // Only show countdown for rewards that are longer than 1 day and end more than 1 day away
  // Discover rewards always start on the current day so only treatLength needs to be checked
  // Currently we require all Discover rewards to be at least 7 days, but keeping this logic here in
  // case we change that requirement in the future to allow for 1 day Discover rewards/automation
  const showCountdownTimer = eng.treatLength > 1 ? '' : 'style="display: none;"';

  if ((eng.engagementType === DiscoverConstants.reward || eng.engagementType === DiscoverConstants.automation) && eng.selectedReward) {
    merge = {
      ...eng.selectedReward,
      offerDetails: eng.message,
      showCountdownTimer: showCountdownTimer,
      subTitle: eng.subTitle,
      endDate: eng.endDate
    };
  } else if (eng.engagementType === DiscoverConstants.email && eng.email) {
    merge = {
      ...eng.email,
      description: eng.message
    };
  }

  // Don't process html if it is a promotion or community care with static images
  if ((eng.engagementType !== DiscoverConstants.promotion) &&
    (eng.engagementType !== DiscoverConstants.categoryCommunityCare)
      && (eng.engagementType !== DiscoverConstants.thirdPartyPromotion) &&
    eng.engagementType !== DiscoverConstants.freeItemInCartPromotion) {
    return replaceMGHtml(merge, html);
  } else {
    return '';
  }
});

export const getIsEndDateRestriction = createSelector(getEngagementEndDateRestriction, getEngagementState,
  (engagementEndDate,
    engagementState) => {
    if (engagementEndDate) {
      let endDate = moment().add(engagementState.treatLength, 'd');
      let restrictDate = moment(engagementEndDate);
      const diff = restrictDate.diff(endDate, 'days');
      return diff < 0;
    }
    return false;
  });

export const getEngagementRewardRequest = createSelector(getEngagementState, getActiveRewardsState,
  (engagement, activeRewards) => {
    let treats = engagement.treats.map((treat) => {
      const offerRecipients = getTreatRecipients(activeRewards, treat);
      const hasMessage = R.find(R.propEq('id', 'description'))(engagement.flow);
      return {
        ...treat,
        campaignName: engagement.campaignName,
        customizedMessage: hasMessage ? engagement.message : treat.customizedMessage,
        endDateLiteral: moment(engagement.endDate).format('YYYY-MM-DD'),
        offerDetails: hasMessage ? engagement.message : treat.offerDetails,
        offerRecipients,
        startDateLiteral: moment(engagement.startDate).format('YYYY-MM-DD')
      };
    }).filter((treat) => treat.offerRecipients.length > 0);

    return {
      recommendationType: engagement.flowId,
      treats
    };
  });

export const getOnePageRewardRequest = createSelector(getEngagementState, getEngagementCount,
  (engagement, customerCount) => {
    let treats = [{
      ...engagement.selectedReward,
      campaignName: engagement.campaignName,
      customizedMessage: engagement.message,
      endDateLiteral: engagement.endDate.format('YYYY-MM-DD'),
      headline: engagement.subTitle,
      offerDetails: engagement.message,
      offerRecipients: engagement.offerRecipients.slice(0, customerCount),
      startDateLiteral: engagement.startDate.format('YYYY-MM-DD'),
      subject: engagement.subject,
      themeId: engagement.themeId,
      treatMessageForApp: engagement.defaultMessageForApp
    }];

    return {
      recommendationType: engagement.flowId,
      treats
    };
  });

export const getOnePageEmailRequest = createSelector(getEngagementState, (engagement) => {
  return {
    ...engagement,
    campaignName: engagement.campaignName,
    engagementType: engagement.engagementType,
    flowId: engagement.flowId,
    subject: engagement.subject,
    emailMessage: engagement.message
  };
});

export const getOnePageThirdPartyPromotionRequest = createSelector(
  getEngagementState, (engagement) => {
    let platforms = R.filter(platform => platform.selected, engagement.platforms)
      .map(platform => { return platform.id; })
    let customerType = R.filter(type => type.selected, engagement.availableCustomerTypes).map(type => {
      return type.displayTitle.toUpperCase();
    });

    let startDate = moment().isSame(R.clone(engagement.startDate), 'day') ? moment()
      : R.clone(engagement.startDate).set({ hour:0, minute:0 });
    let endDate = R.clone(engagement.endDate).set({ hour:23, minute:59 })

    return {
      campaignName: engagement.campaignName,
      engagementType: engagement.engagementType,
      flowId: engagement.flowId,
      startDate: startDate.utc().unix()*1000,
      endDate: endDate.utc().unix() * 1000,
      currencyCode: engagement.currencyCode,
      selectedReward: engagement.selectedReward,
      offerTemplateId: engagement.selectedReward.offerTemplateId,
      customerType,
      platforms
    };
  });

export const getOnePageFreeInCartRequest = createSelector(getEngagementState, (engagement) => {
  let customerType = R.filter(type => type.selected, engagement.availableCustomerTypes).map(type => {
    return type.id;
  });

  let startDate = moment().isSame(R.clone(engagement.startDate), 'day')
    ? moment() : R.clone(engagement.startDate).set({ hour: 0, minute: 0 });
  let endDate = R.clone(engagement.endDate).set({ hour: 23, minute: 59 })
  let repeatOption = {
    repeatOptionId: engagement.selectedRepeatId,
    subOptions: [{ id: engagement.repeatPromotionSubId, name: engagement.repeatPromotionSubId }]
  }

  return {
    campaignName: engagement.campaignName,
    customerType,
    destinationIds: engagement.selectedDestinationIdsForMultiSelect,
    endDate: endDate.unix() * 1000,
    endTime: engagement.endTime,
    engagementType: engagement.engagementType,
    flowId: engagement.flowId,
    offerTemplateId: engagement.selectedReward.offerTemplateId,
    repeatOption,
    selectedReward: engagement.selectedReward,
    timeOfDayId: engagement.selectedTimeOfDayId,
    startDate: startDate.unix() * 1000,
    startTime: engagement.startTime
  };
});

export const getOnePagePromotionRequest = createSelector(getEngagementState, (engagement) => {
  let selectedRepeatOption = engagement.repeatOptions.filter(option => option.id === engagement.selectedRepeatId);
  let selectedSubOptions = selectedRepeatOption[0].subOptions;
  let selectedRepeatSubOption = null;

  if (selectedSubOptions && selectedSubOptions.length > 0) {
    selectedRepeatSubOption = selectedSubOptions.filter(subOption => subOption.id === engagement.repeatPromotionSubId);
  }

  let repeatOption = {
    repeatOptionId: engagement.selectedRepeatId,
    subOptions: selectedRepeatSubOption
  }

  let startDate = moment().isSame(R.clone(engagement.startDate), 'day') ? moment() : R.clone(engagement.startDate).set({ hour:0, minute:0 })
  let endDate = R.clone(engagement.endDate).set({ hour:23, minute:59 })

  return {
    campaignName: engagement.campaignName,
    engagementType: engagement.engagementType,
    destinations: engagement.selectedDestinationIdsForMultiSelect,
    timeOfDayId: engagement.selectedTimeOfDayId,
    flowId: engagement.flowId,
    startDate: startDate.unix()*1000,
    endDate: endDate.unix() * 1000,
    startTime: engagement.startTime,
    endTime: engagement.endTime,
    repeatOption
  };
});

export const getOnePageCommunityEventRequest = createSelector(getEngagementState , (engagement) =>{
  let formattedEndDate = engagement.endDate.format('YYYY-MM-DD') + ' ' + moment(engagement.endTime, 'h:mm a').format('HH:mm:ss');
  let formattedStartDate = engagement.startDate.format('YYYY-MM-DD') + ' ' + moment(engagement.startTime, 'h:mm a').format('HH:mm:ss');
  let startDate: moment.Moment = moment(formattedStartDate);
  let endDate = moment(formattedEndDate)

  // Trim whitespace from any organization's name
  if (engagement.organizations) {
    engagement.organizations.forEach((organization, index) => {
      engagement.organizations[index] = {
        ...engagement.organizations[index],
        organizationName: organization.organizationName.trim()
      }
    });
  }

  return {
    campaignName: engagement.campaignName,
    OptingIn: engagement.optIn,
    engagementType: engagement.engagementType,
    flowId: engagement.flowId,
    startDate: startDate.isValid() ? startDate.unix() * 1000 : null,
    endDate: endDate.isValid() ? endDate.unix() * 1000 : null,
    startTime: engagement.startTime,
    endTime: engagement.endTime,
    engagementOrganizations: engagement.organizations,
  };
})

export const getAutomationTreatRequest = createSelector(getEngagementState,
  (engage) => {
    return {
      awardId: engage.selectedReward ? engage.selectedReward.awardId : '',
      treatValidityDays: engage.treatLength,
      campaignName: engage.campaignName,
      emailSubject: engage.subject,
      emailText: engage.message,
      emailHeadline: engage.subTitle,
      headline: engage.subTitle,
      mobileHeaderImage: engage.mobileImage,
      treatMessageForApp: engage.defaultMessageForApp,
      includeActiveTreats: engage.includeActiveTreats,
      id: engage.flowId
    };
  });

function getTreatRecipients(activeTreats, treat) {
  if (activeTreats.includeActiveCustomers) {
    return treat.offerRecipients;
  } else {
    return treat.offerRecipients.filter((recipient) =>
      !R.contains(recipient.customerUid, activeTreats.activeTreatCustomers));
  }
}

/**
 * Care state root selector
 */
export const getCareState = (state) => state.care;

/**
 * Care state children selectors
 */
export const getCareReports = createSelector(getCareState, fromCare.getReports);

export const getStatusOptions = createSelector(getCareState, fromCare.getStatusOptions);

export const getCareSelectedCustomer = createSelector(getCareState, fromCare.getSelectedCustomer);

export const getSelectedCareReport = createSelector(getCareState, fromCare.getSelectedReport);

export const getSelectedCareRewards = createSelector(getCareState, fromCare.getSelectedRewards);

export const getCareServiceNowCase = createSelector(getCareState, fromCare.getServiceNowCase);

export const getCareServiceNowCaseError = createSelector(getCareState, fromCare.getServiceNowCaseError);

export const getCareServiceNowCaseLoading = createSelector(getCareState, fromCare.getServiceNowCaseLoading);

export const getCareTemplateHtml = createSelector(getCareState, fromCare.getTemplateHtml);

export const getCareCopy = createSelector(getCareState, fromCare.getCopy);

export const getCareDefaultCopy = createSelector(getCareState, fromCare.getDefaultCopy);

export const getCareThemes = createSelector(getCareState, fromCare.getThemes);

export const getCareThemeName = createSelector(getCareState, fromCare.getThemeName);

export const getCareDefaultThemeName = createSelector(getCareState, fromCare.getDefaultThemeName);

export const getCareHeadline = createSelector(getCareState, fromCare.getHeadline);

export const getCarePreHeader = createSelector(getCareState, fromCare.getPreHeader);

export const getCareThemeUrl = createSelector(getCareState, fromCare.getThemeUrl);

export const getCareSelectedView = createSelector(getCareState, fromCare.getCareSelectedView);

export const getRecoveredSelectedView = createSelector(getCareState, fromCare.getRecoveredSelectedView)

export const getClosedCareSelectedView = createSelector(getCareState, fromCare.getClosedCareSelectedView)

export const getCfaOneSelectedView = createSelector(getCareState, fromCare.getCfaOneSelectedView)

export const getCareOpeningStatement = createSelector(getCareState, fromCare.getOpeningStatement);

export const getCareSubject = createSelector(getCareState, fromCare.getSubject);

export const getCareDateSent = createSelector(getCareState, fromCare.getDateSent);

export const getCareRewardCategories = createSelector(getCareState, fromCare.getRewardCategories);

export const getFormattedCareRewardCategories = createSelector(getCareRewardCategories, getSelectedCareRewards,
  (rewardCategories, selectedRewards) => {
    // Set correct count on the rewards based on user selected rewards
    rewardCategories.forEach((rewardCategory, categoryIndex) => {
      rewardCategory.rewards.forEach((reward, rewardIndex) => {
        let selectedRewardIndex = R.findIndex(R.propEq('id', reward.id))(selectedRewards);
        if (selectedRewardIndex > -1) {
          rewardCategories[categoryIndex].rewards[rewardIndex] = {
            ...reward,
            count: selectedRewards[selectedRewardIndex].count
          };
        } else {
          rewardCategories[categoryIndex].rewards[rewardIndex] = { ...reward, count: 0 };
        }
      })
    })
    return rewardCategories.sort((a,b) => a.categoryName.includes('LTO') ? -1 : 1);
  });

export const getLimitedCareRewardDates = createSelector(getSelectedCareRewards,
  (selectedRewards) => {
    let limitedCareRewardDates = [];
    let rewardStartDate = moment().format('MM/DD/YYYY');
    let rewardEndDate = moment().add('90', 'days').format('MM/DD/YYYY');

    selectedRewards.forEach(reward => {
      reward.rewardStartDate = rewardStartDate;
      reward.rewardEndDate = rewardEndDate;
      if (moment(reward.endDateRestriction) < moment(rewardEndDate) && reward.limitedtimeoffer) {
        reward.rewardEndDate = reward.endDateRestriction;
      }
      limitedCareRewardDates.push({ startDate: reward.rewardStartDate, endDate: reward.rewardEndDate });
    });

    // remove duplicates values
    limitedCareRewardDates = limitedCareRewardDates.filter((value, index, self) =>
      index === self.findIndex((t) => (t.endDate === value.endDate))
    );

    return limitedCareRewardDates;
  });

export const getCareTemplateLoading = createSelector(getCareState, fromCare.getTemplateLoading);

export const getCareTemplateError = createSelector(getCareState, fromCare.getTemplateError);

export const getIncludeReward = createSelector(getCareState, fromCare.getIncludeReward);

export const getMemberType = createSelector(getCareState, fromCare.getMemberType);

export const getCareNumberOfPages = createSelector(getCareState, fromCare.getNumberOfPages);

export const getCareTotalReports = createSelector(getCareState, fromCare.getTotalNumberOfReports);

export const getCarePage = createSelector(getCareState, fromCare.getPage);

export const getCarePageSize = createSelector(getCareState, fromCare.getPageSize);

export const getCareSortBy = createSelector(getCareState, fromCare.getSortBy);

export const getCareSortOrder = createSelector(getCareState, fromCare.getSortOrder);

export const getCareLoading = createSelector(getCareState, fromCare.getLoading);

export const getCareRecoverEligibilityLoading = createSelector(getCareState, fromCare.getRecoverEligibilityLoading);

export const getCareError = createSelector(getCareState, fromCare.getError);

export const getCareSearchBy = createSelector(getCareState, fromCare.getSearchBy);

export const getCareSearchTerm = createSelector(getCareState, fromCare.getSearchTerm);

export const getCareCustomerNotes = createSelector(getCareState, fromCare.getCareCustomerNotes);

export const getCareCustomerNotesToDisplay = createSelector(getCareState, fromCare.getCareCustomerNotesToDisplay);

export const getCareCustomerNotesLoading = createSelector(getCareState, fromCare.getCareCustomerNotesLoading);

export const getCareCustomerNotesError = createSelector(getCareState, fromCare.getCareCustomerNotesError);

export const getNoSearchResultMessage = createSelector(getCareSearchTerm, (searchTerm) => {
  if (searchTerm != null) {
    return CareConstants.noSearchResultMessage + ' ' + searchTerm + '.';
  } else {
    return CareConstants.noSearchResultMessage;
  }
});

export const getNoResultsMessage = createSelector(getNoSearchResultMessage, getCareSearchTerm,
  (noSearchResultMessage, searchTerm) => {
    if (searchTerm) {
      return noSearchResultMessage;
    } else {
      return CareConstants.noResultsMessage;
    }
  });

export const getSpotlightContactsSearchBy = createSelector(getCareState, fromCare.getSpotlightContactsSearchBy);

export const getSpotlightContactsSearchTerm = createSelector(getCareState, fromCare.getSpotlightContactsSearchTerm);

export const getCareDisplayErrorText = createSelector(getCareState, fromCare.getSpotlightContactsDisplayErrorText);

export const getSpotlightContactsPageLimit = createSelector(getCareState, fromCare.getSpotlightContactsPageLimit);

export const getSpotlightContactsPage = createSelector(getCareState, fromCare.getSpotlightContactsPage);

export const getSpotlightContactsTotalCountOfCustomers = createSelector(getCareState, fromCare.getSpotlightContactsTotalCountOfCustomers);

export const getSpotlightContactsCustomers = createSelector(getCareState, fromCare.getSpotlightContactsCustomers);

export const getSpotlightContactsLoading = createSelector(getCareState, fromCare.getSpotlightContactsLoading);

export const getSpotlightContactsNumberOfPages = createSelector(getCareState, fromCare.getSpotlightContactsNumberOfPages);

export const getSpotlightContactsError = createSelector(getCareState, fromCare.getSpotlightContactsError);

export const getRecoveredCfaOneContactsTotalElements = createSelector(getCareState, fromCare.getRecoveredCfaOneContactsTotalElements);

export const getRecoveredCfaOneContacts = createSelector(getCareState, fromCare.getRecoveredCfaOneContacts);

export const spotlightContactsSelectedCategoryTitle = createSelector(
  getSpotlightContactsSearchBy, (searchBy) => {
    return R.find(R.propEq('value', searchBy))(CareConstants.spotlightContactsSearchByCategories).title;
  }
)

export const getSpotlightContactsSortBy = createSelector(getCareState, fromCare.getSpotlightContactsSortBy);

export const getSpotlightContactsSortOrder = createSelector(getCareState, fromCare.getSpotlightContactsSortOrder);

export const getSpotlightContactsSortObj = createSelector(
  getSpotlightContactsSortBy, getSpotlightContactsSortOrder,
  (prop, order) => {
    return { prop, order };
  });

export const getSpotlightContactsRequest = createSelector(
  getSpotlightContactsSearchTerm, getSpotlightContactsSearchBy, getSpotlightContactsPageLimit, getSpotlightContactsPage,
  getSpotlightContactsSortBy, getSpotlightContactsSortOrder,getCfaOneSelectedView,
  (searchTerm, searchBy, limit, page, sortBy, sortOrder, view) => {
    let fromDate = moment().subtract(view.slice(5,7), 'days').format('YYYY-MM-DD');
    let toDate = moment().format('YYYY-MM-DD');
    return {
      searchTerm: searchTerm,
      searchBy: searchBy,
      limit: limit,
      page: page,
      sort: sortBy,
      sortOrder: sortOrder,
      anyCfaoneCustomers: searchTerm ? true : false,
      fromDate,
      toDate
    }
  });

export const getCareSelectedCustomerRow = createSelector(
  getCareSelectedCustomer, (customer) => customer ? [customer] : []);

export const getCareCustomerName = createSelector(
  getCareSelectedCustomer, (customer) => customer.fullName);

export const getSelectedCareReportId = createSelector(
  getSelectedCareReport, (report) => report.id);

export const getSelectedCareReportFullName = createSelector(
  getSelectedCareReport, (report) => report.fullName);

export const getSelectedCareReportPriority = createSelector(
  getSelectedCareReport, (report) => report.priority);

export const getReportSpotEngagementEligible = createSelector(
  getSelectedCareReport, (report) => report.eligibleForSpotEngagement);

export const getCareReportHistory = createSelector(
  getSelectedCareReport, (report) => report.activityHistory);

export const getCareReportHistoryLoading = createSelector(
  getSelectedCareReport, (report) => report.historyLoading);

export const getCareReportHistoryError = createSelector(
  getSelectedCareReport, (report) => report.historyError);

export const getCareContactName = createSelector(
  getMemberType, getSelectedCareReport, getCareSelectedCustomer,
  (memberType, report, customer) => {
    return memberType === CareConstants.spotCustomer ? customer.firstName : report.firstName;
  });

export const getCareStatusOptions = createSelector(
  getStatusOptions, (options) => {
    let spotOptions = (opts) => !opts.sendSpotlightResponseToGuest;

    return R.filter(spotOptions, options);
  });

export const getSpotStatusOptions = createSelector(
  getStatusOptions, (options) => {
    return options ? [R.find(R.propEq('sendSpotlightResponseToGuest', true))(options)] : null;
  });

export const getStatusOptionsByType = createSelector(
  getStatusOptions, (options) => {
    return options ? [R.find(R.propEq('sendSpotlightResponseToGuest', true))(options)] : null;
  });

export const getTotalSelectedCareRewards = createSelector(
  getSelectedCareRewards, (rewards) => {
    return rewards.reduce((prev, reward) => {
      return prev + reward.count;
    }, 0);
  });

export const getCareCopyChanged = createSelector(
  getCareDefaultCopy, getCareCopy, (defaultCopy, copy) => defaultCopy !== copy);

export const getCareStrippedEmailCopy = createSelector(getCareCopy,
  (copy) => {
    let newCopy = copy ? copy.replace(/<[^>]*>?/gm, '') : '';
    return newCopy.replace(/&nbsp;/gm, ' ');
  });

export const getCareThemeCopyChanged = createSelector(
  getCareThemes, getCareThemeName, getCareCopy,
  (themes, themeName, copy) => {
    let filteredTheme = R.find(R.propEq('themeName', themeName))(themes);
    return filteredTheme.emailCopy !== copy;
  });

export const getCareThemeChanged = createSelector(
  getCareDefaultThemeName, getCareThemeName,
  (defaultThemeName, themeName) => defaultThemeName !== themeName);

export const getCareSortObj = createSelector(
  getCareSortBy, getCareSortOrder,
  (prop, order) => {
    return { prop, order };
  });

export const getCareReportRequest = createSelector(
  getCarePage, getCarePageSize, getCareSortBy, getCareSortOrder, getCareSearchTerm, getCareSearchBy, getCareSelectedView,
  (page, limit, sortBy, sortOrder, searchTerm, searchBy, selectedView) => {
    const careSearchTerm = searchTerm === '' ? null : searchTerm;
    const careSearchBy = careSearchTerm ? searchBy : null;
    const daysAgo: string = selectedView.slice(5,7);
    const startDate = moment().subtract(selectedView.slice(5,7), 'days').format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');

    return {
      anyCfaoneCustomers: true,
      daysAgo,
      endDate,
      limit,
      page,
      searchBy : careSearchBy,
      searchTerm : careSearchTerm,
      sortBy,
      sortOrder,
      startDate,
      status: 'open'
    };
  });

export const getCareTemplateRequest = createSelector(
  getSelectedCareReportId, getIncludeReward, getMemberType,
  (incidentId, includeReward, memberType) => {
    let type = includeReward ? 'reward' : 'email';
    return { incidentId, type, memberType };
  });

export const getSpotCustomerCareTemplateRequest = createSelector(
  getIncludeReward, (includeReward) => {
    return includeReward ? 'reward' : 'email';
  });

export const getFormattedCareHtml = createSelector(getSelectedCareRewards,
  getSelectedCareReport, getCareCopy, getCareHeadline, getCarePreHeader,
  getCareSubject, getCareThemeUrl, getCareTemplateHtml, getCareContactName,
  (rewards, report, copy, headline, preHeader, subject, themeUrl, html, name) => {
    let placeholderImage = '../../../assets/img/care-reward-placeholder.svg';
    let rewardEndDate = moment().add('90', 'days');
    let endDates = {};
    let offerDateIndex = 0;
    rewards.forEach((reward) => {
      let offerDate = rewardEndDate;
      if (moment(reward.endDateRestriction) < rewardEndDate && reward.limitedtimeoffer) {
        offerDate = moment(reward.endDateRestriction);
      }
      let rewardCount = reward.count;
      while (rewardCount > 0) {
        endDates['offerEndDate' + offerDateIndex] = offerDate;
        rewardCount = rewardCount - 1;
        offerDateIndex = offerDateIndex + 1;
      }
    })
    let mergeVars = {
      ...endDates,
      copy,
      firstName: name,
      headline,
      placeholderImage,
      preHeader,
      rewards,
      subject,
      themeUrl
    };
    return html ? replaceCareHtml(mergeVars, html) : '';
  });

export const getCareRespondPayload = createSelector(getCareState, getCareThemeCopyChanged,
  (care, emailCopyChanged) => {
    let offerTemplateIds = null;

    // If care offers are sent, only send a list of ids
    // including duplicates if multiple are selected
    if (care.includeReward) {
      offerTemplateIds = care.selectedRewards.map((reward) => {
        return R.repeat(reward.id, reward.count);
      });
      offerTemplateIds = R.flatten(offerTemplateIds);
    }

    return {
      emailCopyChanged,
      cfaId: care.selectedReport.cfaId,
      customerMessage: care.copy,
      emailTemplateId: care.templateId,
      emailTemplateName: care.template,
      firstName: care.selectedReport.firstName,
      lastName: care.selectedReport.lastName,
      note: care.selectedReport.note,
      headline: care.headline,
      headLine: care.headline,
      incidentId: care.selectedReport.id,
      includeReward: care.includeReward,
      isResolved: care.isResolved,
      offerTemplateIds,
      preHeader: care.preHeader,
      resolutionStatus: care.resolutionStatus,
      selectedTheme: care.themeName,
      selectedThemeUrl: care.themeUrl,
      subject: care.subject
    };
  });

export const getCareSpotRespondPayload = createSelector(getCareState, getCareThemeCopyChanged,
  (care, emailCopyChanged) => {
    let offerTemplateIds = null;
    const activeSpotlightCustomer = care.selectedCustomer && care.selectedCustomer.showCustomerProfile;

    // If spotlight offers are sent, only send a list of ids
    // including duplicates if multiple are selected
    if (care.includeReward) {
      offerTemplateIds = care.selectedRewards.map((reward) => {
        return R.repeat(reward.id, reward.count);
      });
      offerTemplateIds = R.flatten(offerTemplateIds);
    }

    return {
      activeSpotlightCustomer,
      emailCopyChanged,
      cfaId: care.selectedCustomer.id,
      customerMessage: care.copy,
      emailTemplateId: care.templateId,
      emailTemplateName: care.template,
      firstName: care.selectedCustomer.firstName,
      lastName: care.selectedCustomer.lastName,
      note: care.selectedReport.note,
      headline: care.headline,
      headLine: care.headline,
      includeReward: care.includeReward,
      offerTemplateIds,
      preHeader: care.preHeader,
      resolutionStatus: care.resolutionStatus,
      selectedTheme: care.themeName,
      selectedThemeUrl: care.themeUrl,
      subject: care.subject
    };
  });

/**
 * Understand state root selector
 */
export const getUnderstandState = (state) => state.understand;

/**
 * Understand state children selectors
 */
export const getUnderstandDates = createSelector(getUnderstandState, fromUnderstand.getDates);

export const getUnderstandExcludedCemRewards = createSelector(getUnderstandState, fromUnderstand.getExcludeCemRewards);

export const getUnderstandTiles = createSelector(getUnderstandState, fromUnderstand.getUnderstandTiles);

export const getUnderstandLoading = createSelector(getUnderstandState, fromUnderstand.getUnderstandLoading);

export const getUnderstandError = createSelector(getUnderstandState, fromUnderstand.getUnderstandError);

export const getSelectedCategories = createSelector(getUnderstandState, fromUnderstand.getSelectedCategories);

export const getSelectedFilterChips = createSelector(getUnderstandState, fromUnderstand.getSelectedFilters);

export const getUnderstandDropDownFilters = createSelector(getUnderstandState, fromUnderstand.getDropDownFilters);

export const getUnderstandSearchBy = createSelector(getUnderstandState, fromUnderstand.getSearchBy);

export const getUnderstandSearchTerm = createSelector(getUnderstandState, fromUnderstand.getSearchTerm);

export const getSelectedAnalyticsCategories = createSelector(getUnderstandState, fromUnderstand.getSelectedAnalyticsCategories);

export const getSelectedTiles = createSelector(getUnderstandTiles,
  getSelectedCategories, (tiles, categories) => {
    let returnTiles = [];

    R.values(tiles).map((tile) => {
      if (R.contains(tile.category, categories) && tile.enabled) {
        // If tile is MLG, then add a tile for each instance of a MLG
        if (tile.marketGiveawayTile && !R.isEmpty(tile.data)) {
          tile.data.map((giveawayData) => {
            let giveawayTile = {
              ...tile,
              title: giveawayData.displayTitle,
              data: { ...giveawayData }
            };
            returnTiles.push(giveawayTile);
          });
        } else {
          returnTiles.push(tile);
        }

      }
    });

    return returnTiles;
  });

export const getUnderstandDetailView = createSelector(getUnderstandState, fromUnderstand.getDetailViews);

export const getUnderstandDetails = createSelector(getUnderstandDetailView, getUnderstandDates,
  getUnderstandExcludedCemRewards, getSelectedFilterChips, getUnderstandSearchBy, getUnderstandSearchTerm,
  (views, dates, isCemCheck, selectedFilterChips, searchBy, searchTerm) => {
    return {
      ...views,
      ...dates,
      isCemCheck,
      selectedFilterChips,
      searchBy,
      searchTerm
    };
  });

export const getTilesWithoutExistingData = createSelector(getSelectedTiles, getUnderstandDates, getUnderstandExcludedCemRewards,
  (tiles: Tile[], dates, excludeCemRewards) => {
    return tiles.filter((t) => R.isEmpty(t.data))
      .map((tile) => {
        return { ...tile, ...dates, excludeCemRewards };
      });
  });

export const getTilesForMeetPage = createSelector(getUnderstandTiles, getUnderstandDates, getUnderstandExcludedCemRewards,
  (tiles: Tile[], dates, excludeCemRewards) => {
    let meetTiles = [];
    R.values(tiles).forEach((tile) => {
      if (tile.enabled && (tile.category === MeetConstants.analyticsTrendsCategory
          || tile.category === MeetConstants.analyticsCustomerCategory || tile.category === MeetConstants.analyticsCateringCategory)) {
        meetTiles.push(tile);
      }
    });

    return meetTiles.map((tile) => {
      return { ...tile, ...dates, excludeCemRewards };
    });
  });

export const getTrendTiles = createSelector(getUnderstandTiles, getUnderstandDates, getUnderstandExcludedCemRewards,
  (tiles: Tile[], dates, excludeCemRewards) => {
    let trendTiles = [];
    R.values(tiles).forEach((tile) => {
      if (tile.enabled && tile.category === MeetConstants.analyticsTrendsCategory) {
        trendTiles.push(tile);
      }
    });

    return trendTiles.map((tile) => {
      return { ...tile, ...dates, excludeCemRewards };
    });
  });

export const getCustomerTiles = createSelector(getUnderstandTiles, getUnderstandDates, getUnderstandExcludedCemRewards,
  (tiles: Tile[], dates, excludeCemRewards) => {
    let customerTiles = [];
    R.values(tiles).forEach((tile) => {
      if (tile.enabled && tile.category === MeetConstants.analyticsCustomerCategory) {
        customerTiles.push(tile);
      }
    });

    return customerTiles.map((tile) => {
      return { ...tile, ...dates, excludeCemRewards };
    });
  });

export const getCateringTiles = createSelector(getUnderstandTiles, getUnderstandDates, getUnderstandExcludedCemRewards,
  (tiles: Tile[], dates, excludeCemRewards) => {
    let cateringTiles = [];
    R.values(tiles).forEach((tile) => {
      if (tile.enabled && tile.category === MeetConstants.analyticsCateringCategory) {
        cateringTiles.push(tile);
      }
    });

    return cateringTiles.map((tile) => {
      return { ...tile, ...dates, excludeCemRewards };
    });
  });

export const getRewardTiles = createSelector(getUnderstandTiles, getUnderstandDates, getUnderstandExcludedCemRewards,
  (tiles: Tile[], dates, excludeCemRewards) => {
    let rewardTiles = [];
    R.values(tiles).forEach((tile) => {
      if (tile.enabled && tile.category === EngageConstants.analyticsTreatCategory) {
        if (tile.marketGiveawayTile && !R.isEmpty(tile.data)) {
          tile.data.forEach((giveawayData) => {
            let giveawayTile = {
              ...tile,
              title: giveawayData.displayTitle,
              data: { ...giveawayData }
            };
            rewardTiles.push(giveawayTile);
          });
        } else {
          rewardTiles.push(tile);
        }
      }
    });
    return rewardTiles.map((tile) => {
      return { ...tile, ...dates, excludeCemRewards };
    });
  });

export const getEventTiles = createSelector(getUnderstandTiles, getUnderstandDates, getUnderstandExcludedCemRewards,
  (tiles: Tile[], dates, excludeCemRewards) => {
    let eventTiles = [];
    R.values(tiles).forEach((tile) => {
      if (tile.enabled && tile.category === EngageConstants.analyticsEventCategory) {
        eventTiles.push(tile);
      }
    });
    return eventTiles.map((tile) => {
      return { ...tile, ...dates, excludeCemRewards };
    });
  });

export const getEmailTiles = createSelector(getUnderstandTiles, getUnderstandDates, getUnderstandExcludedCemRewards,
  (tiles: Tile[], dates, excludeCemRewards) => {
    let emailTiles = [];
    R.values(tiles).forEach((tile) => {
      if (tile.enabled && tile.category === EngageConstants.analyticsEmailCategory) {
        emailTiles.push(tile);
      }
    });
    return emailTiles.map((tile) => {
      return { ...tile, ...dates, excludeCemRewards };
    });
  });

export const getRecoverTiles = createSelector(getUnderstandTiles, getUnderstandDates, getUnderstandExcludedCemRewards,
  (tiles: Tile[], dates, excludeCemRewards) => {
    let recoverTiles = [];
    R.values(tiles).forEach((tile) => {
      if (tile.enabled && tile.category === CareConstants.analyticsCareCategory) {
        if (tile.marketGiveawayTile && !R.isEmpty(tile.data)) {
          tile.data.forEach((giveawayData) => {
            let giveawayTile = {
              ...tile,
              title: giveawayData.displayTitle,
              data: { ...giveawayData }
            };
            recoverTiles.push(giveawayTile);
          });
        } else {
          recoverTiles.push(tile);
        }
      }
    });
    return recoverTiles.map((tile) => {
      return { ...tile, ...dates, excludeCemRewards };
    });
  });

export const getUnderstandDetailError = createSelector(getUnderstandDetails, (details) => details.error);

export const getUnderstandDetailLoading = createSelector(getUnderstandDetails, (details) => details.loading);

export const getUnderstandDetailDisableSelect = createSelector(getUnderstandDetails, (details) => details.disableSelect);

export const getUnderstandDetailTitle = createSelector(getUnderstandDetails, (details) => details.title);

export const getUnderstandDetailInfoText = createSelector(getUnderstandDetails, (details) => details.infoText);

export const getUnderstandDetailMobileFullTable = createSelector(getUnderstandDetails, (details) => details.mobileFullTable);

export const getUnderstandDetailNoResultMessage = createSelector(getUnderstandDetails, (details) => details.noResultsMessage);

export const getUnderstandDetailNoResultMessageOnSearch = createSelector(getUnderstandDetails,
  (details) => details.noResultsMessageOnSearch);

export const getUnderstandDetailNoResultMessageWithSearchTermOnSearch = createSelector(getUnderstandDetails,
  (details) => details.noResultsMessageWithSearchTermOnSearch);

export const getUnderstandDetailData = createSelector(getUnderstandDetails, (details) => details.data);

export const getUnderstandDetailPages = createSelector(getUnderstandDetails, (details) => details.numberOfPages);

export const getUnderstandDetailCurrentPage = createSelector(getUnderstandDetails, (details) => details.page);

export const getUnderstandDetailPageSizeOptions = createSelector(getUnderstandDetails, (details) => details.pageSizeOptions);

export const getUnderstandDetailPageSize = createSelector(getUnderstandDetails, (details) => details.pageSize);

export const getUnderstandDetailTotalElements = createSelector(getUnderstandDetails, (details) => details.totalElements);

export const getDisplaySearchBox = createSelector(getUnderstandDetails, (details) => details.displaySearchBox);

export const getDisplayAdvancedSearchBox = createSelector(getUnderstandDetails, (details) => details.displayAdvancedSearchBox);

export const getDeepLinkMessage = createSelector(getUnderstandDetails,
  (details) => details.deepLinkMessage);

export const getVisibleDeepLinkMsg = createSelector(getUnderstandSearchTerm, getDeepLinkMessage, (searchTerm, deepLinkMessage) => {
  return searchTerm && searchTerm.termName && deepLinkMessage;
});

export const getSearchByCategories = createSelector(getUnderstandDetails, (details) => details.searchByCategories);

export const getSearchBoxPlaceholder = createSelector(getUnderstandDetails, (details) => details.searchBoxPlaceholder);

export const getMobileSearchBoxPlaceholder = createSelector(getUnderstandDetails, (details) => details.mobileSearchBoxPlaceholder);

export const getHelpText = createSelector(getUnderstandDetails, (details) => details.helpText);

export const getServerPaging = createSelector(getUnderstandDetails, (details) => details.serverPaging);

export const getUnderstandDisableIndividualRowAttribute = createSelector(getUnderstandDetails,
  (details) => details.disableIndividualRowAttribute);

export const getUnderStandDefaultSortObj = createSelector(getUnderstandDetails,
  ((understandDetails) => {
    if (understandDetails.defaultSortBy) {
      return understandDetails.defaultSortBy;
    }
    return (understandDetails.columns) ?
      { prop: understandDetails.columns[0].prop, order: 'asc' } : { prop: null, order: null };
  }));

export const getUnderstandDetailNextView = createSelector(getUnderstandDetails, (detail) => {
  return {
    viewIncidentDetails: detail.viewIncidentDetails,
    viewProfile: detail.viewProfile,
    nextView: detail.nextView,
    intermediateView: detail.intermediateView
  };
});

export const getIsRowNotInteractable = createSelector(getUnderstandDetailNextView, (detailNextView) => {
  return !detailNextView.viewIncidentDetails && !detailNextView.viewProfile
    && !detailNextView.nextView && !detailNextView.intermediateView;
});

export const getUnderstandDetailIdProp = createSelector(getUnderstandDetails, (detail) => {
  return detail.idProp || 'cfaId';
});

export const getUnderstandDetailColumns = (id) => {
  return createSelector(getUnderstandDetails, getFeatureEnabled('careMembershipColumn'), getIsMobile,
    (understandDetails, careMembershipColumnEnabled, isMobile) => {
      if (!careMembershipColumnEnabled
        && (id === 'careContactStatus' || id === 'careContact' || id === 'careStatus' || id === 'careOfferStat')
        && understandDetails.columns) {
        // To remove memberStatus column
        return understandDetails.columns.filter((col) => col.prop !== 'memberStatus');
      }
      if (understandDetails.mobileFullTable && isMobile && understandDetails.columns) {
        return understandDetails.columns.filter((col) => {
          return col.mobile;
        })
      }
      return understandDetails.columns ? understandDetails.columns : null;
    });
};

export const getUnderstandDetailNoResult = createSelector(getUnderstandDetailNoResultMessage,
  getUnderstandDetailNoResultMessageOnSearch, getUnderstandDetailNoResultMessageWithSearchTermOnSearch, getUnderstandSearchTerm,
  (noResultMessage, noResultsMessageOnSearch, noResultsMessageWithSearchTermOnSearch, searchTerm) => {
    if (searchTerm && searchTerm.termName) {
      if (noResultsMessageWithSearchTermOnSearch) {
        return noResultsMessageWithSearchTermOnSearch + ' ' + searchTerm.termName + '.';
      } else {
        return noResultsMessageOnSearch ? noResultsMessageOnSearch : noResultMessage;
      }
    } else {
      return noResultMessage;
    }
  });

export const getDefaultFilters = createSelector(getUnderstandDetailView,
  getSelectedFilterChips,
  (understandDetailView, selectedFilterChips) => {
    let filters = understandDetailView.filters;
    if (selectedFilterChips.length > 0) {
      filters = R.clone(understandDetailView.filters);
      if (filters != null) {
        selectedFilterChips.map((element) => {
          let filterIndex = (R.findIndex(R.propEq('filterId', element.filterId))(filters));
          if (filterIndex !== -1) {
            filters[filterIndex] = element;
          }
        });
      }

    }
    return filters ? filters : [];
  });

export const shouldUseMembershipIconForMobile = (id) => {
  return createSelector(getFeatureEnabled('careMembershipColumn'),
    getUseMembershipIconForMobileFlag,
    (careMembershipColumnEnabled, userMembershipMobileIconFlag) => {
      return userMembershipMobileIconFlag && (careMembershipColumnEnabled
        || (id !== 'careContactStatus' && id !== 'careContact' && id !== 'careStatus' && id !== 'careOfferStat'));
    });
};

export const getUseMembershipIconForMobileFlag = createSelector(getUnderstandDetails,
  fromUnderstand.getUseMembershipIconForMobileFlag);

/**
 * Engage state root selector
 */
export const getEngageState = (state) => state.engage;

/**
 * Engage state children selectors
 */

export const getEngagePageSize = createSelector(getScreenSize,
  (screenSize) => {
    const isMobile = screenSize === AppConstants.extraSmall;
    return isMobile ? 10 : 20;
  });

export const getEngageLoading = createSelector(getEngageState, fromEngage.getLoading);

export const getSelectedTheme = createSelector(getEngageState, fromEngage.getSelectedTheme);

export const getEngageCustomersLoading = createSelector(getEngageState, fromEngage.getCustomerLoading);

export const getEngageCustomersError = createSelector(getEngageState, fromEngage.getCustomerError);

export const getGroupSendOption = createSelector(getEngageState, fromEngage.getGroupSendOption);

/* This selector responsible for calculating what is the current loading state.
Based on that it passes to ui */
export const getFinalLoadingState = createSelector(getEngageLoading, getEngageCustomersLoading,
  (engageLoading, cusLoading) => {
    return cusLoading ? cusLoading : engageLoading;
  });

export const getEngageError = createSelector(getEngageState, fromEngage.getError);

export const getEngageRewards = createSelector(getEngageState, fromEngage.getRewards);

export const getEngageRewardCategories = createSelector(getEngageRewards, (rewards) => {
  let categories = [];
  if (rewards && rewards.templates) {
    categories = R.without([undefined], R.uniq(R.pluck('category', rewards.templates)));
    if (rewards.ltoTemplates && rewards.ltoTemplates.length > 0) {
      categories.unshift(EngageConstants.limitedTimeOffer)
    }
    categories.unshift(EngageConstants.allRewardsCategory);
  }
  return categories;
});

export const getEngageRewardsLoading = createSelector(getEngageState, fromEngage.getRewardsLoading);

export const getEngageRewardsError = createSelector(getEngageState, fromEngage.getRewardsError);

export const getEngageEmails = createSelector(getEngageState, fromEngage.getEmails);

export const getEngageEmailCategories = createSelector(getEngageState, fromEngage.getEmailCategories);

export const getEngageMappedEmails = createSelector(getEngageEmails, getEngageEmailCategories, (emails, categories) =>{
  let mappedEmails ={}
  let displayEmails = R.filter(email => email.enabledForDisplay, emails)
  let displayAll = (R.find(R.propEq('templateCategory',EngageConstants.allEmailsCategory))(categories)).selected
  categories.forEach(category => {
    if (category.templateCategory == EngageConstants.allEmailsCategory) {
      mappedEmails[category.templateCategory] = displayEmails;
    } else {
      mappedEmails[category.templateCategory] = displayAll || category.selected ? R.filter(
        email => email.memberCategory == category.templateCategory, displayEmails) : [];
    }
  })
  return mappedEmails
})

export const getEngageDrafts = createSelector(getEngageState, fromEngage.getDrafts);

export const getFormattedEngageDrafts = createSelector(getEngageDrafts, getEngageEmails, getEngageEmailCategories,
  (drafts, emailTemplates, categories) => {
    let formattedDrafts = [];
    let addDrafts = categories[1].selected;
    if (drafts && addDrafts) {
      formattedDrafts = drafts.reduce((result,draft) => {
        let emailTemplate = R.find(R.propEq('templateName', draft.templateTitle))(emailTemplates);
        if (emailTemplate && emailTemplate.enabledForDisplay) {
          result.push( {
            ...draft,
            memberCategory: emailTemplate.memberCategory,
            previewHtml: emailTemplate.previewHtml,
            title: draft.title || emailTemplate.title,
            templateDescription: emailTemplate.description
          });
        }
        return result
      },[]);
    }
    return formattedDrafts;
  });

export const getEngageEmailDisplayText = createSelector(
  getEngageEmailCategories, getEngageMappedEmails, getFormattedEngageDrafts,
  (categories, mappedEmails, drafts) => {
    let emailLength = 0;
    let draftLength = 0;
    categories.forEach(category =>{
      if (category.selected && category.templateCategory == EngageConstants.allEmailsCategory) {
        emailLength = mappedEmails[category.templateCategory].length
      } else if (category.selected && category.templateCategory != 'Drafts') {
        emailLength = emailLength + (mappedEmails[category.templateCategory] ? mappedEmails[category.templateCategory] : []) .length
      } else if (category.templateCategory == 'Drafts' && category.selected) {
        draftLength = drafts.length;
      }
    });
    return `Displaying ${emailLength} emails and ${draftLength} drafts`;
  });
export const getEngageEmailsLoading = createSelector(getEngageState, fromEngage.getEmailsLoading);

export const getEngageEmailsError = createSelector(getEngageState, fromEngage.getEmailsError);

export const getEngageEvents = createSelector(getEngageState, fromEngage.getEvents);

export const getEngageEventCategories = createSelector(getEngageEvents, (events) => {
  let categories = [];
  if (events) {
    categories = R.without([undefined], R.uniq(R.pluck('category', events)));
    categories.unshift(EngageConstants.allEventsCategory);
    categories = R.sort((a, b) => a.localeCompare(b), categories);
  }
  return categories;
});

export const getEngageEventsLoading = createSelector(getEngageState, fromEngage.getEventsLoading);

export const getEngageEventsError = createSelector(getEngageState, fromEngage.getEventsError);

export const getSelectedTreat = createSelector(getEngageState, fromEngage.getSelectedTreat);

export const getRecipientsSearchTerm = createSelector(getEngageState, fromEngage.getRecipientsSearchTerm);

export const getSelectedTabIndex = createSelector(getEngageState, fromEngage.getSelectedTabIndex);

export const getSelectedHtmlTabIndex = createSelector(getEngageState, fromEngage.getSelectedHtmlTabIndex);
export const getEngagementId = createSelector(getEngageState, fromEngage.getEngagementId);

export const getSelectedEvent = createSelector(getEngageState, fromEngage.getSelectedEvent);

export const getSelectedEmail = createSelector(getEngageState, fromEngage.getSelectedEmail);

export const getSelectedBonusPointPromotion = createSelector(getEngageState, fromEngage.getSelectedBonusPointPromotion);

export const getSelectedCommunityCareEvent = createSelector(getEngageState, fromEngage.getSelectedCommunityCareEvent);

export const getSelectedThirdPartyPromotion = createSelector(getEngageState, fromEngage.getSelectedThirdPartyPromotion);

export const getSelectedFreeInCartPromotion = createSelector(getEngageState, fromEngage.getSelectedFreeInCartPromotion);
export const getDefaultSort = createSelector(getEngageState, fromEngage.getDefaultSort);

export const getDefaultSortOrder = createSelector(getEngageState, fromEngage.getDefaultSortOrder);

export const getCustomizeSortArray = createSelector(getEngageState, fromEngage.getCustomizeSortArray);

export const getDefaultSortObjForRecipients = createSelector(getDefaultSort, getDefaultSortOrder, getCustomizeSortArray,
  (prop, order, customizeSortArray) => ({ prop, order, customizeSortArray }));

export const getSelectedEngageCustomers = createSelector(getEngageState, fromEngage.getSelectedCustomers);

export const getEngageAppliedIncludedCustomers = createSelector(getEngageState, fromEngage.getIncludedCustomers);

export const getEngageAppliedExcludedCustomers = createSelector(getEngageState, fromEngage.getExcludedCustomers);

export const getEngageAppliedFilterOnBetweenId = createSelector(getEngageState, fromEngage.getFilterOnBetweenId);

export const getEngageAppliedFilterStartDate = createSelector(getEngageState, fromEngage.getFilterStartDate);

export const getEngageAppliedFilterEndDate = createSelector(getEngageState, fromEngage.getFilterEndDate);

export const getSelectedEngageIndividualIds = createSelector(getEngageAppliedIncludedCustomers, (individualIds) => {
  return individualIds;
});

export const getSelectedEngageSavedFilters = createSelector(getEngageState, fromEngage.getSelectedSavedFilters);

export const getSelectedEngageSavedFiltersIds = createSelector(getSelectedEngageSavedFilters,
  (savedFilters) => {
    // On the edit flow, if the user does not click into the customer selection component at all, the
    // savedFilters variable will be an array of ids already and won't have any id attributes
    return savedFilters[0] && savedFilters[0].id ? R.pluck('id', savedFilters) : savedFilters;
  });

export const getSelectedEngageDefaultFilters = createSelector(getEngageState, fromEngage.getSelectedDefaultFilters);

export const getSelectedEngageDefaultFiltersIds = createSelector(getSelectedEngageDefaultFilters,
  (defaultFilters) => {
    // On the edit flow, if the user does not click into the customer selection component at all, the
    // defaultFilters variable will be an array of ids already and won't have any id attributes
    return defaultFilters[0] && defaultFilters[0].id ? R.pluck('id', defaultFilters) : defaultFilters;
  });

export const getTotalSelectedEngageFilters = createSelector(
  getSelectedEngageSavedFilters, getSelectedEngageDefaultFilters,
  (savedGroups, selectedFilters) => {
    return savedGroups.length + selectedFilters.length;
  });

export const areNewEngageDateFiltersSelected = createSelector(
  getEngageFiltersOnBetweenId, getEngageFiltersStartDate, getEngageFiltersEndDate,
  getEngageAppliedFilterOnBetweenId, getEngageAppliedFilterStartDate, getEngageAppliedFilterEndDate,
  (selectedOnBetweenId, selectedStartDate, selectedEndDate, appliedOnBetweenId, appliedStartDate, appliedEndDate) => {
    let areNewDateFiltersSelected = false;
    // Check if new On/Between date option has been selected
    if (selectedOnBetweenId !== appliedOnBetweenId) {
      areNewDateFiltersSelected = true;
    }

    // Check if new Custom Date has been selected
    if (selectedStartDate !== appliedStartDate || selectedEndDate !== appliedEndDate) {
      areNewDateFiltersSelected = true;
    }

    return areNewDateFiltersSelected;
  });

export const areNewEngageFiltersSelected = createSelector(
  getDisplayFilters(AppConstants.engagePage), getEngageSelectedFilters, getEngageSelectedSavedGroups, getEngageIncludedCustomers,
  getEngageExcludedCustomers, getSelectedEngageDefaultFilters, getSelectedEngageSavedFilters, getEngageAppliedIncludedCustomers,
  getEngageAppliedExcludedCustomers, areNewEngageDateFiltersSelected,
  (displayFilters, selectedDisplayFilters, selectedSavedGroups, selectedIncludedCustomers, selectedExcludedCustomers,
    appliedFilters, appliedSavedGroups, appliedIncludedCustomers, appliedExcludedCustomers, newDateFiltersSelected) => {
    let areNewFiltersSelected = false;

    if (appliedFilters.length !== selectedDisplayFilters.length) {
      areNewFiltersSelected = true;
    }

    // Check if new filters have been added/removed/modified
    appliedFilters.map(appliedFilter => {
      const selectedCategory = R.find(R.propEq('id', appliedFilter.parentCategory))(displayFilters);
      const selectedSubFilter = R.find(R.propEq('id', appliedFilter.id))(selectedCategory.subFilters);

      if (appliedFilter.buttonToggles) {
        let selectedDisplayToggle = R.find(R.propEq('selected', true))(selectedSubFilter.buttonToggles);

        if (appliedFilter.selectedButtonToggle !== selectedDisplayToggle.id) {
          areNewFiltersSelected = true;
        }
      }

      if (appliedFilter.buttonChipSections) {
        selectedSubFilter.buttonChipSections.map(buttonChipSection => {
          let displaySelectedChips = [];
          let appliedSelectedChips = [];
          let selectedButtonChipSection = R.find(R.propEq('id', buttonChipSection.id))(appliedFilter.selectedButtonChipSections);

          let selectedChips = R.filter(chip => chip.selected, buttonChipSection.buttonChips);
          if (selectedChips.length > 0) {
            displaySelectedChips = R.flatten(R.pluck('id', selectedChips));
            appliedSelectedChips = selectedButtonChipSection ? selectedButtonChipSection.selectedButtonChips : [];
            if (!R.equals(appliedSelectedChips, displaySelectedChips)) {
              areNewFiltersSelected = true;
            }
          }
        });
      }

      if (appliedFilter.sliderOptions) {
        if (appliedFilter.sliderOptions.value !== selectedSubFilter.sliderOptions.value
            || appliedFilter.sliderOptions.highValue !== selectedSubFilter.sliderOptions.highValue) {
          areNewFiltersSelected = true;
        }
      }
    });

    // Check if new included individuals have been added/removed
    let selectedIncludedIds = R.flatten(R.pluck('customerUid', selectedIncludedCustomers));
    let appliedIncludedIds = R.flatten(R.pluck('customerUid', appliedIncludedCustomers));
    if (!R.equals(selectedIncludedIds, appliedIncludedIds)) {
      areNewFiltersSelected = true;
    }

    // Check if new excluded individuals have been added/removed
    let selectedExcludedIds = R.flatten(R.pluck('customerUid', selectedExcludedCustomers));
    let appliedExcludedIds = R.flatten(R.pluck('customerUid', appliedExcludedCustomers));
    if (!R.equals(selectedExcludedIds, appliedExcludedIds)) {
      areNewFiltersSelected = true;
    }

    // Check if new saved groups have been added/removed
    let selectedSavedGroupIds = R.flatten(R.pluck('id', selectedSavedGroups));
    let appliedSavedGroupsIds = R.flatten(R.pluck('id', appliedSavedGroups));
    if (!R.equals(selectedSavedGroupIds, appliedSavedGroupsIds)) {
      areNewFiltersSelected = true;
    }

    if (newDateFiltersSelected) {
      areNewFiltersSelected = true;
    }

    return areNewFiltersSelected;
  });

export const getEngageType = createSelector(getEngageState, fromEngage.getType);

export const getEngageMode = createSelector(getEngageState, fromEngage.getMode);

export const getSelectedEngagement = createSelector(getSelectedTreat, getSelectedEvent,
  getSelectedEmail, getSelectedBonusPointPromotion, getSelectedCommunityCareEvent,
  getSelectedThirdPartyPromotion, getSelectedFreeInCartPromotion, getEngageType,
  (selectedTreat, selectedEvent, selectedEmail, selectedBonusPointPromotion,
    selectedCommunityCareEvent, selectedThirdPartyPromotion, selectedFreeInCartPromotion, type) => {
    let selectedEngagement;

    if (type === EngageConstants.offer
      || type === EngageConstants.discover) {
      selectedEngagement = selectedTreat;
    } else if (type === EngageConstants.event) {
      selectedEngagement = selectedEvent;
    } else if (type === EngageConstants.email || type === EngageConstants.draft) {
      selectedEngagement = selectedEmail;
    } else if (type === EngageConstants.bonusPoint) {
      selectedEngagement = selectedBonusPointPromotion;
    } else if ( type == EngageConstants.communityCare) {
      selectedEngagement = selectedCommunityCareEvent;
    } else if ( type == EngageConstants.thirdPartyPromotion || type == EngageConstants.nationalThirdPartyPromotion) {
      selectedEngagement = selectedThirdPartyPromotion
    } else if (type == EngageConstants.freeItemInCartPromotion) {
      selectedEngagement = selectedFreeInCartPromotion
    }

    return selectedEngagement;
  });

export const getEngageSendImmediate = createSelector(getSelectedEngagement, (selectedEngagement) => {
  return selectedEngagement.sendImmediate;
});

export const getSelectedEngagementImage = createSelector(getEngageType,
  (type) => {
    let selectedEngagementImage: string;

    if (type === EngageConstants.offer || type === EngageConstants.discover) {
      selectedEngagementImage = '../../../assets/img/red_gift.png';
    } else if (type === EngageConstants.email || type === EngageConstants.draft || type === EngageConstants.event) {
      selectedEngagementImage = '../../../assets/img/red_bag.png';
    }

    return selectedEngagementImage;
  });

export const getLocationStreetAddress = createSelector(getSelectedLocation,
  (selectedLocation) => {
    let address: any = selectedLocation.address;
    let formattedAddress = address.address1;

    if (address.address2 !== null) {
      formattedAddress += ' ' + address.address2;
    }
    if (address.address3 !== null) {
      formattedAddress += ' ' + address.address3;
    }
    formattedAddress += ', ' + address.city + ', ' + address.state + ' ' + address.zipCode;
    return formattedAddress;
  });

export const getEngageCopyHeader = createSelector(getEngageType, (type) => {
  let copyHeader;

  if (type === EngageConstants.offer) {
    copyHeader = EngageConstants.rewardCopyHeader;
  } else {
    copyHeader = EngageConstants.emailCopyHeader;
  }

  return copyHeader;
});

export const getEngageCopyText = createSelector(getEngageType, (type) => {
  let copyText;

  if (type === EngageConstants.offer) {
    copyText = EngageConstants.rewardEmailCopyText;
  } else if (type === EngageConstants.event) {
    copyText = EngageConstants.eventCopyText;
  } else if (type === EngageConstants.email || type === EngageConstants.draft) {
    copyText = EngageConstants.emailCopyText;
  }

  return copyText;
});

export const isPushNotificationEnabled = createSelector(
  getFeatureEnabled(EngageConstants.pushNotificationFlag),
  getSelectedEngagement,
  (pushNotificationFlag, engagement) => {
    let disablePushNotification = engagement.endDate ? engagement.endDate.diff(engagement.startDate, 'days') < 7 : true;
    return pushNotificationFlag && !disablePushNotification;
  });

export const getEngageCampaignName = createSelector(getEngageState, fromEngage.getCampaignName);

export const getStockImages = createSelector(getEngageState, fromEngage.getStockImages);

export const getEngageSavedFilters = createSelector(getEngageState, fromEngage.getSavedFilters);

export const getEngageDefaultFilters = createSelector(getEngageState, fromEngage.getDefaultFilters);

export const getEngageSavedFiltersLoading = createSelector(getEngageState,
  fromEngage.getSavedFiltersLoading);

export const getEngageDefaultFiltersLoading = createSelector(getEngageState,
  fromEngage.getDefaultFiltersLoading);

export const getEngageSavedFiltersError = createSelector(getEngageState,
  fromEngage.getSavedFiltersError);

export const getEngageDefaultFiltersError = createSelector(getEngageState,
  fromEngage.getDefaultFiltersError);

export const getEngageThemes = createSelector(getEngageState, fromEngage.getThemes);

export const getEngageDisplayThemes = createSelector(getSelectedEngagement,
  (engagement) => engagement.displayThemes);

export const getEngageSubject = createSelector(getSelectedEngagement,
  (engagement) => engagement.subject);

export const getEngageThemeTitle = createSelector(getSelectedEngagement,
  (engagement) => engagement.title);

export const getEngageEmailHeadline = createSelector(getSelectedEngagement,
  (engagement) => engagement.emailheadline);

export const getEngagementEmailCopy = createSelector(getSelectedEngagement,
  (engagement) => engagement.emailCopy);

export const getEngagementStrippedEmailCopy = createSelector(getEngagementEmailCopy,
  (copy) => {
    let newCopy = copy ? copy.replace(/<[^>]*>?/gm, '') : '';
    return newCopy.replace(/&nbsp;/gm, ' ');
  });

export const getEngageAppCopy = createSelector(getSelectedEngagement,
  (engagement) => engagement.defaultMessageForApp);

export const getEngagePushMessageCopy = createSelector(getSelectedEngagement,
  (engagement) => engagement.pushMsgBody);

export const getRewardRecipients = createSelector(getEngageState,
  fromEngage.getRewardRecipients);

export const getDuplicateRecipients = createSelector(getEngageState,
  fromEngage.getDuplicateRecipients);

export const getHoldoutRecipients = createSelector(getEngageState,
  fromEngage.getHoldoutRecipients);

export const getChangeImageOption = createSelector(getSelectedEngagement, getStockImages,
  (engagement, stockImage) => {
    let stockImages = R.clone(stockImage);
    stockImages.map(options => {
      options.changeImageOptions.map(image => {
        image.isSelected = image.imageUrl === engagement.imageUrl;
      });
    });
    return stockImages;
  });

export const getStockImagesAvailable = createSelector(getStockImages,
  (stockImage) => {
    return stockImage && stockImage.length > 0 && stockImage[0].changeImageOptions
      && stockImage[0].changeImageOptions.length > 0;
  });

export const getAreAllDuplicates = createSelector(
  getRewardRecipients,
  getDuplicateRecipients,
  (totalCustomers, duplicateCustomers) => {
    if (totalCustomers && duplicateCustomers) {
      return totalCustomers.length === 0 && duplicateCustomers.length > 0;
    }
    return false;
  });

export const getIsHoldoutRecipients = createSelector(
  getRewardRecipients,
  getHoldoutRecipients,
  (totalCustomers, holdoutCustomers) => {
    if (totalCustomers && holdoutCustomers) {
      return totalCustomers.length === 0 && holdoutCustomers.length > 0;
    }
    return false;
  });

export const shouldFetchIndividualCustomers = createSelector(
  getSelectedEngageIndividualIds,
  getSelectedEngageCustomers,
  (selectedCustomerIds, selectedCustomerObjs) => {
    // Only want to fetch customer objects from ids if they are not already on the state
    return selectedCustomerIds.length !== selectedCustomerObjs.length;
  });

// Check for null specifically so that empty strings are allowed
export const getEngageDisplayCampaignName = createSelector(getEngageCampaignName,
  (campaignName) => campaignName !== null ? campaignName : '');

export const getEngageSendOperatorImage = createSelector(getSelectedTreat,
  (treat) => treat.sendOperatorImage);

export const getEngageStartDate = createSelector(getSelectedEngagement,
  (engagement) => engagement.startDate);

export const getEngageEndDate = createSelector(getSelectedEngagement,
  (engagement) => engagement.endDate);

export const getEngageCalendarPayload = createSelector(
  getEngageStartDate, getEngageEndDate, getSelectedEngagement,
  (start, end, engagement) => {
    return {
      campaignTypes:  ['rewards', 'events', 'bonusPointPromotions', 'communityCareEvent',
        'free-item-in-cart-promotion', 'third-party-promotion', 'nationalThirdPartyPromotion'],
      fromDate: start.format('YYYY-MM-DD'),
      id: engagement.id,
      toDate: end.format('YYYY-MM-DD')
    };
  });
export const getEngageMaxDaysAllowed = createSelector(getSelectedEngagement,
  (engagement) => engagement.maxDaysAllowed);

export const getEngageSendDate = createSelector(getSelectedEngagement,
  (engagement) => engagement.sendDate);

export const getEngageSendTime = createSelector(getSelectedEngagement,
  (engagement) => engagement.sendTime);

export const getEngageStartTime = createSelector(getSelectedEngagement,
  (engagement) => engagement.startTime);

export const getEngageEndTime = createSelector(getSelectedEngagement,
  (engagement) => engagement.endTime);

export const getEngageStartTimeRestriction = createSelector(getSelectedEngagement,
  (engagement) => engagement.startTimeRestriction);

export const getEngageEndTimeRestriction = createSelector(getSelectedEngagement,
  (engagement) => engagement.endTimeRestriction);

export const getEngageSendTimeStartRestriction = createSelector(getSelectedEngagement,
  (engagement) => engagement.sendTimeStartRestriction);

export const getEngageSendTimeEndRestriction = createSelector(getSelectedEngagement,
  (engagement) => engagement.sendTimeEndRestriction);

export const getEngageVenue = createSelector(getSelectedEngagement,
  (engagement) => engagement.venue);

export const getEngageRsvpRequired = createSelector(getSelectedEvent,
  (event) => event.rsvpRequired);

export const getEngageLimitedReservations = createSelector(getSelectedEvent,
  (event) => event.limitedReservations);

export const getEngageMaxNumberOfReservations = createSelector(getSelectedEvent,
  (event) => event.maxNumberOfReservations);

export const getEngageAdultsOnly = createSelector(getSelectedEvent,
  (event) => event.adultsOnly);

export const getTreatEndDateRestriction = createSelector(getSelectedTreat,
  (treat) => treat.endDateRestriction);

export const getEngageReminderEmailsEnabled = createSelector(getSelectedEngagement,
  (engagement) => {
    let { startDate, sendDate, sendImmediate } = engagement;
    let beginningOfToday = moment().set({ hour:0,minute:0,second:0,millisecond:0 });
    let inviteSendDate = sendImmediate ? beginningOfToday : sendDate;
    return moment(startDate).diff(inviteSendDate, 'days') > 1;
  });

export const getEngageNonRsvpReminderEnabled = createSelector(getSelectedEngagement,
  (engagement) => {
    let { startDate, sendDate, sendImmediate } = engagement;
    let beginningOfToday = moment().set({ hour:0,minute:0,second:0,millisecond:0 });
    let inviteSendDate = sendImmediate ? beginningOfToday : sendDate;
    return moment(startDate).diff(inviteSendDate, 'days') > 3;
  });

export const getResponsiveOptionFlag = createSelector(getSelectedEngagement, getEngageType,
  getTotalSelectedEngageFilters,
  (engagement, type, totalSelectedEngageFilters) => {
    let { startDate, sendDate, sendImmediate } = engagement;

    if ((type === EngageConstants.email || type === EngageConstants.event
      || type === EngageConstants.draft) && !sendImmediate && totalSelectedEngageFilters > 0
      && moment().add(1, 'd').isBefore(moment(sendDate))) {
      return true;
    }

    if (type === EngageConstants.offer && totalSelectedEngageFilters > 0
      && moment().add(1, 'd').isBefore(moment(startDate))) {
      return true;
    }

    return false;
  });

export const getEngageAppliedFiltersWithIndividualsAndDate = createSelector(
  getDisplayFilters(AppConstants.engagePage), getSelectedEngageDefaultFilters, getEngageAppliedIncludedCustomers,
  getEngageAppliedExcludedCustomers, getEngageAppliedFilterOnBetweenId, getEngageAppliedFilterStartDate, getEngageAppliedFilterEndDate,
  getMeetSavedGroupBeingEdited,
  (allFilters, selectedFilters, includedCustomers, excludedCustomers, onBetweenId, startDate, endDate, meetSavedGroupBeingEdited) => {
    return addExtraFilters(allFilters, selectedFilters, includedCustomers, excludedCustomers, onBetweenId, startDate, endDate,
      meetSavedGroupBeingEdited);
  }
)

export const getEngageAllSelectedCustomerIds = createSelector(getEngageState, fromEngage.getAllSelectedCustomerIds);

export const getSendOfferRequest = createSelector(
  getSelectedTreat, getEngageDisplayCampaignName, getIncludeActiveCustomers, getEngageAllSelectedCustomerIds, getActiveRewardCustomers,
  getSelectedTheme, getGroupSendOption, getEngageAppliedFiltersWithIndividualsAndDate, getSelectedEngageSavedFilters,
  (treat, campaignName, includeActive, totalCustomers, activeCustomers, selectedTheme, dynamicFlag,
    selectedFilters, selectedSavedGroups) => {
    let individualCustomersFilter = R.find(R.propEq('id', MeetConstants.includeFilterId))(selectedFilters);
    const numberOfCustomers = includeActive ? totalCustomers.length : totalCustomers.length - activeCustomers.length;
    // Remove all activeCustomers from the individual customer filter if includeActive is false
    if (individualCustomersFilter && individualCustomersFilter.cfaIdList && activeCustomers && !includeActive) {
      activeCustomers.forEach((activeCustomer) => {
        const cfaIdIndex = individualCustomersFilter.cfaIdList.indexOf(activeCustomer);
        if (cfaIdIndex > -1) {
          individualCustomersFilter.cfaIdList.splice(cfaIdIndex, 1);
        }
        const customerProfileIndex = R.findIndex(R.propEq('id', activeCustomer))(individualCustomersFilter.customerProfiles);
        if (customerProfileIndex > -1) {
          individualCustomersFilter.customerProfiles.splice(customerProfileIndex, 1);
        }
      });
    }
    return {
      ...treat,
      campaignName,
      dynamicFlag,
      includeActive,
      numberOfCustomers,
      selectedFilters,
      selectedSavedGroups,
      selectedTheme
    };
  });

export const getEngageRecruitmentModuleEnabled = createSelector(getSelectedEngagement, (selectedEngagement) => {
  return selectedEngagement.recruitmentModuleEnabled;
});

export const getEngageRecruitmentModuleLink = createSelector(getSelectedEngagement, (selectedEngagement) => {
  return selectedEngagement.recruitmentModuleLink;
});

export const getEventPayload = createSelector(getSelectedEvent, getEngageDisplayCampaignName, getSelectedLocation, getGroupSendOption,
  getResponsiveOptionFlag, getEngageAppliedFiltersWithIndividualsAndDate, getSelectedEngageSavedFilters, getEngageAllSelectedCustomerIds,
  (event, campaignName, location, dynamicFlag, isResponsiveOption, selectedFilters, selectedSavedGroups, customerIds) => {
    // The following combines all the dates and times and formats them the way the backend expects
    // endDate, startDate, and sendDate are formatted like 2019-09-12T00:00:00.000Z
    // endDateParam and startDateParam are formatted like 2019-09-12T00:00:00-04:00
    let endDate = event.endDate.format('YYYY-MM-DD') + ' ' + event.endTime;
    let formattedEndDate = moment(endDate, 'YYYY-MM-DD h:mm:ss a');
    let startDate = event.startDate.format('YYYY-MM-DD') + ' ' + event.startTime;
    let formattedStartDate = moment(startDate, 'YYYY-MM-DD h:mm:ss a');
    let sendDate = event.sendDate.format('YYYY-MM-DD') + ' ' + event.sendTime;
    let formattedSendDate = moment(sendDate, 'YYYY-MM-DD h:mm:ss a');
    let sendDateString = event.sendDate.format('YYYY-MM-DD');
    let isDynamicFlag = isResponsiveOption ? dynamicFlag : null;

    const eventChronology = [
      {
        endDate: moment.utc(formattedEndDate).format(),
        endDateParam: formattedEndDate.format(),
        startDate: moment.utc(formattedStartDate).format(),
        startDateParam: formattedStartDate.format(),
        timeIndicator: true
      }
    ];
    return {
      // adultsOnly and rsvpRequired were initially set up as 'Yes' or 'No' strings and have been
      // saved in Dynamo as such ever since so it would be very difficult to use booleans now, which
      // would be preferred, as that would break the calendarEvents call unless every existing Dynamo
      // row were changed from the 'Yes' and 'No' strings to booleans (and the backend was updated)
      adultsOnly: event.adultsOnly ? 'Yes' : 'No',
      campaignName,
      createdAt: moment(),
      description: event.emailCopy,
      dynamicFlag: isDynamicFlag,
      emailheadline: event.emailheadline,
      emailheadlineOptions: event.emailheadlineOptions,
      eventChronology,
      eventTitle: campaignName,
      displayThemes: event.displayThemes,
      externalLink: event.externalLink,
      imageUrl: event.imageUrl,
      imageUrl2: event.imageUrl2,
      mobileImageUrl: event.mobileImageUrl,
      mobileHeaderImageUrl: event.mobileHeaderImageUrl,
      locationNumber: location.locationNumber,
      maxCustomers: event.maxNumberOfReservations,
      name: event.title,
      rsvpRequired: event.rsvpRequired ? 'Yes' : 'No',
      sendDate: event.sendImmediate ? moment.utc().format() : moment.utc(formattedSendDate).format(),
      sendDateString: event.sendImmediate ? moment.utc().format('YYYY-MM-DD') : sendDateString,
      sendImmediate: event.sendImmediate,
      sendReminderForNotRSVPed: event.sendNonRsvpReminder,
      sendReminderForRSVPed: event.sendRsvpReminder,
      subject: event.subject,
      templateId: event.id,
      venue: event.venue,
      recruitmentModuleEnabled: event.recruitmentModuleEnabled,
      recruitmentModuleLink: event.recruitmentModuleLink,
      type: EngageConstants.event,
      selectedFilters,
      numberOfCustomers: customerIds.length,
      selectedSavedGroups
    };
  });

export const getEmailPayload = createSelector(
  getSelectedEmail, getEngageDisplayCampaignName, getSelectedLocation, getGroupSendOption,
  getResponsiveOptionFlag, getEngageAppliedFiltersWithIndividualsAndDate, getSelectedEngageSavedFilters, getEngageAllSelectedCustomerIds,
  (email, campaignName, location, dynamicFlag, isResponsiveOption, selectedFilters, selectedSavedGroups, customerIds) => {
    let fullSendDate = email.sendDate;
    let isDynamicFlag = isResponsiveOption ? dynamicFlag : null;
    let startDateString = email.sendDate.format('YYYY-MM-DD');
    if (!email.sendImmediate) {
      let fullSendDateString = `${fullSendDate.format('MM/DD/YYYY')} ${email.sendTime}`;
      fullSendDate = moment(fullSendDateString, 'MM/DD/YYYY h:mm a');
    }

    // return null for id since backend will override previous email if sent
    return {
      ...email,
      id: null,
      campaignName,
      ctaButtonLink: email.showCtaButton ? email.ctaButtonLink : 'style="display:none;"',
      description: email.emailCopy,
      locationNumber: location.locationNumber,
      name: email.subject,
      sendImmediate: email.sendImmediate,
      startDate: email.sendImmediate ? moment() : fullSendDate,
      startDateString: email.sendImmediate ? moment().format('YYYY-MM-DD') : startDateString,
      templateTitle: email.templateName,
      recruitmentModuleEnabled: email.recruitmentModuleEnabled,
      recruitmentModuleLink: email.recruitmentModuleLink,
      type: EngageConstants.email,
      dynamicFlag: isDynamicFlag,
      themes: [],
      stockImages: [],
      numberOfCustomers: customerIds.length,
      selectedFilters,
      selectedSavedGroups
    };
  });

export const getEventEditPayload = createSelector(
  getSelectedEvent, getEngageDisplayCampaignName, getEngageAppliedFiltersWithIndividualsAndDate, getSelectedEngageSavedFilters,
  (event, campaignName, selectedFilters, selectedSavedGroups) => {
    // The following combines all the dates and times and formats them the way the backend expects
    // endDate, startDate, and sendDate are formatted like 2019-09-12T00:00:00.000Z
    // endDateParam and startDateParam are formatted like 2019-09-12T00:00:00-04:00
    let endDate = event.endDate.format('YYYY-MM-DD') + ' ' + event.endTime;
    let formattedEndDate = moment(endDate, 'YYYY-MM-DD h:mm:ss a');
    let startDate = event.startDate.format('YYYY-MM-DD') + ' ' + event.startTime;
    let formattedStartDate = moment(startDate, 'YYYY-MM-DD h:mm:ss a');
    let sendDate = event.sendDate.format('YYYY-MM-DD') + ' ' + event.sendTime;
    let formattedSendDate = moment(sendDate, 'YYYY-MM-DD h:mm:ss a');
    const eventChronology = [
      {
        endDate: moment.utc(formattedEndDate).format(),
        endDateParam: formattedEndDate.format(),
        startDate: moment.utc(formattedStartDate).format(),
        startDateParam: formattedStartDate.format(),
        timeIndicator: true
      }
    ];

    return {
      campaignName,
      description: event.emailCopy,
      eventChronology,
      eventId: event.id,
      displayThemes: event.displayThemes,
      externalLink: event.externalLink,
      imageUrl2: event.imageUrl2,
      maxCustomers: event.maxNumberOfReservations,
      name: event.title,
      sendDate: event.sendImmediate ? moment.utc().format() : moment.utc(formattedSendDate).format(),
      sendImmediate: event.sendImmediate,
      sendReminderForNotRSVPed: event.sendNonRsvpReminder,
      sendReminderForRSVPed: event.sendRsvpReminder,
      subject: event.subject,
      type: EngageConstants.event,
      venue: event.venue,
      imageUrl: event.imageUrl,
      dynamicFlag: event.dynamicFlag,
      recruitmentModuleEnabled: event.recruitmentModuleEnabled,
      recruitmentModuleLink: event.recruitmentModuleLink,
      createdAt: moment(event.createdAt),
      selectedFilters,
      selectedSavedGroups
    };
  });

export const getEmailEditPayload = createSelector(
  getSelectedEmail, getEngageDisplayCampaignName, getSelectedLocation, getResponsiveOptionFlag,
  getEngageAppliedFiltersWithIndividualsAndDate, getSelectedEngageSavedFilters,
  (email, campaignName, location, isResponsiveOption, selectedFilters, selectedSavedGroups) => {
    let fullSendDate = email.sendDate;
    let dynamicFlag = isResponsiveOption ? email.dynamicFlag : null;
    if (!email.sendImmediate) {
      let fullSendDateString = `${fullSendDate.format('MM/DD/YYYY')} ${email.sendTime}`;
      fullSendDate = moment(fullSendDateString, 'MM/DD/YYYY h:mm a');
    }

    return {
      campaignName,
      description: email.emailCopy,
      headerImage: email.headerImage,
      id: email.id,
      image: email.image,
      imageUrl: email.imageUrl,
      imageUrl2: email.imageUrl2,
      locationNumber: location.locationNumber,
      name: email.subject,
      sendImmediate: email.sendImmediate,
      startDate: email.sendImmediate ? moment() : fullSendDate,
      templateTitle: email.templateTitle,
      title: email.title,
      themeId: email.themeId,
      type: email.type,
      recruitmentModuleEnabled: email.recruitmentModuleEnabled,
      recruitmentModuleLink: email.recruitmentModuleLink,
      dynamicFlag,
      createdAt: moment(email.createdAt),
      mgCampaignJobId: email.mgCampaignJobId,
      showCtaButton: email.showCtaButton,
      ctaButtonDesktopImage: email.ctaButtonDesktopImage,
      ctaButtonLink:email.ctaButtonLink,
      ctaButtonMobileImage: email.ctaButtonMobileImage,
      selectedFilters,
      selectedSavedGroups
    };
  });

export const getDraftPayload = (shouldSendId) => {
  return createSelector(
    getSelectedEmail, getEngageDisplayCampaignName, getSelectedLocation, getGroupSendOption, getResponsiveOptionFlag,
    getEngageType, getEngageAppliedFiltersWithIndividualsAndDate, getSelectedEngageSavedFilters,
    (draft, campaignName, location, dynamicFlag, isResponsiveOption, type, selectedFilters, selectedSavedGroups) => {
      let fullSendDate = draft.sendDate;
      let startDateString;
      let isDynamicFlag = isResponsiveOption ? dynamicFlag : null;
      if (draft.sendDate !== undefined) {
        startDateString = draft.sendDate.format('YYYY-MM-DD');
      }
      if (!draft.sendImmediate) {
        let fullSendDateString = `${fullSendDate.format('MM/DD/YYYY')} ${draft.sendTime}`;
        fullSendDate = moment(fullSendDateString, 'MM/DD/YYYY h:mm a');
      }
      let id = (!shouldSendId || type !== EngageConstants.draft) ? null : draft.id;

      // return null for id for initial draft save so that it doesn't override
      return {
        ...draft,
        id,
        campaignName,
        description: draft.emailCopy || draft.defaultText,
        headerImage: draft.headerImage || draft.image,
        locationNumber: location.locationNumber,
        mobileHeaderImage: draft.mobileHeaderImage || draft.headerImage || draft.imageUrl2,
        name: draft.subject,
        startDate: draft.sendImmediate ? moment() : fullSendDate,
        startDateString: draft.sendImmediate ? moment().format('YYYY-MM-DD') : startDateString,
        templateTitle: draft.templateName,
        draftId: draft.id,
        type: EngageConstants.email,
        dynamicFlag: isDynamicFlag,
        recruitmentModuleEnabled: draft.recruitmentModuleEnabled,
        recruitmentModuleLink: draft.recruitmentModuleLink,
        selectedFilters,
        selectedSavedGroups
      };
    });
};

export const getUpdateSavedGroupPayload = createSelector(
  getMeetFiltersStartDate, getMeetFiltersEndDate, getMeetFiltersOnBetweenId, getMeetSavedGroupBeingEdited, getTotalCountOfCustomers,
  getFormattedFiltersWithDateAndIndividuals(AppConstants.meetPage),
  (startDate, endDate, onBetweenId, savedGroup, customerCount, selectedFilters) => {
    return {
      customerCount,
      selectedFilters,
      startDate,
      endDate,
      onBetweenId,
      id: savedGroup.id,
      name: savedGroup.name
    };
  });

export const getEngagementUpdated = createSelector(getEngageState, fromEngage.getUpdated);

export const getOverlappingEngagements = createSelector(getEngageState, fromEngage.getOverlappingEngagements);

export const getEngagementCalendarPayload = createSelector(getEngagementStartDate, getEngagementEndDate,
  (start, end) => {
    return {
      campaignTypes:  ['rewards', 'events',
        'bonusPointPromotions', 'communityCareEvent',
        'free-item-in-cart-promotion', 'third-party-promotion',
        'nationalThirdPartyPromotion'],
      fromDate: start.format('YYYY-MM-DD'),
      toDate: end.format('YYYY-MM-DD')
    };
  });

export const getEngagementStartAndEndDate = createSelector(getEngagementStartDate, getEngagementEndDate,
  (start, end) => {
    return {
      startDate: start,
      endDate: end
    };
  });

export const getEngageHtml = createSelector(getEngageState, getSelectedEngagement, getScreenSize,
  (eng: fromEngage.State, engagement, screenSize) => {
    let merge;
    const isMobile = screenSize === AppConstants.extraSmall;
    const html = eng.previewHtml;
    const heroImage = isMobile ? engagement.mobileImageUrl : (engagement.imageUrl || engagement.mobileImageUrl);
    // Only replace html if engagement has been selected and base html exists
    if ((engagement.id || engagement.treatId) && html) {
      merge = {
        breakfast: engagement.breakfast,
        breakfastDisclaimerText: engagement.breakfastDisclaimerText || '',
        ctaButtonDesktopImage: engagement.ctaButtonDesktopImage,
        ctaButtonLink: engagement.ctaButtonLink,
        ctaButtonMobileImage: engagement.ctaButtonMobileImage,
        description: engagement.emailCopy || engagement.offerDetails,
        endDate: moment(engagement.endDate),
        endTime: engagement.endTime,
        headerImage: engagement.headerImage || engagement.image || engagement.imageUrl2,
        headline: engagement.emailheadline || engagement.subject,
        imageUrl: heroImage || engagement.imageUrl || engagement.offerImage,
        limitedReservations: engagement.limitedReservations,
        limitedTimeOffer: engagement.limitedtimeoffer,
        mobileHeaderImage: engagement.mobileHeaderImage || engagement.mobileHeaderImageUrl,
        mobileImageUrl:heroImage || engagement.imageUrl || engagement.offerImage,
        name: engagement.offerName,
        recruitmentModuleEnabled: engagement.recruitmentModuleEnabled,
        rsvpRequired: engagement.rsvpRequired,
        showCountdownTimer: engagement.showCountdownTimer,
        showCtaButton: engagement.showCtaButton,
        startDate: moment(engagement.startDate),
        startTime: engagement.startTime,
        subTitle: engagement.subTitle,
        title: engagement.title || '',
        validDetails: engagement.details || engagement.validDetails,
        venue: engagement.venue
      };
      return replaceMGHtml(merge, html);
    } else {
      return '';
    }
  });

export const getRsvpHtml = createSelector(getEngageState, getSelectedEngagement,
  (eng: fromEngage.State, engagement) => {
    const html = eng.rsvpHtml;
    const merge = {
      startDate: moment(engagement.startDate),
      startTime: engagement.startTime,
      endDate: moment(engagement.endDate),
      endTime: engagement.endTime,
      title: engagement.title || '',
      description: engagement.emailCopy || engagement.offerDetails,
      name: engagement.offerName,
      validDetails: engagement.details || engagement.validDetails,
      imageUrl: engagement.imageURL || engagement.imageUrl || engagement.offerImage,
      breakfastDisclaimerText: engagement.breakfastDisclaimerText || '',
      mobileHeaderImage: engagement.mobileHeaderImage || engagement.mobileHeaderImageUrl,
      headerImage: engagement.headerImage || engagement.image || engagement.imageUrl2,
      headline: engagement.emailheadline || engagement.subject,
      rsvpRequired: engagement.rsvpRequired,
      limitedReservations: engagement.limitedReservations,
      limitedTimeOffer: engagement.limitedtimeoffer,
      breakfast: engagement.breakfast,
      venue: engagement.venue
    };
    return replaceMGHtml(merge, html);
  });

export const getNoRsvpHtml = createSelector(getEngageState, getSelectedEngagement,
  (eng: fromEngage.State, engagement) => {
    const html = eng.noRsvpHtml;
    const merge = {
      startDate: moment(engagement.startDate),
      startTime: engagement.startTime,
      endDate: moment(engagement.endDate),
      endTime: engagement.endTime,
      title: engagement.title || '',
      description: engagement.emailCopy || engagement.offerDetails,
      name: engagement.offerName,
      validDetails: engagement.details || engagement.validDetails,
      imageUrl: engagement.imageURL || engagement.imageUrl || engagement.offerImage,
      breakfastDisclaimerText: engagement.breakfastDisclaimerText || '',
      mobileHeaderImage: engagement.mobileHeaderImage || engagement.mobileHeaderImageUrl,
      headerImage: engagement.headerImage || engagement.image || engagement.imageUrl2,
      headline: engagement.emailheadline || engagement.subject,
      rsvpRequired: engagement.rsvpRequired,
      limitedReservations: engagement.limitedReservations,
      limitedTimeOffer: engagement.limitedtimeoffer,
      breakfast: engagement.breakfast,
      venue: engagement.venue
    };
    return replaceMGHtml(merge, html);
  });

export const getCategoryTreatsById = (id) => {
  return createSelector(getEngageRewards, (treats) => {
    if (id === EngageConstants.limitedTimeOffer ) {
      return treats.ltoTemplates;
    } else {
      const treatsByCategory = R.groupBy(R.prop('category'), treats.templates);
      return treatsByCategory[id];
    }
  });
};

export const getCopyTitle = createSelector(getEngageState, (eng: fromEngage.State) => {
  let type = eng.type === EngageConstants.offer ? 'reward' : eng.type;
  return type + ' message';
});

export const getEngageFilterDisplayCustomers = createSelector(getEngageState, fromEngage.getFilterDisplayCustomers);

export const getEngageFilterDisplayCustomerLoading = createSelector(getEngageState, fromEngage.getFilterDisplayCustomerLoading);

export const getEngageFilterDisplayCustomerError = createSelector(getEngageState, fromEngage.getFilterDisplayCustomerError);

export const getEngageFilterDisplayCustomerSort = createSelector(getEngageState, fromEngage.getFilterDisplayCustomerSort);

export const getEngageFilterDisplayCustomerSortOrder = createSelector(getEngageState, fromEngage.getFilterDisplayCustomerSortOrder);

export const getEngageFilterDisplayCustomerSortObj = createSelector(
  getEngageFilterDisplayCustomerSort, getEngageFilterDisplayCustomerSortOrder, (prop, order) => {
    return ({ prop, order });
  });

export const getEngageFilterDisplayCustomerLimit = createSelector(getEngageState, fromEngage.getFilterDisplayCustomerLimit);

export const getEngageFilterDisplayCustomerPage = createSelector(getEngageState, fromEngage.getFilterDisplayCustomerPage);

export const getEngageFilterDisplayCustomerNumberOfPages = createSelector(getEngageState, fromEngage.getFilterDisplayCustomerNumberOfPages);

export const getEngageFilterDisplayCustomerAllCustomerIds =
  createSelector(getEngageState, fromEngage.getFilterDisplayCustomerAllCustomerIds);

export const getEngageFilterDisplayCustomerCount = createSelector(getEngageState, fromEngage.getFilterDisplayCustomerCount);

export const getEngageSelectedCustomerLoading = createSelector(getEngageState, fromEngage.getSelectedCustomerLoading);

export const getEngageSendLoading = createSelector(getEngageState, fromEngage.getSendLoading);

export const getEngageStepperProgress = createSelector(getEngageState, fromEngage.getStepperProgress);

export const getEngageSendError = createSelector(getEngageState, fromEngage.getSendError);

export const shouldGetAllCustomers = createSelector(getEngageState, fromEngage.shouldGetAllCustomers);

export const getEngageIsCompleted = createSelector(getEngageState, getEngageStepperProgress,
  (engageState, sendProgress) => {
    let count = 0;
    let errorCount = 0;
    if (sendProgress) {
      sendProgress.map(menuItem => {
        if (menuItem.isCompleted === true) {
          count = count + 1;
        }
        if (menuItem.error) {
          errorCount = errorCount + 1;
        }
      });
    }
    return count < 3 && errorCount === 0;
  });

export const getFlaggedEngageAllSelectedCustomerIds = createSelector(getEngageAllSelectedCustomerIds,
  (allSelectedIds) => {
    return allSelectedIds;
  });

export const getEngageTotalSelectedCustomers = createSelector(getEngageAllSelectedCustomerIds,
  (allSelectedCustomers) => allSelectedCustomers.length);

export const getEngageAllAppliedFilters = createSelector(
  getSelectedEngageDefaultFilters,
  getSelectedEngageSavedFilters,
  getEngageAppliedIncludedCustomers,
  getEngageAppliedExcludedCustomers,
  getEngageAppliedFilterOnBetweenId,
  getEngageAppliedFilterStartDate,
  getEngageAppliedFilterEndDate,
  (selectedFilters, selectedSavedGroups, includedCustomers,
    excludedCustomers, onBetweenId, startDate, endDate) => {
    return { selectedFilters, selectedSavedGroups, includedCustomers,
      excludedCustomers, onBetweenId, startDate, endDate };
  });

/**
 * Calendar state root selector
 */
export const getCalendarState = (state) => state.calendar;

/**
 * Calendar state children selectors
 */
export const getCalendarEvents = createSelector(getCalendarState, fromCalendar.getCalendarEvents);

export const getCalendarLoading = createSelector(getCalendarState, fromCalendar.getLoading);

export const getCalendarError = createSelector(getCalendarState, fromCalendar.getError);

export const getCalendarEventDetails = createSelector(
  getCalendarState, fromCalendar.getCalendarEventDetails);

export const getCampaignsToggleActive = createSelector(
  getCalendarState, fromCalendar.getCampaignToggleActive);

export const getRewardToggleActive = createSelector(
  getCalendarState, fromCalendar.getRewardToggleActive);

export const getRewardAutomationToggleActive = createSelector(
  getCalendarState, fromCalendar.getRewardAutomationToggleActive);

export const getRewardOneRecipientToggleActive = createSelector(
  getCalendarState, fromCalendar.getRewardOneRecipientToggleActive);

export const getEventToggleActive = createSelector(
  getCalendarState, fromCalendar.getEventToggleActive);

export const getEmailToggleActive = createSelector(
  getCalendarState, fromCalendar.getEmailToggleActive);

export const getNationalEmailToggleActive = createSelector(
  getCalendarState, fromCalendar.getNationalEmailToggleActive);

export const getIncentivizeOrderToggleActive = createSelector(
  getCalendarState, fromCalendar.getIncentivizeOrderToggleActive);

export const getCommunityCareToggleActive = createSelector(
  getCalendarState, fromCalendar.getCommunityCareToggleActive);

export const getThirdPartyPromotionToggleActive = createSelector(
  getCalendarState, fromCalendar.getThirdPartyPromotionToggleActive);

export const getNationalThirdPartyPromotionToggleActive = createSelector(
  getCalendarState, fromCalendar.getNationalThirdPartyPromotionToggleActive);

export const getFreeItemInCartPromotionToggleActive = createSelector(
  getCalendarState, fromCalendar.getFreeItemInCartPromotionToggleActive);

export const getCurrentDate = createSelector(
  getCalendarState, fromCalendar.getCurrentDate);

export const getCurrentView = createSelector(
  getCalendarState, fromCalendar.getCurrentView);

export const getEarliestShownDate = createSelector(
  getCalendarState, fromCalendar.getEarliestShownDate);

export const getLatestShownDate = createSelector(
  getCalendarState, fromCalendar.getLatestShownDate);

export const getCalendarSearchTerm = createSelector(
  getCalendarState, fromCalendar.getSearchObj);

export const getAutoCompleteSelectedCustomer = createSelector(
  getMeetState, fromMeet.getAutoCompleteSelectedCustomer);

export const getSelectedCategoryTitle = createSelector(
  getCalendarSearchTerm, (searchObject) => {
    return R.find(R.propEq('value', searchObject.searchBy))(CalendarConstants.searchByCategories).title;
  }
);

export const getCaresSelectedCategoryTitle = createSelector(
  getCareSearchBy, (searchBy) => {
    return R.find(R.propEq('value', searchBy))(CareConstants.searchByCategories).title;
  }
)

export const getCalendarEventsPayload = createSelector(
  getEarliestShownDate, getLatestShownDate, getCalendarSearchTerm,
  getRewardAutomationToggleActive, getRewardOneRecipientToggleActive, getAutoCompleteSelectedCustomer,
  (start, end, calSearchObj, includeAutomationCampaigns,
    includeSingleRecipientRewards, autoCompleteSelectedCustomer) => {
    let searchTerm = (calSearchObj && calSearchObj.searchTerm) ? calSearchObj.searchTerm : null;
    if (calSearchObj && calSearchObj.searchBy === CalendarConstants.searchByCategories[0].value) {
      if (autoCompleteSelectedCustomer) {
        searchTerm = autoCompleteSelectedCustomer.id;
      } else {
        // This is when user do not select any customer, just search with term and navigates back to calendar screen.
        searchTerm = null;
      }
    }
    let campaignTypes = ['rewards', 'emails', 'events','holidaysAndCampaigns', 'bonusPointPromotions', 'communityCareEvent',
      'third-party-promotion', 'nationalThirdPartyPromotion', 'free-item-in-cart-promotion'];
    return {
      fromDate: start.format('YYYY-MM-DD'),
      toDate: end.format('YYYY-MM-DD'),
      searchBy: searchTerm ? calSearchObj.searchBy : null,
      searchTerm: searchTerm,
      includeAutomationCampaigns,
      campaignTypes,
      includeSingleRecipientRewards
    };
    // NOTE: for searchBy: if condition here is to check if user has search term entered or else no need to send searchBy too!
  });

export const getCurrentCFACampaigns = createSelector(getCalendarEvents, (events) => {
  let displayCampaigns = [];
  let isCampaign = (event) => (event.type === 'campaign' && event.startDateString);
  let allCampaigns = R.filter(isCampaign, events);
  let sortedCampaignsByStart = R.sortBy(R.prop('startDate'))(allCampaigns);

  // Combine primary/secondary campaigns by if they have the same startDate
  let campaignGroups = R.groupWith((a, b) => a.startDate === b.startDate, sortedCampaignsByStart);
  displayCampaigns = campaignGroups.map((campaignGroup) => {
    let sortedCampaignGroup = R.sortBy(R.prop('campaignType'))(campaignGroup);
    let startDate = moment(sortedCampaignGroup[0].startDateString, 'MM-DD-YYYY');
    let endDate = moment(sortedCampaignGroup[0].endDateString, 'MM-DD-YYYY');
    return {
      title: sortedCampaignGroup[0].title,
      displayStart: startDate.format('MMM DD'),
      displayEnd: endDate.format('MMM DD, YYYY'),
      startDate,
      endDate,
      subCampaigns: sortedCampaignGroup
    };
  });

  return displayCampaigns;
});

export const getMappedCalendarEvents = createSelector(getCalendarEvents, (events) => {
  // Map events to match ICalendarEvent format from the cfa-calendar component
  return events.map((event) => {
    let type = event.type;
    let calendarId = type === CalendarConstants.discover ? CalendarConstants.offer : type;
    let startDate = moment(event.startDate);
    let endDate = moment(event.endDate);
    // Make it an all day event for emails and rewards so that it appears correctly for day and week view
    let displayAsAllDay = false;
    if (type === CalendarConstants.email || type === CalendarConstants.offer
      || type === CalendarConstants.nationalEmail || type === CalendarConstants.discover
      || type == CalendarConstants.thirdPartyPromotion
      || type == CalendarConstants.freeItemInCartPromotion || type == CalendarConstants.bonusPoint ) {
      displayAsAllDay = true;
    }
    if ((type == CalendarConstants.bonusPoint || type == CalendarConstants.freeItemInCartPromotion) && startDate
      .isSame(endDate, 'day') && event.startTime && event.endTime) {
      displayAsAllDay = false
      startDate = moment(startDate.format('DD/MM/YYYY') + ' ' + event.startTime, 'DD/MM/YYYY h:mm a');
      endDate = moment(endDate.format('DD/MM/YYYY') + ' ' + event.endTime, 'DD/MM/YYYY h:mm a');
    }
    if ((type == CalendarConstants.thirdPartyPromotion || type == CalendarConstants.freeItemInCartPromotion) && event.stopDate) {
      endDate = moment(event.stopDate);
    }
    return {
      ...event,
      calendarId,
      startDate,
      endDate,
      displayAsAllDay
    };
  });
});

export const getCalendarFeatureFlags= createSelector(getFeatureEnabled(CalendarConstants.bonusPointPromotionsFlag),
  getFeatureEnabled(DiscoverConstants.communityCareEventsFlag),
  getThirdPartyEnabled(), getFeatureEnabled(DiscoverConstants.freeItemInCartPromotionFlag),
  (bonusPointFlag, communityCareFlag, thirdPartyFlag, freeItemFlag) =>{
    return { bonusPointFlag: bonusPointFlag,
      communityCareFlag: communityCareFlag,thirdPartyFlag: thirdPartyFlag, freeItemFlag: freeItemFlag };
  })

export const getCalendarTogglesActive = createSelector(getIncentivizeOrderToggleActive,
  getCommunityCareToggleActive,
  getThirdPartyPromotionToggleActive,
  getNationalThirdPartyPromotionToggleActive,
  getFreeItemInCartPromotionToggleActive,
  (incentivizeOrderToggleActive, communityCareToggleActive, thirdPartyPromotionToggleActive,
    nationalThirdPartyPromotionToggleActive, freeItemInCartPromotionActive) => {
    return { incentivizeOrderToggleActive: incentivizeOrderToggleActive,
      communityCareToggleActive: communityCareToggleActive,
      thirdPartyPromotionToggleActive: thirdPartyPromotionToggleActive,
      nationalThirdPartyPromotionToggleActive : nationalThirdPartyPromotionToggleActive,
      freeItemInCartPromotionActive: freeItemInCartPromotionActive };
  })

export const getCalendarCategories = createSelector(getCalendarEvents, getRewardToggleActive,
  getEventToggleActive, getEmailToggleActive,
  getNationalEmailToggleActive, getRewardAutomationToggleActive,
  getRewardOneRecipientToggleActive, getCalendarFeatureFlags, getCalendarTogglesActive,
  (events, rewardToggleActive, eventToggleActive, emailToggleActive, nationalEmailToggleActive,
    automationToggleActive, oneRecipientActive, calendarFeatureFlags, calendarTogglesActive) => {

    let isReoccurringNationalEmail = (event) => event.type === 'nationalEmail';
    let allNationalEmails = R.filter(isReoccurringNationalEmail, events);
    let description = '';
    // Only show first 2
    allNationalEmails = allNationalEmails.slice(0, 2);
    if (allNationalEmails.length > 0) {
      description = '<div class="calendar-subtext">Dates are subject to change</div>';
    }

    let rewardSubToggles = CalendarConstants.rewardSubToggles;
    let automationToggleIndex = R.findIndex(R.propEq('categoryId', CalendarConstants.automationId))(rewardSubToggles);
    let oneRecipientToggleIndex = R.findIndex(R.propEq('categoryId', CalendarConstants.singleRecipientId))(rewardSubToggles);
    rewardSubToggles[automationToggleIndex].active = automationToggleActive;
    rewardSubToggles[oneRecipientToggleIndex].active = oneRecipientActive;

    let calendarCategories = [
      {
        active: rewardToggleActive,
        calendarId: 'offer',
        name: 'Rewards',
        color: '#3EB1C8',
        categories: rewardSubToggles
      },
      { active: eventToggleActive, calendarId: 'event', name: 'Events', color: '#FA9370' },
      { active: emailToggleActive, calendarId: 'email', name: 'Emails', color: '#994878' },
      {
        active: nationalEmailToggleActive,
        calendarId: 'nationalEmail',
        name: 'National Email',
        color: '#249E6B',
        description
      }
    ];

    if (calendarFeatureFlags.freeItemFlag) {
      calendarCategories.push(
        {
          active: calendarTogglesActive.freeItemInCartPromotionActive,
          calendarId: 'free-item-in-cart-promotion',
          name: 'Grow Owned Ordering',
          color: '#5C3454'
        });
    }

    if (calendarFeatureFlags.bonusPointFlag) {
      calendarCategories.push(
        {
          active: calendarTogglesActive.incentivizeOrderToggleActive,
          calendarId: 'bonus-point',
          name: 'Incentivize Order',
          color: '#DD0031'
        });
    }

    if (calendarFeatureFlags.communityCareFlag) {
      calendarCategories.push(
        {
          active: calendarTogglesActive.communityCareToggleActive,
          calendarId: 'communityCareEvent',
          name: 'Community Care Events',
          color: '#84001D'
        }
      );
    }

    if (calendarFeatureFlags.thirdPartyFlag) {
      calendarCategories.push(
        {
          active: calendarTogglesActive.thirdPartyPromotionToggleActive,
          calendarId: 'third-party-promotion',
          name: 'Local Third Party Promotions',
          color: '#00635B'
        }
      );
      calendarCategories.push(
        {
          active: calendarTogglesActive.nationalThirdPartyPromotionToggleActive,
          calendarId: 'nationalThirdPartyPromotion',
          name: 'National Third Party Promotions',
          color: '#249E6B'
        }
      );
    }

    return calendarCategories;
  });

export const getCalendarEventResendTreat = (ids) => {
  return createSelector(getCalendarEventDetails, (details) => {
    const treat = details.treats[0];

    // filter out recipients who are not in the resend id list
    let alreadyReceivedTreat = (recipient) => R.contains(recipient.customerUid, ids);
    let recipients = R.filter(alreadyReceivedTreat, treat.recipients);

    return { ...treat, recipients, resentSameTreat: true, earlierBatchId: treat.batchId };
  });
};

export const getCalendarDetailsDateRange = createSelector(getCalendarEventDetails, (details) => {
  if (details) {
    const treat = details.treats[0];
    const start = moment(treat.startDate).format('ddd, MMM DD');
    const end = moment(treat.endDate).format('ddd, MMM DD');
    return `${start} - ${end}`;
  }
});

export const getAutomatedCampaignDuration = createSelector(getCalendarEventDetails, (details) => {
  if (details) {
    const duration = details.treatValidityDays;
    return duration + ' day reward duration';
  }
});

export const getAutomatedCampaign = createSelector(getCalendarEventDetails, (details) => {
  if (details) {
    return details.automatedCampaign;
  }
});

export const getCampaignTimeSpan = createSelector(getAutomatedCampaign, getAutomatedCampaignDuration, getCalendarDetailsDateRange,
  (automatedCampaign, automatedCampaignDuration, calendarDateRange) => {
    return automatedCampaign ? automatedCampaignDuration : calendarDateRange;
  });

export const getCalendarDetailsSummary = createSelector(getCalendarEventDetails, (details) => {
  if (details) {
    let treatString = details.treats.length > 1 ? 'Rewards' : 'Reward';
    let customerString = details.totalCustomers > 1 ? 'Customers' : 'Customer';

    return `${details.treats.length} ${treatString} / ${details.totalCustomers} ${customerString}`;
  }
});

/**
 * Notifications state root selector
 */
export const getNotificationsState = (state) => state.notifications;

/**
 * Notifications state children selectors
 */
export const getAllNotifications = createSelector(getNotificationsState, fromNotifications.getNotifications);

export const getNotificationsByCategory = createSelector(getAllNotifications, (notifications) => {
  const types = NotificationListConstants.notificationTypes;

  const grouped = R.groupBy(R.prop('type'), notifications);
  return types.map((type) => {
    const name = type.name;
    return {
      ...type,
      notifications: grouped[name] || []
    };
  });
});

export const isNotificationsEmpty = createSelector(getNotificationsByCategory,
  (notificationCategories) => {
    let isEmpty = true;
    notificationCategories.forEach((categories) => {
      if (categories.notifications.length > 0) {
        isEmpty = false;
      }
    });
    return isEmpty;
  });
export const getNotificationUnreadCount = createSelector(
  getNotificationsState, fromNotifications.getUnreadCount);

export const getNotificationLoading = createSelector(
  getNotificationsState, fromNotifications.getLoading);

export const getNotificationError = createSelector(
  getNotificationsState, fromNotifications.getError);

/**
 * Error state root selector
 */
export const getErrorState = (state) => state.error;

/**
 * Error state children selectors
 */
export const getErrorPages = createSelector(
  getErrorState, fromError.getErrorPages);

/**
 * Settings state root selector
 */
export const getSettingsState = (state) => state.settings;

/**
 * Settings state children selectors
 */
export const getFutureEvents = createSelector(
  getSettingsState, fromSettings.getFutureEvents);

export const getUpdatedFutureEvents = createSelector(
  getSettingsState, fromSettings.getUpdatedFutureEvents);

export const getFutureEventsLoading = createSelector(
  getSettingsState, fromSettings.getFutureEventsLoading);

export const getFutureEventsError = createSelector(
  getSettingsState, fromSettings.getFutureEventsError);

export const getSuccessMessage = createSelector(
  getSettingsState, fromSettings.getSuccessMessage);

export const getFutureEventsEdited = createSelector(
  getFutureEvents,
  getUpdatedFutureEvents,
  (baseFutureEvents, updatedFutureEvents) => {
    return !R.equals(baseFutureEvents, updatedFutureEvents);
  });

/**
 * Manage Attendees state root selector
 */
export const getManageAttendeesState = (state) => state.manageAttendees;

/**
 * Manage Attendees state children selectors
 */
export const getInvitedCustomers = createSelector(
  getManageAttendeesState, fromManageAttendees.getInvitedCustomers);

export const getWalkInCustomers = createSelector(
  getManageAttendeesState, fromManageAttendees.getWalkInCustomers);

export const getAttendeesDataLoading = createSelector(
  getManageAttendeesState, fromManageAttendees.getAttendeesLoading);

export const getAttendeesIsUpdated = createSelector(
  getManageAttendeesState, fromManageAttendees.getIsUpdated);

export const getManageAttendeesEventLoading = createSelector(
  getManageAttendeesState, fromManageAttendees.getEventLoading);

export const getManageAttendeesError = createSelector(
  getManageAttendeesState, fromManageAttendees.getError);

export const getAcceptedCustomers = createSelector(
  getInvitedCustomers, (invited: any) => invited ? invited.accepted : []);

export const getDeclinedCustomers = createSelector(
  getInvitedCustomers, (invited: any) => invited ? invited.declined : []);

export const getNoResponseCustomers = createSelector(
  getInvitedCustomers, (invited: any) => invited ? invited.noResponse : []);

export const getWalkInSpotlightCustomers = createSelector(
  getWalkInCustomers, (walkIns: any) => walkIns ? walkIns.inMySpotlight : []);

export const getWalkInNotSpotlightCustomers = createSelector(
  getWalkInCustomers, (walkIns: any) => {
    return walkIns ? walkIns.notInMySpotlight[0] : {};
  });

export const getManageAttendeesLoading = createSelector(
  getAttendeesDataLoading,
  getManageAttendeesEventLoading,
  (attendeesLoading, eventLoading) => {
    return attendeesLoading || eventLoading;
  });

export const getFormattedAcceptedAttendees = createSelector(
  getAcceptedCustomers,
  getSelectedEngagement,
  (selectedAcceptedInvitees, engagement) => {
    let allAcceptedInvitees = engagement.acceptedRecipients;
    let selectedInvitees = selectedAcceptedInvitees ? selectedAcceptedInvitees : [];
    let formattedAcceptedAttendees = [];

    if (allAcceptedInvitees) {
      formattedAcceptedAttendees = allAcceptedInvitees.map((acceptedInvitee) => {
        let attendee = R.find(R.propEq('id', acceptedInvitee.id))(selectedInvitees);
        return { ...acceptedInvitee, ...attendee };
      });
    }
    return formattedAcceptedAttendees;
  });

export const getFormattedDeclinedAttendees = createSelector(
  getDeclinedCustomers,
  getSelectedEngagement,
  (selectedDeclinedInvitees, engagement) => {
    let allDeclinedInvitees = engagement.declinedRecipients;
    let selectedInvitees = selectedDeclinedInvitees ? selectedDeclinedInvitees : [];
    let formattedDeclinedAttendees = [];

    if (allDeclinedInvitees) {
      formattedDeclinedAttendees = allDeclinedInvitees.map((declinedInvitee) => {
        let attendee = R.find(R.propEq('id', declinedInvitee.id))(selectedInvitees);
        return { ...declinedInvitee, ...attendee };
      });
    }
    return formattedDeclinedAttendees;
  });

export const getFormattedNoResponseAttendees = createSelector(
  getNoResponseCustomers,
  getSelectedEngagement,
  (selectedNoResponseInvitees, engagement) => {
    let allNoResponseInvitees = engagement.noResponseRecipients;
    let selectedInvitees = selectedNoResponseInvitees ? selectedNoResponseInvitees : [];
    let formattedNoResponseAttendees = [];

    if (allNoResponseInvitees) {
      formattedNoResponseAttendees = allNoResponseInvitees.map((noResponseInvitee) => {
        let attendee = R.find(R.propEq('id', noResponseInvitee.id))(selectedInvitees);
        return { ...noResponseInvitee, ...attendee };
      });
    }
    return formattedNoResponseAttendees;
  });

export const getAttendeesAutocompleteCustomers = createSelector(
  getAutocompleteCustomers,
  getFormattedAcceptedAttendees,
  getFormattedDeclinedAttendees,
  getFormattedNoResponseAttendees,
  getWalkInSpotlightCustomers,
  (autocompleteCustomers, accepted, declined, noResponse, spotWalkIn) => {
    let addedAccepted = R.pluck('id', accepted);
    let addedDeclined = R.pluck('id', declined);
    let addedNoResponse = R.pluck('id', noResponse);
    let addedSpotlightWalkIn = R.pluck('id', spotWalkIn);

    let addedIds = R.flatten([
      addedAccepted,
      addedDeclined,
      addedNoResponse,
      addedSpotlightWalkIn
    ]);

    let alreadyAdded = (cust) => !R.contains(cust.id, addedIds);
    return R.filter(alreadyAdded, autocompleteCustomers);
  });

export const getTotalAcceptedAttendance = createSelector(
  getFormattedAcceptedAttendees, (acceptedCustomers) => {
    let adultsAttending = 0;
    let kidsAttending = 0;
    if (acceptedCustomers && acceptedCustomers.length > 0) {
      acceptedCustomers.map((customer) => {
        adultsAttending =
          customer.adultsAttending ? adultsAttending + customer.adultsAttending : adultsAttending;
        kidsAttending =
          customer.kidsAttending ? kidsAttending + customer.kidsAttending : kidsAttending;
      });
    }
    return { adultsAttending, kidsAttending };
  });

export const getTotalDeclinedAttendance = createSelector(
  getDeclinedCustomers, (declinedCustomers) => {
    let adultsAttending = 0;
    let kidsAttending = 0;
    if (declinedCustomers && declinedCustomers.length > 0) {
      declinedCustomers.map((customer) => {
        adultsAttending =
          customer.adultsAttending ? adultsAttending + customer.adultsAttending : adultsAttending;
        kidsAttending =
          customer.kidsAttending ? kidsAttending + customer.kidsAttending : kidsAttending;
      });
    }
    return { adultsAttending, kidsAttending };
  });

export const getTotalNoResponseAttendance = createSelector(
  getNoResponseCustomers, (noResponseCustomers) => {
    let adultsAttending = 0;
    let kidsAttending = 0;
    if (noResponseCustomers && noResponseCustomers.length > 0) {
      noResponseCustomers.map((customer) => {
        adultsAttending =
          customer.adultsAttending ? adultsAttending + customer.adultsAttending : adultsAttending;
        kidsAttending =
          customer.kidsAttending ? kidsAttending + customer.kidsAttending : kidsAttending;
      });
    }
    return { adultsAttending, kidsAttending };
  });

export const getTotalSpotWalkInAttendance = createSelector(
  getWalkInSpotlightCustomers, (walkInCustomers) => {
    let adultsAttending = 0;
    let kidsAttending = 0;
    if (walkInCustomers && walkInCustomers.length > 0) {
      walkInCustomers.map((customer) => {
        adultsAttending =
          customer.adultsAttending ? adultsAttending + customer.adultsAttending : adultsAttending;
        kidsAttending =
          customer.kidsAttending ? kidsAttending + customer.kidsAttending : kidsAttending;
      });
    }
    return { adultsAttending, kidsAttending };
  });

export const getTotalWalkInAttendees = createSelector(
  getTotalSpotWalkInAttendance,
  getWalkInNotSpotlightCustomers,
  (totalSpotWalkIn, nonSpotWalkIn) => {
    return totalSpotWalkIn.adultsAttending
      + totalSpotWalkIn.kidsAttending
      + nonSpotWalkIn.adultsAttending
      + nonSpotWalkIn.kidsAttending;
  });

export const getTotalAttendees = createSelector(
  getTotalAcceptedAttendance,
  getTotalDeclinedAttendance,
  getTotalNoResponseAttendance,
  getTotalSpotWalkInAttendance,
  getWalkInNotSpotlightCustomers,
  (totalAccepted, totalDeclined, totalNoResponse, totalSpotWalkIn, nonSpotWalkIn) => {
    return totalAccepted.adultsAttending
      + totalAccepted.kidsAttending
      + totalDeclined.adultsAttending
      + totalDeclined.kidsAttending
      + totalNoResponse.adultsAttending
      + totalNoResponse.kidsAttending
      + totalSpotWalkIn.adultsAttending
      + totalSpotWalkIn.kidsAttending
      + nonSpotWalkIn.adultsAttending
      + nonSpotWalkIn.kidsAttending;
  });

export const getFilteredAcceptedAttendees = createSelector(
  getFormattedAcceptedAttendees, (acceptedAttendees) => {
    return acceptedAttendees.filter((attendee) => {
      return attendee.adultsAttending || attendee.kidsAttending;
    });
  });

export const getFilteredDeclinedAttendees = createSelector(
  getFormattedDeclinedAttendees, (declinedAttendees) => {
    return declinedAttendees.filter((attendee) => {
      return attendee.adultsAttending || attendee.kidsAttending;
    });
  });

export const getFilteredNoResponseAttendees = createSelector(
  getFormattedNoResponseAttendees, (noResponseAttendees) => {
    return noResponseAttendees.filter((attendee) => {
      return attendee.adultsAttending || attendee.kidsAttending;
    });
  });

export const getAllFilteredAttendeeObjects = createSelector(
  getFilteredAcceptedAttendees,
  getFilteredDeclinedAttendees,
  getFilteredNoResponseAttendees,
  getWalkInSpotlightCustomers,
  (acceptedAttendees, declinedAttendees, noResponseAttendees, walkInAttendees) => {
    let accepted = acceptedAttendees.map((attendee) => {
      return { ...attendee, type: 'Invited' };
    });

    let declined = declinedAttendees.map((attendee) => {
      return { ...attendee, type: 'Invited' };
    });

    let noResponse = noResponseAttendees.map((attendee) => {
      return { ...attendee, type: 'Invited' };
    });

    let walkIn = walkInAttendees.map((attendee) => {
      return { ...attendee, type: 'Walk-in' };
    });

    return R.flatten([accepted, declined, noResponse, walkIn]);
  });

export const getFilteredAcceptedAttendance = createSelector(
  getFilteredAcceptedAttendees, (acceptedCustomers) => {
    let adultsAttending = 0;
    let kidsAttending = 0;
    if (acceptedCustomers && acceptedCustomers.length > 0) {
      acceptedCustomers.map((customer) => {
        adultsAttending =
          customer.adultsAttending ? adultsAttending + customer.adultsAttending : adultsAttending;
        kidsAttending =
          customer.kidsAttending ? kidsAttending + customer.kidsAttending : kidsAttending;
      });
    }
    return { adultsAttending, kidsAttending };
  });

export const getFilteredDeclinedAttendance = createSelector(
  getFilteredDeclinedAttendees, (declinedCustomers) => {
    let adultsAttending = 0;
    let kidsAttending = 0;
    if (declinedCustomers && declinedCustomers.length > 0) {
      declinedCustomers.map((customer) => {
        adultsAttending =
          customer.adultsAttending ? adultsAttending + customer.adultsAttending : adultsAttending;
        kidsAttending =
          customer.kidsAttending ? kidsAttending + customer.kidsAttending : kidsAttending;
      });
    }
    return { adultsAttending, kidsAttending };
  });

export const getFilteredNoResponseAttendance = createSelector(
  getFilteredNoResponseAttendees, (noResponseCustomers) => {
    let adultsAttending = 0;
    let kidsAttending = 0;
    if (noResponseCustomers && noResponseCustomers.length > 0) {
      noResponseCustomers.map((customer) => {
        adultsAttending =
          customer.adultsAttending ? adultsAttending + customer.adultsAttending : adultsAttending;
        kidsAttending =
          customer.kidsAttending ? kidsAttending + customer.kidsAttending : kidsAttending;
      });
    }
    return { adultsAttending, kidsAttending };
  });

export const getFilteredInvitedAttendance = createSelector(
  getFilteredAcceptedAttendance,
  getFilteredDeclinedAttendance,
  getFilteredNoResponseAttendance,
  (totalAccepted, totalDeclined, totalNoResponse) => {
    return totalAccepted.adultsAttending
      + totalAccepted.kidsAttending
      + totalDeclined.adultsAttending
      + totalDeclined.kidsAttending
      + totalNoResponse.adultsAttending
      + totalNoResponse.kidsAttending;
  });

export const getManageAttendeesUpdatePayload = (id) => {
  return createSelector(getTotalAttendees, getInvitedCustomers, getWalkInCustomers,
    (totalAttendees, invitedCustomers, walkInCustomers) => {
      return {
        id,
        totalAttendees,
        invitedCustomers,
        walkInCustomers
      };
    });
};

export const getFilteredRecipients = createSelector(
  getRecipientsSearchTerm,
  getSelectedEngagement,
  getEngageType,
  getEngageRsvpRequired,
  (recipientsSearchTerm, selectedEngagement, type, rsvpRequired) => {
    if (type === EngageConstants.offer || type === EngageConstants.discover || type === EngageConstants.communityCare) {
      let filterRecipients;
      if (selectedEngagement.recipients) {
        filterRecipients = selectedEngagement.recipients.filter((customer) => {
          let customerFullName = '';
          if (customer.customerName && customer.customerName.displayName) {
            customerFullName = customer.customerName.displayName.toUpperCase();
          } else if (customer.firstName && customer.lastName) {
            customerFullName = (customer.firstName + ' ' + customer.lastName).toUpperCase();
          }
          return customerFullName.includes(recipientsSearchTerm.toUpperCase());
        });
      } else {
        filterRecipients = [];
      }

      return filterRecipients;
    } else if (type === EngageConstants.email) {
      let filterEmailRecipients = {
        'openedEmailCustomers': [],
        'unOpenedEmailCustomers': []
      };
      filterEmailRecipients.openedEmailCustomers = selectedEngagement.openedEmailCustomers.filter((customer) => {
        return getMatchingCustomer(customer, recipientsSearchTerm);
      });
      filterEmailRecipients.unOpenedEmailCustomers = selectedEngagement.unOpenedEmailCustomers.filter((customer) => {
        return getMatchingCustomer(customer, recipientsSearchTerm);
      });

      return filterEmailRecipients;
    } else if (type === EngageConstants.event) {
      let filterEventRecipients = {
        'acceptedRecipients': [],
        'declinedRecipients': [],
        'noResponseRecipients': []
      };
      if (rsvpRequired) {
        filterEventRecipients.acceptedRecipients = selectedEngagement.acceptedRecipients.filter((customer) => {
          return getMatchingCustomer(customer, recipientsSearchTerm);
        });
        filterEventRecipients.declinedRecipients = selectedEngagement.declinedRecipients.filter((customer) => {
          return getMatchingCustomer(customer, recipientsSearchTerm);
        });
      }
      filterEventRecipients.noResponseRecipients = selectedEngagement.noResponseRecipients.filter((customer) => {
        return getMatchingCustomer(customer, recipientsSearchTerm);
      });

      return filterEventRecipients;
    }
  });

export const getFilteredRecipientsForCommunityCareEvent = createSelector(
  getRecipientsSearchTerm, getSelectedEngagement, (recipientsSearchTerm, selectedEngagement) => {
    let filteredRecipients = [];
    selectedEngagement.organizations.forEach((organization, index) => {
      if (organization.recipients) {
        filteredRecipients[index] = organization.recipients.filter((customer) => {
          let customerFullName = '';
          if (customer.customerName && customer.customerName.displayName) {
            customerFullName = customer.customerName.displayName.toUpperCase();
          } else if (customer.firstName && customer.lastName) {
            customerFullName = (customer.firstName + ' ' + customer.lastName).toUpperCase();
          }
          return customerFullName.includes(recipientsSearchTerm.toUpperCase());
        });
      } else {
        filteredRecipients[index] = [];
      }
    });

    return filteredRecipients;
  });

export const isFutureEmailOrEventSend = createSelector(getSelectedEngagement, (selectedEngagement) => {
  return !(selectedEngagement.sendImmediate) && (moment(selectedEngagement.sendDate).isAfter(moment()));
});

export const getCustomerTabTitle = createSelector(getFilteredRecipients, isFutureEmailOrEventSend, getEngageType,
  (filteredRecipients, scheduledEventOrEmail, type) => {
    let count, title;
    if (type === EngageConstants.event) {
      count = filteredRecipients.noResponseRecipients.length;
      title = 'No Response';
    } else if (type === EngageConstants.email) {
      count = filteredRecipients.unOpenedEmailCustomers.length;
      title = 'Unopened Emails';
    }
    if (scheduledEventOrEmail) {
      return 'Scheduled Recipients (' + count + ')';
    } else {
      return title + ' (' + count + ')';
    }
  });

export const getEngageSendLoadingMessage = createSelector(getEngageType, getEngageMode,
  getEngageSendImmediate, (type, mode, sendImmediateBool) => {
    let sendImmediate = sendImmediateBool;
    let typeValue = type;
    let sendImmediateValue = 'created';

    if (type === EngageConstants.offer) {
      sendImmediate = true;
      typeValue = 'reward';
    }

    if (type === EngageConstants.draft) {
      typeValue = EngageConstants.email;
    }

    if (mode === EngageConstants.editMode) {
      sendImmediateValue = 'updated';
    } else if (!sendImmediate) {
      sendImmediateValue = 'scheduled';
    }
    return `Please wait while the ${typeValue} is being ${sendImmediateValue}!`;
  });

export const getEngageSendErrorMessage = createSelector(getEngageSendError,
  (sendError) => {
    return {
      headingMessage: 'Oh no! Looks like something went wrong.',
      errorSubText: sendError
    };
  });

export const getEngageSendSuccessMessage = createSelector(getEngageType, getEngageMode,
  getEngageSendImmediate, (type, mode, sendImmediateBool) => {
    let sendImmediate = sendImmediateBool;
    let typeValue = type;
    let sendImmediateValue = 'created';

    if (type === EngageConstants.offer) {
      sendImmediate = true;
      typeValue = 'reward';
    }

    if (type === EngageConstants.draft) {
      typeValue = EngageConstants.email;
    }

    if (mode === EngageConstants.editMode) {
      sendImmediateValue = 'updated';
    } else if (!sendImmediate) {
      sendImmediateValue = 'scheduled';
    }

    return {
      headingMessage: `Yay! Your ${typeValue} has been ${sendImmediateValue} successfully!`,
      subText: `Please wait 24 to 48 hours to access statistics for this ${typeValue}.`
    };
  });

export const getSendConfirmationAction = createSelector(getEngageSendLoading, getEngageSendError,
  getEngageSendLoadingMessage, getEngageSendErrorMessage, getEngageSendSuccessMessage,
  (isLoading, sendError, loadingMessage, errorMessage, successMessage) => {
    if (isLoading) {
      return loadingMessage;
    } else {
      return sendError ? errorMessage : successMessage;
    }
  });

export const getEngagementSendLoadingMessage = createSelector(getEngagementType, getEngagementMode, getEngagementAutomationAction,
  (type, mode, engagementAction) => {
    let typeValue = type;
    let actionValue = 'created';

    if (type === EngageConstants.email) {
      typeValue = EngageConstants.email;
    }
    if (type === EngageConstants.thirdPartyPromotion) {
      typeValue = 'Third Party Promotion';
    }
    if (type === EngageConstants.offer) {
      typeValue = 'reward';
    }
    if (type === EngageConstants.freeItemInCartPromotion) {
      typeValue = 'free item in cart promotion';
    }

    if (mode === EngageConstants.editMode) {
      actionValue = 'updated';
      if (engagementAction === DiscoverConstants.stop) {
        actionValue = 'stopped'
      }
    }
    return `Please wait while the ${typeValue} is being ${actionValue}!`;
  });

export const getEngagementSendErrorMessage = createSelector(getEngagementSendError,
  (sendError) => {
    return {
      errorSubText: sendError === true ? '' : sendError,
      headingMessage: 'Oh no! Looks like something went wrong.'
    };
  });

export const getEngagementSendSuccessMessage = createSelector(getEngagementType, getEngagementMode, getEngagementAutomationAction,
  getCurrentEngagementId, (type, mode, engagementAction, flowId) => {
    let typeValue = type;
    let actionValue = 'created';
    if (type === EngageConstants.offer) {
      typeValue = 'reward';
    }

    // On delete the type for CCE is still automation so check flowId as well
    if (type === EngageConstants.communityCare || flowId === DiscoverConstants.communityCareEventTileId) {
      typeValue = 'event';
    }

    let subText = `Please wait 24 to 48 hours to access statistics for this ${typeValue}.`;

    if (type === EngageConstants.thirdPartyPromotion || flowId === EngageConstants.thirdPartyPromotion
      || type === EngageConstants.freeItemInCartPromotion || flowId === EngageConstants.freeItemInCartPromotion) {
      typeValue = 'promotion';
      subText = '';
    }

    if (mode === EngageConstants.editMode) {
      actionValue = 'updated';
      subText = '';
      if (engagementAction === DiscoverConstants.stop) {
        actionValue = 'stopped'
      }
    }
    return {
      headingMessage: `Yay! Your ${typeValue} has been ${actionValue} successfully!`,
      subText: subText
    };
  });

export const getEngagementSendAction = createSelector(getEngagementSendLoading, getEngagementSendError,
  getEngagementSendLoadingMessage, getEngagementSendErrorMessage, getEngagementSendSuccessMessage,
  (isLoading, sendError, loadingMessage, errorMessage, successMessage) => {
    if (isLoading) {
      return loadingMessage;
    } else {
      return sendError ? errorMessage : successMessage;
    }
  });

export const enabledRecruitmentModule = createSelector(getSelectedEngagement,
  getFeatureEnabled(EngageConstants.recruitmentModuleFlag), (engagement, recruitmentModuleEnabled) => {
    if (recruitmentModuleEnabled) {
      if (engagement.defaultSelectedTheme && (engagement.defaultSelectedTheme === EngageConstants.themeWeApologize ||
          engagement.defaultSelectedTheme === EngageConstants.themeSorryForTheSnag)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  });

export const getClosedCareContactsSearchTerm = createSelector(getCareState, fromCare.getClosedCareContactsSearchTerm);

export const getClosedCareContactsSearchBy = createSelector(getCareState, fromCare.getClosedCareContactsSearchBy);

export const getClosedCareContactsPage = createSelector(getCareState, fromCare.getClosedCareContactsPage);

export const getClosedCareContactsPageSize = createSelector(getCareState, fromCare.getClosedCareContactsPageSize);

export const getClosedCareContactsSortBy = createSelector(getCareState, fromCare.getClosedCareContactsSortBy);

export const getClosedCareContactsSortOrder = createSelector(getCareState, fromCare.getClosedCareContactsSortOrder);

export const getClosedCareContactsTotalElements = createSelector(getCareState, fromCare.getClosedCareContactsTotalElements);

export const getClosedCareContacts = createSelector(getCareState, fromCare.getClosedCareContacts);

export const getClosedCareContactsNumberOfPages = createSelector(getCareState, fromCare.getClosedCareContactsNumberOfPages);

export const getClosedCareContactsLoading = createSelector(getCareState, fromCare.getClosedCareContactsLoading);

export const getClosedCareContactsError = createSelector(getCareState, fromCare.getClosedCareContactsError);

export const getClosedCareContactsSelectedCategoryTitle = createSelector(
  getClosedCareContactsSearchBy, (searchBy) => {
    return R.find(R.propEq('value', searchBy))(CareConstants.searchByCategories).title;
  }
)

export const getClosedCareContactsRequest = createSelector(
  getClosedCareContactsPage, getClosedCareContactsPageSize, getClosedCareContactsSortBy, getClosedCareContactsSortOrder,
  getClosedCareContactsSearchTerm, getClosedCareContactsSearchBy, getClosedCareSelectedView,
  (page, limit, sortBy, sortOrder, searchTerm, searchBy, view) => {
    const daysAgo = view.slice(5,7);
    const fromDate = moment().subtract(view.slice(5,7), 'days').format('YYYY-MM-DD');
    const toDate = moment().format('YYYY-MM-DD');
    const closedCareContactsSearchTerm = searchTerm === '' ? null : searchTerm;
    const closedCareContactsSearchBy = closedCareContactsSearchTerm ? searchBy : null;

    return {
      daysAgo,
      fromDate,
      limit,
      page,
      searchBy : closedCareContactsSearchBy,
      searchTerm : closedCareContactsSearchTerm,
      sortBy,
      sortOrder,
      status: 'closed',
      toDate
    };
  });

export const getClosedCareContactsSortObj = createSelector(
  getClosedCareContactsSortBy, getClosedCareContactsSortOrder,
  (prop, order) => {
    return { prop, order };
  });

export const getClosedCareContactsNoSearchResultMessage = createSelector(getClosedCareContactsSearchTerm, (searchTerm) => {
  if (searchTerm != null) {
    return CareConstants.noClosedCareContactsSearchResultMessage + ' ' + searchTerm + '.';
  } else {
    return CareConstants.noClosedCareContactsSearchResultMessage;
  }
});

export const getClosedCareContactsNoResultsMessage =
  createSelector(getClosedCareContactsNoSearchResultMessage, getClosedCareContactsSearchTerm,
    (noSearchResultMessage, searchTerm) => {
      if (searchTerm) {
        return noSearchResultMessage;
      } else {
        return CareConstants.noResultsMessage;
      }
    });

export const getRestaurantRecoveryColumns =
  createSelector(getSpotlightContactsSearchBy, getFeatureEnabled(CareConstants.digitalRefundsFlag),(searchBy, digitalRefundsEnabled) => {
    let columns = digitalRefundsEnabled
      ? CareConstants.spotCustomerColumnsNewWithRedFlag : CareConstants.spotCustomerColumnsNewWithOutRedFlag;

    if (searchBy === CareConstants.phoneNumberValue || searchBy === CareConstants.customerEmailValue) {
      // To remove memberStatus column
      return columns.filter((column) => column.prop !== 'membership');
    } else {
      return columns;
    }
  });

export const getEnableMembershipAsIcon = createSelector(getSpotlightContactsSearchBy, (searchBy) => {
  return (searchBy != CareConstants.phoneNumberValue && searchBy != CareConstants.customerEmailValue);
});

export const getRecoveredCfaOneContactsLoading = createSelector(getCareState, fromCare.getRecoveredCfaOneContactsLoading);

export const getRecoveredCfaOneContactsError = createSelector(getCareState, fromCare.getRecoveredCfaOneContactsError);

export const getRecoveredCfaOneContactsSearchTerm = createSelector(getCareState, fromCare.getRecoveredCfaOneContactsSearchTerm);

export const getRecoveredCfaOneContactsSearchBy = createSelector(getCareState, fromCare.getRecoveredCfaOneContactsSearchBy);

export const getRecoveredCfaOneContactsPageSize = createSelector(getCareState, fromCare.getRecoveredCfaOneContactsPageSize);

export const getRecoveredCfaOneContactsCurrentPage = createSelector(getCareState, fromCare.getRecoveredCfaOneContactsCurrentPage);

export const getRecoveredCfaOneContactsNumberOfPages = createSelector(getCareState, fromCare.getRecoveredCfaOneContactsNumberOfPages);

export const getRecoveredCfaOneContactsSortBy = createSelector(getCareState, fromCare.getRecoveredCfaOneContactsSortBy);

export const getRecoveredCfaOneContactsSortOrder = createSelector(getCareState, fromCare.getRecoveredCfaOneContactsSortOrder);

export const getRecoveredCfaOneContactsDisplayErrorText = createSelector(getCareState, fromCare.getRecoveredCfaOneContactsDisplayErrorText);

export const getLoadRecoveredCfaOneContacts = createSelector(getCareState, fromCare.getLoadRecoveredCfaOneContacts);

export const getCareSelectedTabIndex = createSelector(getCareState, fromCare.getCareSelectedTabIndex);

export const getDigitalRefundColumns = createSelector(getFeatureEnabled(ProfileConstants.curbsideFlag), (curbsideEnabled)=>{
  let col = ProfileConstants.digitalRefundsTableColumns;
  if (curbsideEnabled) {
    col.splice(col.length - 1, 0, ProfileConstants.recoveryIssuedColumn);
    col.join();
  }
  return col;
});

export const getRecoveredCfaOneContactsNoSearchResultMessage = createSelector(getRecoveredCfaOneContactsSearchTerm, (searchTerm) => {
  if (searchTerm != null) {
    return CareConstants.recoveredCfaOneContactsNoSearchResultMessage + ' ' + searchTerm + '.';
  } else {
    return CareConstants.recoveredCfaOneContactsNoSearchResultMessage;
  }
});

export const getRecoveredCfaOneContactsNoResultsMessage =
  createSelector(getRecoveredCfaOneContactsNoSearchResultMessage, getRecoveredCfaOneContactsSearchTerm,
    (noSearchResultMessage, searchTerm) => {
      if (searchTerm) {
        return noSearchResultMessage;
      } else {
        return CareConstants.recoveredCfaOneContactsNoResultsMessage;
      }
    });

export const getRecoveredCfaOneContactsSortObj = createSelector(
  getRecoveredCfaOneContactsSortBy, getRecoveredCfaOneContactsSortOrder,
  (prop, order) => {
    return { prop, order };
  });

export const getRecoveredCfaOneContactsRequest = createSelector(
  getRecoveredCfaOneContactsCurrentPage, getRecoveredCfaOneContactsPageSize, getRecoveredCfaOneContactsSortBy,
  getRecoveredCfaOneContactsSortOrder, getRecoveredCfaOneContactsSearchTerm, getRecoveredCfaOneContactsSearchBy, getRecoveredSelectedView,
  (page, limit, sortBy, sortOrder, searchTerm, searchBy, view) => {
    let fromDate = moment().subtract(view.slice(5,7), 'days').format('YYYY-MM-DD');
    let toDate = moment().format('YYYY-MM-DD');
    let recoveredCfaOneContactsSearchTerm = searchTerm === '' ? null : searchTerm;
    let recoveredCfaOneContactsSearchBy = recoveredCfaOneContactsSearchTerm ? searchBy : null;
    return {
      page,
      limit,
      sortBy,
      sortOrder,
      searchBy : recoveredCfaOneContactsSearchBy,
      searchTerm : recoveredCfaOneContactsSearchTerm,
      fromDate,
      toDate
    };
  });

export const getRecoveredCfaOneContactsSelectedCategoryTitle = createSelector(
  getRecoveredCfaOneContactsSearchBy, (searchBy) => {
    return R.find(R.propEq('value', searchBy))(CareConstants.recoveredCfaOneContactsSearchByCategories).title;
  }
);
