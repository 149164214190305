import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as R from 'ramda';

@Component({
  selector: 'data-table-header',
  templateUrl: './data-table-header.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('arrowState', [
      state('desc', style({
        transform: 'rotate(-90deg)',
        opacity: 1.0
      })),
      state('asc', style({
        transform: 'rotate(90deg)',
        opacity: 1.0
      })),
      state('none', style({
        opacity: 0.0
      })),
      transition('desc => asc', animate('150ms ease-in-out')),
      transition('asc => desc', animate('150ms ease-in-out')),
      transition('none => asc', animate('150ms ease-in-out')),
      transition('asc => none', animate('150ms ease-in-out')),
      transition('desc => none', animate('150ms ease-in-out'))
    ])
  ],
})
export class DataTableHeaderComponent {
  @Input() public columns: any[];
  @Input() public currentSort: any;
  @Input() public isLoading: boolean;
  @Input() public showCheckboxes: boolean;
  @Input() public showSort: boolean;
  @Input() public selectedItems: any[];
  @Input() public allCustomerIds: any[];
  @Input() public rowIdProp: string;
  @Input() public canSelectAll: boolean;
  @Input() public disableCheckbox: boolean;
  @Input() public disableIndividualRowAttribute: string;
  @Input() public useSmallRowSize: boolean = false;
  @Input() public useFullMobileTable: boolean = false;
  @Input() public isAllCustomersNonSpotlight: boolean;
  @Input() public isMobile: boolean = false;

  @Input()
  set rows(rows: any[]) {
    if (rows) {
      let filterDisabled = (row) => !row[this.disableIndividualRowAttribute];
      this.uniqueRowsOnPage = R.filter(filterDisabled)(rows);
      this.uniqueRowsOnPage = R.uniq(R.pluck(this.rowIdProp)(this.uniqueRowsOnPage));
      this._rows = rows;
    } else {
      this.uniqueRowsOnPage = [];
      this._rows = [];
    }
  }

  get rows() {
    return this._rows;
  }

  @Output() public onSortChange = new EventEmitter<any>();
  @Output() public onSelectAllOnPageOnly = new EventEmitter<boolean>();
  @Output() public onSelectAllCustomers = new EventEmitter<boolean>();

  public uniqueRowsOnPage: any[];
  public _rows: any[];

  public changeSort(col) {
    // sortBySubProp - set in dynamo, understand-drilldowns table, item as mobileOfferGiveaway
    if (this.colIsNotSortable(col) || this.rows.length == 0) {
      return;
    } else if (this.colIsAlreadySelected(col)) {
      // reverse sorting
      let currOrd = this.currentSort['order'];
      let order = currOrd === 'asc' ? 'desc' : 'asc';
      let subProp = col.sortBySubProp ? col.sortBySubProp : null;
      let customizeSortArray = col.customizeSortArray ? col.customizeSortArray : null;
      this.currentSort = Object.assign({}, this.currentSort, { subProp, order, customizeSortArray });
    } else {
      let prop = col.prop;
      let order = 'asc';
      let subProp = col.sortBySubProp ? col.sortBySubProp : null;
      let customizeSortArray = col.customizeSortArray ? col.customizeSortArray : null;
      this.currentSort = Object.assign({}, this.currentSort, { prop, subProp, order, customizeSortArray });
    }
    this.onSortChange.emit(this.currentSort);
  }

  public isAllCustomersSelected() {
    if (this.rows !== undefined && this.rows.length > 0) {
      return R.uniq(this.selectedItems).length === this.allCustomerIds.length;
    }
  }

  public isCustomersSelectedOnPage() {
    if (this.rows !== undefined && this.rows.length > 0) {
      let countSelectedNumberOfRows = 0;
      this.rows.forEach(row => {
        if (row.state) {
          countSelectedNumberOfRows = countSelectedNumberOfRows + 1;
        }
      })
      return countSelectedNumberOfRows === this.uniqueRowsOnPage.length ? true : false;
    }
  }

  public isOnePage() {
    return this.allCustomerIds.length === this.uniqueRowsOnPage.length;
  }

  public selectAllClicked(checked) {
    this.onSelectAllCustomers.emit(checked);
  }

  public selectAllOnPageOnly(state) {
    this.onSelectAllOnPageOnly.emit(state.checked);
  }

  public getColState(col) {
    if (this.currentSort && this.currentSort['prop'] && this.currentSort['order']
      && this.currentSort['prop'] === col.prop) {
      return this.currentSort['order'];
    } else {
      return 'none';
    }
  }

  public getFlex() {
    return this.showCheckboxes ? '65px' : this.useFullMobileTable ? '0' : '25px';
  }

  public colIsNotSortable(col) {
    return col.hasOwnProperty('sort') && col.sort === false;
  }

  public colIsAlreadySelected(col) {
    return this.currentSort['prop'] && this.currentSort['prop'] === col.prop;
  }

  public getColSize(col) {
    // undefined doesn't work with fxFlex so need to return null
    return col.size ? col.size : null;
  }

  public getColTitle(col,isMobile) {
    return isMobile ? col.mobileTitle ? col.mobileTitle : col.title : col.title;
  }
}
