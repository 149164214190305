<div *ngIf="categories && categories.length > 1"
     [ngClass]="{'dropdown__mobile': isMobile}"
     id="dropdown">
  <div class="dropdown__base-container" (click)="toggleDropdown()">
    <div class="dropdown__base-header-text" [attr.data-testid]="headerText + 'dropdown'">{{headerText}}</div>
    <div class="dropdown__category" data-testid="selected-dropdown-option">{{selectedCategory}}</div>
    <mat-icon matSuffix class="dropdown__chevron-closed">expand_more</mat-icon>
  </div>

  <div *ngIf="dropdownOpen && !isMobile" class="dropdown__expanded-container" id="expanded-dropdown">
    <div class="dropdown__expanded-header-text">{{headerText}}</div>
    <mat-icon matSuffix class="dropdown__chevron-open" (click)="toggleDropdown()">expand_less</mat-icon>
    <div *ngFor="let category of categories let i = index; last as isLast"
         class="dropdown__row-hover"
         [ngClass]="{'dropdown__selected-category': category === selectedCategory}">
      <div class="dropdown__categories" (click)="selectCategory(category)" [attr.data-testid]="'dropdown-option-' + i">{{category}}</div>
      <hr *ngIf="!isLast">
    </div>
  </div>

  <div *ngIf="dropdownOpen && isMobile"
       class="dropdown__mobile-slide-up"
       (click)="openMobileCategories()">
  </div>
</div>
