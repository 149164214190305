import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EngageConstants } from '../../engage/engage.constants';

@Component({
  selector: 'template-summary',
  templateUrl: './template-summary.component.html',
  styleUrls: ['./template-summary.component.scss']
})

export class TemplateSummaryComponent {
  @Input() public templateName: string;
  @Input() public type: string;
  @Input() public imageURL: string;
  @Input() public details: string;
  @Input() public isMobile: boolean;
  @Input() public isDisabled: boolean;
  @Input() public isRoundedBorder: boolean;

  @Output() public onSummaryClick = new EventEmitter<any>();

  public rewardType = EngageConstants.offer;

  public summaryClicked() {
    if (!this.isDisabled) {
      this.onSummaryClick.emit();
    }
  }
}
