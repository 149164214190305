<mat-dialog-content class="additional-customer-filter__container"
        [ngClass]="{'additional-customer-filter__align-content' : (loading$ | async)}">
    <div *ngIf="(error$ | async) && !(loading$ | async)"
         fxLayout="row"
         fxLayoutAlign="space-around start">
        <error-message [error]="error$ | async"
                       [page]="'Saved Group Additional Customers Dialog'">
        </error-message>
        <mat-icon class="additional-customer-filter__close" (click)="closeDialog()">close</mat-icon>
    </div>
    <div *ngIf="(loading$ | async) && !(error$ | async)"
         class="additional-customer-filter__loading"
         [cfaLoading]="true">
    </div>
    <div *ngIf="!(loading$ | async) && !(error$ | async)"
         fxLayout="column">
        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div class="additional-customer-filter__title">
                {{filterTitle}}
            </div>
            <mat-icon class="additional-customer-filter__close" (click)="closeDialog()">close</mat-icon>
        </div>
        <additional-customers-filter-chips [customerFilter]="customerFilter$ | async"
                                           [isSelectedSavedGroupLarge]="isSelectedSavedGroupLarge$ | async"
                                           [noCustomersAvailableMsg]="(customerFilter$ | async) &&
																																			(customerFilter$ | async).length === 0 ? noCustomersAvailableMsg : ''"
                                           [maxCustomersMessage]="maxCustomersMessage"
                                           [isMobile]="false"
                                           [startFromIndex]="startFromIndex"
                                           (removeClicked)="removedCustomer($event)">
				</additional-customers-filter-chips>
    </div>
</mat-dialog-content>
