import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import moment from 'moment-timezone';

@Component({
  selector: 'time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimePickerComponent implements OnInit {
  @Input() public time: string = 'Send Time';
  @Input() public placeholder: string;
  @Input() public disabled: boolean;
  @Input() public validationControl: FormControl;

  // Accepts a string that will be the earliest time displayed
  @Input() startTimeRestriction;
  // Accepts a string that will be the latest time displayed
  @Input() public endTimeRestriction;
  @Input() public disabledTimes: string[];

  @Output() public timeSelected = new EventEmitter();

  public allTimeOptions;
  public displayTimes;

  public ngOnInit() {
    this.allTimeOptions = [];
    for (let i = 0; i < 24; i++) {
      let hour = (i === 0 ? 12 : (i > 12 ? i % 12 : i));
      for (let j = 0; j <= 3; j++) {
        let timeStr = hour + ':' + ((j * 15) === 0 ? '00' : (j * 15)) + (i < 12 ? ' am' : ' pm');
        this.allTimeOptions.push(timeStr);
      }
    }
    this.displayTimes = this.allTimeOptions;

  }

  public checkTimeDisabled(time: string) {
    let disabled = false
    if (this.startTimeRestriction) {
      disabled = moment(time,'h:mma').isBefore(moment(this.startTimeRestriction,'h:mma'))
    }
    if (!disabled && this.endTimeRestriction) {
      disabled = moment(time,'h:mma').isAfter(moment(this.endTimeRestriction,'h:mma'))
    }
    if (!disabled && this.disabledTimes) {
      disabled = this.disabledTimes.includes(time);
    }
    return disabled;
  }
}
