import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { EulaService } from '../eula/eula.service';
import * as eula from '../actions/eula';

@Injectable()
export class EulaEffects {
  public saveEulaAccept$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(eula.ActionTypes.SAVE_EULA_ACCEPT),
    switchMap(() => this.es.saveEulaAccept()),
    tap(() => this.es.restartApp()),
    catchError((error) => of(new eula.EulaErrorAction(error)))
  )});

  constructor(
    private actions$: Actions,
    private es: EulaService,
  ) { }
}
