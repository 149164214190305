import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorHandlerService } from '../core/error-handler';
import { UserService } from '../core/user';
import { forkJoin, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class CalendarService {
  constructor(
    private ehs: ErrorHandlerService,
    private us: UserService,
    private http: HttpClient
  ) { }

  public getCalendarEvents(payload) : Observable<any> {
    if (payload.campaignTypes) {
      let observables = [];
      payload.campaignTypes.forEach((type)=>{
        observables = observables.concat((this.getCalendarEventsByType(payload, type)));
      });
      return forkJoin(observables);
    }
    return of([]);
  }

  public getCalendarEventsByType(payload,type) : Observable<any> {
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/calendarEvents?campaignType=${type}`
        return this.http.post(environment.spotlightApiUrl + url, payload);
      }),
      map((res: any) => { return res.responseObject }),
      catchError(() => {
        //returns an empty array for that type with out failing that entire flow
        return of([]);
      })
    );
  }
}
