import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AnalyticsService } from '../../../core/analytics';
import * as fromRoot from '../../../reducers';
import * as engagement from '../../../actions/engagement';
import { DiscoverConstants } from '../../discover.constants';
import { browserRefreshed } from '../../../app.component';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { ActiveRewardDialogComponent } from '../../../shared/active-reward-dialog/active-reward-dialog.component';
import * as moment from 'moment-timezone';
import { DiscoverTile } from '../../../reducers/discover';
import { BottomSheetComponent } from '../../../shared/bottom-sheet/bottom-sheet.component';
import { AutomationInfoDialogComponent } from '../../automation-info-dialog/automation-info-dialog.component';
import * as engage from '../../../actions/engage';

@Component({
  templateUrl: './edit-details.component.html',
  styleUrls: ['./edit-details.component.scss'],
})
export class DiscoverEditDetailsComponent implements OnInit, OnDestroy {
  public subjectSubscription: Subscription;
  public campaignNameSubscription: Subscription;
  public durationSubscription: Subscription;
  public duration$: Observable<number>;
  public tile$: Observable<DiscoverTile>;
  public title$: Observable<string>;
  public suggestedUseText$: Observable<string>;
  public customerCount$: Observable<number>;
  public maxCustomers$: Observable<number>;
  public campaignName$: Observable<string>;
  public subject$: Observable<string>;
  public rewardCategories$: Observable<any[]>;
  public selectedReward$: Observable<any>;
  public engagementType$: Observable<string>;
  public engagementMode$: Observable<string>;
  public error$: Observable<string>;
  public loading$: Observable<boolean>;
  public discoverTilesLoading$: Observable<boolean>;
  public isMobile$: Observable<boolean>;
  public editableCustomerSelection$: Observable<boolean>;
  public isRewardCategoriesAvailable$: Observable<boolean>;
  public isPushNotificationEnabled$: Observable<boolean>;
  public rewardList$: Observable<any[]>;
  public emailCopy$: Observable<string>;
  public appCopy$: Observable<string>;
  public appPushCopy$: Observable<string>;
  public readOnlyUser$: Observable<boolean>;
  public isEndDateRestriction$: Observable<boolean>;
  public endDateRestriction$: Observable<string>;

  public engagementType: string;
  public minCustomers: number = 1;
  public editForm;
  public campaignNameFormControl = new FormControl();
  public subjectFormControl = new FormControl();
  public durationFormControl = new FormControl();
  public customerCountFormControl = new FormControl();
  public selectedRewardFormControl = new FormControl();
  public automationType = DiscoverConstants.automation;
  public editMode = DiscoverConstants.edit;
  public emailType = DiscoverConstants.email;
  public rewardType = DiscoverConstants.reward;
  public minDays = DiscoverConstants.minDays;
  public maxDays = DiscoverConstants.defaultDuration;
  public bottomSheetData = DiscoverConstants.discoverSearchBottomSheet;
  public today: string = moment().format('dddd, MMMM Do');
  public tomorrow: string = moment().add(1, 'd').format('dddd, MMMM Do');
  public expandMore: boolean = true;
  private rewardSubscription: Subscription;

  constructor(private analytics: AnalyticsService,
              private router: Router,
              private store: Store<fromRoot.State>,
              private dialog: MatDialog,
              private bottomSheet: MatBottomSheet) {}

  public ngOnInit() {
    window.scroll(0, 0);
    if (browserRefreshed) {
      this.goToDiscover();
    } else {
      this.emailCopy$ = this.store.select(fromRoot.getEngagementStrippedMessage);
      this.appCopy$ = this.store.select(fromRoot.getEngagementDefaultMessageForApp);
      this.appPushCopy$ = this.store.select(fromRoot.getEngagementPushMessageText);
      this.tile$ = this.store.select(fromRoot.getDiscoverTileById);
      this.readOnlyUser$ = this.store.select(fromRoot.getReadOnlyUser);
      this.customerCount$ = this.store.select(fromRoot.getEngagementCount);
      this.suggestedUseText$ = this.tile$.pipe(map((tile) => tile.descriptionText.suggestedUseText));
      this.title$ = this.tile$.pipe(map((tile) => tile.descriptionText.summary));
      this.editableCustomerSelection$ = this.tile$.pipe(map((tile) => tile.editableCustomerSelection));
      this.maxCustomers$ = this.tile$.pipe(map((tile) => {
        return tile.count;
      }));
      this.rewardList$ = this.store.select(fromRoot.getEngagementRewards);
      this.rewardCategories$ = this.store.select(fromRoot.getCategorizedEngagementRewards);
      this.isRewardCategoriesAvailable$ = this.store.select(fromRoot.getIsEngagementRewardsAvailable);
      this.engagementType$ = this.store.select(fromRoot.getEngagementType);
      this.engagementMode$ = this.store.select(fromRoot.getEngagementMode);
      this.loading$ = this.store.select(fromRoot.getEngagementLoading);
      this.error$ = this.store.select(fromRoot.getEngagementError);
      this.isMobile$ = this.store.select(fromRoot.getIsMobile);

      this.campaignNameFormControl = new FormControl(
        '',
        [Validators.pattern('^(?!\\s*$).+'), Validators.required]
      );

      this.subjectFormControl = new FormControl(
        '',
        [Validators.pattern('^(?!\\s*$).+'), Validators.required]
      );

      this.durationFormControl = new FormControl(
        '',
        [Validators.pattern('^[0-9]*$'), Validators.required, Validators.min(this.minDays), Validators.max(this.maxDays)]
      );

      this.selectedRewardFormControl = new FormControl(
        '', [ Validators.required]
      );

      if (this.engagementType == this.rewardType) {
        this.editForm = new FormGroup({
          campaignName: this.campaignNameFormControl,
          subject: this.subjectFormControl,
          duration: this.durationFormControl,
          selectedReward: this.selectedRewardFormControl
        });
      } else {
        this.editForm = new FormGroup({
          campaignName: this.campaignNameFormControl,
          subject: this.subjectFormControl,
        });
      }

      this.editableCustomerSelection$.pipe(take(1)).subscribe((editableCustomerSelection) => {
        this.engagementType$.pipe(take(1)).subscribe((type) => {
          if (type === DiscoverConstants.reward && editableCustomerSelection) {
            this.maxCustomers$.pipe(take(1)).subscribe((maxCust) => {
              this.customerCount$.pipe(take(1)).subscribe((count) => {
                this.customerCountFormControl = new FormControl(
                  count,
                  [Validators.pattern('^[0-9]*$'), Validators.required, Validators.min(this.minCustomers), Validators.max(maxCust)]
                );
                this.editForm.addControl('customerCount', this.customerCountFormControl);
              });
            });
          }
        });
      });

      // We need to manually set the initial value of the formControl on load
      // or else it won't work correctly when user navigates to another screen then back
      // The only exception to this is for inputs in child components
      // and for asynchronous values that get loaded after routing is completed
      this.campaignName$ = this.store.select(fromRoot.getEngagementCampaignName);
      this.campaignNameSubscription = this.campaignName$.subscribe((campaignName) => {
        this.campaignNameFormControl.patchValue(campaignName);
      });

      this.subject$ = this.store.select(fromRoot.getEngagementSubject);
      this.subjectSubscription = this.subject$.subscribe((subject) => {
        this.subjectFormControl.patchValue(subject);
      });

      this.duration$ = this.store.select(fromRoot.getEngagementLength);
      this.durationSubscription = this.duration$.subscribe((duration) => {
        this.durationFormControl.patchValue(duration);
      });

      this.selectedReward$ = this.store.select(fromRoot.getEngagementSelectedReward);
      this.rewardSubscription = this.selectedReward$.subscribe((currentReward) => {
        this.rewardCategories$.subscribe((categories) =>{
          categories.forEach((category) => {
            if (category.rewards) {
              category.rewards.forEach(reward =>{
                if (reward.name == currentReward.name) {
                  this.selectedRewardFormControl.patchValue(reward);
                }
              })
            }
          })
        })
      });

      this.endDateRestriction$ = this.store.select(fromRoot.getEngagementEndDateRestriction);
      this.isEndDateRestriction$ = this.store.select(fromRoot.getIsEndDateRestriction);
    }
    this.isPushNotificationEnabled$ = this.store.select(fromRoot.getFeatureEnabled(DiscoverConstants.pushNotificationFlag));
  }

  public updateReward(selectedReward) {
    this.store.dispatch(new engagement.SelectNewReward(selectedReward));
  }

  public updateCustomerCount(evt) {
    this.store.dispatch(new engagement.UpdateEngagement({ count: evt.target.value }));
  }

  public openRewardSelection() {
    this.router.navigate(['/discover/edit/reward-selection']);
  }

  public onCampaignNameChange(evt) {
    this.store.dispatch(new engagement.UpdateEngagement({ campaignName: evt.target.value }));
  }

  public onSubjectChange(evt) {
    this.store.dispatch(new engagement.UpdateEngagement({ subject: evt.target.value }));
  }

  public goToDiscover() {
    this.router.navigate(['/discover']);
  }

  public goToRewardCopy(index) {
    this.router.navigate(['/discover/edit/copy-review'], { queryParams: { index } });
  }

  public automationChanged(isAutomated) {
    this.title$.pipe(take(1)).subscribe((defaultCampaignName) => {
      this.store.dispatch(new engagement.UpdateIsAutomated({ isAutomated, defaultCampaignName }));
    });
  }

  public goToPreviewPage() {
    this.router.navigate(['/discover/edit/email-preview']);
  }

  private getDayToAdd() {
    if (this.isStartASunday() && (this.engagementType !== this.automationType)) {
      return Number(this.durationFormControl.value) + 1;
    } else {
      return this.durationFormControl.value;
    }
  }

  public restrictNumeric(event) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar) && event.key !== 'Backspace') {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  public isOutsideOfRange() {
    return this.durationFormControl.value > this.maxDays || this.durationFormControl.value < this.minDays;
  }

  public isStartASunday() {
    return moment().weekday() === 0;
  }

  public isEndASunday$() {
    return this.engagementType$.pipe(take(1), map((type) => {
      let endDate = moment().add(this.durationFormControl.value, 'd');
      if (this.isStartASunday()) {
        endDate = endDate.add(1, 'd');
      }
      return endDate.weekday() === 0 && !(type === DiscoverConstants.email);
    }));
  }

  public getSundayEndDate() {
    let endDate = moment().add(this.getDayToAdd(), 'd');
    return endDate.format('dddd, MMMM Do');
  }

  public updateTreatData() {
    this.store.dispatch(new engagement.SelectEngagementDates({
      startDate: this.isStartASunday() ? moment().add(1, 'd') : moment(),
      endDate: moment().add(this.getDayToAdd(), 'd'),
      treatLength: this.durationFormControl.value
    }));
  }

  public sendReward() {
    this.customerCount$.pipe(take(1)).subscribe((count) => {
      let startDate = this.isStartASunday() ? moment().add(1, 'd') : moment();
      let config = new MatDialogConfig();
      config.data = {
        count,
        startDate: startDate,
        endDate: moment().add(this.getDayToAdd(), 'd'),
        confirmationOnly: true
      };
      config.panelClass = 'dialog-responsive';
      this.dialog.open(ActiveRewardDialogComponent, config).afterClosed()
        .subscribe((confirm) => {
          if (confirm) {
            this.store.dispatch(new engagement.SendEngagement());
          }
        });
    });
  }

  public sendAutomation() {
    this.tile$.pipe(take(1)).subscribe((tile) => {
      let config = new MatDialogConfig();
      config.data = {
        displayText: tile.automationConfirmationText,
        confirmText: 'Automate Reward'
      };
      config.panelClass = 'dialog-responsive';
      this.dialog.open(ConfirmationDialogComponent, config).afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.store.dispatch(new engagement.SendEngagement());
        }
      });
    });
  }

  public sendEmail() {
    this.maxCustomers$.pipe(take(1)).subscribe((count) => {
      let config = new MatDialogConfig();
      config.data = {
        displayText: `You\'ve selected ${count} customer(s) to receive this communication. Would you like to proceed?`,
        confirmText: 'Send Email'
      };
      config.panelClass = 'dialog-responsive';
      this.dialog.open(ConfirmationDialogComponent, config).afterClosed()
        .subscribe((confirm) => {
          if (confirm) {
            this.store.dispatch(new engagement.SendEngagement());
          }
        });
    });
  }

  public update() {
    let config = new MatDialogConfig();
    config.data = {
      displayText: DiscoverConstants.automationWarning,
      confirmText: 'Save Updates'
    };
    config.panelClass = 'dialog-responsive';
    this.dialog.open(ConfirmationDialogComponent, config).afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.store.dispatch(new engagement.EditAutomation());
      }
    });
  }

  public stopAutomation() {
    this.tile$.pipe(take(1)).subscribe((tile) => {
      let config = new MatDialogConfig();
      let fieldId = tile.tileObjectId ? tile.tileObjectId : '';

      config.data = {
        title: tile.automationCancellationTitle,
        displayText: tile.automationCancellationBody,
        confirmText: 'Stop Automation'
      };
      config.panelClass = 'dialog-responsive';
      this.dialog.open(ConfirmationDialogComponent, config).afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.store.select(fromRoot.getCurrentEngagementId).pipe(take(1)).subscribe((id) => {
            this.store.dispatch(new engagement.StopAutomation({ id, fieldId }));
          });
        }
      });
    });
  }

  public ngOnDestroy() {
    if (this.subjectSubscription) {
      this.subjectSubscription.unsubscribe();
    }

    if (this.campaignNameSubscription) {
      this.campaignNameSubscription.unsubscribe();
    }

    if (this.durationSubscription) {
      this.durationSubscription.unsubscribe();
    }
    if (this.rewardSubscription) {
      this.rewardSubscription.unsubscribe();
    }
  }

  public openSelectRewardForMB() {
    this.bottomSheetData.list = [];
    let rewardListMap = [];
    this.rewardList$.pipe(take(1)).subscribe((reward) => {
      rewardListMap = reward;
      reward = reward.map(item => {
        return {
          name: item.name,
          value: item.awardId,
          image: item.offerImage
        };
      });

      this.bottomSheetData.list = this.bottomSheetData.list.concat(reward);
    });

    this.selectedReward$.pipe(take(1)).subscribe((selectedReward) => {
      this.bottomSheetData.selectedItem = selectedReward.awardId;
    });

    const bottomSheetRef = this.bottomSheet.open(BottomSheetComponent, {
      data: { content: this.bottomSheetData }
    });

    bottomSheetRef.afterDismissed().pipe(take(1)).subscribe((dataFromBottomSheet) => {
      if (dataFromBottomSheet && dataFromBottomSheet.value) {
        this.updateReward(rewardListMap.filter(t => t.awardId === dataFromBottomSheet.value)[0]);
      }
    });
  }

  public openAutomationHelpDialog() {
    this.analytics.sendMParticleEvent('General Automation Info Viewed', { });
    this.isMobile$.pipe(take(1)).subscribe(isMobile => {
      if (isMobile) {
        this.router.navigate(['/discover/automation-info']);
      } else {
        this.dialog.open(AutomationInfoDialogComponent, {
          restoreFocus: false,
          panelClass: 'dialog-responsive'
        });
      }
    })
  }
}
