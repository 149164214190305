import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'data-filter-chips',
  templateUrl: './data-filter-chips.component.html',
  styleUrls: ['./data-filter-chips.component.scss'],
})
export class DataFilterChipsComponent {
  @Input() public filters: any;
  @Input() public helpText: string;

  @Output() public onFilterSelection = new EventEmitter<any>();

  selectChip(filter) {
    filter.selected = !filter.selected;
  }
}
