<div fxLayout="column" fxLayoutAlign="start space-around" class="date-range-picker__date-section-container">
    <div *ngIf="!stacked" fxLayout="row" fxLayoutAlign="start center" class="date-range-picker__date-section-container">
      <date-picker class="date-range-picker__picker"
                   data-testid="start-date-picker"
                   [campaignType]="campaignType"
                   [placeholder]="startDatePlaceholder"
                   [isMobile]="isMobile"
                   [date]="start"
                   [disabled]="disabled || allDaysDisabled"
                   [disabledDays]="disabledDays"
                   [disableSundays]="disableSundays"
                   [minDate]="minStart"
                   [maxDate]="maxStart"
                   [validationControl]="startDateFormControl"
                   (dateChanged)="startDateChanged($event)">
      </date-picker>
      <date-picker class="date-range-picker__picker"
                   data-testid="end-date-picker"
                   [campaignType]="campaignType"
                   [placeholder]="endDatePlaceholder"
                   [isMobile]="isMobile"
                   [date]="endDate"
                   [disabled]="disabled || allDaysDisabled"
                   [disabledDays]="disabledDays"
                   [disableSundays]="disableSundays"
                   [minDate]="minEnd"
                   [maxDate]="maxEnd"
                   [validationControl]="endDateFormControl"
                   (dateChanged)="endDateChanged($event)">
      </date-picker>
  </div>
    <div *ngIf="stacked" fxLayout="column" fxLayoutAlign="space-around start" class="date-range-picker__date-section-container">
        <date-picker class="date-range-picker__picker"
                     data-testid="start-date-picker"
                     [campaignType]="campaignType"
                     [placeholder]="startDatePlaceholder"
                     [isMobile]="isMobile"
                     [date]="start"
                     [disabled]="disabled || allDaysDisabled"
                     [disabledDays]="disabledDays"
                     [disableSundays]="disableSundays"
                     [minDate]="minStart"
                     [maxDate]="maxStart"
                     [validationControl]="startDateFormControl"
                     (dateChanged)="startDateChanged($event)">
        </date-picker>
        <br>
        <date-picker class="date-range-picker__picker"
                     data-testid="end-date-picker"
                     [campaignType]="campaignType"
                     [placeholder]="endDatePlaceholder"
                     [isMobile]="isMobile"
                     [date]="endDate"
                     [disabled]="disabled || allDaysDisabled"
                     [disabledDays]="disabledDays"
                     [disableSundays]="disableSundays"
                     [minDate]="minEnd"
                     [maxDate]="maxEnd"
                     [validationControl]="endDateFormControl"
                     (dateChanged)="endDateChanged($event)">
        </date-picker>
    </div>

    <mat-error *ngIf="allDaysDisabled" class="date-range-picker__all-disabled-error">
        No available dates
    </mat-error>
    <mat-error *ngIf = "stopDate"  class="date-range-picker__all-disabled-error">
        * Stopped on {{this.stopDate | myDate : 'MMM DD, YYYY'}} at {{this.stopDate | myDate : 'h:mm a'}}
    </mat-error>
</div>
