import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { SharedConstants } from '../shared.constants';
import { findIndex, propEq } from 'ramda';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IconConfirmationDialogComponent } from '../icon-confirmation-dialog/icon-confirmation-dialog.component';
import { NoteComponent } from '../note/note.component';
import { AnalyticsService } from '../../core/analytics';
import * as care from '../../actions/care';
import * as profile from '../../actions/profile';
import * as fromRoot from '../../reducers';

@Component({
  selector: 'note-section',
  templateUrl: './note-section.component.html',
  styleUrls: ['./note-section.component.scss']
})
export class NoteSectionComponent {
  @ViewChildren(NoteComponent, { read: NoteComponent }) noteComponents: QueryList<NoteComponent>;

  @Input() public page: string;
  @Input() public notes: any[];
  @Input() public displayNotes: any[];
  @Input() public userName: string;
  @Input() public customerId: string;
  @Input() public loading: boolean;
  @Input() public error: string;
  @Input() public disabledChanges: boolean;
  @Input() public subText: string;
  @Input() public openActionHistoryNote: boolean = false;
  @Input() public createNoteText: string = 'Create New Note';

  @Output() public onNewNoteSaveClick = new EventEmitter();
  @Output() public onDeleteNoteClick = new EventEmitter();
  @Output() public saveNoteClicked = new EventEmitter();

  constructor(private dialog: MatDialog, public store: Store<fromRoot.State>, private analytics: AnalyticsService) { }

  public noteNoResultsMessage: string = SharedConstants.NoteNoResultsMessage;
  public noteErrorMessage: string = SharedConstants.NoteErrorMessage;
  public pageSize: number = 5;
  public currentPage: number = 0;
  public unsavedChangesTitle: string = SharedConstants.unsavedChangesTitle;
  public unsavedChangesText: string = SharedConstants.unsavedChangesText;
  public unsavedChangesIcon: string = SharedConstants.unsavedChangesIcon;
  public unsavedChangesConfirm: string = SharedConstants.unsavedChangesConfirmButton;
  public unsavedChangesCancel: string = SharedConstants.unsavedChangesCancelButton;

  public getNumberOfPages() {
    return Math.ceil(this.notes.length / this.pageSize);
  }

  public createNote = () : void => {
    if (this.page === SharedConstants.customerProfilePage) {
      this.store.dispatch(new profile.OpenNoteAction({ customerId: this.customerId, userName: this.userName, pageSize: this.pageSize }));
    } else {
      this.store.dispatch(new care.OpenCustomerNote({ customerId: this.customerId, userName: this.userName, pageSize: this.pageSize }));
    }
  }

  public deleteNote(note) {
    this.onDeleteNoteClick.emit(note);
  }

  public editNote = (note) : void => {
    if (this.page === SharedConstants.customerProfilePage) {
      this.store.dispatch(new profile.EditNoteAction({
        pageSize: this.pageSize,
        currentPage: this.currentPage,
        customerId: this.customerId,
        noteId: note.noteId
      }));
    } else {
      this.store.dispatch(new care.EditCustomerNote({ pageSize: this.pageSize, currentPage: this.currentPage, noteId: note.noteId }));
    }
    let selectedIndex = findIndex(propEq('noteId', note.noteId))(this.notes);

    setTimeout(() => {
      // Adjust index if user is on a different page of notes since viewChildren only includes the visible notes
      selectedIndex = selectedIndex - (this.currentPage * this.pageSize);
      const selectedNote: NoteComponent = this.noteComponents.toArray()[selectedIndex];
      if (selectedNote && selectedNote.textInputContainer && selectedNote.textInputContainer.nativeElement) {
        selectedNote.textInputContainer.nativeElement.focus();
      }
    });
  }

  public updateNoteText(text, note) {
    let selectedIndex = findIndex(propEq('noteId', note.noteId))(this.notes);
    this.notes[selectedIndex].updatedText = text;
  }

  public cancelEdit = (note) : void => {
    if (this.page === SharedConstants.customerProfilePage) {
      this.store.dispatch(new profile.CloseNoteAction({
        customerId: this.customerId,
        currentPage: this.currentPage,
        pageSize: this.pageSize
      }));
    } else {
      this.store.dispatch(new care.CloseCustomerNote());
    }
    if (note.isNewNote) {
      this.notes.shift();
      this.sliceArrBasedOnCurrentPage();
    } else {
      let index = findIndex(propEq('noteId', note.noteId))(this.notes);
      this.notes[index].isEditMode = false;
      this.notes[index].updatedText = this.notes[index].note;
    }
  }

  public saveNote(note) {
    let index = findIndex(propEq('noteId', note.noteId))(this.notes);
    let selectedNote = this.notes[index];
    let updatedNote = { ...selectedNote, note: selectedNote.updatedText.trim() };
    if (updatedNote.isNewNote) {
      this.onNewNoteSaveClick.emit(updatedNote);
    } else {
      this.saveNoteClicked.emit(updatedNote);
      this.currentPage = 0;
    }
  }

  public sliceArrBasedOnCurrentPage() {
    const nextRowIndex = this.currentPage * this.pageSize;
    if (nextRowIndex + this.pageSize < this.notes.length) {
      this.displayNotes = this.notes.slice(nextRowIndex, nextRowIndex + this.pageSize);
    } else {
      this.displayNotes = this.notes.slice(nextRowIndex, this.notes.length);
    }
  }

  public pageLeft() {
    this.currentPage--;
    this.sliceArrBasedOnCurrentPage();
  }

  public pageRight() {
    this.currentPage++;
    this.sliceArrBasedOnCurrentPage();
  }

  // If there is an open note with unsaved changes, open confirmation dialog
  // This will accept a function as an input that will be called based on confirmation dialog status
  public checkForUnsavedChanges(userAction: (param: any) => any, optionalParam?) : void {
    if (this.isNoteUnsaved()) {
      let config: MatDialogConfig = {
        data: {
          title: this.unsavedChangesTitle,
          displayText: this.unsavedChangesText,
          iconUrl: this.unsavedChangesIcon,
          cancelText: this.unsavedChangesCancel,
          confirmText: this.unsavedChangesConfirm
        },
        autoFocus: false,
        panelClass: 'dialog-responsive'
      }

      this.dialog.open(IconConfirmationDialogComponent, config).afterClosed().subscribe((shouldCancel) => {
        // Specifically check for false since clicking outside of dialog will return undefined
        let shouldDiscardChanges = shouldCancel === false;
        this.analytics.sendMParticleEvent(
          'Unsaved Changes Closed',
          { changesDiscarded: shouldDiscardChanges }
        );
        if (shouldDiscardChanges) {
          userAction(optionalParam);
        }
      });
    } else {
      userAction(optionalParam);
    }
  }

  // Returns if a note is both open and has changes
  public isNoteUnsaved() {
    if (this.notes) {
      let index = findIndex(propEq('isEditMode', true))(this.notes);
      if (index > -1) {
        let selectedNote = this.notes[index];
        return (selectedNote.updatedText && selectedNote.updatedText !== selectedNote.note) || this.openActionHistoryNote;
      } else {
        return this.openActionHistoryNote;
      }
    } else {
      return this.openActionHistoryNote;
    }
  }
}
