import * as customer from '../actions/customer';
import * as meet from '../actions/meet';
import * as user from '../actions/user';
import { AppConstants } from '../app.constants';

export interface State {
  allCustomerIds: any[];
  count: number;
  customerActionError: string;
  customers: any[];
  error: string;
  fullCustomerIdList: any[];
  loading: boolean;
  numberOfPages: number;
  selectedCustomers: any[];
}

export const initialState: State = {
  allCustomerIds: [],
  count: 0,
  customerActionError: null,
  customers: [],
  error: null,
  fullCustomerIdList: [],
  loading: true,
  numberOfPages: 0,
  selectedCustomers: []
};

export function reducer(state = initialState, action: customer.Actions | meet.Actions | user.Actions): State {
  switch (action.type) {
    case customer.ActionTypes.CHANGE_PAGE:
    case customer.ActionTypes.CHANGE_PAGE_SIZE:
    case customer.ActionTypes.CHANGE_SORT:
    case customer.ActionTypes.CHANGE_VIEW:
    case customer.ActionTypes.CLEAR_SEARCH:
    case customer.ActionTypes.MAKE_ACTIVE:
    case customer.ActionTypes.MAKE_ACTIVE_SUCCESS:
    case customer.ActionTypes.MAKE_INACTIVE:
    case customer.ActionTypes.MAKE_INACTIVE_SUCCESS:
    case customer.ActionTypes.SEARCH:
    case meet.ActionTypes.EDIT_SAVED_GROUP:
    case meet.ActionTypes.EXIT_SAVED_GROUP_EDIT: {
      return { ...state, error: null, loading: true };
    }

    case customer.ActionTypes.CUSTOMER_ERROR: {
      const error = action.payload;
      return {
        ...state,
        allCustomerIds: [],
        count: 0,
        customers: [],
        error,
        loading: false,
        numberOfPages: 0
      };
    }

    case customer.ActionTypes.FIND_CUSTOMER_COMPLETE: {
      const payload = action.payload;

      // Only set full customer list on first findCustomers call so that it includes the entire list
      let fullCustomerIdList = state.fullCustomerIdList.length ? state.fullCustomerIdList : payload.allCustomerIds;

      return {
        ...state,
        allCustomerIds: payload.allCustomerIds,
        count: payload.totalElements,
        customers: payload.customerProfiles,
        error: null,
        fullCustomerIdList,
        loading: false,
        numberOfPages: payload.numberOfPages
      };
    }

    case customer.ActionTypes.MAKE_ACTIVE_FAILURE:
    case customer.ActionTypes.MAKE_INACTIVE_FAILURE: {
      const customerActionError = action.payload;
      return { ...state, customerActionError, loading: false };
    }

    case customer.ActionTypes.SELECT_CUSTOMERS: {
      const payload = action.payload;
      return { ...state, selectedCustomers: payload };
    }

    case meet.ActionTypes.ADD_CUSTOM_DATE_FILTER:
    case meet.ActionTypes.ADD_INDIVIDUAL_EXCLUDE_CUSTOMER:
    case meet.ActionTypes.ADD_INDIVIDUAL_INCLUDE_CUSTOMER:
    case meet.ActionTypes.ADD_SAVED_GROUP:
    case meet.ActionTypes.ADD_SELECTED_FILTER:
    case meet.ActionTypes.CLEAR_FILTERS:
    case meet.ActionTypes.REMOVE_INDIVIDUAL_EXCLUDE_CUSTOMER:
    case meet.ActionTypes.REMOVE_INDIVIDUAL_INCLUDE_CUSTOMER:
    case meet.ActionTypes.REMOVE_SAVED_GROUP:
    case meet.ActionTypes.REMOVE_SELECTED_FILTER:
    case meet.ActionTypes.UPDATE_BUTTON_CHIP_ATTRIBUTE:
    case meet.ActionTypes.UPDATE_BUTTON_TOGGLE_ATTRIBUTE:
    case meet.ActionTypes.UPDATE_FILTER_DATE_RANGE_OPTION:
    case meet.ActionTypes.UPDATE_SLIDER_VALUE:
    case meet.ActionTypes.UPDATE_SUB_OPTION_ATTRIBUTE:
    case meet.ActionTypes.UPDATE_TWO_VARIABLE_SLIDER_VALUE: {
      const { page } = action.payload;
      const loading = page === AppConstants.meetPage;

      return { ...state, error: null, loading };
    }

    case meet.ActionTypes.UPDATE_FILTER_DATES: {
      const payload = action.payload;
      // Only set loading to true if both a start date and end date are selected
      let loading = !!payload.dates.startDate && !!payload.dates.endDate;

      return { ...state, error: null, loading };
    }

    case user.ActionTypes.SELECT_LOCATION: {
      return {
        ...state,
        error: null,
        fullCustomerIdList: initialState.fullCustomerIdList,
        loading: true
      };
    }

    default: {
      return state;
    }
  }
}

export const getAllCustomerIds = (state: State) => state.allCustomerIds;

export const getCustomerActionError = (state: State) => state.customerActionError;

export const getCustomers = (state: State) => state.customers;

export const getCustomersError = (state: State) => state.error;

export const getFullCustomerIdList = (state: State) => state.fullCustomerIdList;

export const getLoading = (state: State) => state.loading;

export const getNumberOfPages = (state: State) => state.numberOfPages;

export const getSelectedCustomers = (state: State) => state.selectedCustomers;

export const getTotalCountOfCustomers = (state: State) => state.count;
