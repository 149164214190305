<mat-chip class="filter-chip__chip">
  <span class="filter-chip__text"
        [attr.data-testid]="title + '-filter-chip'"
        [ngClass]="{'filter-chip__mobile-text': isMobile}">
    {{title}}
  </span>
  <img alt="X Icon"
       class="filter-chip__icon"
       src="https://www.cfacdn.com/img/Spotlight/Icons/Close.png"
       [attr.data-testid]="title + '-remove-chip'"
       (click)="removeClicked.emit()"/>
</mat-chip>
<span *ngIf="isShowGroup" class="filter-chip__clickable-text" (click)="showGroupClicked.emit()">Show Group</span>
