<mat-list class="template-summary__list" data-testid="template-summary" [ngClass]="{'template-summary__list-mobile': isMobile}">
  <mat-list-item class="template-summary__list-item"
                 [ngClass]="{'template-summary__remove-hover-effect': isDisabled}"
                 [disableRipple]="isDisabled"
                 (click)="summaryClicked()">
    <img matListItemAvatar
         alt="Template Image"
         class="template-summary__image"
         [ngClass]="{'template-summary__image-border': isRoundedBorder}"
         src="{{imageURL}}"/>
    <h4 matListItemTitle class="template-summary__summary-header" [attr.data-testid]="templateName + 'template'">
      {{templateName}}
    </h4>
    <span *ngIf="type !== rewardType" matListItemLine class="template-summary__summary-message">
      <span [innerHTML]="details"></span>
    </span>
    <button mat-icon-button matListItemMeta *ngIf="!isDisabled" data-testid="template-right-icon-button">
      <img alt="Chevron Icon"
           class="template-summary__chevron-icon"
           src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png">
    </button>
  </mat-list-item>
</mat-list>
