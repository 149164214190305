import { Component, Input, OnInit } from '@angular/core';
import { pluck } from 'ramda';
import { SharedConstants } from '../../../shared.constants';
import * as moment from 'moment-timezone';

@Component({
  selector: 'bar-line-chart',
  templateUrl: './bar-line-chart.component.html'
})

export class BarLineChartComponent implements OnInit {
  @Input() public data: any = [];
  @Input() public category: string;
  @Input() public labelProp: string;
  @Input() public barValueProp: string;
  @Input() public barTitleProp: string;
  @Input() public lineValueProp: string;
  @Input() public lineTitleProp: string;
  @Input() public lineFilterProp: string;
  @Input() public chartTitle: string;
  @Input() public xAxisLabel: string;
  @Input() public yAxisLabel: string;
  @Input() public yAxisFormat: string;
  @Input()
  set isMobile(isMobile: boolean) {
    this.options = SharedConstants.chartOptions(this.yAxisFormat, isMobile);
    this.width = isMobile ? 340 : 500;
  }

  public chartData;
  public options;
  public width;

  public ngOnInit() {
    this.data.sort((a, b) => {
      let isBefore = moment(a.monthFromReportDate + ' ' + a.yearFromReportDate, 'MMMM YYYY')
        .isBefore(moment(b.monthFromReportDate + ' ' + b.yearFromReportDate, 'MMMM YYYY'));
      return isBefore ? -1 : 1;
    })
    this.options.title.text = this.chartTitle;
    if (this.chartTitle) {
      this.options.title.display = true;
      this.options.title.text = this.chartTitle;
    }
    this.options.legend.display = true;
    this.options.scales.yAxes[0].ticks.beginAtZero = true;

    if (this.yAxisLabel) {
      this.options.scales.yAxes[0].scaleLabel.labelString = this.yAxisLabel;
      this.options.scales.yAxes[0].scaleLabel.display = true;
    }

    if (this.xAxisLabel) {
      this.options.scales.xAxes[0].scaleLabel.labelString = this.xAxisLabel;
      this.options.scales.xAxes[0].scaleLabel.display = true;
    }

    let labels = pluck(this.labelProp)(this.data);
    let barValues = pluck(this.barValueProp)(this.data);

    let lineValues = [];

    // If line chart shouldn't show a data point, insert null
    // so that chartJs can handle it correctly
    for (const monthData of this.data) {
      let shouldInclude = monthData[this.lineFilterProp];
      lineValues.push(shouldInclude ? monthData[this.lineValueProp] : null);
    }

    this.chartData = {
      datasets: [{
        label: this.barTitleProp,
        data: barValues,
        borderColor: SharedConstants.categoryFadeColor(this.category),
        backgroundColor: SharedConstants.categoryFadeColor(this.category),
        borderWidth: 1,
        pointStyle: 'rect'
      }, {
        label: this.lineTitleProp,
        data: lineValues,
        type: 'lineOffsetLeft',
        fill: false,
        borderColor: SharedConstants.categoryColor(this.category),
        backgroundColor: SharedConstants.categoryColor(this.category),
        borderWidth: 1,
        pointStyle: 'line'
      }],
      labels
    };
  }
}
