<div *ngIf="totalElements > 0" fxLayout="row" fxLayoutAlign="center center" class="pagination__margin-top">
    <button mat-button *ngIf="currentPage !== 0" (click)="pageLeft.emit()">
        <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <span class="pagination__empty-nav-space" *ngIf="currentPage === 0"></span>
    <span>
          {{getPageStart()}} - {{getPageEnd()}} of {{totalElements}}
    </span>
    <button mat-button *ngIf="!isLastPage()" (click)="pageRight.emit()">
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
    <span class="pagination__empty-nav-space" *ngIf="isLastPage()"></span>
</div>
