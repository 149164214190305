import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, OnDestroy, ChangeDetectorRef
} from '@angular/core';
import { MeetConstants } from '../../meet/meet.constants';
import { DataTableConstants } from './data-table.constants';
import { UserService } from '../../core/user';
import * as R from 'ramda';
import { Subject } from 'rxjs';

@Component({
  selector: 'data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DataTableComponent implements OnInit, OnDestroy {
  @Input() public rowId: string;
  @Input() public totalElements: number;
  @Input() public frontEndPaging: boolean = false;
  @Input() public preventPagination: boolean = false;
  @Input() public rowIdProp: string = 'id';
  @Input() public isEnableSignatureMembership: boolean = false;
  @Input() public shouldDisplayIcon: boolean = true;
  @Input() public shouldDisplayLabel: boolean = true;
  @Input() public shouldDisplayMemberText: boolean = false;

  @Input()
  set columns(columns: any[]) {
    this._columns = columns;
    const firstColumn = columns ? columns[0] : {};
    if (R.isEmpty(this._currentSort)) {
      this._currentSort = {
        prop: firstColumn.prop,
        order: 'asc',
      };
    }
  }

  get columns() {
    return this._columns;
  }

  @Input() public detailColumns: any[];
  @Input() public numberOfPages: number;
  @Input() public allCustomerIds: any[];
  @Input() public pageSize: number = DataTableConstants.defaultPageSize;
  @Input() public pageSizeOptions: number[];
  @Input() public sort: any;
  @Input() public currentPage: number;
  @Input()
  set currentSort(sort) {
    if (sort) {
      this._currentSort = sort
    }
  }

  get currentSort() {
    return this._currentSort
  }

  @Input() public isLoading: boolean;
  @Input() public noResultsMessage: string;
  @Input() public detailProp: string;
  @Input() public isDetailView: boolean = false;
  @Input() public isMobile: boolean = false;
  @Input() public enableCaresOVCC: boolean = false;
  @Input() public noteProp: string;

  @Input()
  set isMobileWithDesktopView(value: boolean) {
    this._columns = this._columns.filter((col) => {
      col.hide = col.isHideOnMobile && value;
      return col;
    });
  }

  @Input() public useFullMobileTable: boolean = false;
  @Input() public disableSelect: boolean = false;
  @Input() public canSelectAll: boolean = true;
  @Input() public actionableErrorText: string;
  @Input() public mobileDeleteEnabled: boolean = false;
  @Input() public useMembershipAsIcon: boolean = false;
  @Input() public disableIndividualRowAttribute: string = '';
  @Input() public disableCheckbox: boolean;
  @Input() public disableDeleteIcon: boolean;
  @Input() public enableMobileCheckbox: boolean = false;
  @Input() public disableRipple: boolean = false;
  @Input() public mobileActionMenuMetaData: any;
  @Input() public useSmallRowSize: boolean = false;
  @Input() public detailViewNoteText: string;
  @Input() public deepLinkMessage: string;
  @Input() public isVisibleDeepLinkMsg: string;
  @Input() public isRowNotInteractable: boolean;

  @Input()
  set rows(rows: any[]) {
    if (rows && rows.length > 0) {
      this._rows = this.setRows(rows);
      if (this.frontEndPaging) {
        this.allCustomerIds = R.pluck(this.rowIdProp, this.rows.filter((row) => !row[this.disableIndividualRowAttribute]));
        this.currentPage = 0;
        this.allRows = this.sortData(this.currentSort, this._rows);
        if (!this.preventPagination) {
          this.sliceRowsBasedOnCurrentPage();
        } else {
          this._rows = this.allRows;
        }
      }
    } else {
      this._rows = [];
    }
  }

  get rows() {
    return this._rows;
  }

  @Input()
  set view(view: any) {
    // TODO: move this logic to the reducer
    if (view === MeetConstants.inactivesView || this.previousView === MeetConstants.inactivesView) {
      this.clearSelections();
    }
    this.previousView = view;
  }

  @Input()
  set initiallySelectedCustomers(initialCustomers: any[]) {
    this.selectedItems = R.clone(initialCustomers);
    this.rows = this.setRows(this.rows);
  }

  @Input() public openRowSubject$: Subject<any>;
  @Input() public navigateToPage$: Subject<number>;

  @Output() public onPageChange = new EventEmitter<number>();
  @Output() public onPageSizeChange = new EventEmitter<number>();
  @Output() public onSortChange = new EventEmitter<any>();
  @Output() public onRowClick = new EventEmitter<any>();
  @Output() public onSelection = new EventEmitter<any[]>();
  @Output() public errorTextClick = new EventEmitter<any>();
  @Output() public onButtonClick = new EventEmitter<any>();
  @Output() public onExternalLinkClick = new EventEmitter<any>();
  @Output() public onDeleteClick = new EventEmitter<any>();
  @Output() public deepLinkMessageClick = new EventEmitter<any>();
  @Output() public onOrderNumberClicked = new EventEmitter<any>();
  @Output() public onSortedData = new EventEmitter<any>();

  public selectedItems: any[] = [];
  public preSelectedItems: any[] = [];
  public hasOnClickListeners: boolean;
  public previousView;
  public allRows: any[];
  public _rows: any[];
  public _columns: any[];
  public _currentSort: any = {};
  public subscriptionArray = [];

  constructor(public us: UserService, public cd: ChangeDetectorRef) {
    this.subscriptionArray.push(this.us.selectedLocation$.subscribe(() => {
      this.clearSelections();
    }));
  }

  public ngOnInit() {
    this.hasOnClickListeners = this.onRowClick.observers.length > 0;
    if (this.navigateToPage$) {
      this.subscriptionArray.push(this.navigateToPage$.subscribe(page => {
        this.pageChange(page);
        this.cd.detectChanges();
      }));
    }

  }

  get showCheckboxes(): boolean {
    return !this.disableSelect && this.onSelection.observers.length > 0;
  }

  get showFooter(): boolean {
    return this.onPageChange.observers.length > 0 ||
      this.onPageSizeChange.observers.length > 0 ||
      (this.frontEndPaging && !this.preventPagination);
  }

  public onSelectAllCustomers(val: boolean) {
    this.selectAllCustomers(val);
  }

  public rowSelected(rowData: any) {
    this.addOrRemoveSelection(rowData);
    this.onSelection.emit(this.selectedItems);
  }

  public rowClicked(rowData: any) {
    this.onRowClick.emit(rowData);
  }

  public sortChange(event) {
    if (this.onSortChange.observers.length > 0) {
      this.onSortChange.emit(event);
    } else {
      this.allRows = this.sortData(event, this.allRows);
      if (!this.preventPagination) {
        this.sliceRowsBasedOnCurrentPage();
      } else {
        this.rows = this.allRows;
      }
    }
  }

  public pageChange(event) {
    this.currentPage = event;
    if (this.frontEndPaging) {
      this.sliceRowsBasedOnCurrentPage();
    } else {
      this.onPageChange.emit(event);
    }
  }

  public checkAllCustomerNonSpotLight() {
    if (this.allRows !== undefined && this.allRows.length > 0) {
      return this.allRows.every(ele => ele.nonSpotCustomer);
    } else {
      return false;
    }
  }

  public pageSizeChange(event) {
    this.pageSize = event;
    if (this.frontEndPaging) {
      this.currentPage = 0;
      this.numberOfPages = Math.ceil(this.totalElements / this.pageSize);
      this.sliceRowsBasedOnCurrentPage();
    } else {
      this.onPageSizeChange.emit(event);
    }
  }

  public errorTextClicked() {
    this.errorTextClick.emit();
  }

  public sortData(sort, data) {
    let sortedRows = [];
    let sortFn;
    if (data && sort && !sort.customizeSortArray) {
      const isString = R.propIs(String, sort.prop);
      const ignoreCase = R.compose(R.toLower, R.prop(sort.prop));
      let sortByProp = R.prop(sort.prop);
      if (sort.subProp) {
        sortByProp = R.compose(R.prop(sort.subProp), R.prop(sort.prop));
      }
      sortFn = R.sortBy(R.ifElse(
        isString,
        ignoreCase,
        sortByProp
      ));
    } else if (data && sort && sort.customizeSortArray) {
      let sortByPropArray = [];
      sort.customizeSortArray.forEach(obj => {
        let fn;
        const isString = R.propIs(String, obj.prop);
        const ignoreCase = R.compose(R.toLower, R.prop(obj.prop));
        if (obj.order === 'asc') {
          fn = R.ascend(R.ifElse(isString, ignoreCase, R.prop(obj.prop)));
        } else if (obj.order === 'desc') {
          fn = R.descend(R.ifElse(isString, ignoreCase, R.prop(obj.prop)));
        }
        sortByPropArray.push(fn);
      });
      sortFn = R.sortWith(sortByPropArray);
    }
    sortedRows = sortFn(data);
    if (sort.order === 'desc') {
      sortedRows = R.reverse(sortedRows);
    }
    this.onSortedData.emit(sortedRows);
    return sortedRows;
  }

  public sliceRowsBasedOnCurrentPage() {
    const nextRowIndex = this.currentPage * this.pageSize;
    if (nextRowIndex + this.pageSize < this.totalElements) {
      this._rows = this.allRows.slice(nextRowIndex, nextRowIndex + this.pageSize);
    } else {
      this._rows = this.allRows.slice(nextRowIndex, this.totalElements);
    }
  }

  // TODO move this to a util function
  public addOrRemoveSelection(row) {
    let findIndex = this.selectedItems
      .findIndex((val) => val === row[this.rowIdProp]);
    let preSelectedfindIndex = this.preSelectedItems
      .findIndex((val) => val === row[this.rowIdProp]);

    if (findIndex > -1) {
      this.selectedItems.splice(findIndex, 1);
      this.preSelectedItems.splice(preSelectedfindIndex, 1);
    } else {
      this.selectedItems.push(row[this.rowIdProp]);
      this.preSelectedItems.push(row[this.rowIdProp]);
    }
    this._rows = [...this._rows];
    this.selectedItems = [...this.selectedItems];
    this.preSelectedItems = [...this.preSelectedItems];
  }

  public clearSelections() {
    this.selectedItems = [];
    this.preSelectedItems = [];
    this.onSelection.emit(this.selectedItems);
  }

  public setRows(rows) {
    let ids = this.selectedItems;
    return rows.reduce((curr, next) => {
      next = { ...next };
      if (ids.indexOf(next[this.rowIdProp]) > -1) {
        next = { ...next, state: true };
      }
      curr.push(next);
      return curr;
    }, []);
  }

  public selectAllRowsOnPageOnly(isChecked) {
    this.selectedItems = [];
    this._rows = this._rows.map((row) => {
      if (!row[this.disableIndividualRowAttribute]) {
        this.selectedItems.push(row[this.rowIdProp]);
        this.preSelectedItems.push(row[this.rowIdProp]);
        row = { ...row, state: isChecked };
      }
      return row;
    });
    if (isChecked) {
      this.selectedItems = R.uniq(R.concat(this.selectedItems, this.preSelectedItems));
      this.preSelectedItems = R.uniq(R.concat(this.selectedItems, this.preSelectedItems));
      this.onSelection.emit(this.selectedItems);
    } else {
      this.preSelectedItems = this.preSelectedItems.filter(val => !this.selectedItems.includes(val));
      this.selectedItems = [...this.preSelectedItems];
      this.onSelection.emit(this.selectedItems);
    }
  }

  public selectAllCustomers(isChecked) {
    this._rows = this.rows.map((row) => {
      let mapRow = { ...row };
      if (!row[this.disableIndividualRowAttribute]) {
        mapRow = { ...row, state: isChecked };
      }
      return mapRow;
    });
    if (isChecked) {
      this.selectedItems = R.uniq(R.concat(this.selectedItems, this.allCustomerIds));
      this.preSelectedItems = R.uniq(R.concat(this.preSelectedItems, this.allCustomerIds));
      this.onSelection.emit(this.selectedItems);
    } else {
      this.clearSelections();
    }
  }

  public deleteClicked(row) {
    this.onDeleteClick.emit(row);
  }

  public buttonClicked(rowObj) {
    this.onButtonClick.emit(rowObj);
  }

  public externalLinkClicked(row) {
    this.onExternalLinkClick.emit(row);
  }

  public onClickDeepLinkMsg() {
    this.deepLinkMessageClick.emit();
  }

  public orderNumberClicked(row) {
    this.onOrderNumberClicked.emit(row);
  }

  public ngOnDestroy() {
    this.subscriptionArray.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
