import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { Action, Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { UserService } from '../core/user';
import * as user from '../actions/user';
import * as fromRoot from '../reducers';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { AnalyticsService } from '../core/analytics';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../shared/snackbar/snackbar.component';
import { AppConstants } from '../app.constants';
import { WelcomeDialogComponent } from '../discover/welcome-dialog/welcome-dialog.component';
import { DiscoverConstants } from '../discover/discover.constants';

@Injectable()
export class UserEffects {

  public dismissAlert$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(user.ActionTypes.DISMISS_ALERT),
    map((action: any) => action.payload),
    switchMap((alertDate) => this.us.dismissAlert(alertDate.alertId).pipe(
      map((res) => new user.DismissAlertSuccessAction(res))
    ))
  )})

  public dismissBanner$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(user.ActionTypes.DISMISS_BANNER),
    map((action: any) => action.payload),
    switchMap((bannerId) => this.us.updateBannerSettings(bannerId)),
    map((res) => new user.DismissBannerSuccessful(res))
  )})

  public getLocationDetails$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(user.ActionTypes.SELECT_LOCATION),
    map((action: any) => action.payload),
    concatLatestFrom(() => this.store),
    switchMap(([payload, state]) => {
      return this.us.getLocationDetails(payload, state.user.adminFull, state.user.adminView).pipe(
        map((res) => new user.GetLocationDetailsSuccess(res)),
        catchError((error) => of(new user.GetLocationDetailsFailure(error)))
      );
    })
  )})

  public openLocationWelcomeSnackbar: any = createEffect(() => { return this.actions$.pipe(
    ofType(user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS),
    switchMap(() => this.store.select(fromRoot.getSelectedLocation).pipe(take(1))),
    concatLatestFrom(() => {
      return [
        this.store.select(fromRoot.getScreenSize),
        this.store.select(fromRoot.getInitialLocation)
      ]
    }),
    tap(([location, screenSize, initialLocation]) => {
      // Only launch snackbar on mobile and for newly selected locations
      if (screenSize === AppConstants.extraSmall && !initialLocation) {
        let config: MatSnackBarConfig;
        config = {
          horizontalPosition: 'center',
          panelClass: ['location-change', 'fade-out'],
          data: {
            dismiss: false,
            fadeOut: true,
            html: `Welcome to<br><b>${location.locationName}</b>`
          }
        };
        this.snackBar.openFromComponent(SnackbarComponent, config);
      }
    })
  )}, { dispatch : false });

  // If an alert dialog is available for this location and there is no maintenance or eula required, show dialog
  public openAlertDialog: any = createEffect(() => { return this.actions$.pipe(
    ofType(user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS),
    map((action: any) => action.payload),
    filter((loc) => loc.displayAlert),
    concatLatestFrom(() => {
      return [
        this.store.select(fromRoot.getEulaAgree),
        this.store.select(fromRoot.getFeatureEnabled(AppConstants.maintenanceFlag))
      ]
    }),
    tap(([loc, eulaAccepted, activeMaintenance]) => {
      if (loc.displayAlert && eulaAccepted && !activeMaintenance) {
        let config = new MatDialogConfig();
        config.data = loc.alertDialog;
        config.panelClass = 'dialog-responsive'
        this.dialog.open(AlertDialogComponent, config).afterClosed().subscribe((isCta) => {
          this.analytics.sendMParticleEvent(
            'Dismissed Dialog',
            { isCta }
          );
          this.store.dispatch(new user.DismissAlertAction({ ...loc.alertDialog, isCta }));
        });
      }
    })
  )}, { dispatch : false });

  public openWelcomeModal: any = createEffect(() => { return this.actions$.pipe(
    ofType(user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS),
    map((action: any) => action.payload),
    filter(loc => loc.notificationBanners.filter((banner => banner.id == DiscoverConstants.welcomeModalBanner))),
    concatLatestFrom(() => {
      return [
        this.store.select(fromRoot.getEulaAgree),
        this.store.select(fromRoot.getFeatureEnabled(AppConstants.maintenanceFlag)),
        this.store.select(fromRoot.getShowWelcomeModal)
      ]
    }),
    tap(([loc, eulaAccepted, activeMaintenance, showWelcomeModal]) => {
      if (showWelcomeModal && eulaAccepted && !activeMaintenance) {
        let config = new MatDialogConfig();
        config.data = this.us.getDiscoverButtons()
        config.autoFocus = false
        config.panelClass = 'dialog-responsive'
        this.dialog.open(WelcomeDialogComponent, config).afterClosed().subscribe(() => {
          this.store.dispatch(new user.DismissBannerAction(DiscoverConstants.welcomeModalBanner));
        });
      }
    }),
  )}, { dispatch : false });

  public navigateToCta: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(user.ActionTypes.DISMISS_ALERT),
    map((action: any) => action.payload),
    filter((alertData) => alertData.isCta && alertData.alertCtaLink),
    tap((alertData) => {
      if (alertData.alertCtaLink.split('?').length >1) {
        let ctaLink = alertData.alertCtaLink.split('?');
        let params = { [ctaLink[1].split('=')[0]]: ctaLink[1].split('=')[1] }
        this.router.navigate([ctaLink[0]], { queryParams: params });
      } else {
        this.router.navigate([alertData.alertCtaLink]);

      }

    })
  )}, { dispatch : false });

  constructor(
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private store: Store<fromRoot.State>,
    private us: UserService,
    private router: Router,
    public dialog: MatDialog,
    private analytics: AnalyticsService
  ) { }
}
