<spot-nav title="Reward Selection"
          [isSmallNav]="true"
          [isMobile]="true"
          (back)="goBack()">
</spot-nav>
<div class="reward-selection__header-text">
    Select the Reward you'd like to send
</div>
<mat-nav-list>
    <div *ngFor="let category of rewardCategories$ | async">
        <div class="reward-selection__list-category">{{category.category}}</div>
        <mat-list-item *ngFor="let reward of category.rewards"
                       class="reward-selection__list-item"
                       (click)="rewardSelected(reward)">
            <img *ngIf="reward.offerImage"
                 alt="Reward Image"
                 class="reward-selection__list-image"
                 matListItemAvatar
                 src="{{reward.offerImage}}"/>
            <h4 matListItemTitle class="reward-selection__list-item-text">
                {{reward.name}}
            </h4>
        </mat-list-item>
    </div>
</mat-nav-list>
