import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators'
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { SettingsService } from '../settings/settings.service';
import { SettingsConstants } from '../settings/settings.constants';
import * as fromRoot from '../reducers';
import * as settings from '../actions/settings';

@Injectable()
export class SettingsEffects {
  public getFutureEvents$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(
      settings.ActionTypes.LOAD_SETTINGS,
      settings.ActionTypes.EVENT_SAVE_SUCCESSFUL
    ),
    map((action: any) => action.payload),
    switchMap(() => this.ss.loadFutureEventSettings()),
    map((events) => new settings.FutureEventsFoundAction(events)),
    catchError((err) => of(new settings.FutureEventErrorAction(err)))
  )})

  public saveDefaultSettings$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(settings.ActionTypes.SAVE_DEFAULT_LOCATION),
    map((action: any) => action.payload),
    concatLatestFrom(() => this.store),
    switchMap(([payload, state]) => this.ss.saveUserSettings(
      payload,
      state.user
    )),
    map((userSettings) => new settings.DefaultLocationUpdatedAction(userSettings))
  )})

  public saveEventSettings$: Observable<any> = createEffect(() => { return this.actions$.pipe(
    ofType(settings.ActionTypes.SAVE_EVENT_SETTINGS),
    map((action: any) => action.payload),
    concatLatestFrom(() => this.store),
    switchMap(([payload, state]) => {
      return this.ss.saveEventSettings(
        state.settings.futureEvents.events,
        state.settings.updatedFutureEvents.events
      ).pipe(
        map(() => new settings.EventSaveSuccessfulAction(SettingsConstants.eventUpdateSuccess)),
        catchError((error) => of(new settings.EventSaveErrorAction(error)))
      );
    })
  )})

  constructor(
    private actions$: Actions,
    private ss: SettingsService,
    private store: Store<fromRoot.State>
  ) { }
}
