import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment-timezone'
import { MatTableDataSource } from '@angular/material/table';
import { DiscoverConstants } from '../../discover/discover.constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-community-care-multiple-event-modal',
  templateUrl: './community-care-multiple-event-modal.component.html',
  styleUrls: ['./community-care-multiple-event-modal.component.scss']
})
export class CommunityCareMultipleEventModalComponent implements OnInit {
  public events: any;
  public displayedColumns: any[];
  public isMobile: boolean;
  public size:string;
  constructor(private router: Router,
              @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
              public dialogRef: MatDialogRef<CommunityCareMultipleEventModalComponent>) { }

  ngOnInit(): void {
    this.dialogData.events.map((event,index) => {event.highlighted= index == 0})
    this.events = new MatTableDataSource(this.dialogData.events);
    this.isMobile = this.dialogData.isMobile;
    this.size = this.isMobile ? '20px' : '30px';
    this.displayedColumns = this.isMobile ? ['eventName', 'start', 'viewEvent'] : ['eventName', 'orgName', 'start', 'end', 'viewEvent'];
  }

  selectEvent(event) {
    let queryParams = {
      type: DiscoverConstants.categoryCommunityCare,
      mode: 'review',
      id: event.id,
    };
    this.router.navigate(['/engage/engage-details'], { queryParams });
    this.dialogRef.close()
  }

  getDisplayData(row,col) {
    switch (col) {
      case 'orgName': {
        let display = '';
        let orgs = row[col]? row[col]:[];
        orgs.forEach((org) => {
          display = display + org.organizationName + ', '
        } )
        return display.slice(0, -2);
      }
      case 'end': {
        return row[col] ? moment.unix(row[col]).format('ddd, MMM Do YYYY'): '';
      }
      case 'start': {
        return row[col] && this.isMobile ? moment.unix(row[col])
          .format('MMM Do YYYY') : row[col] ? moment.unix(row[col]).format('ddd, MMM Do YYYY') : '';
      }
      default:
        return row[col] ? row[col] : ''

    }

  }

  public closeDialog(viewCalendar: boolean) {
    if (viewCalendar) {
      this.router.navigate(['/calendar']);
    }
    this.dialogRef.close()
  }
}
