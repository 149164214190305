import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as fromRoot from '../reducers';
import * as activeRewards from '../actions/active-rewards';
import { ActiveRewardDialogService } from '../shared/active-reward-dialog/active-reward-dialog.service';

@Injectable()
export class ActiveRewardsEffects {

  public fetchActiveCustomers$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(activeRewards.ActionTypes.FETCH_ACTIVE_REWARD_CUSTOMERS),
    map((action: any) => action.payload),
    switchMap((req) => this.cds.getActiveRewardIds(req).pipe(
      map((res) => new activeRewards.FoundActiveRewardCustomers(res.responseObject)),
      catchError((error) => of(new activeRewards.ErrorActiveRewardCustomers(error)))
    ))
  )});

  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private cds: ActiveRewardDialogService
  ) { }
}
