<div class="flex-container"
     fxLayout="row"
     fxLayoutAlign="center center"
     *ngIf="!isDataAvailable() && !isLoading">
  <data-table-error [errorText]="noResultsMessage"
                    [actionableText] = "actionableErrorText"
                    [isVisibleDeepLinkMsg]="isVisibleDeepLinkMsg"
                    [deepLinkMessage]="deepLinkMessage"
                    (deepLinkMessageClick)="onClickDeepLinkMsg()"
                    (textClick)="errorTextClicked()">
  </data-table-error>
</div>
<div *ngIf="isLoading"
     class="ngx-data-table__loading"
     [ngClass]="{'ngx-data-table__loading-data' : !isDataAvailable()}"
     [cfaLoading]="true">
</div>
<div *ngIf="isDataAvailable()">
  <div class="flex-container ngx-data-table__row"
       [ngClass]="{'ngx-data-table__small-row' : useSmallRowSize,
                   'ngx-data-table__row-hover' : !isRowNonInteractive(row),
                   'ngx-data-table__row-bottom-border' : row.bottomBorder,
                   'ngx-data-table__blue-row-background' : (i % 2 !== 0)}"
       fxLayout="row wrap"
       *ngFor="let row of rows; let i = index"
       [id]="row[rowId]">
    <div fxLayout="row" fxFlex="100">
      <div class="flex-item"
           [fxFlex]="getFlex()">
        <div *ngIf="showCheckboxes">
          <mat-checkbox [(ngModel)]="row.state"
                        *ngIf="showCheckboxes && !isDetailView && !shouldDisableRow(row)"
                        [disabled]="disableCheckbox || isLoading"
                        (change)="checkboxClick(row)"
                        [attr.data-testid]="i + '-table-checkbox'"
                        class="ngx-data-table__checkbox">
          </mat-checkbox>
        </div>
      </div>
      <ng-container *ngFor="let col of columns">
        <span *ngIf="!col.hide"
              class="flex-item ngx-data-table__cell"
              [ngClass]="{'ngx-data-table__row-disabled': (shouldDisableRow(row) || disableCheckbox),
                          'ngx-data-table__center-column': col.centerContent}"
              [fxFlex]="getColSize(col)"
              (click)="rowClicked(row)">
          <data-table-cell [row]="row"
                           [col]="col"
                           [disableDeleteIcon]="disableDeleteIcon"
                           [shouldDisableButton]="shouldDisableRow(row)"
                           [isEnableSignatureMembership]="isEnableSignatureMembership"
                           [enableCaresOVCC]="enableCaresOVCC"
                           (onButtonClick)="buttonClicked(row, col.id)"
                           (onExternalLinkClick)="externalLinkClicked(row)"
                           (onDeleteClick)="deleteClicked(row)"
                           (onOrderNumberClicked)="orderNumberClicked(row)"
                           (onViewNotesClick)="viewNotesClicked(row)">
          </data-table-cell>
        </span>
      </ng-container>
      <div *ngIf="isDetailView">
        <img *ngIf="isDetailView && !row.open"
             alt="Chevron Icon Facing Down"
             class="ngx-data-table__chevron-icon-down"
             (click)="openDetail(row)"
             src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png"/>
        <img *ngIf="isDetailView && row.open"
             alt="Chevron Icon Facing Up"
             class="ngx-data-table__chevron-icon-up"
             (click)="openDetail(row)"
             src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png"/>
      </div>
      <div *ngIf="!shouldHideChevron(row)"
           fxFlex="32px"
           (click)="rowClicked(row)">
        <img alt="Chevron Icon"
             class="ngx-data-table__chevron-icon"
             src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png"/>
      </div>
      <div *ngIf="shouldHideChevron(row)" fxFlex="32px"></div>
    </div>

    <div *ngIf="row.isNoteOpen"
         fxLayout="row wrap"
         fxFlex="100">
      <div fxLayout="row"
           fxFlex="100"
           *ngFor="let note of row[noteProp]">
        <div class="ngx-data-table__note">
          <div>
            <span class="ngx-data-table__note-name">{{note.updatedBy}}</span>
            <span class="ngx-data-table__note-date"> - {{note.updatedAt | locTimeZoneDate:'MMM DD, YYYY hh:mm A'}}</span>
          </div>
          <div class="ngx-data-table__note-text">{{note.note}}</div>
        </div>
      </div>
    </div>
    <div *ngIf="isDetailView && row.open"
         fxLayout="row wrap"
         fxFlex="100">
      <div fxLayout="row"
           fxFlex="100"
           class="ngx-data-table__detail-header">
        <span fxFlex="425px"></span>
        <div fxFlex *ngFor="let detRowCol of detailColumns">
            {{detRowCol.title}}
        </div>
      </div>
      <div fxLayout="row"
           fxFlex="100"
           *ngFor="let detRow of row[detailProp]">
        <span fxFlex="425px"></span>
        <div fxFlex *ngFor="let detCol of detailColumns">
          <data-table-cell [row]="detRow"
                           [col]="detCol"
                           [shouldDisableButton]= "shouldDisableRow(row)"
                           [enableCaresOVCC]="enableCaresOVCC"
                           (onButtonClick)="buttonClicked(row)"
                           (onExternalLinkClick)="externalLinkClicked(row)"
                           (onDeleteClick)="deleteClicked(row)">
          </data-table-cell>
        </div>
      </div>
      <div fxLayout="row"
           fxFlex="100"
           *ngIf="row.doorDash">
        <div fxFlex="55px"></div>
        <div fxFlex="16"></div>
        <div fxFlex class="ngx-data-table__notes">
          {{detailViewNoteText}}
        </div>
      </div>
    </div>
  </div>
</div>
