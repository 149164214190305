import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnalyticsService } from '../../core/analytics';
import { CareConstants } from '../care.constants';
import { Observable } from 'rxjs';
import * as fromRoot from '../../reducers';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'action-selection',
  templateUrl: './action-selection.component.html',
  styleUrls: ['./action-selection.component.scss'],
})

export class ActionSelectionComponent implements OnInit {
  @Input() public actionOptions: any[];
  @Input() public name: string;
  @Input() public type: string;
  @Input() public priority: string;
  @Input() public eligibleForSpotEngagement: boolean;
  @Input() public isSendDisabled: boolean;
  @Input() public isMobile: boolean;
  @Input() public recoverEligibilityLoading: boolean = false;

  @Output() public startFlowClicked = new EventEmitter<any>();
  @Output() public cancelClicked = new EventEmitter<any>();

  public selectedCustomer$: Observable<any>;

  public selectedSubtype = null;
  public selectedValue = null;
  public isSend = null;
  public note = null;
  public spotCustomer = CareConstants.spotCustomer;
  public allSendDisabledText = CareConstants.allSendDisabledText;
  public prioritySendDisabledText = CareConstants.prioritySendDisabledText;
  public ineligibleHeaderText = CareConstants.ineligibleHeaderText;
  public ineligibleBodyText = CareConstants.ineligibleBodyText;
  public ineligibleButtonText = CareConstants.ineligibleButtonText;
  public closedSubtype = CareConstants.closedSubtype;
  public resolvedSubtype = CareConstants.resolvedSubtype;

  constructor(private store: Store<fromRoot.State>, private analytics: AnalyticsService) { }

  public ngOnInit() {
    this.selectedCustomer$ = this.store.select(fromRoot.getCareSelectedCustomer);
    this.note = {
      updatedBy: this.name,
      updatedText: ''
    }
  }

  public optionChanged(subtype, isSend) {
    this.selectedSubtype = subtype;
    this.isSend = isSend;
  }

  public getWarningText() {
    return CareConstants.getResolvedWarningText(this.selectedValue, this.name);
  }

  public startRespondFlow() {
    let responseObj = {
      value: this.selectedValue,
      isSend: this.isSend,
      note: this.note.updatedText
    };
    this.analytics.sendMParticleEvent(
      'Recover Report Status Updated',
      { ...responseObj }
    );
    this.startFlowClicked.emit(responseObj);
  }

  public updateText(updatedText) {
    this.note.updatedText = updatedText.target.value;
  }

  public getEligibleForRecovery(): Observable<boolean> {
    return this.selectedCustomer$.pipe(take(1), map((customer) => {
      // If there is no selected customer then it is the CARES flow and we should show available options
      if (!customer) {
        return true;
      } else {
        return customer.eligibleForRecovery;
      }
    }));
  }
}
