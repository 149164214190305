import { Component, Input } from '@angular/core';

@Component({
  selector: 'statistic',
  template: `
    <div class="statistic">
      <div class="statistic__stat-container" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="statistic__stat" [attr.data-testid]="title + '-stat'">
          <ng-container *ngIf="isInteger">{{stat}}</ng-container>
          <ng-container *ngIf="isPercent">{{percentage | percent:'1.0-1'}}</ng-container>
          <ng-container *ngIf="isImage"><img class="statistic__image" [src]="image"></ng-container>
        </div>
        <img *ngIf="displayNewIcon" class="statistic__new-image" src="../../../assets/img/NewBadge.png">
      </div>
      <div class="statistic__descriptor" [attr.data-testid]="title + '-stat-descriptor'">{{text.descriptor}}</div>
      <div class="statistic__primary" [attr.data-testid]="title + '-stat-primary'">{{text.primary}}</div>
    </div>
  `,
  styleUrls: ['./statistic.component.scss']
})
export class StatisticComponent {
  @Input() public stat: number;
  @Input() public percentage: number;
  @Input() public text: any;
  @Input() public image: string;
  @Input() public title: string;
  @Input() public isInteger: boolean;
  @Input() public isPercent: boolean;
  @Input() public isImage: boolean;
  @Input() public displayNewIcon: boolean;
}
