export class EngageConstants {
  public static get categories() {
    return [{
      title: 'Breakfast',
      imageUrl: 'https://www.cfacdn.com/mobile/apps/fs/offers/spotlight/CFBF_0015A_GR4C_R4.jpg',
      fontColor: '#642f00'
    }, {
      title: 'Entrees',
      imageUrl: 'https://www.cfacdn.com/img/order/menu/Mobile/Entrees/Parent/Classic_Deluxe.png',
      fontColor: '#642f00'
    }, {
      title: 'Sides',
      imageUrl: 'https://www.cfacdn.com/img/order/menu/Mobile/Sides/Menu%20Item/' +
      'Md_Chicken_Soup.png',
      fontColor: '#642f00'
    }, {
      title: 'Desserts',
      imageUrl: 'https://www.cfacdn.com/mobile/apps/fs/offers/spotlight/120348' +
      'CFA_milkshape_group_BB_G7C1_300Resized2%5B2%5D.jpg',
      fontColor: '#642f00'
    }, {
      title: 'Beverages',
      imageUrl: 'https://www.cfacdn.com/img/order/menu/Mobile/Beverages/Parent/lemonade_glass.png',
      fontColor: '#642f00'
    }];
  }

  public static get selectedCustomersColumns(): any[] {
    return [{
      prop: 'firstName',
      title: 'First Name',
      size: 45
    }, {
      prop: 'lastName',
      title: 'Last Name',
      size: 45
    }, {
      type: 'remove',
      preventDeletion: 'preventDeletion',
      size: 10
    }];
  }

  public static get filterDetailColumns(): any[] {
    return [{
      prop: 'firstName',
      title: 'First Name'
    }, {
      prop: 'lastName',
      title: 'Last Name'
    }];
  }

  public static get detailCustomerColumns(): any[] {
    return [{
      prop: 'firstName',
      title: 'First Name',
      size: 40,
    }, {
      prop: 'lastName',
      title: 'Last Name',
      size: 40,
    }, {
      prop: 'offerStatus',
      title: 'Status',
      type: 'Status',
      size: 20
    }];
  }

  public static get automatedCampaignCustomerColumn(): any[] {
    return [{
      prop: 'firstName',
      title: 'First Name',
      size: 30
    }, {
      prop: 'lastName',
      title: 'Last Name',
      size: 30
    }, {
      prop: 'offerStatus',
      title: 'Status',
      type: 'Status',
      size: 15
    }, {
      prop: 'dateRange',
      title: 'Reward Dates',
      type: 'rewardDatesRange',
      size: 25,
      isHideOnMobile: true,
      customizeSortArray: [{
        'order': 'asc',
        'prop': 'startDate'
      }, {
        'order': 'desc',
        'prop': 'firstName'
      }]
    }];
  }

  public static get emailPreviewCustomerColumns(): any[] {
    return [{
      prop: 'firstName',
      title: 'First Name',
    }, {
      prop: 'lastName',
      title: 'Last Name'
    }];
  }

  public static get rsvpCustomerColumns(): any[] {
    return [{
      prop: 'firstName',
      title: 'First Name',
    }, {
      prop: 'lastName',
      title: 'Last Name'
    }, {
      prop: 'adultsAttending',
      title: 'Adults'
    }, {
      prop: 'kidsAttending',
      title: 'Kids'
    }];
  }

  public static get reviewMode(): string {
    return 'review';
  }

  public static get createMode(): string {
    return 'create';
  }

  public static get editMode(): string {
    return 'edit';
  }

  public static get starIcon(): string {
    return 'https://www.cfacdn.com/img/Spotlight/Icons/star%20icon.svg';
  }

  public static get bonusPointSuggestedUseText(): string {
    return 'Customers will receive 50 bonus points added to their accounts when' +
      ' they place a pick up or Chick-fil-A Delivery order using the criteria set below.';
  }

  public static get bonusPointNoteText(): string {
    return 'This incentive is enabled for mobile orders only.' +
      ' It is required to have a minimum of $10 worth of order and can only be'
    + ' redeemed one time per day per location';
  }

  public static get communityCareNoteText(): string {
    return 'please refer to';
  }

  public static get communityCareSummary(): string {
    return 'Community Care Event';
  }

  public static get communityCareSecondarySubText(): string {
    return '(Formerly Digital Spirit Night)';
  }

  public static get cancelledStatus(): string {
    return 'cancelled';
  }

  public static get thirdPartyPromotionTileInfo(): any {
    return {
      descriptionText:{
        secondary: 'Free Menu Item on Third Party Platforms',
        suggestedUseText: 'Activate a free menu item promotion for Customers ordering on Third Party Delivery Platforms'
      },
      displayImageUrl: 'https://www.cfacdn.com/img/Spotlight/Icons/3P_Promotions_Group1.png'
    };
  }

  public static get freeInCartTileInfo(): any {
    return {
      descriptionText:{
        summary: 'Free Menu Item Promotion',
        secondary: 'Free Menu Item Promotion',
        suggestedUseText: 'Activate a free menu item promotion for Customers ordering on the Chick-fil-A app or Web Ordering platform.'
      },
      displayImageUrl: 'https://www.cfacdn.com/img/Spotlight/Other/blue_fry2.png'
    };
  }

  public static get destinationOptions(): any {
    return [{
      name: 'Any',
      id: 'any'
    }, {
      name: 'Carry-out',
      id: 'carryOut'
    }, {
      name: 'Drive-Thru',
      id: 'driveThru'
    }, {
      name: 'Curbside',
      id: 'curbside'
    }, {
      name: 'Dine-In',
      id: 'dineIn'
    }, {
      name: 'Delivery',
      id: 'delivery'
    }];
  }

  public static get timeOfDayOptions(): any {
    return [{
      name: 'Any',
      id: 'any'
    }, {
      name: 'Breakfast (Open - 10:30 AM)',
      id: 'breakfast'
    }, {
      name: 'Lunch (10:30 AM - 2:00 PM)',
      id: 'lunch'
    }, {
      name: 'Afternoon (2:00 PM - 5:00 PM)',
      id: 'afternoon'
    }, {
      name: 'Dinner (5:00 PM - Close)',
      id: 'dinner'
    }, {
      name: 'Custom',
      id: 'custom'
    }];
  }

  public static get repeatOptions(): any {
    return [{
      defaultToggle: true,
      id: 'daily',
      name: 'Repeat Daily',
      subOptions: null
    }, {
      defaultToggle: false,
      id: 'weekly',
      name: 'Repeat Weekly',
      subOptions: [{
        defaultToggle: true,
        id: 'monday',
        name: 'Mon'
      }, {
        defaultToggle: false,
        id: 'tuesday',
        name: 'Tue'
      }, {
        defaultToggle: false,
        id: 'wednesday',
        name: 'Wed'
      }, {
        defaultToggle: false,
        id: 'thursday',
        name: 'Thu'
      }, {
        defaultToggle: false,
        id: 'friday',
        name: 'Fri'
      }, {
        defaultToggle: false,
        id: 'saturday',
        name: 'Sat'
      }]
    }];
  }

  public static get repeatDayOptions(): any {
    return [{
      defaultToggle: true,
      id: 'monday',
      name: 'Mon'
    }, {
      defaultToggle: false,
      id: 'tuesday',
      name: 'Tue'
    }, {
      defaultToggle: false,
      id: 'wednesday',
      name: 'Wed'
    }, {
      defaultToggle: false,
      id: 'thursday',
      name: 'Thu'
    }, {
      defaultToggle: false,
      id: 'friday',
      name: 'Fri'
    }, {
      defaultToggle: false,
      id: 'saturday',
      name: 'Sat'
    }];
  }

  public static get optInOptions(): any[] {
    return [{
      defaultToggle: true,
      id: 'none',
      name: 'No Code Required',
      selected: true
    },{
      defaultToggle: false,
      id: 'organizationCode',
      name: 'Organization Code',
      selected: false
    }];
  }

  public static get discover(): string {
    return 'discoverTreat';
  }

  public static get offer(): string {
    return 'offer';
  }

  public static get email(): string {
    return 'email';
  }

  public static get draft(): string {
    return 'draft';
  }

  public static get event(): string {
    return 'event';
  }

  public static get bonusPoint(): string {
    return 'bonus-point';
  }

  public static get communityCare(): string {
    return 'communityCareEvent';
  }

  public static get thirdPartyPromotion(): string {
    return 'third-party-promotion';
  }

  public static get nationalThirdPartyPromotion(): string {
    return 'nationalThirdPartyPromotion';
  }

  public static get freeItemInCartPromotion(): string {
    return 'free-item-in-cart-promotion';
  }

  public static get rewardCopyHeader(): string {
    return 'Messaging';
  }

  public static get rewardEmailCopyText(): string {
    return 'Email';
  }

  public static get rewardAppCopyText(): string {
    return 'Chick-fil-A App';
  }

  public static get pushCopyText(): string {
    return 'App Push (View Only)';
  }

  public static get pushMessageWarningText(): string {
    return 'App Push Message cannot be edited. Not all customers will receive this push message,' +
      'such as if they already have an active reward, have received a reward recently,' +
      'do not have app push notifications enabled, and/or if reward length is less than 7 days.';
  }

  public static get pushNotificationFlag(): string {
    return 'pushNotification';
  }

  public static get emailCopyHeader(): string {
    return 'Email Message';
  }

  public static get emailCopyText(): string {
    return 'Email Message';
  }

  public static get eventCopyText(): string {
    return 'Event Invitation Message';
  }

  public static get cancelRewardFlag(): string {
    return 'cancelReward';
  }

  public static get noRewardsFoundText(): string {
    return 'No Rewards Found';
  }

  public static get noEmailsFoundText(): string {
    return 'No Emails Found';
  }

  public static get noDraftsFoundText(): string {
    return 'No Drafts Found';
  }

  public static get noEventsFoundText(): string {
    return 'No Events Found';
  }

  public static get rewardPlaceholderText(): string {
    return 'Search Reward Templates';
  }

  public static get emailPlaceholderText(): string {
    return 'Search Email Templates';
  }

  public static get eventPlaceholderText(): string {
    return 'Search Event Templates';
  }

  public static get customerSearchHeaderText(): string {
    return 'Search and select individual customers';
  }

  public static get customerSearchPlaceholder(): string {
    return 'Search';
  }

  public static get groupHelpText(): string {
    return 'Select one or more groups';
  }

  public static get noCustomerSearchResultsText(): string {
    return 'No customers found';
  }

  public static get noSavedFiltersSearchResultsText(): string {
    return 'No saved groups found';
  }

  public static get noDefaultFiltersSearchResultsText(): string {
    return 'No default groups found';
  }

  public static get pageSize(): number {
    return 5;
  }

  public static get filterMobilePageSize(): number {
    return 10;
  }

  public static get filterPageSize(): number {
    return 20;
  }

  public static get allRewardsCategory(): string {
    return 'All Rewards';
  }

  public static get limitedTimeOffer(): string {
    return 'Limited Time Offers';
  }

  public static get emailCategories() {
    return [
      {
        templateDisplay: 'All Emails',
        templateCategory: 'All Emails',
        selected: true
      } , {
        templateDisplay: 'Drafts',
        templateCategory: 'Drafts',
        selected: true
      } , {
        templateDisplay: 'Current Campaign Emails',
        templateCategory: 'Current Campaign',
        selected: false
      } , {
        templateDisplay: 'General Emails',
        templateCategory: 'General',
        selected: false
      } , {
        templateDisplay: 'Catering Emails',
        templateCategory: 'Catering',
        subText: 'Seasonal or general, find catering templates to increase this special line of business',
        selected: false
      } , {
        templateDisplay: 'Mobile Ordering Emails',
        templateCategory: 'Mobile Ordering',
        selected: false
      } , {
        templateDisplay: 'Community Emails',
        templateCategory: 'Community',
        selected: false
      } , {
        templateDisplay: 'Delivery Emails',
        templateCategory: 'Delivery',
        selected: false
      } , {
        templateDisplay: 'Daypart & Food Focused Emails',
        templateCategory: 'Daypart & Food Focused',
        selected: false
      }
    ]
  }

  public static get allEmailsCategory(): string {
    return 'All Emails';
  }

  public static get allEventsCategory(): string {
    return 'All Events';
  }

  public static get draftCategory(): string {
    return 'Drafts';
  }

  public static get eventHtmlId(): string {
    return 'spotlight-dynamic-event-template';
  }

  public static get eventRsvpHtmlId(): string {
    return 'spotlight-alist-event-reminder';
  }

  public static get eventNoRsvpHtmlId(): string {
    return 'spotlight-alist-event-norsvp-reminder';
  }

  public static get disableSendingFlag(): string {
    return 'disableAllSending';
  }

  public static get disableEventsTabFlag(): string {
    return 'disableEventsTab';
  }

  public static get disableEmailsTabFlag(): string {
    return 'disableEmailsTab';
  }

  public static get resendFlag(): string {
    return 'resendReward';
  }

  public static get recruitmentModuleFlag(): string {
    return 'recruitmentModule';
  }

  public static get disableIndividualRowAttribute(): string {
    return 'nonSpotCustomer';
  }

  public static get disableSendingText(): string {
    return 'Reward and email sending have been temporarily disabled due to a critical issue that ' +
      'the team is working diligently to resolve. This message will be removed when the ' +
      'functionality is returned.';
  }

  public static get rewardBannerId(): string {
    return 'engage-treat-banner';
  }

  public static get eventBannerId(): string {
    return 'engage-event-banner';
  }

  public static get emailBannerId(): string {
    return 'engage-email-banner';
  }

  public static get rewardTabIndex(): number {
    return 0;
  }

  public static get eventTabIndex(): number {
    return 1;
  }

  public static get emailTabIndex(): number {
    return 2;
  }

  public static get engageAnalyticsTabIndex(): number {
    return 3;
  }

  public static get customerTabLabel(): string {
    return 'Customers';
  }

  public static get savedGroupsTabLabel(): string {
    return 'My Saved Groups';
  }

  public static get defaultGroupsTabLabel(): string {
    return 'Default Groups';
  }

  public static get infoIconText(): string {
    return 'When scheduling a future Engage campaign to My Saved or Default group,' +
      ' you may select to send the campaign to the group members as of now (static list) or ' +
      'to the group members at scheduled send time (dynamic list).';
  }

  public static get emailCopyProp(): string {
    return 'emailCopy';
  }

  public static get imageUrlProp(): string {
    return 'imageUrl';
  }

  public static get themeProp(): string {
    return 'theme';
  }

  public static get defaultFilterFullListHelpText(): string {
    return 'View full list of Customers in this group under Meet -> Filters or Discover Membership tile';
  }

  public static get savedFilterFullListHelpText(): string {
    return 'View full list of Customers in this group under Meet -> My Saved Groups';
  }

  public static get unsavedChangesTitle(): string {
    return 'You have unsaved changes';
  }

  public static get unsavedChangesText(): string {
    return 'Would you like to apply the changes you\'ve made to this message?';
  }

  public static get unsavedChangesIcon(): string {
    return 'https://www.cfacdn.com/img/Spotlight/Icons/UnsavedChanges_Icon.png';
  }

  public static get unsavedChangesConfirmButton(): string {
    return 'Apply';
  }

  public static get unsavedChangesCancelButton(): string {
    return 'Discard Changes';
  }

  public static get filterCustomerColumns(): any[] {
    return [{
      prop: 'fullName',
      title: 'Full Name',
      mobile: 'title',
      hide: true
    }, {
      prop: 'firstName',
      title: 'First Name'
    }, {
      prop: 'lastName',
      title: 'Last Name'
    }, {
      prop: 'lastTransactionDate',
      title: 'Last Visit Date',
      mobile: 'subtitle',
      type: 'visitDate',
      mobileTitle: 'Last visited '
    }, {
      prop: 'birthDate',
      title: 'Birthday'
    }, {
      prop: 'membership',
      title: 'Member Status',
      type: 'membership',
      mobile: 'membership',
      hide: true
    }];
  }

  public static get pageSizeOptions(): number[] {
    return [20, 40, 60];
  }

  public static get noCustomersMessage(): string {
    return 'There were no customers found for the applied filters.';
  }

  public static get filtersUnsavedChangesText(): string {
    return 'You are about to exit this page without saving your changes, are you sure you\'d like to continue?';
  }

  public static get filtersUnsavedChangesConfirmButton(): string {
    return 'Stay on This Page';
  }

  public static get filtersUnsavedChangesCancelButton(): string {
    return 'Discard Changes';
  }

  public static get selectAllUnsavedChangesTitle(): string {
    return 'Selecting all Spotlight customers will override your current filters';
  }

  public static get selectAllUnsavedChangesText(): string {
    return 'Do you want to continue?';
  }

  public static get selectAllUnsavedChangesConfirmButton(): string {
    return 'Select All';
  }

  public static get selectAllUnsavedChangesCancelButton(): string {
    return 'No, thanks';
  }

  public static get defaultRecruitmentModuleLink(): string {
    return 'https://www.chick-fil-a.com/careers';
  }

  public static get analyticsTreatCategory(): string {
    return 'treat';
  }

  public static get analyticsEventCategory(): string {
    return 'event';
  }

  public static get analyticsEmailCategory(): string {
    return 'email';
  }

  public static get analyticsEngageCategory(): string {
    return 'engage';
  }

  public static get themeWeApologize(): string {
    return 'we-apologize';
  }

  public static get themeSorryForTheSnag(): string {
    return 'sorry-for-the-snag';
  }

  public static get deliveryRewardId() {
    return '6000001';
  }
}
