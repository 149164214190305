<mat-card data-testid="discover-banner-card" class="banner" [ngClass]="{'banner__notification-banner-critical': tile.tileCritical}">
    <div [ngClass]="{'banner__banner-scroll': !isMobile}">
        <div class="banner__notification-banner"
             fxLayout="row"
             fxLayoutAlign="space-between center">
            <div fxLayout="column">
                <span fxFlex class="banner__notification-banner-text" data-testid="discover-banner-text" [innerHTML]="tile.tileText"></span>
                <div *ngIf="tile.buttonText" class="banner__button-container" data-testid="discover-banner-button-container">
                    <button mat-button class="banner__button" data-testid="discover-banner-button" (click)="bannerBtnClick.emit(tile.buttonUrl)">{{tile.buttonText}}</button>
                </div>
            </div>
            <div fxFlexAlign="start" fxFlex="25px">
                <mat-icon class="banner__notification-banner-close" data-testid="discover-banner-close" (click)="dismissBanner.emit()">close</mat-icon>
            </div>
        </div>
    </div>
</mat-card>
