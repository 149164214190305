<error-message *ngIf="(serviceNowCaseError$ | async) !== null"
               [error]="serviceNowCaseError$ | async"
               [page]="'Service Now Report'">
</error-message>
<mat-dialog-content *ngIf="!(isMobile$ | async) && !(serviceNowCaseError$ | async)" class="service-now-report__wrapper">
  <div class="service-now-report__header">
    CARES Report #{{(serviceNowCase$ | async).number}}
    <mat-icon class="service-now-report__close-report" (click)="closeReport()">close</mat-icon>
  </div>
  <div *ngIf="serviceNowCaseLoading$ | async"
       class="service-now-report__loading"
       [cfaLoading]="true">
  </div>
  <div *ngIf="!(serviceNowCaseLoading$ | async)" fxLayout="row" fxLayoutGap="10">
    <div fxFlex="30%">
      <div class="service-now-report__personal-details">
        <div>Customer: {{(serviceNowCase$ | async).name}}</div>
        <div>Phone: {{(serviceNowCase$ | async).phone}}</div>
        <div>Email: {{(serviceNowCase$ | async).email}}</div>
      </div>
      <div class="service-now-report__report-details-container">
        <div class="service-now-report__report-details-header">Contact Instruction</div>
        <div>Contact via {{(serviceNowCase$ | async).contactMethod}}</div>
        <div [ngClass]="{'service-now-report__red-text': (serviceNowCase$ | async).p1}">
            Priority {{(serviceNowCase$ | async).priority}}
        </div>
        <div>{{(serviceNowCase$ | async).contactRequirement}}</div>
        <hr class="service-now-report__divider">
        <div class="service-now-report__report-details-header">Occurred On</div>
        <div>{{(serviceNowCase$ | async).incidentDate}}</div>
        <div class="service-now-report__report-details-header">Contacted On</div>
        <div>{{(serviceNowCase$ | async).contactDate}}</div>
        <div class="service-now-report__report-details-header">Report Routed On</div>
        <div>{{(serviceNowCase$ | async).assignmentDate}}</div>
        <hr class="service-now-report__divider">
        <div class="service-now-report__report-details-header">Customer Contact History</div>
        <div>Prior complaints to any Chick-fil-A: {{(serviceNowCase$ | async).priorContactsToAnyCfaLocation}}</div>
        <div>Prior complaints to this Chick-fil-A:
          <span [ngClass]="{'service-now-report__red-text-clickable': (serviceNowCase$ | async).priorContactsToThisLocation > 0
                  && (selectedReport$ | async).eligibleForSpotEngagement}"
                (click)="goToCustomerProfile()">
              {{(serviceNowCase$ | async).priorContactsToThisLocation}}
              <span *ngIf="(serviceNowCase$ | async).priorContactsToThisLocation > 0
                           && (selectedReport$ | async).eligibleForSpotEngagement">
                - View in Member Profile
              </span>
          </span>
        </div>
        <hr class="service-now-report__divider">
        <div class="service-now-report__report-details-header">Location Information</div>
        <div>Number: {{(serviceNowCase$ | async).locationNumber}}</div>
        <div class="service-now-report__report-details-header">Cares Support Tier</div>
        <div *ngIf="(serviceNowCase$ | async).supportTier">{{(serviceNowCase$ | async).supportTier.name}}</div>
      </div>
    </div>
    <div fxFlex="70%">
      <div class="service-now-report__high-priority-details"
           *ngIf="(serviceNowCase$ | async).p1">
        <div>To: {{(serviceNowCase$ | async).reportTo}}</div>
        <div>CC: {{(serviceNowCase$ | async).reportCC}}</div>
      </div>
      <div class="service-now-report__report-history-container">
        <div class="service-now-report__report-details-header">Action Guidance for Restaurant</div>
        <!--TODO: Add logic to only show red styling if action is overdue-->
        <div class="service-now-report__red-text">{{(serviceNowCase$ | async).restaurantActionGuidance}}</div>
        <hr class="service-now-report__divider">
        <div fxLayout="row" fxLayoutGap="10">
          <div fxFlex="25%">
            <div class="service-now-report__report-details-header">Order Placed Via</div>
            <div>{{(serviceNowCase$ | async).orderMethod}}</div>
          </div>
          <div fxFlex="25%">
            <div class="service-now-report__report-details-header">Order Received Via</div>
            <div>{{(serviceNowCase$ | async).fulfillmentMethod}}</div>
          </div>
          <div fxFlex="25%">
            <div class="service-now-report__report-details-header">Receipt?</div>
            <div *ngIf="(serviceNowCase$ | async).receiptAvailable">YES</div>
            <div *ngIf="!(serviceNowCase$ | async).receiptAvailable">NO</div>
          </div>
          <div fxFlex="25%">
            <div class="service-now-report__report-details-header">Order Number</div>
            <div>{{(serviceNowCase$ | async).orderNumber}}</div>
          </div>
        </div>
        <hr class="service-now-report__divider">
        <div class="service-now-report__report-details-header">Customer Comments</div>
        <div>{{(serviceNowCase$ | async).comments}}</div>
        <hr class="service-now-report__divider">
        <div class="service-now-report__report-details-header">Approved Response Sent To Customer</div>
        <div>{{(serviceNowCase$ | async).responseSentToCustomer}}</div>
        <hr class="service-now-report__divider">
        <div fxLayout="row" fxLayoutGap="10">
          <div fxFlex="45%">
            <div class="service-now-report__report-details-header">Resolution Status</div>
            <div>{{(serviceNowCase$ | async).resolutionStatus}}</div>
            <div *ngIf="(serviceNowCase$ | async).reportReasonUnableToResolve">
              Reasons CARES unable to resolve: {{(serviceNowCase$ | async).reportReasonUnableToResolve}}
            </div>
          </div>
          <div fxFlex="10%">
            <div class="service-now-report__vertical-divider"></div>
          </div>
          <div fxFlex="45%">
            <div class="service-now-report__report-details-header">Care Offer Status</div>
            <div>{{(serviceNowCase$ | async).offerStatus}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!(serviceNowCaseLoading$ | async)" class="service-now-report__button-container">
    <button mat-raised-button
            class="service-now-report__acknowledge-report-button"
            *ngIf="(serviceNowCase$ | async).p1"
            (click)="acknowledgeReport()">
      Acknowledge Report
    </button>
    <button mat-raised-button
            class="service-now-report__reassign-report-button"
            (click)="reassignReport()">
      Reassign Report
    </button>
    <button mat-button
            class="service-now-report__close-button"
            data-testid="action-button"
            (click)="goToActionSelection()">
      Action
    </button>
  </div>
</mat-dialog-content>
<mat-dialog-content *ngIf="(isMobile$ | async) && !(serviceNowCaseError$ | async)" class="service-now-report__mobile-wrapper">
  <div class="service-now-report__header">
    CARES Report #{{(serviceNowCase$ | async).number}}
    <mat-icon class="service-now-report__close-report" (click)="closeReport()">close</mat-icon>
  </div>
  <div *ngIf="serviceNowCaseLoading$ | async"
       class="service-now-report__loading"
       [cfaLoading]="true">
  </div>
  <div *ngIf="!(serviceNowCaseLoading$ | async)" class="service-now-report__mobile-body">
    <div class="service-now-report__personal-details">
      <div>Customer: {{(serviceNowCase$ | async).name}}</div>
      <div>Phone: {{(serviceNowCase$ | async).phone}}</div>
      <div>Email: {{(serviceNowCase$ | async).email}}</div>
      <div *ngIf="(serviceNowCase$ | async).p1">
        <div>To: {{(serviceNowCase$ | async).reportTo}}</div>
        <div>CC: {{(serviceNowCase$ | async).reportCC}}</div>
      </div>
    </div>
    <div class="service-now-report__report-details-container">
      <div class="service-now-report__report-details-header">Contact Instruction</div>
      <div>Contact via {{(serviceNowCase$ | async).contactMethod}}</div>
      <div [ngClass]="{'service-now-report__red-text': (serviceNowCase$ | async).p1}">
        Priority {{(serviceNowCase$ | async).priority}}
      </div>
      <div>{{(serviceNowCase$ | async).contactRequirement}}</div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Occurred On</div>
      <div>{{(serviceNowCase$ | async).incidentDate}}</div>
      <div class="service-now-report__report-details-header">Contacted On</div>
      <div>{{(serviceNowCase$ | async).contactDate}}</div>
      <div class="service-now-report__report-details-header">Report Routed On</div>
      <div>{{(serviceNowCase$ | async).assignmentDate}}</div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Customer Contact History</div>
      <div>Prior complaints to any Chick-fil-A: {{(serviceNowCase$ | async).priorContactsToAnyCfaLocation}}</div>
      <div>Prior complaints to this Chick-fil-A:
        <span [ngClass]="{'service-now-report__red-text-clickable': (serviceNowCase$ | async).priorContactsToThisLocation > 0}"
              (click)="goToCustomerProfile()">
          {{(serviceNowCase$ | async).priorContactsToThisLocation}}
          <span *ngIf="(serviceNowCase$ | async).priorContactsToThisLocation > 0"> - View in Member Profile</span>
        </span>
      </div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Location Information</div>
      <div>Number: {{(serviceNowCase$ | async).locationNumber}}</div>
      <div class="service-now-report__report-details-header">Cares Support Tier</div>
      <div *ngIf="(serviceNowCase$ | async).supportTier">{{(serviceNowCase$ | async).supportTier.name}}</div>
    </div>
    <div class="service-now-report__report-history-container-mobile">
      <div class="service-now-report__report-details-header">Action Guidance for Restaurant</div>
      <!--TODO: Add logic to only show red styling if action is overdue-->
      <div class="service-now-report__red-text">{{(serviceNowCase$ | async).restaurantActionGuidance}}</div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Order Placed Via</div>
      <div>{{(serviceNowCase$ | async).orderMethod}}</div>
      <div class="service-now-report__report-details-header">Order Received Via</div>
      <div>{{(serviceNowCase$ | async).fulfillmentMethod}}</div>
      <div class="service-now-report__report-details-header">Receipt?</div>
      <div *ngIf="(serviceNowCase$ | async).receiptAvailable">YES</div>
      <div *ngIf="!(serviceNowCase$ | async).receiptAvailable">NO</div>
      <div class="service-now-report__report-details-header">Order Number</div>
      <div>{{(serviceNowCase$ | async).orderNumber}}</div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Customer Comments</div>
      <div>{{(serviceNowCase$ | async).comments}}</div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Approved Response Sent To Customer</div>
      <div>{{(serviceNowCase$ | async).responseSentToCustomer}}</div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Resolution Status</div>
      <div>{{(serviceNowCase$ | async).resolutionStatus}}</div>
      <div *ngIf="(serviceNowCase$ | async).reportReasonUnableToResolve">
        Reasons CARES unable to resolve: {{(serviceNowCase$ | async).reportReasonUnableToResolve}}
      </div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Care Offer Status</div>
      <div>{{(serviceNowCase$ | async).offerStatus}}</div>
    </div>
  </div>
  <div *ngIf="!(serviceNowCaseLoading$ | async)" class="service-now-report__button-container-mobile">
    <button mat-raised-button
            class="service-now-report__acknowledge-report-button-mobile"
            *ngIf="(serviceNowCase$ | async).p1"
            (click)="acknowledgeReport()">
      Acknowledge Report
    </button>
    <button mat-raised-button
            class="service-now-report__reassign-report-button-mobile"
            (click)="reassignReport()">
      Reassign Report
    </button>
    <button mat-button
            class="service-now-report__close-button-mobile"
            data-testid="action-button"
            (click)="goToActionSelection()">
      Action
    </button>
  </div>
</mat-dialog-content>
