import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, debounceTime } from 'rxjs/operators';

import { CareService } from '../care/care.service';
import { CareConstants } from '../care/care.constants';
import * as fromRoot from '../reducers';
import * as care from '../actions/care';
import * as user from '../actions/user';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ServiceNowReportComponent } from '../care/service-now-report/service-now-report.component';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { AppConstants } from '../app.constants';

@Injectable()
export class CareEffects {
  public getReports$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(
      user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS,
      care.ActionTypes.ACKNOWLEDGE_REPORT_SUCCESS,
      care.ActionTypes.REASSIGN_REPORT_SUCCESS,
      care.ActionTypes.LOAD_CARES_PAGE,
      care.ActionTypes.PAGE_CHANGE,
      care.ActionTypes.PAGE_SIZE_CHANGE,
      care.ActionTypes.SORT_CHANGE,
      care.ActionTypes.SEARCH_TERM,
      care.ActionTypes.CLEAR_SEARCH_TERM,
      care.ActionTypes.SEARCH_BY,
      care.ActionTypes.CARE_CHANGE_VIEW
    ),
    filter(() => this.router.url.includes('/care')),
    debounceTime(200),
    switchMap(() => this.store.select(fromRoot.getFeatureEnabled(AppConstants.enableCaresOVCC))),
    filter((enableCaresOVCC) => !enableCaresOVCC),
    switchMap(() => this.store.select(fromRoot.getCareReportRequest).pipe(take(1))),
    switchMap((requestObj) => this.cs.getCareReports(requestObj).pipe(
      map((res) => new care.GetCareReportsSuccess(res)),
      catchError((error) => of(new care.GetCareReportsError(error)))
    ))
  )});

  public getReportsForOVCC$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(
      care.ActionTypes.ACKNOWLEDGE_REPORT_SUCCESS,
      care.ActionTypes.CARE_CHANGE_VIEW,
      care.ActionTypes.CLEAR_SEARCH_TERM,
      care.ActionTypes.LOAD_CARES_PAGE,
      care.ActionTypes.PAGE_CHANGE,
      care.ActionTypes.PAGE_SIZE_CHANGE,
      care.ActionTypes.REASSIGN_REPORT_SUCCESS,
      care.ActionTypes.SEARCH_BY,
      care.ActionTypes.SEARCH_TERM,
      care.ActionTypes.SORT_CHANGE,
      user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS
    ),
    filter(() => this.router.url.includes('/care')),
    switchMap(() => this.store.select(fromRoot.getFeatureEnabled(AppConstants.enableCaresOVCC))),
    filter((enableCaresOVCC) => enableCaresOVCC),
    switchMap(() => this.store.select(fromRoot.getCareReportRequest).pipe(take(1))),
    switchMap((requestObj) => this.cs.getCareReportsForOVCC(requestObj).pipe(
      map((res) => new care.GetCareReportsSuccess(res)),
      catchError((error) => of(new care.GetCareReportsError(error)))
    ))
  )});

  public acknowledgeReport$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.ACKNOWLEDGE_REPORT),
    map((action: any) => action.payload),
    switchMap((acknowledgeDetails) => this.cs.acknowledgeReport(acknowledgeDetails).pipe(
      map((res) => new care.AcknowledgeReportSuccess(res)),
      catchError((error) => of(new care.AcknowledgeReportError(error)))
    ))
  )});

  public acknowledgeReportError$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.ACKNOWLEDGE_REPORT_ERROR),
    map((action: any) => action.payload),
    tap((payload) => {
      this.router.navigate(['/care']);
      return this.snackBar.open(
        payload,
        'Dismiss', {
          duration: 5000
        }
      );
    })
  )}, { dispatch : false });

  public acknowledgeReportSuccess$: Observable<any> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.ACKNOWLEDGE_REPORT_SUCCESS),
    map(() => {
      let config = new MatDialogConfig();
      config.data = {
        confirmText: 'Back',
        displayText: CareConstants.reassignReportDialogText,
        hideCancel: true
      };
      this.dialog.open(ConfirmationDialogComponent, config).afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.router.navigate(['/care']);
        }
      });
    })
  )}, { dispatch : false });

  public reassignReport$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.REASSIGN_REPORT),
    switchMap(() => this.store.select(fromRoot.getSelectedCareReport).pipe(take(1))),
    filter((report) => !report.reportUrl),
    map((action: any) => action.payload),
    switchMap((reassignDetails) => this.cs.reassignReport(reassignDetails).pipe(
      map((res) => new care.ReassignReportSuccess(res)),
      catchError((error) => of(new care.ReassignReportError(error)))
    ))
  )});

  public reassignReportForServiceCheck$: Observable<any> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.REASSIGN_REPORT),
    switchMap(() => this.store.select(fromRoot.getSelectedCareReport).pipe(take(1))),
    filter((report) => report && report.reportUrl !== null),
    map((report) => {
      window.open(report.reportUrl, '_blank');
    }),
    map((res) => new care.ReassignReportSuccess(res))
  )}, { dispatch : false });

  public reassignReportError$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.REASSIGN_REPORT_ERROR),
    map((action: any) => action.payload),
    tap((payload) => {
      this.router.navigate(['/care']);
      return this.snackBar.open(
        payload,
        'Dismiss', {
          duration: 5000
        }
      );
    })
  )}, { dispatch : false });

  public reassignReportSuccess$: Observable<any> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.REASSIGN_REPORT_SUCCESS),
    map(() => {
      let config = new MatDialogConfig();
      config.data = {
        confirmText: 'Back',
        displayText: CareConstants.reassignReportDialogText,
        hideCancel: true
      };
      config.panelClass = 'dialog-responsive'
      this.dialog.open(ConfirmationDialogComponent, config).afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.router.navigate(['/care']);
        }
      });
    })
  )}, { dispatch : false });

  public getReportDetails$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(
      care.ActionTypes.GET_CARE_REPORT_DETAILS,
      care.ActionTypes.UPDATE_STATUS_SUCCESS,
      care.ActionTypes.REOPEN_REPORT_SUCCESS
    ),
    map((action: any) => action.payload),
    switchMap((id) => this.cs.getReportDetails(id).pipe(
      map((res) => res != null ?
        new care.GetCareReportDetailsSuccess(res) :
        new care.GetCareReportDetailsError('There was an error retrieving care tickets details. Please try again later.')),
      catchError((error) => of(new care.GetCareReportDetailsError(error)))
    ))
  )});

  public getReportActivityHistory$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(
      care.ActionTypes.SELECT_REPORT,
      care.ActionTypes.UPDATE_STATUS_SUCCESS,
      care.ActionTypes.GET_CARE_REPORT_DETAILS_SUCCESS,
      care.ActionTypes.REOPEN_REPORT_SUCCESS
    ),
    switchMap(() => this.store.select(fromRoot.getSelectedCareReport).pipe(take(1))),
    switchMap((report) => this.cs.getReportActivityHistory(report).pipe(
      map((res) => new care.GetActivityHistorySuccess(res)),
      catchError((error) => of(new care.GetActivityHistoryError(error)))
    ))
  )});

  public getCareEmailDetails$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.GET_CARE_EMAIL_DETAILS),
    map((action: any) => action.payload),
    switchMap((id) => this.cs.getCareEmailDetails(id).pipe(
      map((res) => new care.GetCareEmailDetailsSuccess(res)),
      catchError((error) => of(new care.GetCareEmailDetailsError(error)))
    ))
  )});

  public getTemplate$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.SELECT_TEMPLATE),
    map((action: any) => action.payload),
    filter((payload) => payload.memberType !== CareConstants.spotCustomer),
    switchMap(() => this.store.select(fromRoot.getCareTemplateRequest).pipe(take(1))),
    switchMap((request) => this.cs.getCareTemplate(request).pipe(
      map((res) => new care.FetchTemplateSuccess(res)),
      catchError((error) => of(new care.FetchTemplateError(error)))
    ))
  )});

  public getSpotMemberTemplate$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.SELECT_TEMPLATE),
    map((action: any) => action.payload),
    filter((payload) => payload.memberType === CareConstants.spotCustomer),
    switchMap(() => this.store.select(fromRoot.getSpotCustomerCareTemplateRequest).pipe(take(1))),
    switchMap((type) => this.cs.getSpotCustomerCareTemplate(type).pipe(
      map((res) => new care.FetchTemplateSuccess(res)),
      catchError((error) => of(new care.FetchTemplateError(error)))
    ))
  )});

  public setIncidentStatus$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.UPDATE_STATUS),
    map((action: any) => action.payload),
    switchMap((request) => this.cs.updateIncident(request).pipe(
      map((res) => new care.UpdateStatusSuccess(res.incidentId)),
      catchError((error) => of(new care.UpdateStatusError(error)))
    ))
  )});

  public sendResponse$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.SEND_RESPONSE),
    switchMap(() => this.store.select(fromRoot.getMemberType).pipe(take(1))),
    filter((memberType) => memberType !== CareConstants.spotCustomer),
    switchMap(() => this.store.select(fromRoot.getCareRespondPayload).pipe(take(1))),
    switchMap((payload) => this.cs.sendCareResponse(payload).pipe(
      map((incidentId) => {
        return new care.SendResponseSuccess({
          id: incidentId,
          message: CareConstants.sendSuccessMessage
        });
      }),
      catchError((incidentId) => {
        return of(new care.SendResponseError({
          incidentId,
          message: CareConstants.sendErrorMessage
        }));
      })
    ))
  )});

  public sendSpotResponse$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.SEND_RESPONSE),
    switchMap(() => this.store.select(fromRoot.getMemberType).pipe(take(1))),
    filter((memberType) => memberType === CareConstants.spotCustomer),
    switchMap(() => this.store.select(fromRoot.getCareSpotRespondPayload).pipe(take(1))),
    switchMap((payload) => this.cs.sendSpotCareResponse(payload).pipe(
      map((response) => {
        return new care.SendResponseSuccess({
          ...response,
          message: CareConstants.sendSuccessMessage
        });
      }),
      catchError((response) => {
        return of(new care.SendResponseError({
          ...response,
          message: CareConstants.sendErrorMessage
        }));
      })
    ))
  )});

  public reopenReport$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.REOPEN_REPORT),
    switchMap(() => this.store.select(fromRoot.getSelectedCareReport).pipe(take(1))),
    switchMap((report) => this.cs.reopenReport(report).pipe(
      map(() => new care.ReopenReportSuccess(report.id)),
      catchError((error) => of(new care.ReopenReportError(error)))
    ))
  )});

  public showCreateToast$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.SEND_RESPONSE_SUCCESS),
    map((action: any) => action.payload),
    tap((payload) => {
      const { activeSpotlightCustomer, id, message, memberType, customerFirstName } = payload;

      // This checks if it is a CFA One contact send vs a CARES report, not necessarily if they are in the Spotlight
      if (memberType === CareConstants.spotCustomer) {
        // This checks if they are actually are a Spotlight customer at this location
        if (activeSpotlightCustomer) {
          this.router.navigate(
            ['/customer'],
            { queryParams: { id, previousUrl: '/care' } }
          );
        } else {
          this.router.navigate(
            ['/care'],
            { queryParams: { index: CareConstants.closedChickFilAOneContactsTabIndex, searchTerm: customerFirstName } }
          );
        }
        return this.snackBar.open(message, 'Dismiss', { duration: 5000 });
      } else {
        this.router.navigate(['/care/details'], { queryParams: { incidentId: id } });
        return this.snackBar.open(message, 'Dismiss', { duration: 5000 });
      }
    })
  )});

  public showCreateFailToast$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.SEND_RESPONSE_ERROR),
    map((action: any) => action.payload),
    tap((payload) => {
      const { message } = payload;
      this.router.navigate(['/care']);
      return this.snackBar.open(
        message,
        'Dismiss', {
          duration: 5000
        }
      );
    })
  )}, { dispatch : false });

  public showReopenSuccessToast$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.REOPEN_REPORT_SUCCESS),
    switchMap(() => this.store.select(fromRoot.getSelectedCareReportFullName).pipe(take(1))),
    tap((name) => {
      return this.snackBar.open(
        `CARES Contact for ${name} successfully reopened and moved to Spotlight Recover tab.`,
        'Dismiss', {
          duration: 5000
        }
      );
    })
  )}, { dispatch : false });

  public showReopenErrorToast$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.REOPEN_REPORT_ERROR),
    switchMap(() => this.store.select(fromRoot.getSelectedCareReportFullName).pipe(take(1))),
    tap((name) => {
      return this.snackBar.open(
        `CARES Contact for ${name} failed to be reopened.`,
        'Dismiss', {
          duration: 5000
        }
      );
    })
  )}, { dispatch : false });

  public openServiceNowReport$: Observable<any> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.OPEN_SERVICE_NOW_REPORT),
    switchMap((report: any) => this.cs.getCaseDetails(report.payload).pipe(
      map((res) => {
        const config: MatDialogConfig = { maxWidth: '1000px' };
        this.dialog.open(ServiceNowReportComponent, config);
        return new care.OpenServiceNowReportSuccess((res))
      }),
      catchError((error) => of(new care.OpenServiceNowReportError(error)))
    ))
  )});

  public findSpotlightContactsDebounce$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(
      care.ActionTypes.SPOTLIGHT_CONTACTS_SEARCH,
      care.ActionTypes.SPOTLIGHT_CONTACTS_CHANGE_PAGE_SIZE,
      care.ActionTypes.SPOTLIGHT_CONTACTS_CHANGE_PAGE,
      care.ActionTypes.SPOTLIGHT_CONTACTS_CHANGE_SORT,
      care.ActionTypes.CFA_ONE_CHANGE_VIEW
    ),
    debounceTime(200),
    switchMap(() => this.store.select(fromRoot.getSpotlightContactsRequest).pipe(take(1))),
    switchMap((payload) => this.cs.getCustomersHttp(payload).pipe(
      map((res) => new care.SpotlightContactsSearchComplete((res))),
      catchError((error) => of(new care.SpotlightContactsSearchError(error)))
    ))
  )});

  public eligibleForRecovery$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.SELECT_CUSTOMER),
    switchMap(() => this.store.select(fromRoot.getCareSelectedCustomer).pipe(take(1))),
    switchMap((payload) => this.cs.getEligibleForRecovery(payload).pipe(
      map((res) => new care.GetEligibleForRecoverySuccess(res)),
      catchError((error) => of(new care.GetEligibleForRecoveryError(error)))
    ))
  )});

  public getCustomerNotes$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(
      care.ActionTypes.SELECT_REPORT,
      care.ActionTypes.UPDATE_STATUS_SUCCESS,
      care.ActionTypes.GET_CARE_REPORT_DETAILS_SUCCESS,
      care.ActionTypes.REOPEN_REPORT_SUCCESS,
      care.ActionTypes.CUSTOMER_NOTE_ACTION_COMPLETE,
      care.ActionTypes.DELETE_CUSTOMER_NOTE_SUCCESS
    ),
    switchMap(() => this.store.select(fromRoot.getSelectedCareReport).pipe(take(1))),
    switchMap((id) => this.cs.getCustomerInformation(id).pipe(
      map((res) => new care.GetCustomerNotesSuccess(res)),
      catchError((error) => of(new care.GetCustomerNotesError(error)))
    ))
  )});

  public createCustomerNote: Observable<any> = createEffect(() => { return this.actions$.pipe(
    ofType(
      care.ActionTypes.CREATE_CUSTOMER_NOTE
    ),
    switchMap((action: any) =>
      of(action).pipe(
        concatLatestFrom(() =>
          this.store.select(fromRoot.getSelectedCareReport).pipe(take(1))
        ),
        switchMap(([action, report]) => {
          const noteData = action.payload;
          return this.cs.createCustomerNote(noteData, report.id).pipe(
            map((res) => new care.CustomerNoteActionComplete({ noteId: res.noteId })),
            catchError((err) => of(new care.CustomerNoteError({ err })))
          );
        })
      )
    )
  )});

  public updateCustomerNote: Observable<any> = createEffect(() => { return this.actions$.pipe(
    ofType(
      care.ActionTypes.UPDATE_CUSTOMER_NOTE
    ),
    switchMap((action: any) =>
      of(action).pipe(
        concatLatestFrom(() =>
          this.store.select(fromRoot.getSelectedCareReport).pipe(take(1))
        ),
        switchMap(([action, report]) => {
          let noteData = action.payload;
          return this.cs.updateCustomerNote(noteData, report.id).pipe(
            map((res) => new care.CustomerNoteActionComplete({ noteId: noteData.noteId })),
            catchError((err) => of(new care.CustomerNoteError({ err, noteId: noteData.noteId })))
          );
        })
      )
    )
  )});

  public deleteCustomerNote: Observable<any> = createEffect(() => { return this.actions$.pipe(
    ofType(
      care.ActionTypes.DELETE_CUSTOMER_NOTE
    ),
    map((action: any) => action.payload),
    switchMap((noteData) => {
      return this.cs.deleteCustomerNote(noteData).pipe(
        map((res) => new care.DeleteCustomerNoteSuccess({ noteId: noteData.noteId })),
        catchError((err) => of(new care.ErrorAction({ err, noteId: noteData.noteId })))
      );
    })
  )});

  public getClosedCareContacts$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(
      user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS,
      care.ActionTypes.LOAD_CARES_PAGE,
      care.ActionTypes.CLOSED_CARE_CONTACTS_CHANGE_PAGE_SIZE,
      care.ActionTypes.CLOSED_CARE_CONTACTS_CHANGE_PAGE,
      care.ActionTypes.CLOSED_CARE_CONTACTS_CHANGE_SORT,
      care.ActionTypes.CLOSED_CARE_CONTACTS_CLEAR_SEARCH_TERM,
      care.ActionTypes.CLOSED_CARE_CONTACTS_SEARCH,
      care.ActionTypes.CLOSED_CARE_CONTACTS_SEARCH_BY,
      care.ActionTypes.CLOSED_CARE_CHANGE_VIEW
    ),
    filter(() => this.router.url.includes('/care')),
    debounceTime(200),
    switchMap(() => this.store.select(fromRoot.getFeatureEnabled(AppConstants.enableCaresOVCC))),
    filter((enableCaresOVCC) => !enableCaresOVCC),
    switchMap(() => this.store.select(fromRoot.getClosedCareContactsRequest).pipe(take(1))),
    switchMap((requestObj) => this.cs.getClosedCareContacts(requestObj).pipe(
      map((res) => new care.GetClosedCareContactsSuccess(res)),
      catchError((error) => of(new care.GetClosedCareContactsError(error)))
    ))
  )});

  public getClosedCareContactsForOVCC$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(
      user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS,
      care.ActionTypes.LOAD_CARES_PAGE,
      care.ActionTypes.CLOSED_CARE_CONTACTS_CHANGE_PAGE_SIZE,
      care.ActionTypes.CLOSED_CARE_CONTACTS_CHANGE_PAGE,
      care.ActionTypes.CLOSED_CARE_CONTACTS_CHANGE_SORT,
      care.ActionTypes.CLOSED_CARE_CONTACTS_CLEAR_SEARCH_TERM,
      care.ActionTypes.CLOSED_CARE_CONTACTS_SEARCH,
      care.ActionTypes.CLOSED_CARE_CONTACTS_SEARCH_BY,
      care.ActionTypes.CLOSED_CARE_CHANGE_VIEW
    ),
    filter(() => this.router.url.includes('/care')),
    switchMap(() => this.store.select(fromRoot.getFeatureEnabled(AppConstants.enableCaresOVCC))),
    filter((enableCaresOVCC) => enableCaresOVCC),
    switchMap(() => this.store.select(fromRoot.getClosedCareContactsRequest).pipe(take(1))),
    switchMap((requestObj) => this.cs.getClosedCareContactsForOVCC(requestObj).pipe(
      map((res) => new care.GetClosedCareContactsSuccess(res)),
      catchError((error) => of(new care.GetClosedCareContactsError(error)))
    ))
  )});

  public getRecoveredCfaOneContacts$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(
      care.ActionTypes.LOAD_RECOVERED_CFA_ONE_CONTACTS_DATA
    ),
    map((action: any) => action.payload),
    filter((payload) => payload && this.router.url.includes('/care')),
    switchMap(() => this.store.select(fromRoot.getRecoveredCfaOneContactsRequest).pipe(take(1))),
    switchMap((requestObj) => this.cs.getRecoveredCfaOneContacts(requestObj).pipe(
      map((res) => new care.GetRecoveredCfaOneContactsSuccess(res)),
      catchError((error) => of(new care.GetRecoveredCfaOneContactsError(error)))
    ))
  )});

  public getRecoveredCfaOneContactsOnAction$: Observable<Action> = createEffect(() => { return this.actions$.pipe(
    ofType(
      care.ActionTypes.RECOVERED_CFA_ONE_CONTACTS_CHANGE_PAGE_SIZE,
      care.ActionTypes.RECOVERED_CFA_ONE_CONTACTS_CHANGE_PAGE,
      care.ActionTypes.RECOVERED_CFA_ONE_CONTACTS_CHANGE_SORT,
      care.ActionTypes.RECOVERED_CFA_ONE_CONTACTS_CLEAR_SEARCH_TERM,
      care.ActionTypes.RECOVERED_CFA_ONE_CONTACTS_SEARCH,
      care.ActionTypes.RECOVERED_CFA_ONE_CONTACTS_SEARCH_BY,
      care.ActionTypes.RECOVERED_CFA_ONE_CHANGE_VIEW
    ),
    filter(() => this.router.url.includes('/care')),
    debounceTime(200),
    switchMap(() => this.store.select(fromRoot.getRecoveredCfaOneContactsRequest).pipe(take(1))),
    switchMap((requestObj) => this.cs.getRecoveredCfaOneContacts(requestObj).pipe(
      map((res) => new care.GetRecoveredCfaOneContactsSuccess(res)),
      catchError((error) => of(new care.GetRecoveredCfaOneContactsError(error)))
    ))
  )});

  public createActionHistoryNote: Observable<any> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.CREATE_ACTION_HISTORY_NOTE),
    switchMap((action: any) =>
      of(action).pipe(
        concatLatestFrom(() => this.store.select(fromRoot.getSelectedCareReport).pipe(take(1))),
        switchMap(([action]) => {
          let noteData = action.payload;
          return this.cs.createActionHistoryNote(noteData).pipe(
            map((res) => new care.CreateActionHistoryNoteSuccess(res)),
            catchError((err) => of(new care.CreateActionHistoryNoteError({ err })))
          );
        })
      )
    )
  )});

  public updateActionHistoryNote: Observable<any> = createEffect(() => { return this.actions$.pipe(
    ofType(care.ActionTypes.UPDATE_ACTION_HISTORY_NOTE),
    switchMap((action: any) =>
      of(action).pipe(
        concatLatestFrom(() => this.store.select(fromRoot.getSelectedCareReport).pipe(take(1))),
        switchMap(([action]) => {
          const noteData = action.payload;
          return this.cs.updateActionHistoryNote(noteData).pipe(
            map((res) => new care.UpdateActionHistoryNoteSuccess(res)),
            catchError((err) => of(new care.UpdateActionHistoryNoteError({ err, noteId: noteData.noteId })))
          );
        })
      )
    )
  )});

  constructor(
    private actions$: Actions,
    private cs: CareService,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store<fromRoot.State>
  ) { }
}
